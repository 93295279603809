import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";

const Pagination = ({
  onPageChange,
  totalRecords,
  customRows,
  customFirst,
}) => {
  const paginationTemplate = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 25, value: 25 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <Paginator
      template={paginationTemplate}
      first={customFirst}
      rows={customRows}
      totalRecords={totalRecords}
      onPageChange={onPageChange}
      className="justify-content-end my-3"
    ></Paginator>
  );
};

export default Pagination;
