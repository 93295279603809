import React, { useState } from "react";
import { Spaces } from "../../fakeService/spaces";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Table from "../../components/dataTable";
import Sidebar from "../../components/sidebar";

const ClaimSubQuestionsConfigration = () => {
  const [claimSubQuestions, setClaimSubQuestions] = useState(Spaces);

  const DATA = [
    { id: 1, field: "id", header: "Id" },
    { id: 2, field: "title", header: "Title" },
    { id: 3, field: "description", header: "Description" },
    { id: 4, field: "createdOn", header: "Created On" },
    { id: 5, field: "createdBy", header: "Created By" },
    { id: 6, field: "modifiedOn", header: "Modified On" },
    { id: 7, field: "modifiedBy", header: "Modified By" },
  ];

  return (
    <div className="app-main">
      <Sidebar active="claimSubQuestions" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="Claim Sub Questions Configration - Staywo"
            icon="pe-7s-config icon-gradient bg-mean-fruit"
          />
          <div className="row">
            <div className="col-lg-12">
              <Card title="Claim Sub Questions">
                <Table columns={DATA} data={claimSubQuestions} />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimSubQuestionsConfigration;
