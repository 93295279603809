import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

//House rule group
export const getAllHouseRuleGroup = () => {
  return Api(`${endPoints.houseRuleGroup}`, null, requestType.GET, null, null);
};

export const addHouseRuleGroup = (params) => {
  return Api(
    `${endPoints.addhouseRuleGroup}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editHouseRuleGroup = (id, params) => {
  return Api(
    `${endPoints.edithouseRuleGroup}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteHouseRuleGroup = (id) => {
  return Api(
    `${endPoints.deletehouseRuleGroup}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};

//House rule

export const getAllHouseRule = () => {
  return Api(`${endPoints.getAllHouseRule}`, null, requestType.GET, null, null);
};

export const addHouseRule = (params) => {
  return Api(`${endPoints.addHouseRule}`, params, requestType.POST, null, null);
};

export const editHouseRule = (id, params) => {
  return Api(
    `${endPoints.editHouseRule}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};

export const deleteHouseRule = (id) => {
  return Api(
    `${endPoints.deleteHouseRule}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
