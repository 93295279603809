import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import Pagination from "./../../components/pagination";

const CohostDetails = ({
  getCoHosts,
  totalRecordsProperty,
  coHostDetail,
  coHostLoading,
}) => {
  const [rowsProperties, setRowsProperties] = useState(10);
  const [firstProperty, setFirstProperty] = useState(0);

  const onChangeProperties = (event) => {
    setFirstProperty(event.first);
    setRowsProperties(event.rows);
    // getCoHosts(userDetail.id, event.page, event.rows);
  };

  return (
    <>
      {coHostLoading ? (
        <div style={{ textAlign: "center" }}>
          <ProgressSpinner />
        </div>
      ) : (
        <>
          {coHostDetail.length > 0 && (
            <h4 style={{ marginTop: "15px" }}>Cohosts</h4>
          )}
          <div className="row">
            {coHostDetail.length > 0 ? (
              coHostDetail.map((item) => {
                return (
                  <div className="col-sm-4">
                    <div
                      style={{
                        background: "#fff",
                        padding: "24px",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Email: </h6>
                        <span className="address-style">{item.email}</span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Invitation Code: </h6>
                        <span className="address-style">
                          {item.invitationCode}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-sm-4">
                <h3>No Record Found</h3>
              </div>
            )}
          </div>
        </>
      )}

      {/* <Pagination
        totalRecords={totalRecordsProperty}
        customFirst={firstProperty}
        customRows={rowsProperties}
        onPageChange={(e) => onChangeProperties(e)}
      /> */}
    </>
  );
};

export default CohostDetails;
