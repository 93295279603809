import React from "react";
import { Dropdown } from "primereact/dropdown";

const DropdownField = ({
  options,
  optionLabel,
  placeholder,
  title,
  value,
  onChange,
}) => {
  return (
    <div className="field">
      <label htmlFor="username1" className="block">
        {title}
      </label>
      <Dropdown
        options={options}
        optionLabel={optionLabel}
        placeholder={placeholder}
        style={{ width: "100%" }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

// onChange={onCityChange}

export default DropdownField;
