import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { updatePropStatus } from '../../services/properties';
import { Toast } from 'primereact/toast';
import { statusArr } from '../../constants/staticData';

const PropertyStatusDialog = React.forwardRef(
  ({ getData, customRows }, ref) => {
    const toast = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const [dialogData, setDialogData] = useState(null);
    const [inputVal, setInputVal] = useState({
      status: '',
      description: '',
    });

    useImperativeHandle(ref, () => ({
      isVisible(params) {
        setDialogData(params);
        setOpenStatusDialog(true);
      },
    }));

    useEffect(() => {
      setInputVal({
        status: dialogData?.status,
      });
    }, [dialogData]);

    const handleChange = (e) => {
      setInputVal({ ...inputVal, [e.target.name]: e.value });
    };
    const handleChangeText = (e) => {
      setInputVal({ ...inputVal, description: e.target.value });
    };

    const hideDialog = () => {
      setOpenStatusDialog(false);
    };

    const handleChangeStatus = () => {
      let payload = {
        status: inputVal.status,
        reason: inputVal.description,
        property_id: parseInt(dialogData.id),
        userId: dialogData.userId,
      };

      if (inputVal.description == '') {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Reason is required',
          life: 3000,
        });
      } else {
        setIsLoading(true);

        updatePropStatus(payload).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: 'error',
                summary: 'Error',
                detail: val,
                life: 3000,
              });
            });
            toast.current.show(tempArr);
          } else {
            toast.current.show({
              severity: 'success',
              summary: 'Successful',
              detail: 'Status Updated Successfully',
              life: 3000,
            });
            setInputVal({
              status: '',
              description: '',
            });
            getData(
              0,
              customRows,
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              ''
            );
            setTimeout(() => {
              setOpenStatusDialog(false);
            }, 1500);
          }
        });
      }
    };

    const productDialogFooter = (
      <React.Fragment>
        <Button
          label='Cancel'
          icon='pi pi-times'
          className='p-button-text'
          onClick={hideDialog}
        />
        {!isLoading ? (
          <Button
            label='Save'
            icon='pi pi-check'
            className='p-button-text'
            onClick={handleChangeStatus}
          />
        ) : null}
      </React.Fragment>
    );

    return (
      <Dialog
        visible={openStatusDialog}
        style={{ width: '450px' }}
        header='Change Property Status'
        modal
        className='p-fluid'
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <Toast ref={toast} position='bottom-right' />
        <div>
          <Dropdown
            value={inputVal.status}
            options={statusArr}
            onChange={handleChange}
            name='status'
            optionLabel='value'
            placeholder='Select Status'
          />

          <InputTextarea
            style={{ marginTop: '20px' }}
            value={inputVal.description}
            onChange={handleChangeText}
            name='description'
            placeholder='Reason'
            rows={5}
            cols={30}
          />
        </div>
      </Dialog>
    );
  }
);

export default PropertyStatusDialog;
