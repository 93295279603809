import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const addVerifyUser = (params, token) => {
  return Api(
    `${endPoints.addVerifyUser}`,
    params,
    requestType.POST,
    true,
    token
  );
};

export const getVerifyUsersByLimit = (order, page, take, token) => {
  return Api(
    `${endPoints.allUsersVerify}?order=${order}&page=${page}&take=${take}`,
    null,
    requestType.GET,
    null,
    token
  );
};

export const updateUserVerifyStatus = (id, params, token) => {
  return Api(
    `${endPoints.userVerifyStatus}/${id}`,
    params,
    requestType.PUT,
    null,
    token
  );
};

export const deleteVerifyUserById = (id, token) => {
  return Api(
    `${endPoints.deleteVerifyUser}/${id}`,
    null,
    requestType.DELETE,
    null,
    token
  );
};
