

export const requestType = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export const apiHeaders = {
  contentType: 'Content-Type',
  application_json: 'application/json',
  multipart_data: 'multipart/form-data',
  language: 'LANG',
  authorization: 'Authorization',
  dlc: 'dlc',
};

export const endPoints = {
  allUsers: 'users',
  usersByLimit: 'users/pagination',
  addUser: 'users/add',
  deleteUserById: 'users',
  allUsers: 'users/pagination',
  allUsersNoFilter: 'users',
  alluserDropDown:'users/usersForDropdown',
  userStatusUpdateAdmin:"users/updateHostStatusByAdmin",
  userByEmail: 'users/email', 
  userById: 'users/id',
  updateUserById: 'users',
  updateuserData:"users/update",
  updateProfile: 'users/updateprofilepicture',
  updateUserStatus: 'users/user-status',
  updateHostStatus: 'users/updateHostStatus',
  allProperties: 'property/all',
  propertyInfos: 'property/basics-infos',
  propertyByLimit: 'property/pagination',
  propertyByid: 'property',
  updateProperty: 'property/update',
  createPropertyAmenity: 'property-amenities/create',
  updatePropertyListingBasic: 'property/update/listing-basic',
  updatePropertyPricePolicy: 'property_price_policy/update',
  propertySafetyConsideration: 'property/safety-consideration',
  createPropertyPricePolicy: 'property_price_policy',
  updatePolicyProperty: 'property-policy/update/policy',
  cancellationPolicy: 'cancellation-policy',
  propertyAccessibilty: 'property/accessibility',
  propertyFacility: 'property/facility',
  propertySecurity: 'property/security-feature',
  propertyNearbyActivity: 'property/nearby-activity',
  propertySafetyFeature: 'property/safety-feature',
  propertyPriceAll: 'property-price/all',
  propertyPriceUpdateByRange: 'property-price/updateByRange',
  propertyPriceById: 'property-price/byPropertyId',
  updateGuestInfo: 'property/update/info-for-guest',
  createPropertySafetyConsideration: 'property-safety-consideration/create',
  updateAdditionalCharges: 'property-additional-charges/update',
  updatePropertyPhotoAndVideo: 'property/update/photosAndVideoUrl',
  ratingByGuest: 'rating/by-guest',
  updatePropertyStatus: 'property/update/listing-basic',
  groupAmenities: 'amenity-group/all',
  addAmenityGroup: 'amenity-group',
  editAmenityGroup: 'amenity-group/update',
  deleteAmenityGroup: 'amenity-group/delete',
  nearbyActivityGroup: 'nearby-activity-group/all',
  addNearbyActivityGroup: 'nearby-activity-group',
  editNearbyActivityGroup: 'nearby-activity-group/update',
  deleteNearbyActivityGroup: 'nearby-activity-group/delete',
  getAllAmenities: 'amenity/all',
  getAllAmenitiesOfProperty: 'property/amenities',
  addAmenity: 'amenity',
  editAmenity: 'amenity/update',
  deleteAmenity: 'amenity/delete',
  getAllNearbyActivity: 'nearby-activity/all',
  addNearbyActivity: 'nearby-activity',
  editNearbyActivity: 'nearby-activity/update',
  deleteNearbyActivity: 'nearby-activity/delete',
  allUsersVerify: 'user-verify/all',
  addVerifyUser: 'user-verify',
  deleteVerifyUser: 'user-verify/delete',
  getAllFacilities: 'facility/all',
  addFacility: 'facility',
  editFacility: 'facility/update',
  deleteFacility: 'facility/delete',

  addArea: 'area',
  allAreas: 'area/all',
  editArea: 'area/update',
  deleteArea: 'area/delete',
  addSpace: 'space',
  editSpace: 'space/update',
  deleteSpace: 'space/delete',
  allSpaces: 'space/all',

  addSpaceGroup: 'space-type',
  editSpaceGroup: 'space-type/update',
  deleteSpaceGroup: 'space-type/delete',
  allSpaceGroups: 'space-type/all',
  spaceTypeById: 'space-type',
  facilityGroup: 'facility-group/all',
  addfacilityGroup: 'facility-group',
  editfacilityGroup: 'facility-group/update',
  deletefacilityGroup: 'facility-group/delete',
  safetyFeaturegroup: 'safety-feature-group/all',
  addSafetyFeaturegroup: 'safety-feature-group',
  editSafetyFeaturegroup: 'safety-feature-group/update',
  deleteSafetyFeaturegroup: 'safety-feature-group/delete',
  getAllSafetyFeatures: 'safety-feature/all',
  addSafetyFeatures: 'safety-feature',
  editSafetyFeatures: 'safety-feature/update',
  deleteSafetyFeatures: 'safety-feature/delete',
  houseRuleGroup: 'house-rule-group/all',
  addhouseRuleGroup: 'house-rule-group',
  edithouseRuleGroup: 'house-rule-group/update',
  deletehouseRuleGroup: 'house-rule-group/delete',
  getAllHouseRule: 'house-rule/all',
  addHouseRule: 'house-rule',
  editHouseRule: 'house-rule/update',
  deleteHouseRule: 'house-rule/delete',

  addAccessibility: 'accessibility',
  editAccessibility: 'accessibility/update',
  deleteAccessibility: 'accessibility/delete',
  allAccessibility: 'accessibility/all',

  addAccessibilityGroup: 'accessibility-group',
  editAccessibilityGroup: 'accessibility-group/update',
  deleteAccessibilityGroup: 'accessibility-group/delete',
  allAccessibilityGroup: 'accessibility-group/all',

  addSafetyConsideration: 'safety-consideration',
  editSafetyConsideration: 'safety-consideration/update',
  deleteSafetyConsideration: 'safety-consideration/delete',
  allSafetyConsiderations: 'safety-consideration/all',

  addSafetyConsiderationGroup: 'safety-consideration-group',
  editSafetyConsiderationGroup: 'safety-consideration-group/update',
  deleteSafetyConsiderationGroup: 'safety-consideration-group/delete',
  allSafetyConsiderationsGroup: 'safety-consideration-group/all',

  addSecurityGroup: 'security-feature-group',
  editSecurityGroup: 'security-feature-group/update',
  deleteSecurityGroup: 'security-feature-group/delete',
  allSecuritiesGroup: 'security-feature-group/all',

  addSecurity: 'security-feature',
  editSecurity: 'security-feature/update',
  deleteSecurity: 'security-feature/delete',
  allSecurities: 'security-feature/all',

  adminLogin: 'auth/email/login',
  userVerifyStatus: 'user-verify/update',
  adminForgotPass: 'auth/email/forget-password',
  adminResetPass: 'auth/email/reset-password',
  verifyToken: 'auth/email/verify-password',
  userVerifyStatus: 'user-verify/update',

  bookingById: 'booking/all',
  bookingTransactionHistory: 'Booking-Transactions/get-booking-transactions',

  getAllCoupons: 'coupon/all',
  getCouponById: 'coupon/coupon',
  addCoupon: 'coupon',
  editCoupon: 'coupon/update',
  deleteCoupon: 'coupon/delete',
  bookingAll: 'booking/all',
  bookingByBookingId: 'booking',
  createBooking: 'booking/add',
  bookingPriceCalc: 'booking/calculate-booking-price',
  getBookingCancellation: 'booking-cancellation',
  getBookingUpdateRequest: 'booking-update-request',
  getBookingRating: 'rating/by-booking',
  getBookingInvoice: 'booking/invoice',

  getUsedCouponById: 'coupon-usage/couponUsage',
  deleteUsedCoupon: 'coupon-usage/delete',

  allRoles: 'roles',
  addRole: 'roles',
  editRole: 'roles',
  deleteRole: 'roles',

  sysConfigColumn: 'system-configuration/get-column',
  updateSysConfigColumn: 'system-configuration/update',

  getAllPages: 'page',
  addPage: 'page/create-page',
  editPage: 'page/edit-page',
  deletePage: 'page/delete-page',

  getAllWallets: 'wallet/getall',
  addAmount: 'wallet/addamount',
  deductAmount: 'wallet/deductamount',
  updateBalance: 'wallet/updatebalance',
  getTransactionHistory: 'wallet/get-transactions',
  getWalletById: 'wallet/get-user-wallet',

  getCoHostByPropId: 'co-host/getCohosts',
  getRatingbyPropId: 'rating/by-guest',

  userPermissions: 'role-permissions',

  bookingPayment: 'payment/booking/by-stripe',
  paymentCardByUser: 'payment/card-all/stripe',
  
  cmsPost: 'cms-parent-slider/add',
  cmsGet: 'cms-parent-slider/all',
  cmsDelete: 'cms-parent-slider/delete',
  cmsUpdate: 'cms-parent-slider/update',
  cmsPostSliders: 'cms-slider/create',
  cmsGetSliders: 'cms-slider/get-slides-and-count',
  cmsDeleteSliders: 'cms-slider/delete-slide',

  getcommentsById:'user-crm/byCustomerId',
  postcommentsById: 'user-crm',

  postNoteByPropertyId:'porperty-notes/add-note',
  getNoteByPropertyId: 'porperty-notes/get-note-by-id',
};
