import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

const BookingPieChart = ({ chartData }) => {
  let data = {
    labels: ["Booked Nights", "Un Booked Nights"],
    datasets: [
      {
        data: [300, 98],
        backgroundColor: ["#42A5F5", "#66BB6A"],
        hoverBackgroundColor: ["#64B5F6", "#81C784"],
      },
    ],
  };

  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  });

  return (
    <div
      className="flex justify-content-center"
      style={{ background: "#fff", padding: "24px" }}
    >
      <Chart
        type="pie"
        data={data}
        options={lightOptions}
        style={{ position: "relative", width: "100%" }}
      />
    </div>
  );
};

export default BookingPieChart;
