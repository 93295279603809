import React from "react";
// import TextField from '@mui/material/TextField'
import { InputText } from "primereact/inputtext";

//Simple input component
const Input = ({
  id,
  sx,
  name,
  type,
  label,
  value,
  error,
  margin,
  disabled,
  onChange,
  onClick,
  helperText,
  defaultValue,
}) => {
  return (
    <>
      <div className="field">
        {/* <label htmlFor="username1">{label}</label> */}
        <InputText
          variant="outlined"
          fullWidth
          id={id}
          sx={sx}
          type={type}
          inputProps={{
            maxLength: 50,
          }}
          style={{
            width: "100%",
            ...sx,
          }}
          name={name}
          label={label}
          placeholder={label}
          value={value}
          error={error}
          margin={margin}
          disabled={disabled}
          onChange={onChange}
          helperText={helperText}
          defaultValue={defaultValue}
          onClick={onClick}
          className={error && "p-invalid"}
        />
        {error && (
          <small
            id="username2-help"
            className="p-error"
            style={{
              display: "block",
              color: "red",
              textAlign: "left",
            }}
          >
            {helperText}
          </small>
        )}
      </div>
    </>
  );
};

export default Input;
