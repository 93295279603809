import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getBookingTransactionHistory = (
  order,
  page,
  limit,
  propertyId,
  bookingId
) => {
  return Api(
    `${endPoints.bookingTransactionHistory}${order}${page}${limit}${
      propertyId ? propertyId : ""
    }${bookingId ? bookingId : ""}`,
    null,
    requestType.GET,
    null,
    null
  );
};
