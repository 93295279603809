import React, { useState, useEffect } from "react";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
} from "use-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import useOnclickOutside from "react-cool-onclickoutside";
import InputText from "./LocationInput";
import Map from "./../../components/googleMap";

const Location = (props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
 
    // Update the keyword of the input element
    setValue(e.target.value);
    
  };
  

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      
      setValue(description, false);
      props.setInputVal({ ...props.inputVal, address: description });
      clearSuggestions();
      

      let locationLat = "";
      let locationLng = "";
      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          locationLat = lat;
          locationLng = lng;
          
          props.setMapCenter({
            lat,
            lng,
            address: description,
          });
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });
      let place_id = "";
      getGeocode({ address: description })
        // By default we use the "long_name" value from API response, you can tell the utility to use "short_name"
        // by setting the second parameter to "true"
        .then((results) => {
          let city, state, country;
          for (let i = 0; i < results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < results[0].address_components[i].types.length;
              j++
            ) {
              switch (results[0].address_components[i].types[j]) {
                case "locality":
                  city = results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = results[0].address_components[i].long_name;
                  break;
              }
            }
          }

          let zipCode = getZipCode(results[0], false);
          try {
            props.setInputVal({
              ...props.inputVal,
              address: description,
              state: state,
              city: city,
              country: country,
              zipCode: zipCode !== undefined ? zipCode : "",
              latitude: locationLat,
              longitude: locationLng,
            });
          } catch (error) {
            console.log("Error: ", error);
          }
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)}
          style={{ marginBottom: "10px" }}
        >
          <strong>{main_text}</strong> <br />
          <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div className="row">
      <div className="col-lg-12">
        <div ref={ref}>
          <InputText
            value={value}
            onChange={handleInput}
            disabled={!ready}
            label="Where is your property located?"
            defaultValue={value}
          />
          {status === "OK" && (
            <ul
              style={{
                position: "absolute",
                zIndex: 9999,
                background: "#fff",
                padding: "10px",
                listStyle: "none",
              }}
            >
              {renderSuggestions()}
            </ul>
          )}
        </div>
      </div>
      <div className="col-lg-12">
        <div className="map-container">
          <div id="singleMap" className="drag-map">
            <Map mapValues={[props.mapCenter]} />
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <h6 style={{ marginTop: "10px" }}>Address</h6>
        <InputText
          label={"Address"}
          name={"address"}
          id={"address"}
          onChange={props.handleChange}
          value={props.inputVal.address || ""}
          defaultValue={props.inputVal.address}
        />
      </div>
      <div className="col-lg-6">
        <h6 style={{ marginTop: "10px" }}>Country</h6>
        <InputText
          label={"Country"}
          name={"country"}
          id={"country"}
          onChange={props.handleChange}
          value={props.inputVal.country || ""}
          defaultValue={props.inputVal.country}
        />
      </div>
      <div className="col-lg-6">
        <h6 style={{ marginTop: "10px" }}>City</h6>
        <InputText
          label={"City"}
          name={"city"}
          id={"city"}
          onChange={props.handleChange}
          value={props.inputVal.city || ""}
          defaultValue={props.inputVal.city}
        />
      </div>
      <div className="col-lg-6">
        <h6 style={{ marginTop: "10px" }}>State</h6>
        <InputText
          label={"State"}
          name={"state"}
          id={"state"}
          onChange={props.handleChange}
          value={props.inputVal.state || ""}
          defaultValue={props.inputVal.state}
        />
      </div>
      <div className="col-lg-6">
        <h6 style={{ marginTop: "10px" }}>Zip Code</h6>
        <InputText
          label={"Zipcode"}
          name={"zipCode"}
          id={"zipCode"}
          type="number"
          onChange={props.handleChange}
          value={props.inputVal.zipCode || ""}
          defaultValue={props.inputVal.zipCode}
        />
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
})(Location);
