import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Sidebar from "../../components/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import CrudDataTable from "../../components/crudDataTable/index";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { getAllWallets, updateBalance } from "./../../services/wallet";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import AmmountDialog from "./AmmountDialog";
import { priceBodyTemplate } from "../../constants/utils";
const Wallet = () => {
  let emptyProduct = {
    amount: 0,
  };
  const navigate = useNavigate();
  const viewDialogRef = useRef();
  const [wallets, setWallets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);

  const getData = () => {
    setIsLoading(true);

    getAllWallets()
      .then((res) => {
        setIsLoading(false);
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            id: item.id,
            amount: item.amount,
            name: `${item.user.firstName} ${item.user.lastName}`,
            userId: item.user.id,
          });
        });
        setWallets(tempArr.reverse());
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleAddEdit = (product, setProduct, setProductDialog, type) => {
    setIsLoading(true);
    let payload = {
      userId: product.userId,
      amount: product.amount,
    };
    if (type === "edit") {
      setIsLoading(true);
      updateBalance(payload).then((res) => {
        setIsLoading(false);
        if (res.data.statusCode == 400) {
          let tempArr = [];
          res?.data?.message.map((val, ind) => {
            tempArr.push({
              severity: "error",
              summary: "Error",
              detail: val,
              life: 3000,
            });
          });
          setProductDialog(false);
          toast.current.show(tempArr);
        } else {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Balance Updated Successfully",
            life: 3000,
          });
          setProduct({});
          getData();
          setTimeout(() => {
            setProductDialog(false);
          }, 1500);
        }
      });
    }
  };

  const amountTemplate = (rowData) => {
    return (
      <div className="grid">
        <div className="col-3">
          <span className="text-base font-bold">
            {priceBodyTemplate(rowData.amount)}
          </span>
        </div>

        <div className="col-5">
          <Button
            className="ml-1 p-1"
            onClick={() => {
              viewDialogRef.current.isVisible({ data: rowData, type: "add" });
            }}
          >
            Add
          </Button>
          <Button
            className="ml-1 p-1"
            onClick={() => {
              viewDialogRef.current.isVisible({
                data: rowData,
                type: "deduct",
              });
            }}
          >
            Deduct
          </Button>
        </div>
      </div>
    );
  };

  const DATA = [
    { id: 1, field: "id", header: "Id" },
    { id: 2, field: "amount", header: "Amount", body: amountTemplate },
    { id: 2, field: "name", header: "Username" },
  ];

  const handleView = (rowData) => {
    navigate(`/walletDetails/${rowData.userId}`);
  };

  const renderDialog = (
    product,
    submitted,
    onInputChange,
    onInputNumberChange
  ) => {
    return (
      <>
        <div className="grid">
          <div className="col-6">
            <label>Amount</label>
            <InputNumber
              style={{ marginTop: "20px" }}
              value={product.amount}
              onChange={(e) => onInputNumberChange(e, "amount")}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="app-main">
      <Toast ref={toast} position="bottom-right" />
      <Sidebar active="wallet" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="All Wallets - Staywo"
            icon="pe-7s-wallet icon-gradient bg-mean-fruit"
          />

          <div className="row">
            <div className="col-lg-12">
              <Card title="Wallet">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={DATA}
                    data={wallets}
                    renderDialog={renderDialog}
                    emptyProduct={emptyProduct}
                    dialogHeader="Update Balance"
                    tableTitle="Wallets"
                    showView={true}
                    handleView={handleView}
                    addEditFunction={handleAddEdit}
                    showDelete={false}
                    showToolbar={false}
                  />
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
      <AmmountDialog ref={viewDialogRef} getData={getData} />
    </div>
  );
};

export default Wallet;
