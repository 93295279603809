import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const adminLogin = (params) => {
  return Api(`${endPoints.adminLogin}`, params, requestType.POST, null, null);
};

export const getforgotPasswordEmail = (email) => {
  return Api(
    `${endPoints.adminForgotPass}/${email}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getVerifyToken = (token, email) => {
  return Api(
    `${endPoints.verifyToken}/${token}/${email}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const adminResetPass = (params) => {
  return Api(
    `${endPoints.adminResetPass}`,
    params,
    requestType.PATCH,
    null,
    null
  );
};
