import "./App.css";
import Banner from "./components/banner";
import Card from "./components/card";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import AmenitiesConfigration from "./screens/configration/AmenitiesConfigration";
import AmenitiesTypeConfigration from "./screens/configration/AmenitiesTypeConfigration";
import FacilitiesConfigration from "./screens/configration/FacilitiesConfigration";
import RulesConfigration from "./screens/configration/RuleConfigration";
import SpaceConfigration from "./screens/configration/SpaceConfigration";
import SpaceTypeConfigration from "./screens/configration/SpaceTypeConfigration";
import PropertiesList from "./screens/properties/PropertiesList";
import { Routes, Route, Link } from "react-router-dom";
import NewAddedProperties from "./screens/properties/NewAddedProperties";
import UnderClaimProperties from "./screens/properties/UnderClaimProperties";
import SafetyConfigration from "./screens/configration/SafetyConfigration";
import SafetyDevicesConfigration from "./screens/configration/SafetyDevicesConfigration";
import PropertyInfoConfigration from "./screens/configration/PropertyInfoConfigration";
import BedTypeConfigration from "./screens/configration/BedTypeConfigration";
import ActiveCountriesConfigration from "./screens/configration/ActiveCountriesConfigration";
import NearbyActivitiesConfigration from "./screens/configration/NearbyActivitiesConfigration";
import ClaimQuestionsConfigration from "./screens/configration/ClaimQuestionsConfigration";
import ClaimSubQuestionsConfigration from "./screens/configration/ClaimSubQuestionsConfigration";
import ReportPropertyOptionConfigration from "./screens/configration/ReportPropertyOptionConfigration";
import FaqCategoriesConfigration from "./screens/configration/FaqCategoriesConfigration";
import FaqConfigration from "./screens/configration/FaqConfigration";
import DiamondHostRequirementConfigration from "./screens/configration/DiamondHostRequirementConfigration";
import DiamondHostReviewConfigration from "./screens/configration/DiamondHostReviewConfigration";
import CommisionCriteriaConfigration from "./screens/configration/CommisionCriteriaConfigration";
import NotificationCriteriaConfigration from "./screens/configration/NotificationCriteriaConfigration";
// import AddProperty from "./screens/addProperty/AddProperty";
import UsersList from "./screens/users/UsersList";
import AmenitiesGroupList from "./screens/amenitiesGroup/AmenitiesGroupList";
import NearbyActivityGroup from "./screens/nearbyActivityGroup/NearbyActivityGroup";
import AmenitiesList from "./screens/amenities/AmenitiesList";
import NearbyActivity from "./screens/nearbyActivity/NearbyActivity";
import VerifyUsers from "./screens/verifyUsers/VerifyUsers";
import Facility from "./screens/facility/Facility";
import Area from "./screens/area/Area";
import SpaceGroupType from "./screens/spaceGroupType/SpaceGroupType";
import InviteGuestPage from "./screens/InviteGuest/InviteGuestPage";
import Space from "./screens/space/Space";
import FacilityGroup from "./screens/facilityGroup/FacilityGroup";
import SafetyFeatureGroup from "./screens/safetyFeatureGroup/SafetyFeatureGroup";
import SafetyFeatures from "./screens/safetyFeatures/SafetyFeatures";
import HouseRuleGroup from "./screens/houseRuleGroup/HouseRuleGroup";
import HouseRule from "./screens/houseRule/HouseRule";
import Accessibility from "./screens/accessibility/Accessibility";
import AccessibilityGroup from "./screens/accessibilityGroup/AccessibilityGroup";
import SafetyConsiderations from "./screens/safetyConsiderations/SafetyConsiderations";
import SafetyGroupConsiderations from "./screens/safetyGroupConsiderations/SafetyGroupConsiderations";
import Security from "./screens/security/Security";
import SecurityGroupType from "./screens/securityGroup/SecurityGroup";
import LoginForm from "./screens/auth/Login";
import { ProtectedLayout } from "./components/protectedLayout";
import Dashboard from "./screens/dashboard/Dashboard";
import { useAuth } from "./components/hooks/useAuth";
import { Navigate } from "react-router-dom";
import SystemConfiguration from "./screens/systemConfiguration/SystemConfiguration";
import PropertyDetails from "./screens/properties/PropertyDetails";
import UserDetails from "./screens/users/UserDetails";
import Coupon from "./screens/coupon/Coupon";
import ForgotPassword from "./screens/auth/ForgotPassword";
import ResetPassword from "./screens/auth/ResetPassword";
import BookingsList from "./screens/bookings/BookingsList";
import BookingDetails from "./screens/bookings/BookingDetails";
import Roles from "./screens/roles/Roles";
import AddProperty from "./screens/properties/AddProperty";
import EditProperty from "./screens/properties/EditProperty";
import Page from "./screens/page/Page";
import CouponUsage from "./screens/couponUsage/CouponUsage";
import Wallet from "./screens/wallet/Wallet";
import WalletDetails from "./screens/walletDetails/WalletDetails";
import Permissions from "./screens/permissions/Permissions";
import AddEditBookings from "./screens/bookings/AddEditBookings";
import PropertyDetailsTabs from "./screens/properties/PropertyDetailsTabs";
import BookingTransactionHistory from "./screens/bookingTransactionHistory/BookingTransactionHistory";
import CMS from './screens/CMS/CMS';
import Sliders from './screens/CMS/Sliders';
import Reports from "./screens/properties/Reports";

function App() {
  const { user } = useAuth();
  return (
    <>
      <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
        {user !== null ? <Header /> : null}
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route
            path="/auth/admin/verify/:token/:email"
            element={<ResetPassword />}
          />

          {/* PROTECTED LAYOUT */}
          <Route path="/" exact={true} element={<ProtectedLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/propertyList"
              exact={true}
              element={<PropertiesList />}
            />
            <Route
              path="/propertyDetailsTabs/:id"
              exact={true}
              element={<PropertyDetailsTabs />}
            />
           
            <Route
              path='/admin/invite-guest/:id'
              element={<InviteGuestPage />}
            />

            <Route path="/AddProperty" exact={true} element={<AddProperty />} />
            <Route
              path="/EditProperty/:id"
              exact={true}
              element={<EditProperty />}
            />
           
            
            
        

            <Route path="/users" element={<UsersList />} />
            <Route path="/addEditBooking" element={<AddEditBookings />} />
            <Route path="/bookingList" element={<BookingsList />} />
            <Route
              path="/bookingDetails/booking/:bookingId/property/:propertyId"
              element={<BookingDetails />}
            />
            <Route
              path="/bookingTransactionHistory"
              element={<BookingTransactionHistory />}
            />
            <Route path="/roles" element={<Roles />} />
            <Route path="/permissions" element={<Permissions />} />
            <Route
              path="/userDetails/:id"
              exact={true}
              element={<UserDetails />}
            />
            <Route path="/amenitiesGroup" element={<AmenitiesGroupList />} />
            <Route
              path="/nearbyActivityGroup"
              element={<NearbyActivityGroup />}
            />
            <Route path="/amenities" element={<AmenitiesList />} />
            <Route path="/verifyUsers" element={<VerifyUsers />} />
            <Route path="/area" element={<Area />} />
            <Route path="/spaceGroupType" element={<SpaceGroupType />} />
            <Route path="/space" element={<Space />} />
            <Route
              path="/safetyFeatureGroup"
              element={<SafetyFeatureGroup />}
            />
            <Route path="/safetyFeatures" element={<SafetyFeatures />} />

            <Route path="/accessibility" element={<Accessibility />} />
            <Route
              path="/accessibilityGroup"
              element={<AccessibilityGroup />}
            />

            <Route
              path="/safetyConsideration"
              element={<SafetyConsiderations />}
            />
            <Route
              path="/safetyConsiderationGroup"
              element={<SafetyGroupConsiderations />}
            />
            <Route
              path="/systemConfiguration"
              element={<SystemConfiguration />}
            />

            <Route path="/security" element={<Security />} />
            <Route path="/securityGroupType" element={<SecurityGroupType />} />
          </Route>

          <Route path="/new-properties" element={<NewAddedProperties />} />

          <Route path="/facilities" element={<Facility />} />
          <Route
            path="/under-claim-properties"
            element={<UnderClaimProperties />}
          />
          <Route
            path="/property-reports"
            element={<Reports/>}
          />
          <Route
            path="/amenities-configration"
            element={<AmenitiesConfigration />}
          />
          <Route
            path="/amenities-type-configration"
            element={<AmenitiesTypeConfigration />}
          />
          <Route
            path="/spaces-type-configration"
            element={<SpaceTypeConfigration />}
          />
          <Route path="/spaces-configration" element={<SpaceConfigration />} />
          <Route path="/rules-configration" element={<RulesConfigration />} />
          <Route path="/facilities-group" element={<FacilityGroup />} />
          <Route path="/houseRuleGroup" element={<HouseRuleGroup />} />
          <Route path="/houseRule" element={<HouseRule />} />
          <Route
            path="/facilities-configration"
            element={<FacilitiesConfigration />}
          />
          <Route
            path="/safety-consideration-configration"
            element={<SafetyConfigration />}
          />
          <Route path="/page" element={<Page />} />
          <Route path="/coupons" element={<Coupon />} />
          <Route path="/couponUsage/:id" element={<CouponUsage />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/walletDetails/:id" element={<WalletDetails />} />

          <Route
            path="/safety-devices-configration"
            element={<SafetyDevicesConfigration />}
          />
          <Route
            path="/property-info-configration"
            element={<PropertyInfoConfigration />}
          />
          <Route
            path="/bed-type-configration"
            element={<BedTypeConfigration />}
          />
          <Route
            path="/active-countries-configration"
            element={<ActiveCountriesConfigration />}
          />
          <Route
            path="/nearby-activities-configration"
            element={<NearbyActivity />}
          />
          <Route
            path="/claim-questions-configration"
            element={<ClaimQuestionsConfigration />}
          />
          <Route
            path="/claim-sub-questions-configration"
            element={<ClaimSubQuestionsConfigration />}
          />
          <Route
            path="property-report-configration"
            element={<ReportPropertyOptionConfigration />}
          />
          <Route
            path="/faq-categories-configration"
            element={<FaqCategoriesConfigration />}
          />
          <Route path="/faq-configration" element={<FaqConfigration />} />
          <Route
            path="/diamond-host-review-configration"
            element={<DiamondHostReviewConfigration />}
          />
          <Route
            path="/diamond-host-requirement-configration"
            element={<DiamondHostRequirementConfigration />}
          />
          <Route
            path="/property-commision-criteria-configration"
            element={<CommisionCriteriaConfigration />}
          />
          <Route
            path="/notification-criteria-configration"
            element={<NotificationCriteriaConfigration />}
          />
          <Route path="/cms"
            element={<CMS />} />
          <Route path="/sliders/:id"
            element={<Sliders />} />
          {/* <Route path="/add-property" element={<AddProperty />} /> */}
        </Routes>
      </div>
    </>
  );
}

export default App;
