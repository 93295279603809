import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllNearbyActivityGroup = () => {
  return Api(
    `${endPoints.nearbyActivityGroup}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addNearbyActivityGroup = (params) => {
  return Api(
    `${endPoints.addNearbyActivityGroup}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editNearbyActivityGroup = (id, params) => {
  return Api(
    `${endPoints.editNearbyActivityGroup}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteNearbyActivityById = (id) => {
  return Api(
    `${endPoints.deleteNearbyActivityGroup}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
