import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { useNavigate } from "react-router-dom";
import userPlaceholder from "../../images/userPlaceholder.jpg";
import { priceBodyTemplate } from "../../constants/utils";

const imageBodyTemplate = (rowData) => {
  return (
    <Image
      height="90px"
      width="90px"
      className="table-image-type"
      src={rowData.thumbnailUrl ? rowData.thumbnailUrl : userPlaceholder}
    />
  );
};
const statusTepmlate = (rowData) => {
  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <span
          style={{
            fontWeight: 500,
            background: "#c8e6c9",
            color: "#256029",
            padding: "5px 10px",
          }}
        >
          {rowData.isActive ? "Active" : "In Active"}
        </span>
      </div>
    </>
  );
};

const ViewTepmlate = (rowData, route) => {
  const navigate = useNavigate();
  return (
    <>
      <Button
        icon="pi pi-eye"
        className="p-button-rounded p-button-raised mr-2"
        onClick={() => {
          navigate(`/${route}/${rowData.id}`);
        }}
      />
    </>
  );
};

const PropertyTitleTemplate = (rowData) => {
  const navigate = useNavigate();
  return (
    <a
      className="cursor-pointer"
      onClick={() => navigate(`/propertyDetails/${rowData.id}`)}
    >
      {rowData.title} ({rowData.code})
    </a>
  );
};

const PropertyNameTemplate = (rowData) => {
  const navigate = useNavigate();
  return (
    <a
      className="cursor-pointer"
      onClick={() => navigate(`/bookingDetails/${rowData.id}`)}
    >
      {rowData.propertyName}
    </a>
  );
};

const dateFormatTemplate = (date) => {
  return <span>{date}</span>;
};

export const LatestPropertiesData = [
  { id: 1, field: "id", header: "Id" },
  { id: 2, field: "thumbnailUrl", header: "Image", body: imageBodyTemplate },
  { id: 3, field: "title", header: "Name", body: PropertyTitleTemplate },
  { id: 4, field: "propertyOwner", header: "Owner" },
  {
    id: 5,
    field: "price",
    header: "Price",
    body: (e) => priceBodyTemplate(e.price),
  },
  { id: 6, field: "isActive", header: "Status", body: statusTepmlate },
];

export const LatestBookingsData = [
  { id: 1, field: "id", header: "Id" },
  {
    id: 2,
    field: "propertyName",
    header: "Property Name",
    body: PropertyNameTemplate,
  },
  { id: 3, field: "code", header: "Booking Code" },
  { id: 4, field: "bookingDate", header: "Booking Date" },
  {
    id: 5,
    field: "grossAmount",
    header: "Total Amount",
    body: (e) => priceBodyTemplate(e.grossAmount),
  },
  { id: 6, field: "guestName", header: "Guest Name" },
];

export const LatestUsersData = [
  { id: 1, field: "id", header: "Id" },
  {
    id: 2,
    field: "profilePicture",
    header: "Profile Picture",
    body: imageBodyTemplate,
  },
  { id: 3, field: "name", header: "Name" },
  { id: 4, field: "email", header: "Email" },
  { id: 5, field: "phoneNumber", header: "Phone#" },
  { id: 6, field: "isActive", header: "Status", body: statusTepmlate },
];
