import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { updateUserStatusByAdmin } from "../../services/users";
import { useAuth } from "../../components/hooks/useAuth";

const UserStatusDialog = React.forwardRef(({ getData, customRows }, ref) => {
  const user_token = JSON.parse(localStorage.getItem("token"));
  const { user } = useAuth();
  const toast = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [inputVal, setInputVal] = useState({
    status: false,
    description: "",
  });

  useImperativeHandle(ref, () => ({
    isVisible(params) {
      setDialogData(params);
      setOpenStatusDialog(true);
    },
  }));

  useEffect(() => {
    setInputVal({
      ...inputVal,
      status: dialogData?.isActive,
      description: dialogData?.description,
    });
  }, [dialogData]);

  const handleChangeText = (e) => {
    setInputVal({ ...inputVal, description: e.target.value });
  };

  const hideDialog = () => {
    setOpenStatusDialog(false);
  };

  const handleChangeStatus = () => {
    let payload = {
      status: inputVal.status,
      description: inputVal.description ? inputVal.description:"",
      user_id: dialogData.id,
      update_by: user?.id,
    };
    console.log(payload,"llkjh")
    if (inputVal.description === "") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Reason is required",
        life: 3000,
      });
    } else {
      setIsLoading(true);
     console.log(payload,"Ddd")
      updateUserStatusByAdmin(payload, user_token).then((res) => {
        setIsLoading(false);
       
         
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "User Status Change  Successfully",
          life: 3000,
        });
          setInputVal({
            status: "",
            description: "",
          });
          getData("ASC", 1, customRows);
          
            setOpenStatusDialog(false);
          
        
      });
    }
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      {!isLoading ? (
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={handleChangeStatus}
        />
      ) : null}
    </React.Fragment>
  );

  return (
    <Dialog
      visible={openStatusDialog}
      style={{ width: "450px" }}
      header="Change User Status"
      modal
      className="p-fluid"
      footer={productDialogFooter}
      onHide={hideDialog}
    >
      <Toast ref={toast} position="bottom-right" />
      <div>
        <div className="flex justify-content-center">
          <span className="mr-1">DisApprove</span>
          <InputSwitch
            checked={inputVal.status}
            onChange={(e) =>
              setInputVal({
                status: e.value,
              })
            }
          />
          <span className="ml-1">Approve</span>
        </div>
        <InputTextarea
          style={{ marginTop: "20px" }}
          value={inputVal.description}
          onChange={handleChangeText}
          name="description"
          placeholder="Reason"
          rows={5}
          cols={30}
        />
      </div>
    </Dialog>
  );
});

export default UserStatusDialog;
