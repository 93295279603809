import { combineReducers } from 'redux';
import viewSlice from './slices/viewSlice';
import userListSlice from './slices/userList'


const rootReducer = combineReducers({
 ui:viewSlice,
 userList:userListSlice
 
});

export default rootReducer;