import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Sidebar from "../../components/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import Pagination from "../../components/pagination";
import CrudDataTable from "../../components/crudDataTable/index";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useAuth } from "../../components/hooks/useAuth";
import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import {
  getAllCoupons,
  addCoupon,
  editCoupon,
  deleteCoupon,
} from "./../../services/coupon";
import moment from "moment";
import { InputNumber } from "primereact/inputnumber";

const Coupon = () => {
  let emptyProduct = {
    couponCode: "",
    validThrough: new Date(),
    discountPercentage: 0,
    maxUseCount: 0,
  };
  const navigate = useNavigate();
  const { user } = useAuth();
  const [coupons, setCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customFirst, setCustomFirst2] = useState(0);
  const [customRows, setCustomRows2] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const toast = useRef(null);

  const getData = () => {
    setIsLoading(true);

    getAllCoupons()
      .then((res) => {
        setIsLoading(false);
        setCoupons(res.data.data.reverse());
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData(0, customRows);
  }, []);

  const validThroughTemplate = (rowData) => {
    return (
      <span>
        {moment(rowData?.validThrough).format("DD MMM YYYY hh:mm:ss")}
      </span>
    );
  };

  const DATA = [
    { id: 1, field: "id", header: "Id" },
    { id: 2, field: "code", header: "Code" },
    {
      id: 3,
      field: "validThrough",
      header: "Valid Through",
      body: validThroughTemplate,
    },
    { id: 4, field: "discountPercentage", header: "Discounted Percentage" },
    { id: 5, field: "maxUseCount", header: "Max use count" },
    { id: 5, field: "createdFor", header: "Created for" },
  ];

  const onPageChange = (event) => {
    setCustomFirst2(event.first);
    setCustomRows2(event.rows);
    getData(event.page, event.rows);
  };

  const handleAddEdit = (product, setProduct, setProductDialog, type) => {
    if (product.couponCode == "") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Coupon code is required",
        life: 3000,
      });
    } else if (product.validThrough == "") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Validity date is required",
        life: 3000,
      });
    } else if (product.discountPercentage == "") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Discounted Percentage is required",
        life: 3000,
      });
    } else if (product.maxUseCount == "") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Max use count is required",
        life: 3000,
      });
    } else {
      let payload = {
        code: product.couponCode,
        validThrough: product.validThrough,
        discountPercentage: product.discountPercentage,
        maxUseCount: product.maxUseCount,
        usedCount: 10,
        createdBy: user.id,
        createdFor: 10,
      };
      if (type === "add") {
        setIsLoading(true);
        addCoupon(payload).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: "error",
                summary: "Error",
                detail: val,
                life: 3000,
              });
            });
            setProductDialog(false);
            toast.current.show(tempArr);
          } else {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Coupon Created Successfully",
              life: 3000,
            });
            setProduct({});
            getData();
            setTimeout(() => {
              setProductDialog(false);
            }, 1500);
          }
        });
      } else {
        setIsLoading(true);
        editCoupon(product.id, payload).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: "error",
                summary: "Error",
                detail: val,
                life: 3000,
              });
            });
            setProductDialog(false);
            toast.current.show(tempArr);
          } else {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Coupon Updated Successfully",
              life: 3000,
            });
            setProduct({});
            getData();
            setTimeout(() => {
              setProductDialog(false);
            }, 1500);
          }
        });
      }
    }
  };

  const deleteSelectedItem = (id, setOpenDialog) => {
    setIsLoading(true);
    deleteCoupon(id).then((res) => {
      setIsLoading(false);
      if (res.data.statusCode == 400) {
        let tempArr = [];
        res?.data?.message.map((val, ind) => {
          tempArr.push({
            severity: "error",
            summary: "Error",
            detail: val,
            life: 3000,
          });
        });
        setOpenDialog(false);
        toast.current.show(tempArr);
      } else {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Coupon Deleted Successfully",
          life: 3000,
        });
        getData();
        setTimeout(() => {
          setOpenDialog(false);
        }, 1500);
      }
    });
  };

  const handleView = (rowData) => {
    navigate(`/couponUsage/${rowData.id}`);
  };

  const renderDialog = (
    product,
    submitted,
    onInputChange,
    onInputNumberChange
  ) => {
    return (
      <>
        <div className="grid">
          <div className="col-6">
            <label>Code</label>
            <InputText
              value={product.code ? product.code : product.couponCode}
              onChange={(e) => onInputChange(e, "couponCode")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.couponCode,
              })}
            />
          </div>
          <div className="col-6">
            <label>Valid Through</label>
            <Calendar
              id="icon"
              value={new Date(product.validThrough)}
              onChange={(e) => onInputChange(e, "validThrough")}
              showIcon
            />
          </div>
          <div className="col-6">
            <label>Discount Percentage</label>
            <InputNumber
              value={product.discountPercentage}
              onChange={(e) => onInputNumberChange(e, "discountPercentage")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.discountPercentage,
              })}
            />
          </div>
          <div className="col-6">
            <label>Max use count</label>
            <InputNumber
              value={product.maxUseCount}
              onChange={(e) => onInputNumberChange(e, "maxUseCount")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.maxUseCount,
              })}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="app-main">
      <Toast ref={toast} position="bottom-right" />
      <Sidebar active="coupons" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="All Coupons - Staywo"
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />

          <div className="row">
            <div className="col-lg-12">
              <Card title="Coupons">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={DATA}
                    data={coupons}
                    totalRecords={totalRecords}
                    renderDialog={renderDialog}
                    addEditFunction={handleAddEdit}
                    deleteSelectedItem={deleteSelectedItem}
                    emptyProduct={emptyProduct}
                    dialogHeader="Coupon Details"
                    tableTitle="Coupons"
                    showView={true}
                    handleView={handleView}
                  />
                )}
                <Pagination
                  totalRecords={totalRecords}
                  customFirst={customFirst}
                  customRows={customRows}
                  onPageChange={onPageChange}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coupon;
