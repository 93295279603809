import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllAmenities = () => {
  return Api(`${endPoints.getAllAmenities}`, null, requestType.GET, null, null);
};

export const addAmenity = (params) => {
  return Api(`${endPoints.addAmenity}`, params, requestType.POST, null, null);
};

export const editAmenity = (id, params) => {
  return Api(
    `${endPoints.editAmenity}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteAmenityById = (id) => {
  return Api(
    `${endPoints.deleteAmenity}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
