import React, { useEffect, useState } from "react";
import Banner from "../../components/banner";
import Sidebar from "../../components/sidebar";
import InfoBar from "../../components/infobar";
import {
  getLatestBookings,
  getLatestProperties,
  getLatestSignups,
  getCommercialProperties,
  getPrivateProperties,
} from "../../services/dashboard";
import Card from "../../components/card";
import { ProgressSpinner } from "primereact/progressspinner";
import Table from "../../components/dataTable";
import moment from "moment";
import {
  LatestBookingsData,
  LatestPropertiesData,
  LatestUsersData,
} from "./DashbaordData";
import Pagination from "../../components/pagination";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [latestProperties, setLatestProperties] = useState({});
  const [privateProperties, setPrivateProperties] = useState({});
  const [CommercialProperties, setCommercialProperties] = useState({});
  const [latestBookingsCount, setLatestBookingsCount] = useState(0);
  const [latestBookings, setLatestBookings] = useState({});
  const [currentBookings, setCurrentBookings] = useState({});
  const [latestSignUp, setLatestSignUp] = useState({});
  const [paginationValues, setPaginationValues] = useState({
    latestBookingFirst: 0,
    latestBookingsPage: 5,
    currentBookingFirst: 0,
    currentBookingsPage: 5,
    currentUsersFirst: 0,
    currentUsersPage: 5,
    currentPropertiesFirst: 0,
    currentPropertiesPage: 5,
    currentPrivatePropertiesFirst: 0,
    currentPrivatePropertiesPage: 5,
    currentCommercialPropertiesFirst: 0,
    currentCommercialPropertiesPage: 5,
  });
  const user_token = JSON.parse(localStorage.getItem("token"));

  const getUserData = (order, page, limit) => {
    setIsLoading(true);
    getLatestSignups(order, page, limit, user_token)
      .then((res) => {
        let tempArr = [];
        res.data.data.data.map((item) => {
          tempArr.push({
            ...item,
            name: `${item.firstName} ${item.middleName} ${item.lastName}`,
          });
        });
        setLatestSignUp({
          data: tempArr,
          total: res?.data?.data.meta?.itemCount,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getBookingData = (order, page, limit) => {
    setIsLoading(true);
    getLatestBookings(
      `?page=${page}`,
      `&limit=${limit}`,
      `&sortBy=${order}`,
      `&bookingStatus=pending`,
      `&startDate=${new Date().toISOString()}`
    )
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            ...item,
            bookingDate:
              item.startDate && item.endDate !== null
                ? `${moment(item.startDate).format("DD MMM YYYY")} - ${moment(
                    item.endDate
                  ).format("DD MMM YYYY")}`
                : "-",
            guestName: `${item.guest.firstName} ${item.guest.lastName}`,
            propertyName: item.property.title,
          });
        });
        setLatestBookings({ data: tempArr, total: res.data.total });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getLatestBookings("", "", "", "", `?startDate=${new Date().toISOString()}`)
      .then((res) => {
        setLatestBookingsCount(res?.data?.count);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getLatestBookings(
      `?page=${page}`,
      `&limit=${limit}`,
      `&sortBy=${order}`,
      "",
      ""
    )
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            ...item,
            bookingDate:
              item.startDate && item.endDate !== null
                ? `${moment(item.startDate).format("DD MMM YYYY")} - ${moment(
                    item.endDate
                  ).format("DD MMM YYYY")}`
                : "-",
            guestName: `${item.guest.firstName} ${item.guest.lastName}`,
            propertyName: item.property.title,
          });
        });
        setCurrentBookings({ data: tempArr, total: res.data.total });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getPropertiesData = (order, page, limit) => {
    setIsLoading(true);
    getLatestProperties(order, page, limit)
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            ...item,
            propertyOwner: `${item.user.firstName} ${item.user.middleName} ${item.user.lastName}`,
          });
        });
        setLatestProperties({
          data: tempArr,
          total: res?.data?.total,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getPrivateProperties(order, page, limit, "Private")
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            ...item,
            propertyOwner: `${item.user.firstName} ${item.user.middleName} ${item.user.lastName}`,
          });
        });
        setPrivateProperties({
          data: tempArr,
          total: res?.data?.total,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getCommercialProperties(order, page, limit, "Commercial")
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            ...item,
            propertyOwner: `${item.user.firstName} ${item.user.middleName} ${item.user.lastName}`,
          });
        });
        setCommercialProperties({
          data: tempArr,
          total: res?.data?.total,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserData("DESC", 1, 5);
    getBookingData("DESC", 1, 5);
    getPropertiesData("DESC", 1, 5);
  }, []);

  const onPageChangeLatestBookings = (event) => {
    setPaginationValues({
      ...paginationValues,
      latestBookingFirst: event.first,
      latestBookingsPage: event.rows,
    });
    getBookingData("DESC", event.page, event.rows);
  };

  const onPageChangeCurrentBookings = (event) => {
    setPaginationValues({
      ...paginationValues,
      currentBookingFirst: event.first,
      currentBookingsPage: event.rows,
    });
    getBookingData("DESC", event.page, event.rows);
  };

  const onPageChangeCurrentUsers = (event) => {
    setPaginationValues({
      ...paginationValues,
      currentUsersFirst: event.first,
      currentUsersPage: event.rows,
    });
    getUserData("DESC", event.page, event.rows);
  };
  const onPageChangeCurrentProperties = (event) => {
    setPaginationValues({
      ...paginationValues,
      currentPropertiesFirst: event.first,
      currentPropertiesPage: event.rows,
    });
    getPropertiesData("DESC", event.page, event.rows);
  };
  const onPageChangeCurrentPrivateProperties = (event) => {
    setPaginationValues({
      ...paginationValues,
      currentPrivatePropertiesFirst: event.first,
      currentPrivatePropertiesPage: event.rows,
    });
    getPropertiesData("DESC", event.page, event.rows);
  };
  const onPageChangeCurrentCommercialProperties = (event) => {
    setPaginationValues({
      ...paginationValues,
      currentCommercialPropertiesFirst: event.first,
      currentCommercialPropertiesPage: event.rows,
    });
    getPropertiesData("DESC", event.page, event.rows);
  };

  return (
    <div className="app-main">
      <Sidebar active="dashboard" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="Dashboard - Staywo"
            icon="pe-7s-monitor icon-gradient bg-mean-fruit"
          />

          <div className="row">
            <div className="col-lg-4">
              <InfoBar
                title={"Latest Bookings"}
                variant={"widget-numbers text-success"}
                status={latestBookingsCount}
              />
            </div>
            <div className="col-lg-4">
              <InfoBar
                title={"Latest Properties"}
                variant={"widget-numbers text-warning"}
                status={latestProperties?.data?.length}
              />
            </div>
            <div className="col-lg-4">
              <InfoBar
                title={"Latest Signups"}
                variant={"widget-numbers text-danger"}
                status={latestSignUp?.data?.length}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <InfoBar
                title={"Pending Approval Requests"}
                variant={"widget-numbers text-warning"}
                status={latestBookings?.total}
              />
            </div>
            <div className="col-lg-4">
              <InfoBar
                title={"Latest Reported Properties"}
                variant={"widget-numbers text-danger"}
                status={0}
              />
            </div>
            <div className="col-lg-4">
              <InfoBar
                title={"Latest Tickets"}
                variant={"widget-numbers text-danger"}
                status={0}
              />
            </div>
            <div className="col-lg-4">
              <InfoBar
                title={"Total Current Bookings"}
                variant={"widget-numbers text-danger"}
                status={currentBookings?.total}
              />
            </div>
            <div className="col-lg-4">
              <InfoBar
                title={"Total Current Users"}
                variant={"widget-numbers text-danger"}
                status={latestSignUp?.total}
              />
            </div>
            <div className="col-lg-4">
              <InfoBar
                title={"Total Current Properties"}
                variant={"widget-numbers text-danger"}
                status={latestProperties?.total}
              />
            </div>
            <div className="col-lg-4">
              <InfoBar
                title={"Total Current Private Properties"}
                variant={"widget-numbers text-danger"}
                status={privateProperties?.total}
              />
            </div>
            <div className="col-lg-4">
              <InfoBar
                title={"Total Current Commercial Properties"}
                variant={"widget-numbers text-danger"}
                status={CommercialProperties?.total}
              />
            </div>
            <div className="col-lg-4">
              <InfoBar
                title={"Pending Payouts"}
                variant={"widget-numbers text-danger"}
                status={0}
              />
            </div>
          </div>
          <Card title="Latest Users">
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ProgressSpinner />
              </div>
            ) : (
              <Table columns={LatestUsersData} data={latestSignUp?.data} />
            )}
          </Card>
          <Card title="Latest Properties">
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ProgressSpinner />
              </div>
            ) : (
              <Table
                columns={LatestPropertiesData}
                data={latestProperties?.data}
              />
            )}
          </Card>
          <Card title="Latest Bookings">
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ProgressSpinner />
              </div>
            ) : (
              <Table
                columns={LatestBookingsData}
                totalRecords={latestBookings?.total}
                data={latestBookings?.data}
              />
            )}
            <Pagination
              totalRecords={latestBookings?.total}
              customFirst={paginationValues.latestBookingFirst}
              customRows={paginationValues.latestBookingsPage}
              onPageChange={onPageChangeLatestBookings}
            />
          </Card>
          <Card title="Total Current Bookings">
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ProgressSpinner />
              </div>
            ) : (
              <Table
                columns={LatestBookingsData}
                data={currentBookings.data}
                totalRecords={currentBookings?.total}
              />
            )}
            <Pagination
              totalRecords={currentBookings?.total}
              customFirst={paginationValues.currentBookingFirst}
              customRows={paginationValues.currentBookingsPage}
              onPageChange={onPageChangeCurrentBookings}
            />
          </Card>
          <Card title="Total Current Users">
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ProgressSpinner />
              </div>
            ) : (
              <Table
                columns={LatestUsersData}
                data={latestSignUp?.data}
                totalRecords={latestSignUp?.total}
              />
            )}
            <Pagination
              totalRecords={latestSignUp?.total}
              customFirst={paginationValues.currentUsersFirst}
              customRows={paginationValues.currentUsersPage}
              onPageChange={onPageChangeCurrentUsers}
            />
          </Card>
          <Card title="Total Current Properties">
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ProgressSpinner />
              </div>
            ) : (
              <Table
                columns={LatestPropertiesData}
                data={latestProperties?.data}
                totalRecords={latestProperties?.total}
              />
            )}
            <Pagination
              totalRecords={latestProperties?.total}
              customFirst={paginationValues.currentPropertiesFirst}
              customRows={paginationValues.currentPropertiesPage}
              onPageChange={onPageChangeCurrentProperties}
            />
          </Card>
          <Card title="Total Current Private Properties">
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ProgressSpinner />
              </div>
            ) : (
              <Table
                columns={LatestPropertiesData}
                data={privateProperties?.data}
                totalRecords={privateProperties?.total}
              />
            )}
            <Pagination
              totalRecords={privateProperties?.total}
              customFirst={paginationValues.currentPrivatePropertiesFirst}
              customRows={paginationValues.currentPrivatePropertiesPage}
              onPageChange={onPageChangeCurrentPrivateProperties}
            />
          </Card>
          <Card title="Total Current Commercial Properties">
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ProgressSpinner />
              </div>
            ) : (
              <Table
                columns={LatestPropertiesData}
                data={CommercialProperties?.data}
                totalRecords={CommercialProperties?.total}
              />
            )}
            <Pagination
              totalRecords={CommercialProperties?.total}
              customFirst={paginationValues.currentCommercialPropertiesFirst}
              customRows={paginationValues.currentCommercialPropertiesPage}
              onPageChange={onPageChangeCurrentCommercialProperties}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
