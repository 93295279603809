import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getCoHostByPropId = (id) => {
  return Api(
    `${endPoints.getCoHostByPropId}/${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};
