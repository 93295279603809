export const Spaces = [
  {
    id: "SP-001",
    title: "Appartment",
    description:
      "Lorem Ipsum is simply dummy...",
    createdOn: "23-March-2021",
    createdBy: "Mushtaq Muzzamil",
    modifiedOn: "30-March-2021",
    modifiedBy: "Mushtaq Muzzamil",
  },
  {
    id: "SP-002",
    title: "Appartment",
    description:
    "Lorem Ipsum is simply dummy...",
    createdOn: "23-March-2021",
    createdBy: "Mushtaq Muzzamil",
    modifiedOn: "30-March-2021",
    modifiedBy: "Mushtaq Muzzamil",
  },
  {
    id: "SP-003",
    title: "Appartment",
    description:
    "Lorem Ipsum is simply dummy...",
    createdOn: "23-March-2021",
    createdBy: "Mushtaq Muzzamil",
    modifiedOn: "30-March-2021",
    modifiedBy: "Mushtaq Muzzamil",
  },
  {
    id: "SP-004",
    title: "Appartment",
    description:
    "Lorem Ipsum is simply dummy...",
    createdOn: "23-March-2021",
    createdBy: "Mushtaq Muzzamil",
    modifiedOn: "30-March-2021",
    modifiedBy: "Mushtaq Muzzamil",
  },
  {
    id: "SP-005",
    title: "Appartment",
    description:
    "Lorem Ipsum is simply dummy...",
    createdOn: "23-March-2021",
    createdBy: "Mushtaq Muzzamil",
    modifiedOn: "30-March-2021",
    modifiedBy: "Mushtaq Muzzamil",
  },
];
