import React, { useState, useImperativeHandle } from "react";
import { Dialog } from "primereact/dialog";

const PropertyAmentiesDialog = React.forwardRef(({}, ref) => {
  const [openAmenitiesDialog, setOpenAmenitiesDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  useImperativeHandle(ref, () => ({
    isVisible(params) {
      setDialogData(params);
      setOpenAmenitiesDialog(true);
    },
  }));

  const hideDialog = () => {
    setOpenAmenitiesDialog(false);
  };

  return ( 
    <Dialog
      visible={openAmenitiesDialog}
      style={{ width: "450px" }}
      header="All Amenities"
      modal
      className="p-fluid"
      onHide={hideDialog}
    >
      <div className="grid h-25rem">
        {dialogData?.map((item, i) => {
          return (
            <div className="col-6" key={i}>
              <div className="row">
                <img src={item.amenity.icon} style={{width:"20px"}} alt="hello"/>
                
                <h4 className="ml-2 mt-1"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  {item.amenity.title}
                </h4>
              </div>
            </div>
          );
        })}
      </div>
    </Dialog>
  );
});

export default PropertyAmentiesDialog;
