import React, { useState, useRef, useImperativeHandle } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";

const BookingFilterDialog = React.forwardRef(
  ({ getData, customRows, activePage }, ref) => {
    const toast = useRef();
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const [status, setStatus] = useState("pending");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleChangeStatus = (e) => {
      setStatus(e.value);
    };

    const handleStartDate = (e) => {
      setStartDate(e.target.value);
    };

    const handleEndDate = (e) => {
      setEndDate(e.target.value);
    };

    useImperativeHandle(ref, () => ({
      isVisible(params) {
        setOpenFilterDialog(true);
      },
    }));

    const statusData = [
      {
        value: "",
        label: "--",
      },
      {
        value: "pending",
        label: "Pending",
      },
      {
        value: "accepted",
        label: "Accepted",
      },
      {
        value: "confirmed",
        label: "Confirmed",
      },
      {
        value: "ongoing",
        label: "Ongoing",
      },
      {
        value: "completed",
        label: "Completed",
      },
      {
        value: "cancelled",
        label: "Cancelled",
      },
    ];

    const hideDialog = () => {
      setOpenFilterDialog(false);
    };

    const handleChangeFilter = () => {
      setOpenFilterDialog(false);

      let statusValue = status ? `&bookingStatus=${status}` : "";
      let startDateValue = startDate
        ? `&startDate=${startDate?.toISOString()}`
        : "";
      let endDateValue = endDate ? `&endDate=${endDate?.toISOString()}` : "";
      getData(
        activePage,
        customRows,
        statusValue,
        startDateValue,
        endDateValue
      );
    };

    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
        />

        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={handleChangeFilter}
        />
      </React.Fragment>
    );

    return (
      <Dialog
        visible={openFilterDialog}
        style={{ width: "500px" }}
        header="Booking Filters"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <Toast ref={toast} position="bottom-right" />
        <div className="row">
          <div className="field col">
            <div className="grid">
              <div className="col-6">
                <h6>Select Status</h6>
                <Dropdown
                  id="status"
                  value={status}
                  options={statusData}
                  optionLabel="label"
                  optionValue="value"
                  onChange={(e) => handleChangeStatus(e)}
                  placeholder="Select Status"
                />
              </div>
              <div className="col-6">
                <h6>Select Start Date</h6>
                <Calendar
                  id="icon"
                  showIcon
                  value={startDate}
                  onChange={(e) => handleStartDate(e)}
                  className="ml-1"
                />
              </div>
              <div className="col-6">
                <h6>Select End Date</h6>
                <Calendar
                  id="icon"
                  showIcon
                  value={endDate}
                  onChange={(e) => handleEndDate(e)}
                  className="ml-1"
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
);

export default BookingFilterDialog;
