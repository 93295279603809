import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Sidebar from "../../components/sidebar";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import "./style.css";
import {
  getAllSpaces,
  getAllSpaceGroup,
} from "../../services/spaceGroupType/index";
import { getAllAreas } from "../../services/area/index";
import { getAllUsersWithoutFilter } from "../../services/users/index";
import { getAllAmenities } from "../../services/amenities/index";
import { Button } from "primereact/button";
import Location from "./Location";
import { addProperty } from "../../services/properties/index";
import { ProgressSpinner } from "primereact/progressspinner";
import { ReactSVG } from 'react-svg'

const propertypes = [
  {
    label: "Private",
    value: "Private",
  },
  {
    label: "Commercial",
    value: "Commercial",
  },
];

const bathData = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const floodData = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const AddProperty = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [inputVal, setInputVal] = useState({
    userId: "",
    space: "",
    spaceGroup: "",
    areaVal: "",
    propTypeVal: "",
    listingTitle: "",
    propDescription: "",
    bedrooms: "",
    bathrooms: "",
    beds: "",
    customUrl: "",
    privateBathRoom: "",
    adults: "",
    childrens: "",
    infants: "",
    address: "",
    latitude: "",
    longitude: "",
    zipCode: "",
    pets: "",
    price: "",
    country: "",
    city: "",
    state: "",
    nightPrice: "",
    youtubeLink: "",
    floodRelief: "",
  });
  const [allSpaces, setAllSpaces] = useState([]);
  const [allSpacesGroup, setAllSpacesGroup] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [allAmenities, setAllAmenities] = useState([]);
  const [thumnails, setThumbnails] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allUsersList, setAllUsersList] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: 24.8607343,
    lng: 67.0011364,
    address: "DHA PHASE 2",
  });



  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("token"));
    getAllUsersWithoutFilter(token)
      .then((res) => {
        console.log(res,"res")
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          });
        });
        setAllUsersList(tempArr);
      })
      .catch((err) => { });
    getAllSpaces()
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            label: item.title,
            value: item.id,
          });
        });
        setAllSpaces(tempArr);
      })
      .catch((err) => { });
    getAllSpaceGroup()
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            label: item.title,
            value: item.id,
          });
        });
        setAllSpacesGroup(tempArr);
      })
      .catch((err) => { });
    getAllAreas()
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            label: item.title,
            value: item.id,
          });
        });
        setAllAreas(tempArr);
      })
      .catch((err) => { });
    getAllAmenities()
      .then((res) => {
        console.log(res, "ddddd")
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            label: item.title,
            value: item.id,
            isChecked: item.isChecked,
            icon: item.icon
          });
        });
        setAllAmenities(tempArr);
      })
      .catch((err) => { });
  }, []);

  const handleChange = (e) => {
    setInputVal({ ...inputVal, [e.target.name]: e.target.value });
  };

  const handleChangeAmenities = (item, ind) => {
    let tempArr = [...allAmenities];

    tempArr[ind].isChecked = !tempArr[ind].isChecked;
    setAllAmenities(tempArr);
  };

  const handleChangeThumbnail = (e) => {
    if (e.target.files) {
      setThumbnails([e.target.files]);
    }
  };
  const handleChangeGallery = (e) => {
    let tempArr = [...galleryImages];

    if (e.target.files) {
      tempArr.push(e.target.files);
      setGalleryImages(tempArr);
    }
  };

  const handleRemoveImg = (item, ind) => {
    let tempArr = [...galleryImages];
    tempArr.splice(ind, 1);
    setGalleryImages(tempArr);
  };

  const showToast = (msg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const handleCheckValidation = () => {
    if (inputVal.userId == "") {
      showToast("User is required");
    } else if (inputVal.space == "") {
      showToast("Space is required");
    } else if (inputVal.spaceGroup == "") {
      showToast("Space Type is required");
    } else if (inputVal.areaVal == "") {
      showToast("Area is required");
    } else if (inputVal.propTypeVal == "") {
      showToast("Property Type is required");
    } else if (inputVal.listingTitle == "") {
      showToast("Title is required");
    } else if (inputVal.bedrooms == "") {
      showToast("Number of bedrooms is required");
    } else if (inputVal.bathrooms == "") {
      showToast("Number of bathrooms is required");
    } else if (inputVal.beds == "") {
      showToast("Number of beds is required");
    } else if (inputVal.privateBathRoom == "") {
      showToast("Private bathroom field is required");
    } else if (inputVal.adults == "") {
      showToast("Number of adults is required");
    } else if (inputVal.childrens == "") {
      showToast("Number of children is required");
    } else if (inputVal.address == "") {
      showToast("Address is required");
    } else if (inputVal.country == "") {
      showToast("Country is required");
    } else if (inputVal.city == "") {
      showToast("City is required");
    } else if (inputVal.state == "") {
      showToast("State is required");
    } else if (inputVal.zipCode == "") {
      showToast("Zip Code is required");
    } else if (inputVal.price == "") {
      showToast("Price is required");
    } else if (inputVal.nightPrice == "") {
      showToast("Per Night Price is required");
    } else if (thumnails == null) {
      showToast("Thumbnail image is required");
    } else if (galleryImages.length < 1) {
      showToast("Gallery Images are required");
    } else {
      handleAddProperty();
    }
  };

  const handleAddProperty = () => {
    let token = JSON.parse(localStorage.getItem("token"));
    var formData = new FormData();

    formData.append("userId", inputVal.userId);
    formData.append("spaceId", inputVal.space);
    formData.append("spaceTypeId", inputVal.spaceGroup);
    formData.append("areaId", inputVal.areaVal);
    formData.append("propertyType", inputVal.propTypeVal);
    formData.append("title", inputVal.listingTitle);
    formData.append("description", inputVal.propDescription);
    formData.append("floodRelief", inputVal.floodRelief);

    formData.append("propertyCapacity[noOfAdults]", parseInt(inputVal.adults));
    formData.append(
      "propertyCapacity[noOfInfants]",
      parseInt(inputVal.infants)
    );
    formData.append("propertyCapacity[noOfPets]", parseInt(inputVal.pets));
    formData.append("propertyCapacity[noOfBeds]", parseInt(inputVal.beds));
    formData.append(
      "propertyCapacity[noOfChildren]",
      parseInt(inputVal.childrens)
    );
    formData.append(
      "propertyCapacity[noOfBedrooms]",
      parseInt(inputVal.bedrooms)
    );
    formData.append(
      "propertyCapacity[noOfBathrooms]",
      parseInt(inputVal.bathrooms)
    );
    formData.append(
      "propertyCapacity[isPrivateBathroom]",
      inputVal.privateBathRoom
    );

    formData.append("customUrl", inputVal.customUrl);

    formData.append("zipCode", inputVal.zipCode);
    formData.append("address", inputVal.address);
    formData.append("country", inputVal.country);
    formData.append("state", inputVal.state);
    formData.append("city", inputVal.city);
    formData.append("lat", inputVal.latitude);
    formData.append("lng", inputVal.longitude);

    for (let i = 0; i < allAmenities.length; i++) {
      if (allAmenities[i].isChecked) {
        formData.append("propertyAmenityIds[]", allAmenities[i].value);
      }
    }

    formData.append("youtubeVideoLink", inputVal.youtubeLink);
    for (let i = 0; i < galleryImages.length; i++) {
      formData.append("galleryFiles", galleryImages[i][0]);
    }
    for (let i = 0; i < thumnails.length; i++) {
      formData.append("thumbnailFile", thumnails[i][0]);
    }
    formData.append("price", inputVal.price);
    formData.append("perNightPrice", inputVal.nightPrice);

    setIsLoading(true);
    addProperty(formData, token)
      .then((res) => {
        setIsLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Property Add Successfully",
          life: 3000,
        });
        setTimeout(() => {
          navigate("/propertyList");
        }, 1500);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err, "asdsahdksahj");
      });
  };

  return (
    <div className="app-main">
      <Toast ref={toast} position="bottom-right" />
      <Sidebar active="properties" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title={`Add Property - Staywo`}
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />

          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-lg-12">
              <h5>Property Details</h5>
              <div className="row">
                <div className="col-lg-6">
                  <h6>Select User</h6>
                  <Dropdown
                    value={inputVal.userId}
                    options={allUsersList}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    optionLabel="label"
                    name="userId"
                    placeholder="Select User"
                  />
                </div>
                <div className="col-lg-6">
                  <h6>Space</h6>
                  <Dropdown
                    value={inputVal.space}
                    options={allSpaces}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    optionLabel="label"
                    name="space"
                    placeholder="Space"
                  />
                </div>
                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>Space Type</h6>
                  <Dropdown
                    value={inputVal.spaceGroup}
                    options={allSpacesGroup}
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    optionLabel="label"
                    name="spaceGroup"
                    placeholder="Space Type"
                  />
                </div>

                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>Area</h6>
                  <Dropdown
                    value={inputVal.areaVal}
                    options={allAreas}
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    optionLabel="label"
                    name="areaVal"
                    placeholder="Area"
                  />
                </div>
                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>Property Type</h6>
                  <Dropdown
                    value={inputVal.propTypeVal}
                    options={propertypes}
                    style={{
                      width: "100%",
                    }}
                    onChange={handleChange}
                    optionLabel="label"
                    name="propTypeVal"
                    placeholder="Property Type"
                  />
                </div>
                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>Title</h6>
                  <InputText
                    value={inputVal.listingTitle}
                    placeholder="Title"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="listingTitle"
                  />
                </div>
                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>No Of Bedrooms</h6>
                  <InputText
                    value={inputVal.bedrooms}
                    placeholder="Bedrooms"
                    type="number"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="bedrooms"
                  />

                  <h6 style={{ marginTop: "10px" }}>No Of Bathrooms</h6>
                  <InputText
                    value={inputVal.bathrooms}
                    placeholder="Bathrooms"
                    onChange={handleChange}
                    type="number"
                    style={{ width: "100%" }}
                    name="bathrooms"
                    step={0.5}
                  />
                </div>
                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>Property Description</h6>
                  <InputTextarea
                    value={inputVal.propDescription}
                    placeholder="Property Description"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="propDescription"
                    rows={5}
                    cols={30}
                  />
                </div>

                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>Flood Relief</h6>
                  <Dropdown
                    value={inputVal.floodRelief}
                    options={floodData}
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    optionLabel="label"
                    name="floodRelief"
                    placeholder="Flood Relief"
                  />
                </div>

                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>No Of Beds</h6>
                  <InputText
                    value={inputVal.beds}
                    placeholder="Beds"
                    type="number"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="beds"
                  />
                </div>

                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>Private Bathroom</h6>
                  <Dropdown
                    value={inputVal.privateBathRoom}
                    options={bathData}
                    style={{ width: "100%" }}
                    onChange={handleChange}
                    optionLabel="label"
                    name="privateBathRoom"
                    placeholder="Private Bathroom"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <h5 style={{ marginTop: "15px" }}>Guest Detail</h5>
              <div className="row">
                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>No Of Adults</h6>
                  <InputText
                    value={inputVal.adults}
                    placeholder="Adults"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="adults"
                    type="number"
                  />
                </div>
                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>No Of Childrens</h6>
                  <InputText
                    value={inputVal.childrens}
                    placeholder="Childrens"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="childrens"
                    type="number"
                  />
                </div>
                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>No Of Infants</h6>
                  <InputText
                    value={inputVal.infants}
                    placeholder="Infants"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="infants"
                    type="number"
                  />
                </div>
                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>No Of Pets</h6>
                  <InputText
                    value={inputVal.pets}
                    placeholder="Pets"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="pets"
                    type="number"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <h5 style={{ marginTop: "15px" }}>Location</h5>
              <Location
                handleChange={handleChange}
                inputVal={inputVal}
                setInputVal={setInputVal}
                setMapCenter={setMapCenter}
                mapCenter={mapCenter}
              />
            </div>

            <div className="col-lg-12">
              <h5 style={{ marginTop: "15px" }}>Amenities</h5>
              <div className="row">
                {allAmenities && allAmenities.length>0 && allAmenities.map((item, ind) => {
                  console.log(item)
                  return (
                    <div key={item.id} className="col-lg-2">
                      <div
                        className="field-checkbox"
                        onClick={() => handleChangeAmenities(item, ind)}
                      >
                        <Checkbox
                          inputId={item.id}
                          name={item.id}
                          value={item.isChecked}
                          checked={item.isChecked}
                        />
                        <label htmlFor="city1" style={{ display:"flex",alignItems:"center" }}>
                        <img src={item.icon} alt='mySvgImage'  style={{ width: 15,height:15,marginRight:"3px" }} />
                          {item.label}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-lg-12">
              <h5 style={{ marginTop: "15px" }}>Listing Price</h5>
              <div className="row">
                {/* <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>Price</h6>
                  <InputText
                    value={inputVal.price}
                    placeholder="Price"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="price"
                    type="number"
                  />
                </div> */}
                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>Per Night Price</h6>
                  <InputText
                    value={inputVal.nightPrice}
                    placeholder="Per Night Price"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="nightPrice"
                    type="number"
                  />
                </div>
                <div className="col-lg-6">
                  <h6 style={{ marginTop: "10px" }}>Custom URLs</h6>
                  <InputText
                    value={inputVal.customUrl}
                    placeholder="Custom URL"
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="customUrl"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <h5 style={{ marginTop: "15px" }}>Media</h5>
              {inputVal.youtubeLink !== "" && (
                <iframe
                  width="100%"
                  height="350"
                  src={inputVal.youtubeLink}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              )}

              <InputText
                value={inputVal.youtubeLink}
                placeholder="Youtube Video Link"
                onChange={handleChange}
                style={{ width: "100%", marginTop: "10px" }}
                name="youtubeLink"
              />
            </div>

            <div className="col-lg-12">
              <h5 style={{ marginTop: "15px" }}>Thumbnail</h5>
              <InputText
                placeholder="Upload Thumbnail"
                onChange={handleChangeThumbnail}
                type="file"
                style={{ width: "100%", marginTop: "10px" }}
              />
            </div>

            <div className="col-lg-12">
              <h5 style={{ marginTop: "15px" }}>Gallery Images</h5>
              <InputText
                placeholder="Upload Gallery"
                onChange={handleChangeGallery}
                type="file"
                style={{ width: "100%", marginTop: "10px" }}
              />

              {galleryImages.length > 0 &&
                galleryImages.map((item, ind) => {
                  return (
                    <p>
                      {item[0].name}{" "}
                      <span
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveImg(item, ind)}
                      >
                        X
                      </span>
                    </p>
                  );
                })}
            </div>
          </div>

          <div style={{ margin: "10px 0", textAlign: "right" }}>
            {isLoading ? (
              <ProgressSpinner />
            ) : (
              <Button
                label={"Add Proprety"}
                className="p-button-lg"
                style={{ fontSize: "12px", padding: "10px 8px" }}
                onClick={handleCheckValidation}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProperty;
