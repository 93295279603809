import React, { useState, useRef, useImperativeHandle } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputNumber } from "primereact/inputnumber";
import { addAmmount, deductAmount } from "../../services/wallet";
import { ProgressSpinner } from "primereact/progressspinner";

const AmmountDialog = React.forwardRef(({ getData }, ref) => {
  const toast = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [openAmountDialog, setOpenAmountDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [inputVal, setInputVal] = useState({
    add: 0,
    deduct: 0,
  });

  useImperativeHandle(ref, () => ({
    isVisible(params) {
      setDialogData(params);
      setOpenAmountDialog(true);
    },
  }));

  const handleChange = (e, name) => {
    setInputVal({ ...inputVal, [name]: e.value });
  };

  const hideDialog = () => {
    setOpenAmountDialog(false);
  };

  const handleAddDeductAmount = () => {
    if (dialogData?.type === "add") {
      let payload = {
        userId: dialogData?.data?.userId,
        amount: inputVal.add,
      };

      setIsLoading(true);
      addAmmount(payload).then((res) => {
        setIsLoading(false);
        if (res.data.statusCode == 400) {
          let tempArr = [];
          res?.data?.message.map((val, ind) => {
            tempArr.push({
              severity: "error",
              summary: "Error",
              detail: val,
              life: 3000,
            });
          });
          toast.current.show(tempArr);
        } else {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Amount Added Successfully",
            life: 3000,
          });
          setInputVal({
            add: 0,
          });
          setTimeout(() => {
            hideDialog();
            getData();
          }, 1000);
        }
      });
    } else {
      let payload = {
        userId: dialogData?.data?.userId,
        amount: inputVal.deduct,
      };

      setIsLoading(true);
      deductAmount(payload).then((res) => {
        setIsLoading(false);
        if (res.data.statusCode == 400) {
          let tempArr = [];
          res?.data?.message.map((val, ind) => {
            tempArr.push({
              severity: "error",
              summary: "Error",
              detail: val,
              life: 3000,
            });
          });
          toast.current.show(tempArr);
        } else {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Amount Deducted Successfully",
            life: 3000,
          });
          setInputVal({
            deduct: 0,
          });
          setTimeout(() => {
            hideDialog();
            getData();
          }, 1000);
        }
      });
    }
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      {!isLoading ? (
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={handleAddDeductAmount}
        />
      ) : (
        <ProgressSpinner
          style={{ width: "30px", height: "30px", marginBottom: "4px" }}
        />
      )}
    </React.Fragment>
  );

  return (
    <Dialog
      visible={openAmountDialog}
      style={{ width: "450px" }}
      header={dialogData?.type === "add" ? "Add Amount" : "Deduct Amount"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
      onHide={hideDialog}
    >
      <Toast ref={toast} position="bottom-right" />
      {dialogData?.type === "add" ? (
        <>
          <label>Amount</label>

          <div>
            <InputNumber
              style={{ marginTop: "20px" }}
              value={inputVal.add}
              onChange={(e) => handleChange(e, "add")}
            />
          </div>
        </>
      ) : (
        <>
          <label>Amount</label>
          <div>
            <InputNumber
              style={{ marginTop: "20px" }}
              value={inputVal.deduct}
              onChange={(e) => handleChange(e, "deduct")}
            />
          </div>
        </>
      )}
    </Dialog>
  );
});

export default AmmountDialog;
