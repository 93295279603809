import React, { useState, useEffect, useRef } from "react";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Accordion, AccordionTab } from "primereact/accordion";
import { TIMINGS } from "./../../constants/staticData";
import { RadioButton } from "primereact/radiobutton";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {
  getAllPolicies,
  updatePropertyPolicyById,
} from "../../services/properties";

const PropertyPolicies = ({
  inputVal,
  setInputVal,
  propertyDetails,
  handleChange,
}) => {
  const toast = useRef(null);
  const [isLoadingPolicies, setIsLoadingPolicies] = useState(false);
  const [allPolicies, setAllPolicies] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const getPolicies = () => {
    getAllPolicies()
      .then((res) => {
        setAllPolicies(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getPolicies();
  }, []);

  useEffect(() => {
    setInputVal({
      ...inputVal,
      instantBooking: propertyDetails.propertyPolicy?.instantBooking,
      governmentId: propertyDetails.propertyPolicy?.governmentIdRequired,
      guestPhoto: propertyDetails.propertyPolicy?.guestPhotoRequirement,
      bookingMsg: propertyDetails.propertyPolicy?.preBookingMessageRequired,
      checkIn: propertyDetails.propertyPolicy?.checkInWindow,
      checkOut: propertyDetails.propertyPolicy?.checkoutWindow,
    });
  }, [propertyDetails]);

  useEffect(() => {
    let tempArr = [...allPolicies];
    let result = allPolicies.findIndex(
      (item) => item.id == propertyDetails?.propertyPolicy?.cancellationPolicy
    );
    if (result !== -1) {
      tempArr[result].isSelected = true;
    }
    setAllPolicies(tempArr);
    setSelectedItem(propertyDetails.propertyPolicy?.cancellationPolicy);
  }, [propertyDetails]);

  const handleChangeCheck = (e) => {
    setInputVal({
      ...inputVal,
      [e.target.name]: e.target.checked,
    });
  };

  const handleChangeRadio = (item, ind) => {
    let tempArr = [...allPolicies];
    setSelectedItem(item.id);
    setAllPolicies(tempArr);
  };

  const handleUpdatePolicy = () => {
    let payload = {
      cancellationPolicy: `${selectedItem}`,
      propertyId: propertyDetails.id,
      instantBooking: inputVal.instantBooking,
      governmentIdRequired: inputVal.governmentId,
      preBookingMessageRequired: inputVal.bookingMsg,
      guestPhotoRequirement: inputVal.guestPhoto,
      checkInWindow: inputVal.checkIn,
      checkoutWindow: inputVal.checkOut,
    };

    setIsLoadingPolicies(true);
    updatePropertyPolicyById(propertyDetails.id, payload)
      .then((res) => {
        setIsLoadingPolicies(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Property Policy Updated Successfully",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoadingPolicies(false);
      });
  };

  return (
    <div className="col-lg-12">
      <Toast ref={toast} position="bottom-right" />
      <Accordion activeIndex={6}>
        <AccordionTab header="Policies">
          <h5 style={{ marginTop: "10px" }}>Basic Policies Configuration</h5>
          <div className="row">
            <div className="col-lg-4">
              <div className="field-checkbox">
                <Checkbox
                  inputId={"1"}
                  name={"instantBooking"}
                  value={inputVal.instantBooking}
                  checked={inputVal.instantBooking}
                  onChange={(e) => handleChangeCheck(e)}
                />
                <label htmlFor="city1" style={{ marginBottom: 0 }}>
                  Instant Booking
                </label>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="field-checkbox">
                <Checkbox
                  inputId={"2"}
                  name={"governmentId"}
                  value={inputVal.governmentId}
                  checked={inputVal.governmentId}
                  onChange={(e) => handleChangeCheck(e)}
                />
                <label htmlFor="city1" style={{ marginBottom: 0 }}>
                  Government ID Required?
                </label>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="field-checkbox">
                <Checkbox
                  inputId={"3"}
                  name={"guestPhoto"}
                  value={inputVal.guestPhoto}
                  checked={inputVal.guestPhoto}
                  onChange={(e) => handleChangeCheck(e)}
                />
                <label htmlFor="city1" style={{ marginBottom: 0 }}>
                  Guest Photo Required?
                </label>
              </div>
            </div>

            <div className="col-lg-12">
              <h5 style={{ marginTop: "15px" }}>Cancellation Policies</h5>
            </div>
            {allPolicies.length > 0 &&
              allPolicies.map((item, ind) => {
                return (
                  <div className="col-lg-12">
                    <div className="field-radiobutton">
                      <RadioButton
                        onChange={(e) => handleChangeRadio(item, ind)}
                        checked={selectedItem == item.id ? true : false}
                      />
                      <div style={{ marginLeft: "10px" }}>
                        <label
                          htmlFor="city1"
                          style={{ marginBottom: 0, fontSize: "14px" }}
                        >
                          {item.title}
                        </label>
                        <p style={{ marginBottom: 0, fontSize: "13px" }}>
                          {item.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className="col-lg-12">
              <h5 style={{ marginTop: "10px" }}>Check-In/Check-Out</h5>
            </div>
            <div className="col-lg-6">
              <h6>Check-In Window</h6>
              <Dropdown
                value={inputVal.checkIn}
                options={TIMINGS}
                onChange={handleChange}
                style={{ width: "100%" }}
                optionLabel="label"
                name="checkIn"
                placeholder="Select Check In Time"
              />
            </div>
            <div className="col-lg-6">
              <h6>Check-Out Window</h6>
              <Dropdown
                value={inputVal.checkOut}
                options={TIMINGS}
                onChange={handleChange}
                style={{ width: "100%" }}
                optionLabel="label"
                name="checkOut"
                placeholder="Select Check Out Time"
              />
            </div>
          </div>

          <div style={{ margin: "10px 0", textAlign: "right" }}>
            {isLoadingPolicies ? (
              <ProgressSpinner />
            ) : (
              <Button
                label={"Save Policies"}
                className="p-button-lg"
                style={{ fontSize: "12px", padding: "10px 8px" }}
                onClick={handleUpdatePolicy}
              />
            )}
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default PropertyPolicies;
