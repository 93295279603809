import React, { useState, useImperativeHandle } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";

const ViewImagesDialog = React.forwardRef(({ customRows }, ref) => {
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  useImperativeHandle(ref, () => ({
    isVisible(params) {
      setDialogData(params);
      setOpenStatusDialog(true);
    },
  }));

  const hideDialog = () => {
    setOpenStatusDialog(false);
  };

  return (
    <Dialog
      visible={openStatusDialog}
      style={{ width: "100%", margin: "0 10px" }}
      modal
      draggable={false}
      className="p-fluid"
      onHide={hideDialog}
    >
      <div>
        <h6 style={{ fontWeight: "600", fontSize: "20px", marginTop: "20px" }}>
          {dialogData?.title}
        </h6>
        <Image
          height="100%"
          width="100%"
          className="image-type"
          src={dialogData?.image}
        />
      </div>
    </Dialog>
  );
});

export default ViewImagesDialog;
