import React, { useState, useEffect } from "react";
import Banner from "../../components/banner";
import Sidebar from "../../components/sidebar";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";

import { getUserById } from "./../../services/users/index";
import {
  getBookingByOwnerId,
  getBookingByGuestId,
} from "./../../services/bookings/index";
import { getAllPropertiesById } from "./../../services/properties/index";
import { getCommentsById,postCommentsById } from "./../../services/customer/index";
import { useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import "../properties/style.css";
import PropertySection from "./PropertySection";
import OwnerSection from "./OwnerSection";
import GuestSection from "./GuestSections";
import CrudDataTable from "../../components/crudDataTable";
import Card from "../../components/card";
import moment from "moment/moment";
import {
  getWalletById,
  getTransactionHistoryById,
} from "../../services/wallet";
import { priceBodyTemplate } from "../../constants/utils";

const tabVal = [
  "pending",
  "accepted",
  "confirmed",
  "ongoing",
  "completed",
  "cancelled",
];

const UserDetails = () => {
  let { id } = useParams();
  const [userDetail, setUserDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [ownerLoading, setLoadingOwner] = useState(false);
  const [guestLoading, setLoadingGuest] = useState(false);
  const [propertiesLoading, setPropertiesLoading] = useState(false);
  const [ownerData, setOwnerData] = useState([]);
  const [guestData, setGuestData] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [userProperties, setUserProperties] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentsValue, setcommentsValue] = useState(" ");

  const [totalRecordsGuest, setTotalRecordsGuest] = useState(0);
  const [totalRecordsOwner, setTotalRecordsOwner] = useState(0);
  const [totalRecordsProperty, setTotalRecordsProperty] = useState(0);
  const [activeIndexGuestTab, setActiveIndexGuestTab] = useState(0);
  const [activeIndexOwnerTab, setActiveIndexOwnerTab] = useState(0);

  const pickComments=(e)=>{
     setcommentsValue(e.target.value);
    console.log(commentsValue);
}
const postComment=()=>{
  const payLoad={
    
    adminId:2,
    customerId:id,
    description:commentsValue
  }
  
postCommentsById(payLoad)
.then((res) => {
  setLoadingOwner(false);
  
  setcommentsValue(null);
  window.location.reload(false);
})
  .catch((err) => {
    setLoadingOwner(false);
  });

}
  const getData = () => {
    const user_token = JSON.parse(localStorage.getItem("token"));
    setIsLoading(true);
    getUserById(id, user_token)
      .then((res) => {
        console.log(res)
        setIsLoading(false);
        setUserDetail(res.data.data);
       
        getBookingByGuest(res.data.data.id, 0, 10, "pending");
        getBookingByOwner(res.data.data.id, 0, 10, "pending");
        getUserProperties(res.data.data.id, 0, 10);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getTransactionHistoryById(id)
      .then((res) => {
        setIsLoading(false);
        setTransactionHistory(res.data.data[0]);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getWalletById(id)
      .then((res) => {
        const { id, amount, user } = res?.data?.data;
        setIsLoading(false);
        let tempArr = [];
        tempArr.push({
          id: id,
          amount: amount,
          email: user?.email,
          userId: user?.id,
        });
        setWallets(tempArr);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getCommentsById(id)
      .then((res) => {
       setIsLoading(false);
        setComments(res?.data?.data);
        
      })
      .catch((err) => {
        setIsLoading(false);
      });

  };

  const getBookingByOwner = (id, page, limit, status) => {
    setLoadingOwner(true);
    getBookingByOwnerId(`ownerId=${id}`, page, limit, status)
      .then((res) => {
        setLoadingOwner(false);
        setOwnerData(res.data.data);
        setTotalRecordsOwner(res.data.total);
      })
      .catch((err) => {
        setLoadingOwner(false);
      });
  };
  const getBookingByGuest = (id, page, limit, status) => {
    setLoadingGuest(true);
    getBookingByGuestId(`guestId=${id}`, page, limit, status)
      .then((res) => {
        setLoadingGuest(false);
        setGuestData(res.data.data);
        setTotalRecordsGuest(res.data.total);
      })
      .catch((err) => {
        setLoadingGuest(false);
      });
  };
  const getUserProperties = (id, page, limit) => {
    setPropertiesLoading(true);
    getAllPropertiesById(id, page, limit)
      .then((res) => {
        setPropertiesLoading(false);
        setTotalRecordsProperty(res.data.total);
        setUserProperties(res.data.data);
      })
      .catch((err) => {
        setPropertiesLoading(false);
      });
  };

  useEffect(() => {
    getData();
   
  }, []);
  console.log("Newcomments---------->", comments);
  const handleChangeTabGuest = (e) => {
    setActiveIndexGuestTab(e.index);
    getBookingByGuest(userDetail.id, 0, 10, tabVal[e.index]);
  };

  const handleChangeTabOwner = (e) => {
    setActiveIndexOwnerTab(e.index);
    getBookingByOwner(userDetail.id, 0, 10, tabVal[e.index]);
  };

  const renderGuestTab = (heading, bookingStatus) => {
    return (
      <TabPanel header={heading}>
        <GuestSection
          getBookingByGuest={getBookingByGuest}
          totalRecordsGuest={totalRecordsGuest}
          guestData={guestData}
          guestLoading={guestLoading}
          userDetail={userDetail}
          bookingStatus={bookingStatus}
        />
      </TabPanel>
    );
  };

  const renderOwnerTab = (heading, bookingStatus) => {
    return (
      <TabPanel header={heading}>
        <OwnerSection
          getBookingByOwner={getBookingByOwner}
          totalRecordsOwner={totalRecordsOwner}
          ownerData={ownerData}
          ownerLoading={ownerLoading}
          userDetail={userDetail}
          bookingStatus={bookingStatus}
        />
      </TabPanel>
    );
  };

  const createdOnTemplate = (rowData) => {
    return (
      <span>{moment(rowData?.createdOn).format("DD MM YYYY hh:mm:ss")}</span>
    );
  };

  const walletData = [
    { id: 1, field: "id", header: "Id" },
    {
      id: 2,
      field: "amount",
      header: "Amount",
      body: (e) => priceBodyTemplate(e.amount),
    },
    { id: 3, field: "email", header: "Email" },
  ];

  const transactionData = [
    { id: 1, field: "id", header: "Id" },
    { id: 2, field: "description", header: "Description" },
    {
      id: 3,
      field: "previousAmount",
      header: "Previous Amount",
      body: (e) => priceBodyTemplate(e.previousAmount),
    },
    {
      id: 4,
      field: "newAmount",
      header: "New Amount",
      body: (e) => priceBodyTemplate(e.newAmount),
    },
    {
      id: 5,
      field: "difference",
      header: "Difference",
      body: (e) => priceBodyTemplate(e.difference),
    },
    { id: 6, field: "transactionType", header: "Transaction Type" },
    {
      id: 7,
      field: "createdOn",
      header: "Created On",
      body: createdOnTemplate,
    },
  ];

  return (
    <div className="app-main">
      <Sidebar active="users" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="User Details - Staywo"
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />

          {isLoading ? (
            <div style={{ textAlign: "center" }}>
              <ProgressSpinner />
            </div>
          ) : (
            <>
              {userDetail !== null ? (
                <div className="row">
                  <div className="col-sm-12">
                    <h4 style={{ marginTop: "15px" }}>User Details</h4>
                    <div style={{ background: "#fff", padding: "24px" }}>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={
                            userDetail?.profilePicture !== null
                              ? userDetail?.profilePicture
                              : "https://sales.ufaber.com/public/static/website/updated_teacherpanel/asset/img/images/dummy-avatar.jpg"
                          }
                          width="120px"
                          height="120px"
                          style={{
                            borderRadius: "50%",
                            //   border: "1px solid black",
                          }}
                        />
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Name: </h6>
                        <span className="address-style">
                          {userDetail?.firstName} {userDetail?.lastName}
                        </span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Username: </h6>
                        <span className="address-style">
                          {userDetail?.username}
                        </span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Email: </h6>
                        <span className="address-style">
                          {userDetail?.email}
                        </span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Phone Number: </h6>
                        <span className="address-style">
                          {userDetail?.phoneNumber}
                        </span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Status: </h6>
                        <span className="address-style">
                          {userDetail?.account_status_type}
                        </span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>
                          Preferred Language:{" "}
                        </h6>
                        <span className="address-style">
                          {userDetail?.preferredLanguage}
                        </span>
                      </div>
                      {userDetail?.description !== null && (
                        <div style={{ marginTop: "10px", display: "flex" }}>
                          <h6 style={{ fontWeight: "600" }}>
                            Preferred Language:{" "}
                          </h6>
                          <span className="address-style">
                            {userDetail?.description}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <h4 style={{ textAlign: "center" }}>No Data Found</h4>
              )}
            </>
          )}

          {guestData.length > 0 && (
            <h4 style={{ marginTop: "15px" }}>Trips List</h4>
          )}
          <TabView
            activeIndex={activeIndexGuestTab}
            onTabChange={(e) => handleChangeTabGuest(e)}
            style={{ marginTop: "20px" }}
          >
            {renderGuestTab("Pending", "pending")}
            {renderGuestTab("Accepted", "accepted")}
            {renderGuestTab("Confirmed", "confirmed")}
            {renderGuestTab("Ongoing", "ongoing")}
            {renderGuestTab("Completed", "completed")}
            {renderGuestTab("Cancelled", "cancelled")}
          </TabView>

          {ownerData.length > 0 && (
            <h4 style={{ marginTop: "15px" }}>Reservations List</h4>
          )}
          <TabView
            activeIndex={activeIndexOwnerTab}
            onTabChange={(e) => handleChangeTabOwner(e)}
            style={{ marginTop: "20px" }}
          >
            {renderOwnerTab("Pending", "pending")}
            {renderOwnerTab("Accepted", "accepted")}
            {renderOwnerTab("Confirmed", "confirmed")}
            {renderOwnerTab("Ongoing", "ongoing")}
            {renderOwnerTab("Completed", "completed")}
            {renderOwnerTab("Cancelled", "cancelled")}
          </TabView>

          <PropertySection
            getUserProperties={getUserProperties}
            totalRecordsProperty={totalRecordsProperty}
            userProperties={userProperties}
            propertiesLoading={propertiesLoading}
            userDetail={userDetail}
          />
          <div className="row">
            <div className="col-lg-12">
              <Card title="Transaction History">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={transactionData}
                    data={transactionHistory}
                    showActions={false}
                    showView={false}
                    showToolbar={false}
                    showDelete={true}
                    showEdit={false}
                    sortable={false}
                  />
                )}
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Card title="Wallet">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={walletData}
                    data={wallets}
                    tableTitle="Wallet"
                    showActions={false}
                    showToolbar={false}
                    sortable={false}
                  />
                )}
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Card title="Comments">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Profile Picture</th>
                      <th>Name</th>
                      <th>Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                   
               {
                comments&&
                comments.map((data,index)=>(
                  <>
                    <tr>
                      {data.customer.profilePicture?
                      <td>
                      <img
                        src={data.customer.profilePicture}
                        width="20%"
                        height="50px"
                        style={{ borderRadius:"80%"}}
                      /></td>
                      :
                        <td>
                          <img
                            src=""
                            width="30%"
                            height="70px"
                          /></td>
                      }
                      <td>{data.customer.firstName}</td>
                      <td>{data.description}</td>
                    </tr>
                  
                  </>
                ))
              }
                  </tbody>
                </table>
                <div className="form-group">
                <textarea className="form-control" rows="5" id="comment" 
                  placeholder="New Comment"
                 onChange={(e)=>pickComments(e)}></textarea>
                  <Button
                    
                    className="mt-2 h-2rem"
                    onClick={postComment}
                  >
                    Post
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
