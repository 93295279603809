import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getSystemConfiguration = (column) => {
  return Api(
    `${endPoints.sysConfigColumn}?column=${column}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const updateSystemConfiguration = (payload) => {
  return Api(
    `${endPoints.updateSysConfigColumn}`,
    payload,
    requestType.PATCH,
    null,
    null
  );
};
