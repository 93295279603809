import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllSafetyConsiderations = () => {
  return Api(
    `${endPoints.allSafetyConsiderations}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addSafetyConsideration = (params) => {
  return Api(
    `${endPoints.addSafetyConsideration}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editSafetyConsiderationById = (id, params) => {
  return Api(
    `${endPoints.editAmenity}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};

export const deleteSafetyConsiderationById = (id) => {
  return Api(
    `${endPoints.deleteSafetyConsideration}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};

// Group

export const getAllSafetyConsiderationsGroup = () => {
  return Api(
    `${endPoints.allSafetyConsiderationsGroup}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addSafetyConsiderationGroup = (params) => {
  return Api(
    `${endPoints.addSafetyConsiderationGroup}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editSafetyConsiderationGroupById = (id, params) => {
  return Api(
    `${endPoints.editSafetyConsiderationGroup}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteSafetyConsiderationGroupById = (id) => {
  return Api(
    `${endPoints.deleteSafetyConsiderationGroup}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
