import React, { useState } from "react";
import { useGeoJSON } from "react-simple-maps";
import Card from '../../components/card';
import Banner from '../../components/banner';
import { ComposableMap, Geographies, Geography, ZoomableGroup, Marker } from "react-simple-maps";
import Sidebar from '../../components/sidebar';


const Reports = () => {
    const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";
    const [view,setView]=useState('country')
   
const countries=[
    {
        name: "Pakistan",
        coordinates: [60.28, 40.11]
    },
    // {
    //     name:"Afghanistan",
    //     coordinates: [34.28	,55.11]
    // },
    
    // {
    //     name: "India",
    //     coordinates: [90.28, 50.11]
    // },
    // {
    //     name:"Bangladesh",
    //     coordinates: [15.43,140.26]
    // },
    // {
    //     name:"Nepal",
    //     coordinates: [80.45,95.20]
    // },
    // {
    //     name:"China",
    //     coordinates: [30.55,160.20]
    // },
    // {
    //     name: "Bhutan",
    //     coordinates: [22.31,	80.45]
    // },
    // {
    //     name: "Srilanka",
    //     coordinates: [4.8731, 60.7718]
    // },
   
    
]

const states=[
    {
        name:"Punjab",
        coordinates:[36.1704 , 65.7097]
    },
    {
        name: "Sindh",
        coordinates: [10.894, 50.5247]
    },
    {
        name: "Balochistan",
        coordinates: [20.4907, 35.0958]
    },
    {
        name: "K.P.K",
        coordinates: [10.9526, 90.3311]
    },
    {
        name: "N.W.F.P",
        coordinates: [65.9526, 80.3311]
    },
]

const cities=[
    {
        name: "Gilgit, Balochistan",
        coordinates: [29.920834,74.308334],
        property:"10"
},
{
        name: "Bahawalpur, Punjab",
    coordinates: [29.143644 + 40, 80.257240 + 20],
        property:"10"
},
{
        name: "Gujranwala, Punjab",
    coordinates: [31.976515 + 60,	74.222015+30],
        property:"10"
},
{
        name: "Khushab, Punjab",
    coordinates: [32.294445 + 80, 72.349724 + 40],
        property:"10"
},
    {
        name: "Sialkot, Punjab",
        coordinates: [32.497223 + 150, 74.536110 + 50],
        property: "10"
    },
    {
        name: "Faisalabad, Punjab",
        coordinates: [31.368967 + 140, 72.942528 +10],
        property: "10"
    }, {
        name: "Islamabad, Punjab",
        coordinates: [33.738045 + 130, 73.084488 + 50],
        property: "10"
    }, 
    {
        name: "Jamshero, Sindh",
        coordinates: [25.416868 + 120, 68.274307 + 60],
        property: "10"
    },
    {
        name: "Jhang, Sindh",
        coordinates: [31.278046 + 110, 72.311760 + 70],
        property: "10"
    },
    {
        name: "Shahpur, Punjab",
        coordinates: [32.286613 + 100, 72.430252 + 80],
        property: "10"
    },
    {
        name: "Karachi, Sindh",
        coordinates: [24.860966 + 70, 66.990501 + 110],
        property: "10"
    },
    {
        name: "Daska, Punjab",
        coordinates: [32.338779 + 60, 74.353065 + 120],
        property: "10"
    },
    {
        name: "Rawalpindi, Punjab",
        coordinates: [25.416868 + 40, 68.274307 + 140],
        property: "10"
    },
    {
        name: "Mirpur Khas, Sindh",
        coordinates: [25.529104 + 20, 69.013573 + 160],
        property: "10"
    },
    {
        name: "Toba Tek Singh, Punjab",
        coordinates: [30.767956, 72.437813+15],
        property: "10"
    },
    {
        name: "Jacobabad, Sindh",
        coordinates: [28.281891, 68.438171 + 30],
        property: "10"
    },
    {
        name: "Sahiwal,Punjab",
        coordinates: [30.677717, 73.106812+45],
        property: "10"
    },
    {
        name: "Narowal,Punjab",
        coordinates: [32.337006, 74.903336+60],
        property: "10"
    },
    {
        name: "Khanewal,Punjab",
        coordinates: [30.286415, 71.932030+75],
        property: "10"
    },
    {
        name: "Jarawala,Punjab",
        coordinates: [31.345394, 73.429810+90],
        property: "10"
    },
    {
        name: "Azad Kashmir",
        coordinates: [33.148392, 73.751770+105],
        property: "10"
    },
    {
        name: "Multan,Punjab",
        coordinates: [30.181459, 71.492157+120],
        property: "10"
    },
    {
        name: "Nawabshah,Sindh",
        coordinates: [26.244221+20, 68.410034+135],
        property: "10"
    },
    {
        name: "Dera Ghazi Khan,Punjab",
        coordinates: [30.032486 + 50, 70.640244 + 20],
        property: "10"
    },
    {
        name: "Larkana,Sindh",
        coordinates: [27.563993 + 60, 68.215134 + 30],
        property: "10"
    },
    {
        name: "Malakwal,Punjab",
        coordinates: [32.555496 + 70, 73.194351 + 40],
        property: "10"
    },
    {
        name: "Haveli Lakha,Punjab",
        coordinates: [30.448601 + 20, 73.697578 + 50],
        property: "10"
    },
    {
        name: "Nowshera,Khyber pakhtunkhwa",
        coordinates: [34.015858 + 20, 71.975449 + 60],
        property: "10"
    },
    {
        name: "Hafizabad,Punjab",
        coordinates: [32.071697 + 20, 73.685730 + 70],
        property: "10"
    },
    {
        name: "Vehari,Punjab",
        coordinates: [30.045246 + 20, 72.348869 + 80],
        property: "10"
    },
    {
        name: "Okara,Punjab",
        coordinates: [30.808500 + 20, 73.459396 + 90],
        property: "10"
    },
    {
        name: "Abbottabad,Khyber Pakhtunkhwa",
        coordinates: [34.168751 + 20, 73.221497 + 100],
        property: "10"
    },
    {
        name: "Attock,Punjab",
        coordinates: [33.768051 + 20, 72.360703 + 120],
        property: "10"
    },
    {
        name: "Mandi Bahauddin,Punjab",
        coordinates: [32.588169 + 20, 73.497345 + 130],
        property: "10"
    },
    {
        name: "Bahawalpur,Punjab",
        coordinates: [29.395721 + 20, 71.683334 + 140],
        property: "10"
    },
    {
        name: "Karak,Khyber Pakhtunkhwa",
        coordinates: [33.115269 - 20, 71.095535 + 150],
        property: "10"
    },
    {
        name: "Muzaffargarh,Punjab",
        coordinates: [30.074377 - 90, 71.184654 + 70],
        property: "10"
    },
    {
        name: "Shikarpur,Sindh",
        coordinates: [27.955648 - 90, 68.637672 + 60],
        property: "10"
    },
    {
        name: "Khairpur,Sindh",
        coordinates: [27.529951 -90, 68.758141 + 50],
        property: "10"
    },
    {
        name: "Kamoki,Punjab",
        coordinates: [31.975508 -90, 74.223801 + 40],
        property: "10"
    },
    {
        name: "Sargodha,Punjab",
        coordinates: [32.082466 -90, 72.669128 + 50],
        property: "10"
    },
    {
        name: "Paktoki,Punjab",
        coordinates: [31.025009 -90, 73.847878 + 60],
        property: "10"
    },
    {
        name: "Bhawal,Punjab",
        coordinates: [32.265396 -90, 72.905388 + 70],
        property: "10"
    },
    {
        name: "Nankhana,Sahib",
        coordinates: [31.452097 -90, 73.708305 + 80],
        property: "10"
    },
]

const handleSelect=(e)=>{
  setView(e.target.value)
  console.log(e.target.value,"================>VIEW")
}

   return (
      <div className='app-main'>
          <Sidebar active='reports' />
          
                  <div className='app-main__outer'>
                      <div className='app-main__inner'>
                          <Banner
                              title='All Properties - Staywo'
                              icon='pe-7s-plugin icon-gradient bg-mean-fruit'
                          />
                  <div className='row'>
                      <div className='col-lg-12'>
                  <Card title='property reports'>
                      <div className="row">
                      <div className="col-4">
                        <select
                            name="Select Map View"
                            className="dropDownIcon border p-2 rounded "
                            aria-label="Default select example"
                            onChange={handleSelect}
                                style={{ backgroundColor:"#008AD8",color:"white"}}
                        >
                           <option value="country" style={{ backgroundColor: "white", color: "black" }}>View by country</option>
                            <option value="cities" style={{ backgroundColor: "white", color: "black" }}>View by cities</option>
                            <option value="states" style={{ backgroundColor: "white", color: "black" }}>View by states</option>
                           
                        </select>
                      </div>
                              <div className="col-8">

                                       <ComposableMap>
                                           <ZoomableGroup zoom={view === "country" && 1||view === "states" && 3||
                                               view === "cities" && 2 }>
                                <Geographies geography={geoUrl}>
                                    {({ geographies }) =>
                                        geographies.map((geo) => (

                                            <Geography
                                                key={geo.rsmKey}
                                                geography={geo}
                                                fill="#EAEAEC"
                                                stroke="#D6D6DA"
                                                r={2}

                                            />


                                        ))
                                    }
                                </Geographies>
                            </ZoomableGroup>
                            {
                                view==="country"&&
                                countries.map((data)=>{
                                    return(
                                        <Marker key={data} coordinates={data.coordinates}>
                                            <g
                                                fill="none"
                                                stroke="#FF5533"
                                                strokeWidth={10}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                transform="translate(-50, -24)"
                                            >
                                                <circle cx="12" cy="10" r="3" />
                                                <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                                            </g>
                                            <text
                                                textAnchor="middle"
                                                y="-30"
                                                style={{ fontFamily: "system-ui", fill: "#5D5A6D", fontSize: "30px"}}
                                                
                                            >
                                                {data.name}
                                            </text>
                                        </Marker>
                                    )
                                })
                            }
                            {
                                view==="states"&&
                                states.map((data)=>{
                                    return(
                                           <Marker key={data} coordinates={data.coordinates}>
                                               <g
                                                   fill="none"
                                                   stroke="#FF5533"
                                                   strokeWidth="2"
                                                   strokeLinecap="round"
                                                   strokeLinejoin="round"
                                                   transform="translate(-12, -24)"
                                               >
                                                   <circle cx="12" cy="10" r="3" />
                                                   <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                                               </g>
                                               <text
                                                   textAnchor="middle"
                                                   y="-30"
                                                   style={{ fontFamily: "system-ui", fill: "#5D5A6D",  }}

                                               >
                                                   {data.name}
                                               </text>
                                           </Marker>
                                           )
                                })
                            }

                            {
                                view === "cities" &&
                                cities.map((data) => {
                                    
                                    return (
                                        <Marker key={data} coordinates={data.coordinates}>
                                            <g
                                                fill="none"
                                                stroke="#FF5533"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                transform="translate(-12, -24)"
                                            >
                                                <circle cx="12" cy="10" r="3" />
                                                <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                                            </g>
                                            <text
                                                textAnchor="middle"
                                                y="-30"
                                                style={{ fontFamily: "system-ui", fill: "#5D5A6D", }}

                                            >
                                                {data.name}
                                            </text>
                                        </Marker>
                                    )
                                    
                                })
                            }

                            
                        </ComposableMap>
                      </div>
                      </div>
                      </Card>
                      </div>
                      </div>
                      </div>
                      </div>
                      
                          
          

     </div>
      
  )
}

export default Reports