import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Sidebar from "../../components/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import { TabPanel, TabView } from "primereact/tabview";
import {
  addVerifyUser,
  deleteVerifyUserById,
  getVerifyUsersByLimit,
} from "./../../services/verifyUsers/index";
import {
 
  updateProfilePicture,
  
} from "./../../services/users";
import { Image } from "primereact/image";
import Pagination from "../../components/pagination";
import CrudDataTable from "../../components/crudDataTable/index";
import { Toast } from "primereact/toast";
import { Country } from "country-state-city";
import { Button } from "primereact/button";
import VerifyUserStatusDialog from "./StatusDialog";
import { getAllUsers } from "../../services/users";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputNumber } from "primereact/inputnumber";
import ViewDetailsDialog from "./ViewDetailDialog";
import FileUploader from "../../components/formComponents/fileUploader";
import { Navigate } from "react-router-dom";


const idCardType = [
  {
    lable: "Passport",
    value: "passport",
  },
  {
    lable: "CNIC",
    value: "cnic",
  },
];

const VerifyUsers = () => {
  let emptyProduct = {
    userId: "",
    cardType: "",
    countryName: "",
  };
  const user_token = JSON.parse(localStorage.getItem("token"));
  const [users, setUsers] = useState([]);
  const [verifyUsers, setVerifyUsers] = useState([]);
  const [rejectedUsers, setRejectedUsers] = useState([]);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [rows, setRows] = useState(10);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customFirst, setCustomFirst2] = useState(0);
  const [customRows, setCustomRows2] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [frontPic, setFrontPic] = useState([]);
  const [frontPicURLs, setFrontPicURLs] = useState([]);
  const [backPic, setBackPic] = useState([]);
  const [backPicURLs, setBackPicURLs] = useState([]);
  const [livePic, setLivePic] = useState([]);
  const [livePicURLs, setLivePicURLs] = useState([]);
  const [email, setEmail] = useState('');
  const [selected, setSelected] = useState(null);
  const imageRef = useRef();
  const toast = useRef(null);
  const dialogRef = useRef();
  const viewDialogRef = useRef();
  const [activeIndexTab, setActiveIndexTab] = useState(0);
  const handleChangeTab = (e) => {
    console.log(e,"===========>index");
    setActiveIndexTab(e.index);
    // getData(userDetail.id, 0, 10, tabVal[e.index]);
  };
  const handleView = (rowData) => {
    Navigate(`/userDetails/${rowData.id}`);
  };
  const handleChangePicture = (e, rowData) => {
    var data = new FormData();
    data.append("file", e.target.files[0]);
    setIsLoading(true);
    updateProfilePicture(rowData.id, data, user_token)
      .then((res) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Profile Image Updated Successfully",
          life: 3000,
        });
        getData("ASC", 1, rows);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getData = (order, page, limit) => {
    setIsLoading(true);
    getAllUsers(user_token)
      .then((res) => {
        setTotalRecords(res.data.data.meta.itemCount);
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            id: item.id,
            userName: `${item.firstName} ${item.middleName} ${item.lastName} ${item.user.code}`,
          });
        });
        setUsers(tempArr);
        setIsLoading(false);
      })
      .catch((err) => {});
    getVerifyUsersByLimit(order, page, limit, user_token)
      .then((res) => {
        console.log(res,"ddd")
        setIsLoading(false);
        let tempArr = [];
        let tempArr1 = [];
        let tempArr2 = [];
        let tempArr3=[];
        res.data.data.map((item) => {
          tempArr.push({
            ...item,
            userName: `${item.user.firstName} ${item.user.middleName} ${item.user.lastName}   ${`(${item.user.code ? item.user.code:"-"})`}`,
            email: item.user.email,
          });
        });
        setVerifyUsers(tempArr);
        res?.data?.data?.map((item) => {

          if (item.status === "Rejected") {
            console.log(item, "============>pendingloopdata")
             tempArr1.push({
              ...item,
               userName: `${item.user.firstName} ${item.user.middleName} ${item.user.lastName} ${`(${item.user.code ? item.user.code : "-"})`}`,
              email: item.user.email,
            });
           
          }
        })
        setRejectedUsers(tempArr1);
        res?.data?.data?.map((item) => {

          if (item.status === "Approved") {
           
            tempArr2.push({
              ...item,
              userName: `${item.user.firstName} ${item.user.middleName} ${item.user.lastName}  ${`(${item.user.code ? item.user.code : "-"})`}`,
              email: item.user.email,
            });
          }
        })
        setApprovedUsers(tempArr2);
        res?.data?.data?.map((item) => {

          if (item.status === "Pending") {

            tempArr3.push({
              ...item,
              userName: `${item.user.firstName} ${item.user.middleName} ${item.user.lastName}  ${`(${item.user.code ? item.user.code : "-"})`}`,
              email: item.user.email,
            });
          }
        })
        setPendingUsers(tempArr3);
        
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getData("ASC", 1, 10);

    
  }, []);
 
  console.log(verifyUsers?.user?.code,"============>data")
  console.log(rejectedUsers, "============>pendingdata")
  console.log(approvedUsers, "============>approveddata")

  const handleAddEdit = (product, setProduct, setProductDialog, type) => {
    const { userId, cardType, countryName } = product;

    if (product.userId == "") {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "Please select user",
        life: 3000,
      });
    } else if (product.cardType == "") {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "Please select card type",
        life: 3000,
      });
    } else if (product.countryName == "") {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "Please select country name",
        life: 3000,
      });
    } else if (frontPic.length < 0) {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "Please upload front image",
        life: 3000,
      });
    } else if (backPic.length < 0) {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "Please upload back image",
        life: 3000,
      });
    } else if (livePic.length < 0) {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "Please upload live image",
        life: 3000,
      });
    } else {
      var formData = new FormData();
      formData.append("userId", userId);
      formData.append("cardType", cardType);
      formData.append("countryName", countryName);
      for (let i = 0; i < frontPic.length; i++) {
        formData.append("frontPic", frontPic[i]);
      }
      for (let i = 0; i < backPic.length; i++) {
        formData.append("backPic", backPic[i]);
      }
      for (let i = 0; i < livePic.length; i++) {
        formData.append("livePic", livePic[i]);
      }
      if (type === "add") {
        setIsLoading(true);
        addVerifyUser(formData, user_token)
          .then((res) => {
            setIsLoading(false);
            if (res.data.statusCode == 400) {
              let tempArr = [];
              res?.data?.message.map((val, ind) => {
                tempArr.push({
                  severity: "error",
                  summary: "Error",
                  detail: val,
                  life: 3000,
                });
              });
              setProductDialog(false);
              toast.current.show(tempArr);
            } else {
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Requested Successfully",
                life: 3000,
              });
              setProduct({
                userId: "",
                cardType: "",
                countryName: "",
              });
              setFrontPic([]);
              setBackPic([]);
              setLivePic([]);
              getData("ASC", 1, 10);
              setTimeout(() => {
                setProductDialog(false);
              }, 1500);
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    }
  };

  const deleteSelectedItem = (id, setOpenDialog) => {
    setIsLoading(true);
    deleteVerifyUserById(id, user_token).then((res) => {
      setIsLoading(false);
      if (res.data.statusCode == 400) {
        let tempArr = [];
        res?.data?.message.map((val, ind) => {
          tempArr.push({
            severity: "error",
            summary: "Error",
            detail: val,
            life: 3000,
          });
        });
        setOpenDialog(false);
        toast.current.show(tempArr);
      } else {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "User Deleted Successfully",
          life: 3000,
        });
        getData("ASC", 1, 10);
        setTimeout(() => {
          setOpenDialog(false);
        }, 1500);
      }
    });
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <>
      <Image
        height="51px"
        width="90px"
        className="image-type"
        src={rowData?.livePicture ? rowData?.livePicture :"https://sales.ufaber.com/public/static/website/updated_teacherpanel/asset/img/images/dummy-avatar.jpg"}
      />
        <input
          style={{ visibility: "hidden", height: "0" }}
          ref={imageRef}
          onChange={(e) => handleChangePicture(e, rowData)}
          type={"file"}
        />
      <Button
          onClick={(e) => {
            e.stopPropagation();
            imageRef.current.click();
            setSelected(rowData.id);
          }}
          className="mt-2 h-2rem"
        >
          Update
        </Button>
        </>
    );
  };

  const statusTepmlate = (rowData) => {
    return (
      <>
        <div style={{ marginBottom: "10px" }}>
          <span
            style={{
              fontWeight: 500,
              background: "#c8e6c9",
              color: "#256029",
              padding: "5px 10px",
            }}
          >
            {rowData.status}
          </span>
        </div>
        <Button
          label="Change"
          className="p-button-sm"
          style={{ fontSize: "12px", padding: "6px 8px" }}
          onClick={() => {
            dialogRef.current.isVisible(rowData);
          }}
        />
      </>
    );
  };
  const viewTepmlate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-raised mr-2"
          onClick={() => {
            viewDialogRef.current.isVisible(rowData);
          }}
        />
      </>
    );
  };

  const DATA = [
    { id: 1, field: "id", header: "Id" },
    {
      id: 2,
      field: "profilePicture",
      header: "Profile Picture",
      body: imageBodyTemplate,
    },
    { id: 3, field: "userName", header: "User Name" },
    
    { id: 4, field: "email", header: "Email" },
    { id: 5, field: "country", header: "Country" },
    { id: 6, field: "status", header: "Status", body: statusTepmlate },
    { id: 7, header: "Actions", body: viewTepmlate },
  ];

  const onPageChange = (event) => {
    setCustomFirst2(event.first);
    setCustomRows2(event.rows);
    getData("ASC", event.page + 1, event.rows);
  };

  const renderDialog = (
    product,
    submitted,
    onInputChange,
    onInputNumberChange
  ) => {
    return (
      <>
        <div className="formgrid grid">
          <div className="field col-6">
            <Dropdown
              value={product.userId}
              options={users}
              optionLabel="userName"
              optionValue="id"
              onChange={(e) => onInputChange(e, "userId")}
              placeholder="Select User"
            />
          </div>
          <div className="field col-6">
            <Dropdown
              value={product.cardType}
              options={idCardType}
              optionLabel="lable"
              optionValue="value"
              onChange={(e) => onInputChange(e, "cardType")}
              placeholder="Select Id Card Type"
            />
          </div>
          <div className="field col-6">
            <Dropdown
              value={product.countryName}
              options={Country?.getAllCountries()}
              optionLabel="name"
              optionValue="name"
              onChange={(e) => onInputChange(e, "countryName")}
              placeholder="Select Country"
            />
          </div>
        </div>
        <Card title="Media">
          <div className="field col">
            <FileUploader
              setThumbnailURLs={setFrontPicURLs}
              thumbnailURLs={frontPicURLs}
              thumbnail={frontPic}
              setThumbnail={setFrontPic}
              title="Upload Front"
            />
          </div>
          <div className="field col">
            <FileUploader
              setThumbnailURLs={setBackPicURLs}
              thumbnailURLs={backPicURLs}
              thumbnail={backPic}
              setThumbnail={setBackPic}
              title="Upload Back"
            />
          </div>
          <div className="field col">
            <FileUploader
              setThumbnailURLs={setLivePicURLs}
              thumbnailURLs={livePicURLs}
              thumbnail={livePic}
              setThumbnail={setLivePic}
              title="Upload Live Pic"
            />
          </div>
        </Card>
      </>
    );
  };
  const renderViewDialog = (product) => {
    return (
      <>
        <div className="field">
          <label htmlFor="name">Status</label>

          <InputText
            id="name"
            value={product.propertyName}
            required
            autoFocus
            className={classNames({
              "p-invalid": !product.propertyName,
            })}
          />
        </div>
      </>
    );
  };
  const renderTab = (heading) => {
    console.log(heading,"======>verify heading")
    if(activeIndexTab===0){
    return (
      
    
       
      <TabPanel header={heading}>
        <div className="row">
          <div className="col-lg-12">
            <Card title="Verify Users">
              {isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <ProgressSpinner />
                </div>
              ) : (
                <>
                <div className="row justify-content-end">
                <div className="form form-inline">
                <InputText className="mr-2 mb-3" placeholder="search email..."
                onChange={(e)=>setEmail(e.target.value)}/>
                
                <Button
                label="Search"
                className="mr-3 mb-3"

                />
                      </div>
                    </div>
                <CrudDataTable
                  headerColumns={DATA}
                  data={verifyUsers}
                  totalRecords={totalRecords}
                  renderDialog={renderDialog}
                  emptyProduct={emptyProduct}
                  renderViewDialog={renderViewDialog}
                  dialogHeader="Verify User Details"
                  dialogHeaderView="Verify User Details"
                  tableTitle="Verify Users"
                  showToolbar={true}
                  showActions={false}
                  sortable={false}
                  addEditFunction={handleAddEdit}
                  showEdit={false}
                  deleteSelectedItem={deleteSelectedItem}
                  showDelete={true}
                />
                </>
              )}
              <Pagination
                totalRecords={totalRecords}
                customFirst={customFirst}
                customRows={customRows}
                onPageChange={onPageChange}
              />
            </Card>
          </div>
        </div>
      </TabPanel>
              
      
      
     
        
      
      
              
     
    );
      }
    if (activeIndexTab === 1) {
      return (



        <TabPanel header={heading}>
          <div className="row">
            <div className="col-lg-12">
              <Card title="Approved Users">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <>
                  <div className="row justify-content-end">
                <div className="form form-inline">
                <InputText className="mr-2 mb-3" placeholder="search email..."
                onChange={(e)=>setEmail(e.target.value)}/>
                
                <Button
                label="Search"
                className="mr-3 mb-3"

                />
                      </div>
                    </div>
                  <CrudDataTable
                    headerColumns={DATA}
                    data={approvedUsers}
                    totalRecords={totalRecords}
                    renderDialog={renderDialog}
                    emptyProduct={emptyProduct}
                    renderViewDialog={renderViewDialog}
                    dialogHeader="Verify User Details"
                    dialogHeaderView="Verify User Details"
                    tableTitle="Verify Users"
                    showToolbar={true}
                    showActions={false}
                    sortable={false}
                    addEditFunction={handleAddEdit}
                    showEdit={false}
                    deleteSelectedItem={deleteSelectedItem}
                    showDelete={true}
                  />
                    </>
                )}
                <Pagination
                  totalRecords={totalRecords}
                  customFirst={customFirst}
                  customRows={customRows}
                  onPageChange={onPageChange}
                />
              </Card>
            </div>
          </div>
        </TabPanel>









      );
    }
    if (activeIndexTab === 2) {
      return (



        <TabPanel header={heading}>
          <div className="row">
            <div className="col-lg-12">
              <Card title="Rejected Users">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                    <>
                      <div className="row justify-content-end">
                        <div className="form form-inline">
                          <InputText className="mr-2 mb-3" placeholder="search email..."
                            onChange={(e) => setEmail(e.target.value)} />

                          <Button
                            label="Search"
                            className="mr-3 mb-3"

                          />
                        </div>
                      </div>
                  <CrudDataTable
                    headerColumns={DATA}
                    data={rejectedUsers}
                    totalRecords={totalRecords}
                    renderDialog={renderDialog}
                    emptyProduct={emptyProduct}
                    renderViewDialog={renderViewDialog}
                    dialogHeader="Verify User Details"
                    dialogHeaderView="Verify User Details"
                    tableTitle="Verify Users"
                    showToolbar={true}
                    showActions={false}
                    sortable={false}
                    addEditFunction={handleAddEdit}
                    showEdit={false}
                    deleteSelectedItem={deleteSelectedItem}
                    showDelete={true}
                  />
                  </>
                )}
                <Pagination
                  totalRecords={totalRecords}
                  customFirst={customFirst}
                  customRows={customRows}
                  onPageChange={onPageChange}
                />
              </Card>
            </div>
          </div>
        </TabPanel>









      );
    }
    if (activeIndexTab === 3) {
      return (



        <TabPanel header={heading}>
          <div className="row">
            <div className="col-lg-12">
              <Card title="Pending Users">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <>
                    <div className="row justify-content-end">
                      <div className="form form-inline">
                        <InputText className="mr-2 mb-3" placeholder="search email..."
                          onChange={(e) => setEmail(e.target.value)} />

                        <Button
                          label="Search"
                          className="mr-3 mb-3"

                        />
                      </div>
                    </div>
                    <CrudDataTable
                      headerColumns={DATA}
                      data={pendingUsers}
                      totalRecords={totalRecords}
                      renderDialog={renderDialog}
                      emptyProduct={emptyProduct}
                      renderViewDialog={renderViewDialog}
                      dialogHeader="Verify User Details"
                      dialogHeaderView="Verify User Details"
                      tableTitle="Verify Users"
                      showToolbar={true}
                      showActions={false}
                      sortable={false}
                      addEditFunction={handleAddEdit}
                      showEdit={false}
                      deleteSelectedItem={deleteSelectedItem}
                      showDelete={true}
                    />
                  </>
                )}
                <Pagination
                  totalRecords={totalRecords}
                  customFirst={customFirst}
                  customRows={customRows}
                  onPageChange={onPageChange}
                />
              </Card>
            </div>
          </div>
        </TabPanel>









      );
    }
  };

  return (
    <div className="app-main">
      <Toast ref={toast} position="bottom-right" />
      <Sidebar active="verifyUsers" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="All Verify Users - Staywo"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <TabView
            activeIndex={activeIndexTab}
            onTabChange={(e) => handleChangeTab(e)}
            className="users-tab-style"
          >
            {renderTab("Verify Users", "verify users")}
            {renderTab("Approved", "accepted users")}
            {renderTab("Rejected", "rejected users")}
            {renderTab("Pending", "pending users")}
          </TabView>

          
        </div>
      </div>

      <VerifyUserStatusDialog customRows={customRows} ref={dialogRef} />
      <ViewDetailsDialog ref={viewDialogRef} />
    </div>
  );
};

export default VerifyUsers;
