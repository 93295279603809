import React, { useState, useEffect } from "react";
import styled from "styled-components";
// prime react
import { Calendar } from "primereact/calendar";
import moment from "moment";
import "./style.css";

// import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
// import Footer from "react-multi-date-picker/plugins/range_picker_footer";

//Simple date picker component
const DatePickers = ({
  availableToday,
  handleDateStart,
  handleDateEnd,
  handlers,
  basePrice,
  id,
  calendarData,
  setCalendarData,
  value,
  setValue,
}) => {
  const [disabledDates, setDisabledDates] = useState([]);

  const filterCalendarData = (data) => {
    let values = data;
    let array = [];
    values?.forEach((value) => {
      let data = {
        isBooked: value?.isBooked,
        title: `${process.env.REACT_APP_CURRENCY}` + value.price,
        start: new Date(value?.createdDate),
        end: new Date(value?.createdDate),
        isAvailable: value?.isAvailable,
        privateNote:
          value?.privateNote === undefined ? null : value?.privateNote,
      };
      array.push(data);
    });
    setCalendarData(array);
  };

  const dateTemplate = (date) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "10rem",
        }}
      >
        {date.day}
        {basePrice && (
          <>
            <br />
            <span
              style={{
                fontSize: "0.75em",
              }}
            >
              {process.env.REACT_APP_CURRENCY}{basePrice}
            </span>
          </>
        )}
      </div>
    );
  };

  const anotherDateTemplate = (date) => {
    for (let i = 0; i < calendarData.length; i++) {
      if (
        date.day === parseInt(moment(calendarData[i]["forDate"]).format("D")) &&
        date.month ===
          parseInt(moment(calendarData[i]["forDate"]).format("M")) - 1
      ) {
        if (
          calendarData[i]["isBooked"] == true ||
          calendarData[i]["isAvailable"] == false
        ) {
          let now = moment(calendarData[i]["forDate"]);
          disabledDates.push(new Date(now.format("M/D/YYYY")));
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "11rem",
            }}
          >
            {date.day}
            {calendarData[i]["price"] && (
              <>
                <br />
                <span
                  style={{
                    fontSize: "0.55em",
                    display: "flex",
                  }}
                >
                  {process.env.REACT_APP_CURRENCY}{calendarData[i]["price"]}
                </span>
              </>
            )}
          </div>
        );
      }
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "11rem",
        }}
      >
        {date.day}
        {basePrice && (
          <>
            <br />
            <span
              style={{
                fontSize: "0.55em",
                display: "flex",
              }}
            >
              {process.env.REACT_APP_CURRENCY}{basePrice}
            </span>
          </>
        )}
      </div>
    );
  };

  const date = new Date();
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);

  return (
    <DatePickerContainer>
      <Calendar
        id="range"
        minDate={availableToday ? previous : new Date()}
        value={value}
        style={{ fontSize: "25px", background: "#fff" }}
        disabledDates={disabledDates}
        disabledDays={disabledDates}
        dateFormat="dd M yy"
        onChange={(e) => {
          setValue(e.value);
          handlers && handleDateStart(e.value[0]);
          handlers && handleDateEnd(e.value[1]);
        }}
        selectionMode="range"
        readOnlyInput
        placeholder="Select a Date Range"
        dateTemplate={anotherDateTemplate}
        numberOfMonths={2}
      />
    </DatePickerContainer>
  );
};

const DatePickerContainer = styled.div`
  // height: 100%;

  .p-calendar input {
    text-align: center;
  }

  .p-calendar .p-inputtext {
    background: none;
    color: black;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    text-align: "center";
    font-size: 14px;
    cursor: pointer;
    line-height: 1.4375em;
    // min-height: 70px;

    &:hover {
      border-color: #326fd1;
    }
    &:focus {
      box-shadow: none;
      /* border-color: #326fd1; */
      border: 2px solid;
    }
  }

  .p-calendar {
    width: 100%;
    height: 100%;
    font-size: 25px;
  }
  .p-highlight {
    color: #0095f8 !important;
    border-radius: 0 !important;
  }

  #datepicker {
    font-size: 25px;
  }
`;

export default DatePickers;
