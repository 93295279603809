import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Sidebar from "../../components/sidebar";
import {
  getPropertyById,
  getPropertyRatingByGuest,
} from "./../../services/properties/index";
import { getCoHostByPropId } from "./../../services/coHosts/index";
import { getRatingByPropId } from "./../../services/ratings/index";
import { ProgressSpinner } from "primereact/progressspinner";
import { Rating } from "primereact/rating";
import "./style.css";
import BookingPieChart from "./BookingPieChart";
import { Carousel } from "primereact/carousel";
import EarningChart from "./EarningChart";
import moment from "moment";
import CohostDetails from "./CohostDetails";
import { Button } from "primereact/button";
import PropertyAmentiesDialog from "./PropertyAmentiesDialog";
import { priceBodyTemplate } from "../../constants/utils";

const PropertyDetails = ({ id }) => {
  const dialogRef = useRef();
  const [productDetail, setProductDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [ratingData, setRatingData] = useState([]);
  const [coHostDetail, setCoHostDetail] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [coHostLoading, setCohostLoading] = useState(false);
  const [avgRating, setAvgRating] = useState(0);
  const [avgRatingLoading, setAvgRatingLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);
    getPropertyById(id)
      .then((res) => {
        setIsLoading(false);

        setProductDetail(res.data.data);
        
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getAvgRatings = () => {
    setAvgRatingLoading(true);
    getRatingByPropId(id, 0)
      .then((res) => {
        setAvgRatingLoading(false);
        let total = 0;
        let totalLength = res.data.data.items?.length;
        for (let i = 0; i < res.data.data.items?.length; i++) {
          total += res.data.data.items[i].over_all;
        }
        let totalRating = total / totalLength;
        setAvgRating(totalRating);
      })
      .catch((err) => {
        setAvgRatingLoading(false);
      });
  };

  const getCoHosts = () => {
    setCohostLoading(true);
    getCoHostByPropId(id)
      .then((res) => {
        setCohostLoading(false);

        setCoHostDetail(res.data);
      })
      .catch((err) => {
        setCohostLoading(false);
      });
  };

  const getDataRating = () => {
    setRatingLoading(true);
    getPropertyRatingByGuest(0, id)
      .then((res) => {
        setRatingLoading(false);

        setRatingData(res.data.data);
      })
      .catch((err) => {
        setRatingLoading(false);
      });
  };

  useEffect(() => {
    getData();
    getDataRating();
    getCoHosts();
    getAvgRatings();
  }, []);

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 1,
    },
    // {
    //   breakpoint: "600px",
    //   numVisible: 2,
    //   numScroll: 1,
    // },
    // {
    //   breakpoint: "480px",
    //   numVisible: 1,
    //   numScroll: 1,
    // },
  ];

  const carouselTemplate = (image) => {
    return (
      <div className="product-item">
        <div className="product-item-content">
          <div style={{ marginRight: "10px" }}>
            <img
              src={image.locationUrl}
              width="100%"
              style={{ height: "200px" }}
              onError={(e) =>
                (e.target.src =
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
              }
              alt={image.id}
              className="product-image"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-4">
              <div style={{ background: "#fff", padding: "24px" }}>
                <h5>User Details</h5>

                <div style={{ textAlign: "center" }}>
                  <img
                    src={
                      productDetail?.user?.profilePicture !== null
                        ? productDetail?.user?.profilePicture
                        : "https://sales.ufaber.com/public/static/website/updated_teacherpanel/asset/img/images/dummy-avatar.jpg"
                    }
                    width="120px"
                    height="120px"
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <h6>
                    User ID: <span>{productDetail?.user?.id} </span>
                  </h6>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <h6>
                    Name:{" "}
                    <span>
                      {productDetail?.user?.firstName}{" "}
                      {productDetail?.user?.lastName}
                    </span>
                  </h6>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <h6>
                    Member Since:{" "}
                    <span>
                      {moment(productDetail?.user?.createdOn).format(
                        "DD MMM YYYY"
                      )}
                    </span>
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div style={{ background: "#fff", padding: "24px" }}>
                <h5>Other Details</h5>

                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Title: </h6>
                  <span className="address-style">{productDetail?.title}</span>
                </div>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Address: </h6>
                  <span className="address-style">
                    {productDetail?.address}
                  </span>
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Country: </h6>
                  <span className="address-style">
                    {productDetail?.country}
                  </span>
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>State: </h6>
                  <span className="address-style">{productDetail?.state}</span>
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>City: </h6>
                  <span className="address-style">{productDetail?.city}</span>
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Code: </h6>
                  <span className="address-style">{productDetail?.code}</span>
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Per Night Price: </h6>
                  <span className="address-style">
                    {priceBodyTemplate(productDetail?.perNightPrice)}
                  </span>
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Price: </h6>
                  <span className="address-style">
                    {priceBodyTemplate(productDetail?.price)}
                  </span>
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Property Type: </h6>
                  <span className="address-style">
                    {productDetail?.propertyType}
                  </span>
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Average Rating: </h6>
                  &nbsp;
                  <Rating value={avgRating} readOnly stars={5} cancel={false} />
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Property View: </h6>
                  <span className="address-style">
                    {productDetail?.propertyView?.length}
                  </span>
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Zip Code: </h6>
                  <span className="address-style">
                    {productDetail?.zipCode}
                  </span>
                </div>
                <div style={{ marginTop: "5px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Description: </h6>
                  <span className="address-style">
                    {productDetail?.description !== null
                      ? productDetail?.description
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <h4 style={{ marginTop: "15px" }}>Amenities</h4>
              <div
                className="grid"
                style={{
                  background:
                    productDetail?.propertyAmenities?.length > 0 && "#fff",
                  padding: "24px",
                }}
              >
                {productDetail?.propertyAmenities?.length > 0 &&
                  productDetail?.propertyAmenities?.slice(0, 5).map((item) => {
                    if (item?.amenity?.title !== null) {
                      return (
                        <div className="col-6 flex">
                          <img src={item?.amenity?.icon} style={{ width: "20px" }} />
                          <h4
                          className="ml-2 mt-1"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                            }}
                          >
                            {item?.amenity?.title}
                          </h4>
                        </div>
                      );
                    }
                  })}
                {productDetail?.propertyAmenities?.length >= 7 && 
                  (
                  <Button
                    onClick={() => {
                      dialogRef.current.isVisible(
                        productDetail?.propertyAmenities
                      );
                    }}
                    className="p-button"
                  >{`Show all ${productDetail?.propertyAmenities?.length} amenities`}</Button>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <h4 style={{ marginTop: "15px" }}>House Rules</h4>
              {productDetail?.propertyHouseRule?.length > 0 &&
                productDetail?.propertyHouseRule.map((item) => {
                  if (item?.houseRule?.title !== null) {
                    return (
                      <div style={{ background: "#fff", padding: "24px" }}>
                        <h4 style={{ fontSize: "14px", fontWeight: "500" }}>
                          <i className={item?.houseRule?.icon}></i>{" "}
                          {item?.houseRule?.title}
                        </h4>
                      </div>
                    );
                  }
                })}
            </div>

            <div className="col-md-4">
              <h4 style={{ marginTop: "15px" }}>Safety Consideration</h4>
              {productDetail?.propertySafetyConsideration?.length > 0 &&
                productDetail?.propertySafetyConsideration.map((item) => {
                  if (item?.safetyConsideration?.title !== null) {
                    return (
                      <div style={{ background: "#fff", padding: "24px" }}>
                        <h4 style={{ fontSize: "14px", fontWeight: "500" }}>
                          <i className={item?.safetyConsideration?.icon}></i>{" "}
                          {item?.safetyConsideration?.title}
                        </h4>
                      </div>
                    );
                  }
                })}
            </div>
            <div className="col-md-4">
              <h4 style={{ marginTop: "15px" }}>Space Type</h4>
              <div style={{ background: "#fff", padding: "24px" }}>
                <h4 style={{ fontSize: "14px", fontWeight: "500" }}>
                  <i className={productDetail?.spaceType?.code}></i>{" "}
                  {productDetail?.spaceType?.title}
                </h4>
              </div>
            </div>
            <div className="col-md-4">
              <h4 style={{ marginTop: "15px" }}>Space</h4>
              <div style={{ background: "#fff", padding: "24px" }}>
                <h4 style={{ fontSize: "14px", fontWeight: "500" }}>
                  <i className={productDetail?.space?.code}></i>{" "}
                  {productDetail?.space?.title}
                </h4>
              </div>
            </div>
            <div className="col-md-4">
              <h4 style={{ marginTop: "15px" }}>Area</h4>
              <div style={{ background: "#fff", padding: "24px" }}>
                <h4 style={{ fontSize: "14px", fontWeight: "500" }}>
                  <i className={productDetail?.area?.code}></i>{" "}
                  {productDetail?.area?.title}
                </h4>
              </div>
            </div>
          </div>

          <div className="row">
            {productDetail?.propertyFacility?.length > 0 && (
              <div className="col-md-4">
                <h4 style={{ marginTop: "15px" }}>Facilities</h4>
                {productDetail?.propertyFacility.map((item) => {
                  if (item?.facility?.title !== null) {
                    return (
                      <div style={{ background: "#fff", padding: "24px" }}>
                        <h4 style={{ fontSize: "14px", fontWeight: "500" }}>
                          <i className={item?.facility?.icon}></i>{" "}
                          {item?.facility?.title}
                        </h4>
                      </div>
                    );
                  }
                })}
              </div>
            )}

            {productDetail?.propertyNearbyActivity?.length > 0 && (
              <div className="col-md-4">
                <h4 style={{ marginTop: "15px" }}>Nearby Activities</h4>
                {productDetail?.propertyNearbyActivity.map((item) => {
                  if (item?.nearbyActivity?.title !== null) {
                    return (
                      <div style={{ background: "#fff", padding: "24px" }}>
                        <h4 style={{ fontSize: "14px", fontWeight: "500" }}>
                          <i className={item?.nearbyActivity?.icon}></i>{" "}
                          {item?.nearbyActivity?.title}
                        </h4>
                      </div>
                    );
                  }
                })}
              </div>
            )}

            {productDetail?.propertySecurityFeature?.length > 0 && (
              <div className="col-md-4">
                <h4 style={{ marginTop: "15px" }}>Security Features</h4>
                {productDetail?.propertySecurityFeature.map((item) => {
                  if (item?.securityFeature?.title !== null) {
                    return (
                      <div style={{ background: "#fff", padding: "24px" }}>
                        <h4 style={{ fontSize: "14px", fontWeight: "500" }}>
                          <i className={item?.securityFeature?.icon}></i>{" "}
                          {item?.securityFeature?.title}
                        </h4>
                      </div>
                    );
                  }
                })}
              </div>
            )}
            <div className="col-md-4">
              <h4 style={{ marginTop: "15px" }}>Property Capacity</h4>
              <div style={{ background: "#fff", padding: "24px" }}>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>No Of Adults: </h6>
                  <span className="address-style">
                    {productDetail?.propertyCapacity?.noOfAdults}
                  </span>
                </div>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>No Of Bathrooms: </h6>
                  <span className="address-style">
                    {productDetail?.propertyCapacity?.noOfBathrooms}
                  </span>
                </div>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>No Of Bedrooms: </h6>
                  <span className="address-style">
                    {productDetail?.propertyCapacity?.noOfBedrooms}
                  </span>
                </div>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>No Of Beds: </h6>
                  <span className="address-style">
                    {productDetail?.propertyCapacity?.noOfBeds}
                  </span>
                </div>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>No Of Children: </h6>
                  <span className="address-style">
                    {productDetail?.propertyCapacity?.noOfChildren}
                  </span>
                </div>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>No Of Pets: </h6>
                  <span className="address-style">
                    {productDetail?.propertyCapacity?.noOfPets !== null
                      ? productDetail?.propertyCapacity?.noOfPets
                      : 0}
                  </span>
                </div>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>No Of Infants: </h6>
                  <span className="address-style">
                    {productDetail?.propertyCapacity?.noOfInfants !== null
                      ? productDetail?.propertyCapacity?.noOfInfants
                      : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <h4 style={{ marginTop: "15px" }}>Property Policy</h4>
              <div style={{ background: "#fff", padding: "24px" }}>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Cancellation Policy: </h6>
                  <span className="address-style">
                    {productDetail?.propertyPolicy?.cancellationPolicy}
                  </span>
                </div>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Checkin Window: </h6>
                  <span className="address-style">
                    {productDetail?.propertyPolicy?.checkInWindow}
                  </span>
                </div>
                <div style={{ marginTop: "10px", display: "flex" }}>
                  <h6 style={{ fontWeight: "600" }}>Checkout Window: </h6>
                  <span className="address-style">
                    {productDetail?.propertyPolicy?.checkoutWindow}
                  </span>
                </div>
              </div>
            </div>
            {productDetail?.propertyPricePolicy?.length > 0 ? (
              <div className="col-md-4">
                <h4 style={{ marginTop: "15px" }}>Property Price Policy</h4>
                <div className="row">
                  {productDetail?.propertyPricePolicy.map((item) => {
                    return (
                      <div className="col-md-12">
                        <div style={{ background: "#fff", padding: "24px" }}>
                          <div style={{ marginTop: "10px", display: "flex" }}>
                            <h6 style={{ fontWeight: "600" }}>
                              Cleaning Fee:{" "}
                            </h6>
                            <span className="address-style">
                              {item?.cleaningFee}
                            </span>
                          </div>
                          <div style={{ marginTop: "10px", display: "flex" }}>
                            <h6 style={{ fontWeight: "600" }}>
                              Custom Length Of Stay Discount:
                            </h6>
                            <span className="address-style">
                              {item?.customLengthOfStayDiscount}
                            </span>
                          </div>
                          <div style={{ marginTop: "10px", display: "flex" }}>
                            <h6 style={{ fontWeight: "600" }}>
                              Extra Guest Fee:
                            </h6>
                            <span className="address-style">
                              {item?.extraGuestFee}
                            </span>
                          </div>
                          <div style={{ marginTop: "10px", display: "flex" }}>
                            <h6 style={{ fontWeight: "600" }}>Minumum Stay:</h6>
                            <span className="address-style">
                              {item?.minimumStay}
                            </span>
                          </div>
                          <div style={{ marginTop: "10px", display: "flex" }}>
                            <h6 style={{ fontWeight: "600" }}>Maximum Stay:</h6>
                            <span className="address-style">
                              {item?.maximumStay}
                            </span>
                          </div>
                          <div style={{ marginTop: "10px", display: "flex" }}>
                            <h6 style={{ fontWeight: "600" }}>
                              Monthly Night Price:
                            </h6>
                            <span className="address-style">
                              {item?.monthlyNightPrice}
                            </span>
                          </div>
                          <div style={{ marginTop: "10px", display: "flex" }}>
                            <h6 style={{ fontWeight: "600" }}>Pet Fee:</h6>
                            <span className="address-style">
                              {item?.petFee}
                            </span>
                          </div>
                          <div style={{ marginTop: "10px", display: "flex" }}>
                            <h6 style={{ fontWeight: "600" }}>
                              Weekly Night Price:
                            </h6>
                            <span className="address-style">
                              {item?.weeklyNightPrice}
                            </span>
                          </div>
                          <div style={{ marginTop: "10px", display: "flex" }}>
                            <h6 style={{ fontWeight: "600" }}>
                              Weekend Night Price:
                            </h6>
                            <span className="address-style">
                              {item?.weekendNightPrice}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            <div className="col-md-4">
              <h4 style={{ marginTop: "15px" }}>Guest Info</h4>
              <div className="row">
                <div className="col-md-12">
                  <div style={{ background: "#fff", padding: "24px" }}>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Wifi Username: </h6>
                      <span className="address-style">
                        {productDetail?.wifiUsername}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Wifi Password: </h6>
                      <span className="address-style">
                        {productDetail?.wifiPassword}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>
                        Pre-Booking Configration:{" "}
                      </h6>
                      <span className="address-style">
                        {productDetail?.interactionWithGuest}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>
                        Post-Booking Configration:{" "}
                      </h6>
                      <span className="address-style">
                        {productDetail?.guestManual}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Instructions: </h6>
                      <span className="address-style">
                        {productDetail?.checkInInstruction !== null
                          ? productDetail?.checkInInstruction
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {productDetail?.propertyImages?.length > 0 && (
            <>
              <h4 style={{ marginTop: "15px" }}>Gallery Images</h4>
              <div
                className="card"
                style={{
                  padding: "20px 0",
                  marginBottom: "20px",
                  marginTop: "15px",
                }}
              >
                <Carousel
                  value={productDetail?.propertyImages}
                  numVisible={3}
                  numScroll={3}
                  responsiveOptions={responsiveOptions}
                  itemTemplate={carouselTemplate}
                  //   header={<h5>Basic</h5>}
                />
              </div>
            </>
          )}
        </>
      )}

      {ratingLoading ? (
        <div style={{ textAlign: "center" }}>
          <ProgressSpinner />
        </div>
      ) : (
        ratingData?.items?.length > 0 && (
          <div style={{ marginBottom: "40px" }}>
            <h4 style={{ marginTop: "15px" }}>Rating Details</h4>
            <div className="row">
              {ratingData?.items.map((item) => {
                return (
                  <div className="col-lg-4">
                    <div style={{ background: "#fff", padding: "24px" }}>
                      {item?.rating_property?.thumbnailUrl != null && (
                        <img
                          src={item?.rating_property?.thumbnailUrl}
                          width="100%"
                          height="265px"
                        />
                      )}
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Host Name:</h6>
                        <span className="address-style">
                          {item.host_user.firstName} {item.host_user.lastName}
                        </span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Guest Name:</h6>
                        <span className="address-style">
                          {item.guest_user.firstName} {item.guest_user.lastName}
                        </span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Property Name:</h6>
                        <span className="address-style">
                          {item?.rating_property?.title}
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6 style={{ fontWeight: "600" }}>Accuracy:</h6>
                        <span className="address-style">
                          <Rating
                            value={item.accuracy}
                            readOnly
                            stars={5}
                            cancel={false}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6 style={{ fontWeight: "600" }}>Check In:</h6>
                        <span className="address-style">
                          <Rating
                            value={item.check_in}
                            readOnly
                            stars={5}
                            cancel={false}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6 style={{ fontWeight: "600" }}>Cleanliness:</h6>
                        <span className="address-style">
                          <Rating
                            value={item.cleanliness}
                            readOnly
                            stars={5}
                            cancel={false}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6 style={{ fontWeight: "600" }}>Communication:</h6>
                        <span className="address-style">
                          <Rating
                            value={item.communication}
                            readOnly
                            stars={5}
                            cancel={false}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6 style={{ fontWeight: "600" }}>Location:</h6>
                        <span className="address-style">
                          <Rating
                            value={item.location}
                            readOnly
                            stars={5}
                            cancel={false}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6 style={{ fontWeight: "600" }}>Over All:</h6>
                        <span className="address-style">
                          <Rating
                            value={item.over_all}
                            readOnly
                            stars={5}
                            cancel={false}
                          />
                        </span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Public Note:</h6>
                        <span className="address-style">
                          {item.public_note}
                        </span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Private Note:</h6>
                        <span className="address-style">
                          {item.private_note}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )
      )}

      <CohostDetails
        getCoHosts={getCoHosts}
        // totalRecordsProperty={totalRecordsProperty}
        coHostDetail={coHostDetail}
        coHostLoading={coHostLoading}
      />

      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-lg-6">
          <h4 style={{ marginTop: "15px" }}>Booking Chart</h4>
          <BookingPieChart chartData={chartData} />
        </div>
        <div className="col-lg-6">
          <h4 style={{ marginTop: "15px" }}>Monthly Earning Chart</h4>
          <EarningChart />
        </div>
      </div>
      <PropertyAmentiesDialog ref={dialogRef} />
    </div>
  );
};

export default PropertyDetails;
