import React, { useState, useRef } from "react";
import { Checkbox } from "primereact/checkbox";
import { Accordion, AccordionTab } from "primereact/accordion";
import { createPropertySafetyConsideration } from "../../services/properties";
import { Toast } from "primereact/toast";

const GuestSafety = ({
  propertyConsiderations,
  propertyDetails,
  getConsiderations,
}) => {
  const toast = useRef(null);

  const handleUpdateGuestSafety = (item, index, val, ind) => {
    let payload = {
      safetyConsiderationId: val.id,
      propertyId: propertyDetails.id,
      status: !val.check_status,
    };

    createPropertySafetyConsideration(payload)
      .then((res) => {
        getConsiderations();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Guest Safety Consideration Updated Successfully",
          life: 3000,
        });
      })
      .catch((err) => {});
  };
  return (
    <div className="col-lg-12">
      <Toast ref={toast} position="bottom-right" />
      <Accordion activeIndex={4}>
        <AccordionTab header="Guest Safety">
          <h5 style={{ marginTop: "15px" }}>All Safety Guest</h5>
          <div className="row">
            {propertyConsiderations.map((item, index) => {
              return (
                <div className="col-lg-12">
                  <h5 style={{ marginTop: "15px" }}>{item.title}</h5>

                  <div className="row">
                    {item.safetyConsideration.map((val, ind) => {
                      return (
                        <div key={val.id} className="col-lg-4"> 
                          <div
                            className="field-checkbox"
                            onClick={() =>
                              handleUpdateGuestSafety(item, index, val, ind)
                            }
                          >
                            <Checkbox
                              inputId={val.id}
                              name={val.id}
                              value={val.check_status}
                              checked={val.check_status} 
                            />
                            <label htmlFor="city1" style={{ marginBottom: 0 }}>
                              {val.title}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default GuestSafety;
