import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Sidebar from "../../components/sidebar";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  getAllFilterproperties,
  getPropertyById,
} from "../../services/properties";
import { getUsersByLimit } from "../../services/users";
import DatePickers from "../../components/bookingDateRangePicker";
import moment from "moment";
import { getPropertyCalenderPriceById } from "./../../services/properties/index";
import { addUserBooking } from "./../../services/bookings/index";
import PaymentDetailSection from "./PaymentDetailSections";
import {
  paymentBookingStripe,
  getPaymentCardsByUserId,
} from "./../../services/payment/index";
import { getWalletById } from "../../services/wallet/index";

const paymentData = [
  {
    label: "Cheque",
    value: "CHEQUE",
  },
  {
    label: "Account Transfer",
    value: "BANK_TRANSFER",
  },
  {
    label: "Wallet",
    value: "WALLET",
  },
  {
    label: "Card",
    value: "CARD",
  },
  {
    label: "Card (new Card)",
    value: "CARD_NEW",
  },
  {
    label: "Card Plus Wallet (for new Card)",
    value: "CARD_PLUS_WALLET",
  },
  {
    label: "Card Plus Wallet (for existing Card)",
    value: "CARD_PLUS_WALLET_EXISTING",
  },
];

const AddEditBookings = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [inputVal, setInputVal] = useState({
    propertyId: "",
    userId: "",
    paymentType: "",
    chequeNo: "",
    accTitle: "",
    accNumber: "",
    transactionId: "",
    creditCardNumber: "",
    expMonth: "",
    expYear: "",
    cvc: "",
    cardId: "",
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [listedProperties, setListedProperties] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [numberOfGuests, setNumberOfGuests] = useState(0);
  const [showPersons, setShowPersons] = useState(false);
  const [userWallet, setUserWallet] = useState(null);
  const [userPaymentCards, setUserPaymentCards] = useState([]);
  const [dateValue, setDateValue] = useState();
  const [adultChildCount, setAdultChildCount] = useState(0);
  const [capacty, setCapacity] = useState({
    adults: 0,
    children: 0,
    infants: 0,
    pets: 0,
  });
  const [calculatedData, setCalculatedData] = useState(null);

  const now = moment().format("h:mm:ss");
  const [availableToday, setAvailableToday] = useState(true);
  useEffect(() => {
    if (now < "11:0:0") {
      setAvailableToday(true);
    } else {
      setAvailableToday(false);
    }
  }, [now]);

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("token"));
    getAllFilterproperties("LISTED").then((res) => {
      let tempArr = [];
      res.data.data.map((item) => {
        tempArr.push({
          ...item,
          label: item.title,
          value: item.id,
        });
      });
      setListedProperties(tempArr);
    });

    getUsersByLimit("ASC", 1, 50, token).then((res) => {
      let tempArr = [];
      if (res.data.statusCode == 401) {
        showToast("Yout need to login first to get users");
      } else {
        res.data.data.data.map((item) => {
          tempArr.push({
            ...item,
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          });
        });
        setAllUsers(tempArr);
      }
    });
  }, []);

  const handleChange = (e) => {
    if (e.target.name == "propertyId") {
      setCalendarData([]);
      setDateValue();
      setStartDate("");
      setEndDate("");
      setCalculatedData(null);
      getPropertyCalenderPriceById(e.target.value).then((response) => {
        if (response.status === 200) {
          setCalendarData(response.data.data);
          setNumberOfGuests(0);
          setCapacity({
            adults: 0,
            children: 0,
            infants: 0,
            pets: 0,
          });
        }
      });
      getPropertyById(e.target.value).then((res) => {
        setPropertyDetail(res.data.data);
        let propCapacity = res.data.data.propertyCapacity;
        if (propCapacity)
          setAdultChildCount(
            propCapacity.noOfAdults + propCapacity.noOfChildren
          );
      });
      setInputVal({ ...inputVal, [e.target.name]: e.target.value });
    } else if (e.target.name == "userId") {
      setInputVal({ ...inputVal, userId: e.target.value, cardId: "" });
      setNumberOfGuests(0);
      setUserWallet(null);
      setCapacity({ adults: 0, children: 0, infants: 0, pets: 0 });
      getWalletById(e.target.value).then((res) => {
        if (res.data.statusCode == 404) {
          showToast(res.data.error);
        } else {
          setUserWallet(res.data.data);
        }
      });
      getPaymentCardsByUserId(e.target.value).then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            label: item.brand,
            value: item.id,
          });
        });
        setUserPaymentCards(tempArr);
      });
    } else if (e.target.name == "paymentType") {
      setInputVal({
        ...inputVal,
        [e.target.name]: e.target.value,
        cardId: "",
        accTitle: "",
        creditCardNumber: "",
        expMonth: "",
        expYear: "",
        cvc: "",
      });
    } else {
      setInputVal({ ...inputVal, [e.target.name]: e.target.value });
    }
  };

  const showToast = (msg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const handleIncAdult = () => {
    const limit =
      propertyDetail?.propertyCapacity !== null
        ? propertyDetail?.propertyCapacity?.noOfAdults
        : 0;

    if (limit == null || limit == 0) {
    } else {
      if (capacty.adults + capacty.children == adultChildCount) {
      } else {
        setCapacity({ ...capacty, adults: capacty.adults + 1 });
        setNumberOfGuests(numberOfGuests + 1);
      }
    }
  };
  const handleDecAdult = () => {
    const limit =
      propertyDetail?.propertyCapacity !== null
        ? propertyDetail?.propertyCapacity?.noOfAdults
        : 0;

    if (limit == null || limit == 0) {
    } else {
      if (capacty.adults == 0) {
      } else {
        setCapacity({ ...capacty, adults: capacty.adults - 1 });
        setNumberOfGuests(numberOfGuests - 1);
      }
    }
  };

  const handleIncChild = () => {
    const limit =
      propertyDetail?.propertyCapacity !== null
        ? propertyDetail?.propertyCapacity?.noOfChildren
        : 0;

    if (limit == null || limit == 0) {
    } else {
      if (capacty.children + capacty.adults == adultChildCount) {
      } else {
        setCapacity({ ...capacty, children: capacty.children + 1 });
        setNumberOfGuests(numberOfGuests + 1);
      }
    }
  };
  const handleDecChild = () => {
    const limit =
      propertyDetail?.propertyCapacity !== null
        ? propertyDetail?.propertyCapacity?.noOfChildren
        : 0;
    if (limit == null || limit == 0) {
    } else {
      if (capacty.children == 0) {
      } else {
        setCapacity({ ...capacty, children: capacty.children - 1 });
        setNumberOfGuests(numberOfGuests - 1);
      }
    }
  };

  const handleIncInfants = () => {
    const limit =
      propertyDetail?.propertyCapacity !== null
        ? propertyDetail?.propertyCapacity?.noOfInfants
        : 0;

    if (limit == null || limit == 0) {
    } else {
      if (capacty.infants == limit) {
      } else {
        setCapacity({ ...capacty, infants: capacty.infants + 1 });
      }
    }
  };
  const handleDecInfants = () => {
    const limit =
      propertyDetail?.propertyCapacity !== null
        ? propertyDetail?.propertyCapacity?.noOfInfants
        : 0;

    if (limit == null || limit == 0) {
    } else {
      if (capacty.infants == 0) {
      } else {
        setCapacity({ ...capacty, infants: capacty.infants - 1 });
      }
    }
  };

  const handleIncPet = () => {
    const limit =
      propertyDetail?.propertyCapacity !== null
        ? propertyDetail?.propertyCapacity?.noOfPets
        : 0;

    if (limit == null || limit == 0) {
    } else {
      if (capacty.pets == limit) {
      } else {
        setCapacity({ ...capacty, pets: capacty.pets + 1 });
      }
    }
  };
  const handleDecPet = () => {
    const limit =
      propertyDetail?.propertyCapacity !== null
        ? propertyDetail?.propertyCapacity?.noOfPets
        : 0;

    if (limit == null || limit == 0) {
    } else {
      if (capacty.pets == 0) {
      } else {
        setCapacity({ ...capacty, pets: capacty.pets - 1 });
      }
    }
  };

  const formatDate = (val) => {
    let formattt = val
      .toISOString()
      .split("T")[0]
      .split("-")
      .concat(val.toISOString().split("T")[1].split(":"));
    formattt[2] = parseInt(formattt[2]) + 1;
    return `${formattt[0]}-${formattt[1]}-${formattt[2]}T${formattt[3]}:${formattt[4]}:${formattt[5]}`;
  };

  const handleCheckValidation = () => {
    if (inputVal.propertyId == "" || inputVal.propertyId == undefined) {
      showToast("Please select property");
    } else if (inputVal.userId == "") {
      showToast("Please select user");
    } else if (startDate === "") {
      showToast("Please select start date");
    } else if (endDate === "" || endDate == null) {
      showToast("Please select end date");
    } else if (inputVal.paymentType == "") {
      showToast("Please select payment method");
    } else if (inputVal.paymentType == "CHEQUE" && inputVal.chequeNo == "") {
      showToast("Cheque number is required");
    } else if (
      inputVal.paymentType == "BANK_TRANSFER" &&
      inputVal.accTitle == ""
    ) {
      showToast("Account title is required");
    } else if (
      inputVal.paymentType == "BANK_TRANSFER" &&
      inputVal.accNumber == ""
    ) {
      showToast("Account number is required");
    } else if (inputVal.paymentType == "WALLET" && userWallet?.amount == 0) {
      showToast(
        "Your amount is insufficient, please add your card details to charge the remaining amount"
      );
    } else if (
      (inputVal.paymentType == "CARD" && inputVal.cardId == "") ||
      (inputVal.paymentType == "CARD_PLUS_WALLET_EXISTING" &&
        inputVal.cardId == "")
    ) {
      showToast("Please select card");
    } else if (
      (inputVal.paymentType == "CARD_NEW" && inputVal.accTitle == "") ||
      (inputVal.paymentType == "CARD_PLUS_WALLET" && inputVal.accTitle == "")
    ) {
      showToast("Account title is required");
    } else if (
      (inputVal.paymentType == "CARD_NEW" && inputVal.creditCardNumber == "") ||
      (inputVal.paymentType == "CARD_PLUS_WALLET" &&
        inputVal.creditCardNumber == "")
    ) {
      showToast("Card number is required");
    } else if (
      (inputVal.paymentType == "CARD_NEW" && inputVal.expMonth == "") ||
      (inputVal.paymentType == "CARD_PLUS_WALLET" && inputVal.expMonth == "")
    ) {
      showToast("Expiry month is required");
    } else if (
      (inputVal.paymentType == "CARD_NEW" && inputVal.expYear == "") ||
      (inputVal.paymentType == "CARD_PLUS_WALLET" && inputVal.expYear == "")
    ) {
      showToast("Expiry year is required");
    } else if (
      (inputVal.paymentType == "CARD_NEW" && inputVal.cvc == "") ||
      (inputVal.paymentType == "CARD_PLUS_WALLET" && inputVal.cvc == "")
    ) {
      showToast("CVC is required");
    } else confirmationDialog();
  };

  const accept = () => {
    addBooking();
  };

  const confirmationDialog = () => {
    confirmDialog({
      message: "Are you sure you want to add booking?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
    });
  };

  const addBooking = () => {
    let start = formatDate(startDate);
    let end = formatDate(endDate);
    let payload = {
      ownerId: propertyDetail?.user?.id,
      guestId: inputVal.userId,
      propertyId: propertyDetail?.id,
      startDate: start,
      endDate: end,
      noOfGuest: numberOfGuests,
      noOfAdults: capacty.adults,
      noOfChildren: capacty.children,
      noOfInfants: capacty.infants,
      noOfPets: capacty.pets,
      bookingType: "NORMAL",
    };

    setIsLoading(true);
    addUserBooking(payload)
      .then((res) => {
        if (res.data.statusCode == 400) {
          setIsLoading(false);
          showToast(res.data.error.message);
        } else {
          let bookingId = res.data.data.id;

          setIsLoading(false);

          let chequePayload = {
            user_id: inputVal.userId,
            booking_id: bookingId,
            paymentMethod: "CHEQUE",
            chequeNumber: inputVal.chequeNo,
          };

          let bankTransferPayload = {
            user_id: inputVal.userId,
            booking_id: bookingId,
            paymentMethod: "BANK_TRANSFER",
            accountTitle: inputVal.accTitle,
            accountNumber: inputVal.accNumber,
            transactionId: inputVal.transactionId,
          };

          let walletPayload = {
            user_id: inputVal.userId,
            booking_id: bookingId,
            paymentMethod: "WALLET",
          };

          let cardPayload = {
            user_id: inputVal.userId,
            booking_id: bookingId,
            card_id: inputVal.cardId,
            paymentMethod: "CARD",
          };

          let newCardPlusWalletPayload = {
            name: inputVal.accTitle,
            number: inputVal.accNumber,
            exp_month: inputVal.expMonth,
            exp_year: inputVal.expYear,
            cvc: inputVal.cvc,
            user_id: inputVal.userId,
            booking_id: bookingId,
            paymentMethod: "CARD_PLUS_WALLET",
          };

          let finalPayload =
            inputVal.paymentType === "CHEQUE"
              ? chequePayload
              : inputVal.paymentType === "BANK_TRANSFER"
              ? bankTransferPayload
              : inputVal.paymentType === "WALLET"
              ? walletPayload
              : inputVal.paymentType == "CARD_NEW" ||
                inputVal.paymentType == "CARD_PLUS_WALLET"
              ? newCardPlusWalletPayload
              : inputVal.paymentType == "CARD" ||
                inputVal.paymentType == "CARD_PLUS_WALLET_EXISTING"
              ? cardPayload
              : "";

          paymentBookingStripe(finalPayload)
            .then((res) => {
              setStartDate("");
              setEndDate("");
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Congratulations booking created Successfully",
                life: 3000,
              });
            })
            .catch((err) => {});
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err, "Asdlksadlksajd");
      });
  };

  return (
    <div className="app-main">
      <ConfirmDialog />

      <Toast ref={toast} position="bottom-right" />
      <Sidebar active="bookingList" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title={`Add Booking - Staywo`}
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />

          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <h6>Select Property</h6>
                  <Dropdown
                    value={inputVal.propertyId}
                    options={listedProperties}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    optionLabel="label"
                    name="propertyId"
                    placeholder="Select Property"
                  />
                </div>
                {propertyDetail !== null && (
                  <div className="col-lg-6">
                    <h6>Select Guest</h6>
                    <Dropdown
                      value={inputVal.userId}
                      options={allUsers}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                      optionLabel="label"
                      name="userId"
                      placeholder="Select Guest"
                    />
                  </div>
                )}

                {propertyDetail !== null && (
                  <div className="col-lg-6">
                    <h6 style={{ marginTop: "10px" }}>Check In/ Check Out</h6>
                    <DatePickers
                      basePrice={propertyDetail?.perNightPrice}
                      handlers
                      availableToday={availableToday}
                      handleDateStart={(value) => setStartDate(value)}
                      handleDateEnd={(value) => setEndDate(value)}
                      id={propertyDetail.propertyId}
                      calendarData={calendarData}
                      setCalendarData={setCalendarData}
                      value={dateValue}
                      setValue={setDateValue}
                    />
                  </div>
                )}

                {propertyDetail !== null && (
                  <div className="col-lg-6" style={{ position: "relative" }}>
                    <h6 style={{ marginTop: "10px" }}>Persons</h6>
                    <div
                      style={{
                        border: "1px solid #ced4da",
                        padding: "10px",
                        background: "#ffff",
                        borderRadius: "4px",
                      }}
                      onClick={() => setShowPersons(!showPersons)}
                    >
                      {numberOfGuests == 0
                        ? "Select # of Guests"
                        : numberOfGuests == 1
                        ? `${numberOfGuests} guest`
                        : `${numberOfGuests} guests`}
                    </div>
                    {showPersons && (
                      <div
                        style={{
                          position: "absolute",
                          zIndex: 9999,
                          color: "#fff",
                          width: "100%",
                          width: "95%",
                          marginTop: "10px",
                          background: "#fff",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                          }}
                        >
                          <div>
                            <h6>Adults</h6>
                            <p>Age 13+</p>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <i
                              className="pi pi-minus"
                              style={{ cursor: "pointer" }}
                              onClick={handleDecAdult}
                            ></i>
                            <p style={{ margin: "0 10px" }}>{capacty.adults}</p>
                            <i
                              className="pi pi-plus"
                              style={{ cursor: "pointer" }}
                              onClick={handleIncAdult}
                            ></i>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                          }}
                        >
                          <div>
                            <h6>Children</h6>
                            <p>Age 2-12</p>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <i
                              className="pi pi-minus"
                              style={{ cursor: "pointer" }}
                              onClick={handleDecChild}
                            ></i>
                            <p style={{ margin: "0 10px" }}>
                              {capacty.children}
                            </p>
                            <i
                              className="pi pi-plus"
                              style={{ cursor: "pointer" }}
                              onClick={handleIncChild}
                            ></i>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                          }}
                        >
                          <div>
                            <h6>Infants</h6>
                            <p>Under 2</p>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <i
                              className="pi pi-minus"
                              style={{ cursor: "pointer" }}
                              onClick={handleDecInfants}
                            ></i>
                            <p style={{ margin: "0 10px" }}>
                              {capacty.infants}
                            </p>
                            <i
                              className="pi pi-plus"
                              style={{ cursor: "pointer" }}
                              onClick={handleIncInfants}
                            ></i>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            color: "black",
                          }}
                        >
                          <div>
                            <h6>Pets</h6>
                            <p>Bringing a service animal?</p>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <i
                              className="pi pi-minus"
                              style={{ cursor: "pointer" }}
                              onClick={handleDecPet}
                            ></i>
                            <p style={{ margin: "0 10px" }}>{capacty.pets}</p>
                            <i
                              className="pi pi-plus"
                              style={{ cursor: "pointer" }}
                              onClick={handleIncPet}
                            ></i>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {propertyDetail !== null && (
                  <>
                    <div className="col-lg-6" style={{ marginTop: "10px" }}>
                      <h6>Select Payment Method</h6>
                      <Dropdown
                        value={inputVal.paymentType}
                        options={paymentData}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        optionLabel="label"
                        name="paymentType"
                        placeholder="Select Payment Method"
                      />
                    </div>
                    <PaymentDetailSection
                      inputVal={inputVal}
                      handleChange={handleChange}
                      userPaymentCards={userPaymentCards}
                      userWallet={userWallet}
                      startDate={startDate}
                      endDate={endDate}
                      propertyDetail={propertyDetail}
                      capacty={capacty}
                      calculatedData={calculatedData}
                      setCalculatedData={setCalculatedData}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div style={{ margin: "10px 0", textAlign: "right" }}>
            {isLoading ? (
              <ProgressSpinner />
            ) : (
              <Button
                label={"Add Booking"}
                className="p-button-lg"
                style={{ fontSize: "12px", padding: "10px 8px" }}
                onClick={handleCheckValidation}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditBookings;
