import React from "react";
import { useAuth } from "./hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { logout } = useAuth();
  const navigate=useNavigate()
  return (
    <React.Fragment>
      <div className="app-header header-shadow">
        <div className="app-header__logo">
          <div className="logo-src">
            {/* <img
              src={require("../images/app-logo.png")}
              width="100%"
              height="100%"
            /> */}
          </div>
          <div className="header__pane ml-auto">
            <div>
              <button
                type="button"
                className="hamburger close-sidebar-btn hamburger--elastic"
                data-class="closed-sidebar"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button
              type="button"
              className="hamburger hamburger--elastic mobile-toggle-nav"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
        <div className="app-header__menu">
          <span>
            <button
              type="button"
              className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
            >
              <span className="btn-icon-wrapper">
                <i className="fa fa-ellipsis-v fa-w-6"></i>
              </span>
            </button>
          </span>
        </div>
        <div className="app-header__content">
          <div class="app-header-left">
            <ul class="header-menu nav">
              {/* <li class="nav-item">
                <a href="javascript:void(0);" class="nav-link">
                  <i class="nav-link-icon fa fa-database"> </i>
                  Statistics
                </a>
              </li> */}
              {/* <li class="btn-group nav-item">
                <a href="javascript:void(0);" class="nav-link">
                  <i class="nav-link-icon fa fa-edit"></i>
                  Projects
                </a>
              </li> */}
              <li class="dropdown nav-item" onClick={()=>navigate("/systemConfiguration")}>
                <a href="javascript:void(0);" class="nav-link">
                  <i class="nav-link-icon fa fa-cog"></i>
                  Settings
                </a>
              </li>
              <li class="dropdown nav-item">
                <a onClick={() => logout()} class="nav-link">
                  {/* <i class="nav-link-icon fa fa-cog"></i> */}
                  <i className="nav-link-icon pi pi-sign-out"></i>
                  Logout
                </a>
              </li>
            </ul>{" "}
          </div>

          <div className="app-header-right">
            <div className="header-btn-lg pr-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
