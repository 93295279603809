import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";
import { axios } from 'axios';


export const postSliders = (data) => {
  return Api(
    `${endPoints.cmsPost}`,
    data,
    requestType.POST,
    null,
    null
  );
}

export const getPostSidlers = (data) => {
  return Api(
    `${endPoints.cmsGet}`,
    data,
    requestType.GET,
    null,
    null
  );


}

export const deletePostSliders = (id) => {
  return Api(
    `${endPoints.cmsDelete}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
}

export const updatePostSliders = (id, data) => {
  return Api(
    `${endPoints.cmsUpdate}/${id}`,
    data,
    requestType.PUT,
    null,
    null
  );
}

let header = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}
export const cmsPostSliders = async (data) => {
  for (var pair of data.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }

  // await fetch('https://api-staging.staywo.com/cms-slider/create',
  //   {
  //     method: 'POST',
  //     body: data
  //   }
  // )
  //   .then(response => response.json())
  //   .then(data => {
  //     console.log('Success:', data);
  //     return data
  //   })

  // let result = await axios.post("https://api-staging.staywo.com/cms-slider/create", data, header)
  // return result

  return Api(
    `${endPoints.cmsPostSliders}`,
    data,
    requestType.POST,
    true,
    null
  );
}
export const cmsGetSliders = (data, id) => {
  return Api(
    `${endPoints.cmsGetSliders}/${id}`,
    data,
    requestType.GET,
    null,
    null

  );
}
export const cmsDeleteSliders = (data) => {
  return Api(
    `${endPoints.cmsDeleteSliders}`,
    data,
    requestType.DELETE,
    null,
    null
  );
}


