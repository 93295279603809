export const  data = [
  {
    propertyId: "1000",
    address: "Karachi, Pakistan",
    propertyName: "House For Small Family",
    image: "house.jpg",
    price: 6500,
    propertyOwner: "Uzair Ahmed Baig",
    inventoryStatus: "Active",
    rating: 5,
  },
  {
   propertyId: "1001",
   address: "Quetta, Pakistan",
   propertyName: "Vip 5 Star House",
   image: "house2.jpg",
   price: 650,
   propertyOwner: "Faheem Yasir",
   inventoryStatus: "Active",
   rating: 3,
 },
 {
   propertyId: "1002",
   address: "Islamabad, Pakistan",
   propertyName: "Appartment For Rent",
   image: "house3.jpg",
   price: 9500,
   propertyOwner: "Qasim Shah",
   inventoryStatus: "Active",
   rating: 5,
 },
 {
   propertyId: "1000",
   address: "Karachi, Pakistan",
   propertyName: "House For Small Family",
   image: "house4.jpg",
   price: 6500,
   propertyOwner: "Uzair Ahmed Baig",
   inventoryStatus: "Active",
   rating: 5,
 },
 {
   propertyId: "1003",
   address: "Karachi, Pakistan",
   propertyName: "Villa For Rent",
   image: "house5.jpg",
   price: 500,
   propertyOwner: "Shamoon Ismail",
   inventoryStatus: "Active",
   rating: 2,
 },
];
