import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Sidebar from "../../components/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import Pagination from "../../components/pagination";
import CrudDataTable from "../../components/crudDataTable/index";
import { Toast } from "primereact/toast";
import { useAuth } from "../../components/hooks/useAuth";

import {
  getUsedCouponById,
  deleteUsedCoupon,
} from "./../../services/couponUsage";
import moment from "moment";
import { getCouponById } from "../../services/coupon";

const CouponUsage = () => {
  let emptyProduct = {
    usedDate: new Date(),
    code: "",
    discountedAmount: "",
  };
  const { id } = useParams();
  const [coupon, setCoupon] = useState({});
  const [usedCoupons, setUsedCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customFirst, setCustomFirst2] = useState(0);
  const [customRows, setCustomRows2] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const toast = useRef(null);

  const getData = () => {
    setIsLoading(true);
    getCouponById(id)
      .then((res) => {
        setIsLoading(false);
        getUsedCouponById(res.data.data.id)
          .then((res) => {
            if (res.data.statusCode == 404) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: res.data.error.message,
                life: 3000,
              });
            } else {
              setIsLoading(false);
              setUsedCoupons(res.data.data.reverse());
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData(0, customRows);
  }, []);

  const validThroughTemplate = (rowData) => {
    return (
      <span>{moment(rowData?.createdOn).format("DD-MM-YYYY hh:mm:ss")}</span>
    );
  };

  const DATA = [
    { id: 1, field: "id", header: "Id" },
    { id: 2, field: "code", header: "Code" },
    {
      id: 3,
      field: "createdOn",
      header: "Created On",
      body: validThroughTemplate,
    },
    { id: 4, field: "discountedAmount", header: "Discounted Percentage" },
  ];

  const onPageChange = (event) => {
    setCustomFirst2(event.first);
    setCustomRows2(event.rows);
    getData(event.page, event.rows);
  };

  const deleteSelectedItem = (id, setOpenDialog) => {
    setIsLoading(true);
    deleteUsedCoupon(id).then((res) => {
      setIsLoading(false);
      if (res.data.statusCode == 400) {
        let tempArr = [];
        res?.data?.message.map((val, ind) => {
          tempArr.push({
            severity: "error",
            summary: "Error",
            detail: val,
            life: 3000,
          });
        });
        setOpenDialog(false);
        toast.current.show(tempArr);
      } else {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Deleted Successfully",
          life: 3000,
        });
        getData();
        setTimeout(() => {
          setOpenDialog(false);
        }, 1500);
      }
    });
  };

  return (
    <div className="app-main">
      <Toast ref={toast} position="bottom-right" />
      <Sidebar active="coupons" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="Coupons Usage - Staywo"
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />

          <div className="row">
            <div className="col-lg-12">
              <Card title="Coupon Usage">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={DATA}
                    data={usedCoupons}
                    totalRecords={totalRecords}
                    deleteSelectedItem={deleteSelectedItem}
                    emptyProduct={emptyProduct}
                    dialogHeader="Coupon Usage"
                    tableTitle="Coupon Usage"
                    showActions={true}
                    showView={false}
                    showToolbar={false}
                    showDelete={true}
                    showEdit={false}
                  />
                )}
                <Pagination
                  totalRecords={totalRecords}
                  customFirst={customFirst}
                  customRows={customRows}
                  onPageChange={onPageChange}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponUsage;
