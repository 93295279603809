import React, { useState, useEffect, useRef } from 'react';
import Banner from '../../components/banner';
import Card from '../../components/card';
import Sidebar from '../../components/sidebar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { getPropertyInfos } from './../../services/properties/index';
import { Image } from 'primereact/image';
import { Toolbar } from 'primereact/toolbar';
import Pagination from '../../components/pagination';
import CrudDataTable from '../../components/crudDataTable/index';
import { Toast } from 'primereact/toast';
import PropertyStatusDialog from './StatusDialog';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import './style.css';
import PropertyFilterDialog from './PropertyFilterDialog';
import { priceBodyTemplate } from '../../constants/utils';
import { ComposableMap, Geographies, Geography,ZoomableGroup,Marker } from "react-simple-maps";





const PropertiesList = () => {
  let emptyProduct = {
    image: null,
    propertyName: '',
    propertyOwner: '',
    price: 0,
    rating: 0,
    status: '',
  };
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customFirst, setCustomFirst2] = useState(0);
  const [customRows, setCustomRows2] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(0);
  
 
  const toast = useRef(null);
  const dialogRef = useRef();
  const filterDialogRef = useRef();

  // const selectedCountry = find(countries, { iso3: "PAK" });
  
  const getData = async (
    page,
    limit,
    sortBy,
    code,
    country,
    state,
    city,
    propertyType,
    recommended,
    inspiredDestination,
    minPrice,
    maxPrice,
    areaId,
    adults,
    beds,
    bedrooms,
    pets,
    infants,
    childValue,
    spaceId,
    spaceType,
    userId,
    status,
    booking,
    floodRelief,
    propAmenityId
  ) => {
    setIsLoading(true);

    await getPropertyInfos(
      page,
      limit,
      sortBy,
      code,
      country,
      state,
      city,
      propertyType,
      recommended,
      inspiredDestination,
      minPrice,
      maxPrice,
      areaId,
      adults,
      beds,
      bedrooms,
      pets,
      infants,
      childValue,
      spaceId,
      spaceType,
      userId,
      status,
      booking,
      floodRelief,
      propAmenityId
    )
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        let tempArr = [];
        res.data.data.map((item) => {
          return tempArr.push({
            ...item,
            // propertyOwner: `${item.user.firstName} ${item.user.middleName} ${item.user.lastName}`,
          });
        });
        setTotalRecords(res.data.total);
        setProducts(tempArr);
        console.log('tempArr', tempArr);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData(
      0,
      customRows,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );
  }, []);

  const imageBodyTemplate = (rowData) => {
    return (
      <Image
        height='60px'
        width='90px'
        className='table-image-type'
        src={rowData.thumbnailUrl}
      />
    );
  };

  const propertyTitleTemplate = (rowData) => {
    return (
      <span>
        {rowData.title} ({rowData.code})
      </span>
    );
  };

  const statusTepmlate = (rowData) => {
    return (
      <>
        <div style={{ marginBottom: '10px' }}>
          <span
            style={{
              fontWeight: 500,
              background: '#c8e6c9',
              color: '#256029',
              padding: '5px 10px',
            }}
          >
            {rowData.status}
          </span>
        </div>
        <Button
          label='Change'
          className='p-button-sm'
          style={{ fontSize: '12px', padding: '6px 8px' }}
          onClick={() => {
            dialogRef.current.isVisible(rowData);
          }}
        />
      </>
    );
  };

  const handleView = (rowData) => {
    navigate(`/propertyDetailsTabs/${rowData.id}`);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          icon='pi pi-eye'
          className='p-button-rounded p-button-raised mr-2'
          onClick={() => handleView(rowData)}
        />
        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-button-success mr-2'
          onClick={() =>
            navigate(`/EditProperty/${rowData.id}`, {
              state: { type: 'edit', propertyDetail: rowData },
            })
          }
        />
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-danger'
          // onClick={() => confirmDeleteProduct(rowData)}
        />
        
      </div>
    );
  };

  const DATA = [
    { id: 1, field: 'id', header: 'Id' },
    { id: 2, field: 'thumbnailUrl', header: 'Image', body: imageBodyTemplate },
    { id: 3, field: 'title', header: 'Name', body: propertyTitleTemplate },
    { id: 4, field: 'user.firstName', header: 'Owner' },
    {
      id: 5,
      field: 'perNightPrice',
      header: 'Price',
      body: (e) => "$"+e.perNightPrice,
    },
    { id: 6, field: 'isActive', header: 'Status', body: statusTepmlate },
    { id: 7, header: 'Actions', body: actionBodyTemplate },
  ];

  const onPageChange = (event) => {
    setCustomFirst2(event.first);
    setCustomRows2(event.rows);
    setActivePage(event.page);
    getData(
      event.page,
      event.rows,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label='Add'
          icon='pi pi-plus'
          className='p-button-success mr-2'
          onClick={() => navigate('/AddProperty')}
        />
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label='Advance Filter'
          icon='pi pi-filter'
          className='p-button-help'
          style={{ fontSize: '12px', padding: '8px' }}
          onClick={() => filterDialogRef.current.isVisible({})}
        />
      </React.Fragment>
    );
  };
  
  const handlePropertyCalendar=(rowData)=>{
    navigate(`/calendar/${rowData.id}`)
  }

  return (
    
    <div className='app-main'>
     
      <Toast ref={toast} position='bottom-right' />
      <Sidebar active='properties' />
      <div className='app-main__outer'>
        <div className='app-main__inner'>
          <Banner
            title='All Properties - Staywo'
            icon='pe-7s-plugin icon-gradient bg-mean-fruit'
          />
          <div className='row'>
            <div className='col-lg-12'>
              <Card title='Properties'>
                <Toolbar
                  className='mb-4'
                  left={leftToolbarTemplate}
                  right={rightToolbarTemplate}
                ></Toolbar>
                
                  
                
                

                {isLoading ? (
                  <div style={{ textAlign: 'center' }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <>
                  <CrudDataTable
                    headerColumns={DATA}
                    data={products}
                    totalRecords={totalRecords}
                    emptyProduct={emptyProduct}
                    dialogHeader='Property Details'
                    tableTitle='Properties'
                    showActions={false}
                    showView={true}
                    showExport={false}
                    showToolbar={false}
                    sortable={false}
                    handleView={handleView}
                    showEdit={true}
                  />
                  
                    </>
                  
                )}
                <Pagination
                  totalRecords={totalRecords}
                  customFirst={customFirst}
                  customRows={customRows}
                  onPageChange={onPageChange}
                />
                
              </Card>
            </div>
          </div>
        </div>
      </div>
      <PropertyStatusDialog
        getData={getData}
        customRows={customRows}
        ref={dialogRef}
      />
      <PropertyFilterDialog
        getData={getData}
        customRows={customRows}
        activePage={activePage}
        ref={filterDialogRef}
      />
      
    </div>
  );
};

export default PropertiesList;
