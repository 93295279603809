import React, { useState, useEffect } from 'react'
import Sidebar from './../../components/sidebar';
import { Container, Draggable } from 'react-smooth-dnd'
import { useParams } from 'react-router-dom';
import { cmsPostSliders, cmsGetSliders, cmsDeleteSliders } from '../../services/Cms/Cms';
function Sliders() {
  const { id } = useParams();
  const [images, setImages] = useState([])
  const [files, setFiles] = useState(null)
  const [userId, setUserId] = useState({})

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"))
    setUserId(user)
  }, [])

  const handleUploadImage = (e) => {
    setFiles(e.target.files[0])
  }

  const getCmsSliders = () => {
    cmsGetSliders(null, id)
      .then((res) => {
        setImages(res.data.data)
        console.log(res)
      }).catch((e) => console.log(e))
  }

  useEffect(() => {
    getCmsSliders();
  }, [])


  const handlePostSliders = () => {
    let formData = new FormData();
    formData.append('parentSliderId', id)
    formData.append("images", files);

    cmsPostSliders(formData)
      .then((res) => {
        console.log(res)
        getCmsSliders();
      }).catch((e) => console.log(e))
  }
  //delete
  const handleDeleteSliders = (slide) => {
    let payload = { slideId: slide, userId: 1 }
    cmsDeleteSliders(payload).then((res) => {
      console.log(res)
      getCmsSliders();
    }).catch((e) => console.log(e))
  }

  const applyDrag = (arr, dragResult) => {
    const { removeIndex, addedIndex, payload } = dragResult
    if (removeIndex === null && addedIndex === null) return arr
    const result = [...arr]
    let itemToAdd = payload
    if (removeIndex !== null) {
      itemToAdd = result.splice(removeIndex, 1)[0]
    }
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd)
    }
    return result
  }
  // const [columns, setColumns] = useState(images)
  // const onColumnDrop = (dropResult) => {
  //   let newColumns = [...columns]
  //   newColumns = applyDrag(newColumns, dropResult)
  //   setColumns(newColumns)
  // }



  return (
    <>
      <div className="app-main" style={{ flex: "0", flexDirection: "column" }}>
        <Sidebar active="sliders" />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <div>Sliders
                  </div>
                </div>
                <div className="page-title-actions">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "85%", marginLeft: "295px" }}>
          <div className="d-flex justify-content-between px-4 bg-light py-4">
            <div className="sliders">
              <h5 className="font-weight-bold">Edit Slider</h5>
            </div>
            {/* live preview button */}
            <div className="live__preview">
              <button className="btn btn-light">
                <i className="fa fa-eye">
                </i>  &nbsp; Live Preview  &nbsp;
              </button>
            </div>

            <div className="addButton">
              <button className="btn btn-danger"
                onClick={handlePostSliders}>
                &nbsp; Save  &nbsp;  <i className="fa fa-plus">
                </i></button>
            </div>
          </div>

          <div className="main-card mb-3 card" >
            <div className="card-body">
              <div className='d-flex'>
                {/* image icon */}
                <i
                  className="fa fa-image"
                  style={{
                    fontSize: "30px",
                    color: "#9e9e9e",
                    marginLeft: "10px"
                  }}
                ></i>
                <span>
                  <h5 className="font-weight-bold" style={{ marginLeft: "10px" }}>Slides</h5>
                </span>
              </div>
              <div className="paragraph">
                <p style={{ marginLeft: "10px" }}>Slides that represents your content that will display in the slider. Double Click if you want add HTML instead.</p>
              </div>
              {/* slides */}
              {/* <Container
                orientation="horizontal"
                onDrop={onColumnDrop}
                getChildPayload={index => columns[index]}
                dragHandleSelector=".column-drag-handle"
                dropPlaceholder={{
                  animationDuration: 150,
                  showOnTop: true,
                  className: 'columns-drop-preview'
                }}
              > */}
              <div className="row">
                {/* <div className="col-md-2"> */}
                {
                  images.map((item, index) => (
                    <div className="first__slide" key={index}>
                      <i onClick={() => handleDeleteSliders(item.id)} className="fa fa-trash" style={{
                        fontSize: "20px", color: "#9e9e9e",
                        marginLeft: "10px",
                        cursor: 'pointer'
                      }}></i>
                      <div className="col-md-2">
                        <img src={item.fileURL} alt="image"
                          style={{
                            width: "100px",
                            height: "100px",
                            marginLeft: "10px"
                          }}
                        />
                      </div>
                    </div>))}
                {/* </div> */}
              </div>
              {/* </Container> */}
              <div className="add_Slides-button my-4">
                <input
                  type="file"
                  name="file"
                  id="file"
                  multiple
                  onChange={handleUploadImage}
                  style={{ display: "none" }} />
                <label htmlFor="file" className="btn btn-light">
                  <i className="fa fa-plus">
                  </i>  &nbsp; Add Slides  &nbsp;
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Sliders