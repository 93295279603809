import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllWallets = () => {
  return Api(`${endPoints.getAllWallets}`, null, requestType.GET, null, null);
};

export const addAmmount = (params) => {
  return Api(`${endPoints.addAmount}`, params, requestType.PATCH, null, null);
};

export const deductAmount = (params) => {
  return Api(
    `${endPoints.deductAmount}`,
    params,
    requestType.PATCH,
    null,
    null
  );
};

export const updateBalance = (params) => {
  return Api(
    `${endPoints.updateBalance}`,
    params,
    requestType.PATCH,
    null,
    null
  );
};

export const getTransactionHistoryById = (id) => {
  return Api(
    `${endPoints.getTransactionHistory}/${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getWalletById = (id) => {
  return Api(
    `${endPoints.getWalletById}/${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};
