import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Toast } from "primereact/toast";
import { calculateBookingPrice } from "./../../services/bookings/index";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  MdChildCare,
  MdPets,
  MdCleaningServices,
  MdPayments,
} from "react-icons/md";
import { BsFillMoonFill } from "react-icons/bs";
import { BiUserPlus } from "react-icons/bi";
import { MdOutlineAccountBalance } from "react-icons/md";
import { ImCheckmark2 } from "react-icons/im";

const PaymentDetailSection = ({
  inputVal,
  handleChange,
  userWallet,
  userPaymentCards,
  startDate,
  endDate,
  propertyDetail,
  capacty,
  calculatedData,
  setCalculatedData,
}) => {
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [totalNights, setTotalNights] = useState(0);
  // const [serviceFee, setServiceFee] = useState(0);
  // const [totalNightsCost, setTotalNightsCost] = useState(0);
  // const [totalGst, setTotalGst] = useState(0);

  const getDayDifference = (start, end) => {
    var given = moment(new Date(start));
    var current = moment(new Date(end));
    const diff = current.diff(given);
    const diffDuration = moment.duration(diff);
    return Math.ceil(diffDuration._milliseconds / (1000 * 60 * 60 * 24));
  };

  const showToast = (msg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const handleCalcPrice = () => {
    if (startDate == "" && endDate == "") {
      showToast("Please select date range");
    } else {
      let payload = {
        propertyId: propertyDetail?.id,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        noOfAdults: capacty.adults,
        noOfChildren: capacty.children,
        noOfInfants: capacty.infants,
        noOfPets: capacty.pets,
        validate: true,
      };

      setIsLoading(true);
      calculateBookingPrice(payload)
        .then((res) => {
          setCalculatedData(res.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  // useEffect(() => {
  //   if (endDate !== "") {
  //     setTotalNights(getDayDifference(startDate, endDate));
  //     setTotalNightsCost(
  //       propertyDetail?.perNightPrice * getDayDifference(startDate, endDate)
  //     );
  //   }
  // }, [endDate]);

  // useEffect(() => {
  //   getSystemConfiguration("service_fee")
  //     .then((res) => {
  //       setServiceFee(res.data.data[0].service_fee);
  //     })
  //     .catch((err) => {});
  //   getSystemConfiguration("gst")
  //     .then((res) => {
  //       setTotalGst(res.data.data[0].gst);
  //     })
  //     .catch((err) => {});
  // }, []);

  // let totalPrice =
  //   totalNightsCost +
  //   parseInt(propertyDetail.propertyPricePolicy[0].cleaningFee) +
  //   parseInt(propertyDetail.propertyPricePolicy[0].extraGuestFee) +
  //   parseInt(propertyDetail.propertyPricePolicy[0].petFee) +
  //   (totalGst * totalNightsCost) / 100 +
  //   (serviceFee * totalNightsCost) / 100;
  return (
    <>
      <Toast ref={toast} position="bottom-right" />
      {inputVal.paymentType == "CHEQUE" && (
        <div className="col-lg-6">
          <h6 style={{ marginTop: "10px" }}>Cheque Number</h6>
          <InputText
            value={inputVal.chequeNo}
            onChange={handleChange}
            name="chequeNo"
            style={{ width: "100%" }}
            placeholder="Cheque Number"
          />
        </div>
      )}
      {inputVal.paymentType == "BANK_TRANSFER" && (
        <>
          <div className="col-lg-6">
            <h6 style={{ marginTop: "10px" }}>Account Title</h6>
            <InputText
              value={inputVal.accTitle}
              onChange={handleChange}
              name="accTitle"
              style={{ width: "100%" }}
              placeholder="Account Title"
            />
          </div>
          <div className="col-lg-6">
            <h6 style={{ marginTop: "10px" }}>Account Number</h6>
            <InputText
              value={inputVal.accNumber}
              onChange={handleChange}
              name="accNumber"
              type="number"
              style={{ width: "100%" }}
              placeholder="Account Number"
            />
          </div>
          <div className="col-lg-6">
            <h6 style={{ marginTop: "10px" }}>Transaction ID (Optional)</h6>
            <InputText
              value={inputVal.transactionId}
              onChange={handleChange}
              name="transactionId"
              type="number"
              style={{ width: "100%" }}
              placeholder="Transaction ID (Optional)"
            />
          </div>
        </>
      )}
      {inputVal.paymentType == "WALLET" && (
        <>
          <div className="col-lg-6">
            <h5 style={{ marginTop: "10px" }}>Wallet Amount</h5>
            <div style={{ background: "#fff", padding: "10px" }}>
              <p style={{ fontSize: "18px" }}>
                Rs. {userWallet?.amount > 0 ? userWallet?.amount : 0}
              </p>
              <p style={{ fontSize: "18px" }}>
                Total Booking Charges Rs. {calculatedData?.grossAmount}
              </p>
              {calculatedData && (
                <p style={{ fontSize: "18px" }}>
                  Wallet Remaining Amount Rs.{" "}
                  {userWallet?.amount > 0
                    ? userWallet?.amount - calculatedData?.grossAmount
                    : 0}
                </p>
              )}
            </div>
          </div>
        </>
      )}
      {inputVal.paymentType == "CARD" ||
      inputVal.paymentType == "CARD_PLUS_WALLET_EXISTING" ? (
        <>
          <div className="col-lg-6">
            <h6 style={{ marginTop: "10px" }}>Select Card</h6>
            <Dropdown
              value={inputVal.cardId}
              options={userPaymentCards}
              onChange={handleChange}
              style={{ width: "100%" }}
              optionLabel="label"
              name="cardId"
              placeholder="Select Card"
            />
          </div>
        </>
      ) : null}
      {inputVal.paymentType == "CARD_NEW" ||
      inputVal.paymentType == "CARD_PLUS_WALLET" ? (
        <>
          <div className="col-lg-6">
            <h6 style={{ marginTop: "10px" }}>Account Title</h6>
            <InputText
              value={inputVal.accTitle}
              onChange={handleChange}
              name="accTitle"
              style={{ width: "100%" }}
              placeholder="Account Title"
            />
          </div>
          <div className="col-lg-6">
            <h6 style={{ marginTop: "10px" }}>Card Number</h6>
            <InputText
              value={inputVal.creditCardNumber}
              onChange={handleChange}
              name="creditCardNumber"
              type="number"
              style={{ width: "100%" }}
              placeholder="Card Number"
            />
          </div>
          <div className="col-lg-6">
            <h6 style={{ marginTop: "10px" }}>Expiry Month</h6>
            <InputText
              value={inputVal.expMonth}
              onChange={handleChange}
              name="expMonth"
              type="number"
              style={{ width: "100%" }}
              placeholder="Expiry Month"
            />
          </div>
          <div className="col-lg-6">
            <h6 style={{ marginTop: "10px" }}>Expiry Year</h6>
            <InputText
              value={inputVal.expYear}
              onChange={handleChange}
              name="expYear"
              type="number"
              style={{ width: "100%" }}
              placeholder="Expiry Year"
            />
          </div>
          <div className="col-lg-6">
            <h6 style={{ marginTop: "10px" }}>CVC</h6>
            <InputText
              value={inputVal.cvc}
              onChange={handleChange}
              name="cvc"
              type="number"
              style={{ width: "100%" }}
              placeholder="CVC"
            />
          </div>
        </>
      ) : null}
      <div className="col-lg-6">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "10px 0",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <MdPayments style={{ fontSize: "25px", marginRight: "10px" }} />
            <h5 style={{ marginBottom: 0 }}>Price Details</h5>
          </div>
          {isLoading ? (
            <ProgressSpinner
              style={{ width: "50px", height: "50px", margin: 0 }}
            />
          ) : (
            <Button
              label="Calculate Price"
              className="p-button-help"
              style={{ fontSize: "12px", padding: "8px" }}
              onClick={handleCalcPrice}
            />
          )}
        </div>
        <div style={{ background: "#fff", padding: "10px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <BsFillMoonFill
                style={{ fontSize: "20px", marginRight: "5px" }}
              />
              <p style={{ marginBottom: 0 }}>
                Rs. {calculatedData ? calculatedData.perNightPrice : 0} x{" "}
                {calculatedData?.noOfNights} (nights)
              </p>
            </div>
            <p style={{ marginBottom: 0 }}>
              Rs. {calculatedData ? calculatedData.totalNightsCharges : 0}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MdCleaningServices
                style={{ fontSize: "20px", marginRight: "5px" }}
              />
              <p style={{ marginBottom: 0 }}>Cleaning Fee</p>
            </div>
            <p style={{ marginBottom: 0 }}>
              Rs. {calculatedData ? calculatedData.cleaningFee : 0}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <BiUserPlus style={{ fontSize: "20px", marginRight: "5px" }} />
              <p style={{ marginBottom: 0 }}>Additional Guest Fee</p>
            </div>
            <p style={{ marginBottom: 0 }}>
              Rs. {calculatedData ? calculatedData.extraGuestFee : 0}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MdChildCare style={{ fontSize: "20px", marginRight: "5px" }} />
              <p style={{ marginBottom: 0 }}>Infant Fee</p>
            </div>
            <p style={{ marginBottom: 0 }}>No Fee</p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MdPets style={{ fontSize: "20px", marginRight: "5px" }} />
              <p style={{ marginBottom: 0 }}>Pet Fee</p>
            </div>
            <p style={{ marginBottom: 0 }}>
              {calculatedData && calculatedData.noOfPets > 0
                ? `Rs. ${calculatedData.noOfPets * calculatedData.petFee}`
                : "Not Allowed"}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MdOutlineAccountBalance
                style={{ fontSize: "20px", marginRight: "5px" }}
              />
              <p style={{ marginBottom: 0 }}>
                Service Fee ({calculatedData ? calculatedData?.serviceFee : 0}{" "}
                %)
              </p>
            </div>

            <p style={{ marginBottom: 0 }}>
              Rs. {calculatedData ? calculatedData?.totalServiceCharges : 0}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MdOutlineAccountBalance
                style={{ fontSize: "20px", marginRight: "5px" }}
              />
              <p style={{ marginBottom: 0 }}>
                GST ({calculatedData ? calculatedData?.gst : 0} %)
              </p>
            </div>
            <p style={{ marginBottom: 0 }}>
              Rs. {calculatedData ? calculatedData?.totalGSTFee : 0}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ImCheckmark2 style={{ fontSize: "20px", marginRight: "5px" }} />
              <p style={{ marginBottom: 0 }}>Total Price</p>
            </div>
            <p style={{ marginBottom: 0 }}>
              Rs. {calculatedData ? calculatedData?.grossAmount : 0}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetailSection;
