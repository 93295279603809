const currency = process.env.REACT_APP_CURRENCY;
const country = process.env.REACT_APP_COUNTRY_CODE;

const formatCurrency = (value) => {
  return value?.toLocaleString(country, {
    style: "currency",
    currency: currency,
  });
};

export const priceBodyTemplate = (price) => {
  return formatCurrency(price);
};
