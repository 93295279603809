import React,{useEffect, useState} from 'react'
import { getPropertyDocumentsApi } from '../../services/properties/getPropertyDocument'
import {  useParams } from "react-router-dom";
import { updateDocumentStatus } from '../../services/properties/updateDocumentStatus';
import Input from '../../components/formComponents/input';
import Swal from 'sweetalert2';

const Document = () => {
const [propertyDocument,setPopertyDocument]=useState([]);
const [status, setStatus]=useState('');
const [description, setDescription] = useState();

const { id}=useParams();


const getDocumentById=async()=>{
    console.log(id,"===========>");
    let response=await getPropertyDocumentsApi(id);

    if (response.status === 200) {
        setPopertyDocument(response?.data);
        setDescription(response?.data?.description);
        setStatus(response?.data?.status)
        
        
    }
}
    console.log(propertyDocument, "==========>resposedocument")
    
const updateValues={
    status:status,
    description:description,
    approvedBy:0
}
    const updateDocument = async () => {
        console.log(description, "=====>description")
if(description){
    
    
        console.log(updateValues, "status=========>")
        await updateDocumentStatus(id,updateValues)

        
    }
    else{
        
    Swal.fire({
        position: "center",
        icon: "warning",
        text: "Please add description",
        color: "black",
        showConfirmButton: false,
        timer: 2000,
    });

    }
}
   
    console.log(propertyDocument, "==========>document")
useEffect(()=>{
getDocumentById();
// updateDocument();
   
},[])
const handleAccept=()=>{
    setStatus("approved");
}
const handleReject = () => {
    setStatus("rejected");
    }

    const handlePending = () => {
        setStatus("pending");
    }

  return (
    <>
    
          <div className="">
              <div style={{ background: "#fff", padding: "24px" }}>
                  <h5>Documents</h5>
                  
                    
                  <div className="mt-4">
                  <img style={{width:"40%"}} src={propertyDocument?.filename}/>
                  
                      <div className="col-5 mt-3">
                          <h6 style={{ fontWeight: "600" }}>Description: </h6>
                          <Input
                             
                                onChange={(e)=>setDescription(e.target.value)}
                              placeholder={propertyDocument?.description}
                          />
                          </div>

                          
                      <h6 className='ml-2' style={{ fontWeight: "600" }}>Status:  <span className='ml-1' style={{fontSize:"16px"}}>{status}</span></h6>
                    
                          
                     
                         <div className='row'>
                         
                      <div className="dropdown ml-4 ">
                          <button type="button" 
                          className="btn btn-outline-primary dropdown-toggle mt-3" data-toggle="dropdown"
                            >
                              Set Document Status
                          </button>
                      
                            <div className="dropdown-menu" >
                            <a className="dropdown-item" href="#" value="Accept" onClick={handleAccept}>Accept</a>
                            <a className="dropdown-item" href="#" value="Reject" onClick={handleReject}>Reject</a>
                            <a className="dropdown-item" href="#" value="Pending" onClick={handlePending}>Pending</a>
                            
                          </div>
                          
                      </div>
                      <div>
                          <button type="button" className="btn btn-primary  mt-3 ml-4"
                          onClick={updateDocument} >
                             Update Document
                          </button>
                      </div>
                  </div>
                  </div> 
                
                  
               

                  
              </div>
          </div>
    </>
  )
}

export default Document