import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getLatestProperties = (order, page, limit) => {
  return Api(
    `${endPoints.allProperties}?page=${page}&limit=${limit}&sortBy=${order}`,
    null,
    requestType.GET,
    null,
    null
  );
};
export const getPrivateProperties = (order, page, limit, privateProp) => {
  return Api(
    `${endPoints.allProperties}?page=${page}&limit=${limit}&sortBy=${order}&propertyType=${privateProp}`,
    null,
    requestType.GET,
    null,
    null
  );
};
export const getCommercialProperties = (order, page, limit, commercialProp) => {
  return Api(
    `${endPoints.allProperties}?page=${page}&limit=${limit}&sortBy=${order}&propertyType=${commercialProp}`,
    null,
    requestType.GET,
    null,
    null
  );
};
export const getLatestSignups = (order, page, take, token) => {
  return Api(
    `${endPoints.usersByLimit}?order=${order}&page=${page}&take=${take}`,
    null,
    requestType.GET,
    null,
    token
  );
};

export const getLatestBookings = (page, limit, order, status, date) => {
  return Api(
    `${endPoints.bookingAll}${page ? page : ""}${limit ? limit : ""}${
      order ? order : ""
    }${status ? status : ""}${date ? date : ""}`,
    null,
    requestType.GET,
    null,
    null
  );
};
