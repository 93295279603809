import { MdChildCare, MdPets, MdCleaningServices } from "react-icons/md";
import { BsFillMoonFill } from "react-icons/bs";
import { BiUserPlus } from "react-icons/bi";
import { MdOutlineAccountBalance } from "react-icons/md";
import { ImCheckmark2 } from "react-icons/im";
import { priceBodyTemplate } from "../../constants/utils";

const BookingDetailCard = ({ calculatedData }) => {
  return (
    <div>
      <div className="col-lg-12">
        <div style={{ background: "#fff", padding: "10px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <BsFillMoonFill
                style={{ fontSize: "20px", marginRight: "5px" }}
              />
              <p style={{ marginBottom: 0 }}>
                {calculatedData
                  ? priceBodyTemplate(calculatedData.perNightPrice)
                  : 0}{" "}
                x {calculatedData?.noOfNights} (nights)
              </p>
            </div>
            <p style={{ marginBottom: 0 }}>
              {calculatedData
                ? priceBodyTemplate(calculatedData.totalNightsCharges)
                : 0}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MdCleaningServices
                style={{ fontSize: "20px", marginRight: "5px" }}
              />
              <p style={{ marginBottom: 0 }}>Cleaning Fee</p>
            </div>
            <p style={{ marginBottom: 0 }}>
              {calculatedData
                ? priceBodyTemplate(calculatedData.cleaningFee)
                : 0}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <BiUserPlus style={{ fontSize: "20px", marginRight: "5px" }} />
              <p style={{ marginBottom: 0 }}>Additional Guest Fee</p>
            </div>
            <p style={{ marginBottom: 0 }}>
              {calculatedData
                ? priceBodyTemplate(calculatedData.extraGuestFee)
                : 0}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MdChildCare style={{ fontSize: "20px", marginRight: "5px" }} />
              <p style={{ marginBottom: 0 }}>Infant Fee</p>
            </div>
            <p style={{ marginBottom: 0 }}>No Fee</p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MdPets style={{ fontSize: "20px", marginRight: "5px" }} />
              <p style={{ marginBottom: 0 }}>Pet Fee</p>
            </div>
            <p style={{ marginBottom: 0 }}>
              {calculatedData && calculatedData.noOfPets > 0
                ? priceBodyTemplate(
                    calculatedData.noOfPets * calculatedData.petFee
                  )
                : "Not Allowed"}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MdOutlineAccountBalance
                style={{ fontSize: "20px", marginRight: "5px" }}
              />
              <p style={{ marginBottom: 0 }}>
                Service Fee ({calculatedData ? calculatedData?.serviceFee : 0}{" "}
                %)
              </p>
            </div>

            <p style={{ marginBottom: 0 }}>
              {calculatedData
                ? priceBodyTemplate(calculatedData?.totalServiceCharges)
                : 0}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MdOutlineAccountBalance
                style={{ fontSize: "20px", marginRight: "5px" }}
              />
              <p style={{ marginBottom: 0 }}>
                GST ({calculatedData ? calculatedData?.gst : 0} %)
              </p>
            </div>
            <p style={{ marginBottom: 0 }}>
              {calculatedData
                ? priceBodyTemplate(calculatedData?.totalGSTFee)
                : 0}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "10px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ImCheckmark2 style={{ fontSize: "20px", marginRight: "5px" }} />
              <p style={{ marginBottom: 0 }}>Total Price</p>
            </div>
            <p style={{ marginBottom: 0 }}>
              {calculatedData
                ? priceBodyTemplate(calculatedData?.grossAmount)
                : 0}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetailCard;
