import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";

const PermissionsDialog = React.forwardRef(({ getData, customRows }, ref) => {
  const user_token = JSON.parse(localStorage.getItem("token"));
  const toast = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [inputVal, setInputVal] = useState({
    status: "",
    description: "",
  });

  useImperativeHandle(ref, () => ({
    isVisible(params) {
      let newObj = {};
      let tempArr = [];

      if (params.actions) {
        params.actions.map((item) => {
          tempArr.push({
            ...item,
            isSelected: false,
          });
        });

        newObj = {
          module: params.module,
          actions: tempArr,
        };
        setDialogData(newObj);
      }
      setOpenStatusDialog(true);
    },
  }));

  const hideDialog = () => {
    setOpenStatusDialog(false);
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      {!isLoading ? (
        <Button label="Save" icon="pi pi-check" className="p-button-text" />
      ) : null}
    </React.Fragment>
  );

  console.log(dialogData, "asldkasldja");

  const handleSelectPermisions = (item, ind) => {
    let newObj = { ...dialogData };

    newObj.actions.map((val) => {
      newObj.actions[ind].isSelected = !newObj.actions[ind].isSelected;
    });

    setDialogData(newObj);
  };

  return (
    <Dialog
      visible={openStatusDialog}
      style={{ width: "450px" }}
      header="Permission Details"
      modal
      className="p-fluid"
      footer={productDialogFooter}
      onHide={hideDialog}
    >
      <Toast ref={toast} position="bottom-right" />
      <div>
        <h5>Module: {dialogData?.module}</h5>
        <h4>Actions</h4>

        {dialogData?.actions.map((item, ind) => {
          return (
            <div
              className="field-checkbox"
              onClick={() => handleSelectPermisions(item, ind)}
            >
              <Checkbox value={item.isSelected} checked={item.isSelected} />
              <label htmlFor="city1" style={{ marginBottom: 0 }}>
                {item.label}
              </label>
            </div>
          );
        })}
      </div>
    </Dialog>
  );
});

export default PermissionsDialog;
