import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllNearbyActivity = () => {
  return Api(
    `${endPoints.getAllNearbyActivity}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addNearbyActivity = (params) => {
  return Api(
    `${endPoints.addNearbyActivity}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editNearbyActivity = (id, params) => {
  return Api(
    `${endPoints.editNearbyActivity}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};

export const deleteNearbyActivityById = (id) => {
  return Api(
    `${endPoints.deleteNearbyActivity}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
