import { API_URL } from '../../config';
import axios from 'axios';

export const updateDocumentStatus = async (id,data) => {
    try {
        const response = await axios.patch(
            API_URL + `property-document/updateDocumentStatus/${id}`,data
        );
        return response;
    } catch (error) {
        if (error) {
            const { response } = error;
            console.log('Bad Response of updatigDocument: ', response);
            return response;
        }
    }
};