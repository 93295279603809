import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllAmenitiesGroup = () => {
  return Api(`${endPoints.groupAmenities}`, null, requestType.GET, null, null);
};

export const addAmenityGroup = (params) => {
  return Api(
    `${endPoints.addAmenityGroup}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editAmenityGroup = (id, params) => {
  return Api(
    `${endPoints.editAmenityGroup}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteAmenityGroupById = (id) => {
  return Api(
    `${endPoints.deleteAmenityGroup}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
