import React from "react";
import { InputText } from "primereact/inputtext";

const Input = ({
  title,
  placeholder,
  value,
  onChange,
  id,
  type,
  name,
  defaultValue,
  error,
  helperText,
}) => {
  return (
    <div className="field">
      <label htmlFor="username1" className="block">
        {title}
      </label>
      <InputText
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        defaultValue={defaultValue}
        name={name}
        style={{ width: "100%" }}
      />
      {error && (
        <small
          id="username2-help"
          className="p-error"
          style={{
            display: "block",
            color: "red",
            textAlign: "left",
          }}
        >
          {helperText}
        </small>
      )}
    </div>
  );
};

export default Input;
