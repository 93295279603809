import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const paymentBookingStripe = (params) => {
  return Api(
    `${endPoints.bookingPayment}`,
    params,
    requestType.POST,
    true,
    null
  );
};

export const getPaymentCardsByUserId = (id) => {
  return Api(
    `${endPoints.paymentCardByUser}/${id}`,
    null,
    requestType.GET,
    true,
    null
  );
};
