import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const addUser = (params, token) => {
  return Api(`${endPoints.addUser}`, params, requestType.POST, null, token);
};

export const getAllUsers = (token) => {
  return Api(`${endPoints.allUsers}`, null, requestType.GET, null, token);
};

export const getUsersByLimit = (order, page, take, token) => {
  return Api(
    `${endPoints.usersByLimit}?order=${order}&page=${page}&take=${take}`,
    null,
    requestType.GET,
    null,
    token
  );
};

export const getAllUsersWithoutFilter = (token) => {
  return Api(
    `${endPoints.alluserDropDown}`,
    null,
    requestType.GET,
    null,
    token
  );
};

export const updateUser = (payload, userId, token) => {
  return Api(
    `${endPoints.updateUserById}/${userId}`,
    payload,
    requestType.PATCH,
    null,
    token
  );
};

export const updateuserData = (payload, userId, token) => {
  return Api(
    `${endPoints.updateuserData}/${userId}`,
    payload,
    requestType.PATCH,
    null,
    token
  );
};

export const getUserById = (id, token) => {
  return Api(`${endPoints.userById}/${id}`, null, requestType.GET, null, token);
};

export const updateProfilePicture = (id, params, token) => {
  return Api(
    `${endPoints.updateProfile}/${id}`,
    params,
    requestType.PUT,
    true,
    token
  );
};

export const updateUserStatusByAdmin = (params, token) => {
  return Api(
    `${endPoints.updateUserStatus}`,
    params,
    requestType.POST,
    null,
    token
  );
};

export const updateHostStatus = (id,data, token) => {
  
  return Api(
    `${endPoints.updateHostStatus}/${id}`,
    null,
    requestType.PUT,
    data,
    token
  );
};

export const updateStatusbyAdmin = (id,data, token) => {
  
  return Api(
    `${endPoints.userStatusUpdateAdmin}`,
    data,
    requestType.PUT,
    null,
    token
  );
};

export const deleteUserById = (id, token) => {
  return Api(
    `${endPoints.deleteUserById}/${id}`,
    null,
    requestType.DELETE,
    null,
    token
  );
};
