import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from '../../fakeService/productService';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Avatar } from 'primereact/avatar';
import 'primeflex/primeflex.css';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { changeType } from '../../redux/slices/viewSlice';
import TableData from './TableData';
import { storeEditData } from '../../redux/slices/userList';

const CrudDataTable = ({
  data,
  totalRecords,
  headerColumns,
  renderDialog,
  dialogHeader,
  dialogHeaderView,
  renderViewDialog,

  addEditFunction,
  deleteSelectedItem,
  tableTitle,
  showToolbar,
  showActions,
  sortable,
  handleView,
  showEdit,
  showView,
  showExport,
  showDelete,
  showSearch,
}) => {
  const [products, setProducts] = useState(null);
  const [editImage, setEditImage] = useState(null)
  const [productDialog, setProductDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState({

    username: "",
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    role: "",
    address: "",
    description: "",



  });
  const [selectedProducts, setSelectedProducts] = useState(null);
  const type = useSelector(state => state.ui.type)
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const { userEditData } = useSelector(state => state.userList)
  const toast = useRef(null);
  const dt = useRef(null);
  const dispatch = useDispatch()
  const userdata = useSelector(state => state.userList.userList)
  const location = useLocation();
  React.useEffect(()=>{
   
      dispatch(changeType("table"))
    
  },[location])

  

  

  const openNew = () => {
    setProduct(product);
    dispatch(changeType("add"))
    setSubmitted(false);
    // setProductDialog(true);
  };


  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = () => {
    addEditFunction(product, setProduct, setProductDialog, type);
  };


  React.useEffect(() => {


    setEditImage(userEditData.profilePicture)
    setProduct({
      ...product,
      username: userEditData.username ? userEditData.username : "",
      firstName: userEditData.name ? userEditData.name.split(" ")[0] : "",
      middleName: userEditData.name ? userEditData.name.split(" ")[1] : "",
      email: userEditData.email ? userEditData.email : "",

      lastName: userEditData.name ? userEditData.name.split(" ")[2] : "",
      phoneNumber: userEditData.phoneNumber ? userEditData.phoneNumber : "",
      password: "",
      role: "",
      address: "",
      description: "",

    });
  }, [userEditData])

  const editProduct = (data) => {

    dispatch(storeEditData(data))
    setEditImage(userEditData.profilePicture)
    setProduct({
      ...product,
      username: data.username ? data.username : "",
      firstName: data.name ? data.name.split(" ")[0] : "",
      middleName: data.name ? data.name.split(" ")[1] : "",
      email: data.email ? data.email : "",

      lastName: data.name ? data.name.split(" ")[2] : "",
      phoneNumber: data.phoneNumber ? data.phoneNumber : "",
      password: "",
      role: "",
      address: "",
      description: "",
      middleName: "",
    });
    dispatch(changeType("edit"))

  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  // const deleteProduct = () => {
  //   console.log(product.id, "Asdlksajdlsalk");
  //   let _products = products.filter((val) => val.id !== product.id);
  //   setProducts(_products);
  //   setDeleteProductDialog(false);
  //   setProduct(emptyProduct);
  //   toast.current.show({
  //     severity: "success",
  //     summary: "Successful",
  //     detail: "Product Deleted",
  //     life: 3000,
  //   });
  // };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: 'success',
      summary: 'Successful',
      detail: 'Products Deleted',
      life: 3000,
    });
  };

  const onInputChange = (e) => {
    console.log(e.target.name)
    setProduct({ ...product, [e.target.name]: e.target.value })
    // if (name == 'icon' || name == 'code') {
    //   let _product = { ...product };
    //   _product[`${name}`] = e;

    //   setProduct(_product);
    // } else if (name == 'isActive') {
    //   const val = e.value;
    //   let _product = { ...product };
    //   _product[`${name}`] = val;

    //   setProduct(_product);
    // } else {
    //   const val = (e.target && e.target.value) || '';
    //   let _product = { ...product };
    //   _product[`${name}`] = val;

    //   setProduct(_product);
    // }
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label='Add'
          icon='pi pi-plus'
          className='p-button-success mr-2'
          onClick={openNew}
        />
        {/* <Button
          label="Delete"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedProducts || !selectedProducts.length}
        /> */}
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label='Export'
          icon='pi pi-upload'
          className='p-button-help'
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div style={{ display: 'flex' }}>
        {showView && (
          <Button
            icon='pi pi-eye'
            className='p-button-rounded p-button-raised mr-2'
            onClick={() => handleView(rowData)}
          />
        )}
        {showEdit && (
          <Button
            icon='pi pi-pencil'
            className='p-button-rounded p-button-success mr-2'
            onClick={() => editProduct(rowData)}
          />
        )}
        {showDelete && (
          <Button
            icon='pi pi-trash'
            className='p-button-rounded p-button-danger'
            onClick={() => confirmDeleteProduct(rowData)}
          />
        )}
      </div>
    );
  };

  const header = (
    <div className='table-header'>
      <h5 className='mx-0 my-1'>Manage {tableTitle}</h5>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder='Search...'
        />
      </span>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button
        label='Cancel'
        icon='pi pi-times'
        className='p-button-text'
        onClick={hideDialog}
      />
      <Button
        label='Save'
        icon='pi pi-check'
        className='p-button-text'
        onClick={saveProduct}
      />
    </React.Fragment>
  );
  const viewDialogFooter = (
    <React.Fragment>
      <Button
        label='Cancel'
        icon='pi pi-times'
        className='p-button-text'
        onClick={hideDialog}
      />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label='No'
        icon='pi pi-times'
        className='p-button-text'
        onClick={hideDeleteProductDialog}
      />
      <Button
        label='Yes'
        icon='pi pi-check'
        className='p-button-text'
        onClick={() => deleteSelectedItem(product.id, setDeleteProductDialog)}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label='No'
        icon='pi pi-times'
        className='p-button-text'
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label='Yes'
        icon='pi pi-check'
        className='p-button-text'
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  return (
    <div className='datatable-crud-demo'>
      <div className=''>
        {(showToolbar && type === "table") ? (
          <Toolbar
            className='mb-4'
            left={leftToolbarTemplate}
            right={showExport ? rightToolbarTemplate : <></>}
          ></Toolbar>
        ) : null}
        <Toast ref={toast} />
        {type === "edit" && (
          <>
            {renderDialog &&
              renderDialog(
                product,
                submitted,
                onInputChange,
                onInputNumberChange,
                setProduct,
                editImage,
                setEditImage,
                type
              )}
          </>
        )}
      

        {type === "add" && (
          <>
            {renderDialog &&
              renderDialog(
                product,
                submitted,
                onInputChange,
                onInputNumberChange,
                setProduct,
                editImage,
                setEditImage,
                type
              )}
          </>
        )}
        {type === "table" &&
          //  <TableData editProduct={editProduct} handleView={handleView}/>
          <DataTable
            ref={dt}
            value={data}
            // selection={selectedProducts}
            // onSelectionChange={(e) => setSelectedProducts(e.value)}
            rows={totalRecords}
            globalFilter={globalFilter}
            header={showSearch ? header : null}
            responsiveLayout='scroll'
            size='small'
          >
            {/* <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
            exportable={true}
          ></Column> */}
            {headerColumns?.map((column) => {
              return (
                <Column
                  sortable={sortable}
                  key={column.id}
                  field={column.field}
                  header={column.header}
                  body={column.body}
                ></Column>
              );
            })}
            {showActions ? (
              <Column
                body={actionBodyTemplate}
                header='Actions'
                exportable={false}
              ></Column>
            ) : null}
          </DataTable>


        }
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: '450px' }}
        header={dialogHeader}
        modal
        className='p-fluid'
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        {renderDialog &&
          renderDialog(
            product,
            submitted,
            onInputChange,
            onInputNumberChange,
            setProduct,
            type
          )}
      </Dialog>

      <Dialog
        visible={viewDialog}
        style={{ width: '450px' }}
        header={dialogHeaderView}
        modal
        className='p-fluid'
        footer={viewDialogFooter}
        onHide={() => setViewDialog(false)}
      >
        {renderViewDialog && renderViewDialog(product)}
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: '450px' }}
        header='Confirm'
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className='confirmation-content'>
          <i
            className='pi pi-exclamation-triangle mr-3'
            style={{ fontSize: '2rem' }}
          />
          {product && <span>Are you sure you want to delete this item?</span>}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: '450px' }}
        header='Confirm'
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className='confirmation-content'>
          <i
            className='pi pi-exclamation-triangle mr-3'
            style={{ fontSize: '2rem' }}
          />
          {product && (
            <span>Are you sure you want to delete the selected products?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

CrudDataTable.defaultProps = {
  showToolbar: true,
  showActions: true,
  sortable: true,
  showEdit: true,
  showView: false,
  showExport: true,
  showDelete: true,
};

export default CrudDataTable;
