import { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import PropertyDetails from "./PropertyDetails";
import BookingCalendar from "./Calendar";
import { useParams } from "react-router-dom";
import Banner from "../../components/banner";
import Sidebar from "../../components/sidebar";
import "./style.css";
import Document from "./Document";
import PropertyComments from "./PropertyComments";

const PropertyDetailsTabs = () => {
  let { id } = useParams();
  const [activeIndexTab, setActiveIndexTab] = useState(0);

  const handleChangeTab = (e) => {
    setActiveIndexTab(e.index);
  };

  return (
    <div className="app-main">
      <Sidebar active="properties" />
      <div className="app-main__outer" style={{ overflowX: "auto" }}>
        <div className="app-main__inner">
          <Banner
            title="Property Details - Staywo"
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />

          <TabView activeIndex={activeIndexTab} onTabChange={handleChangeTab}>
            <TabPanel header="Details">
              <PropertyDetails id={id} />
            </TabPanel>
            <TabPanel header="Calendar">
              <BookingCalendar />
            </TabPanel>
            <TabPanel header="Document">
              <Document />
            </TabPanel>
            <TabPanel header="Comments">
              <PropertyComments />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailsTabs;
