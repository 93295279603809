import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllAreas = () => {
  return Api(`${endPoints.allAreas}`, null, requestType.GET, null, null);
};

export const addArea = (params) => {
  return Api(`${endPoints.addArea}`, params, requestType.POST, null, null);
};

export const editAreaByID = (id, params) => {
  return Api(
    `${endPoints.editArea}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteAreaById = (id) => {
  return Api(
    `${endPoints.deleteArea}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
