import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Sidebar from "../../components/sidebar";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import {
  getSystemConfiguration,
  updateSystemConfiguration,
} from "./../../services/systemConfiguration/index";
import { Button } from "primereact/button";

const columns = [
  { name: "Title", value: "title" },
  { name: "Commission Percentage", value: "commission_percentage" },
  { name: "Escrow Days", value: "escrow_days" },
  { name: "GST", value: "gst" },
];

const SystemConfiguration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [titleVal, setTitleVal] = useState("");
  const [commissionVal, setCommissionVal] = useState(0);
  const [escrowDaysVal, setEscrowDaysVal] = useState(0);
  const [gstVal, setGstVal] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const toast = useRef(null);

  const getDataTitle = (value) => {
    setIsLoading(true);
    getSystemConfiguration(value)
      .then((res) => {
        let value = Object.values(res.data.data[0]);
        setIsLoading(false);
        setTitleVal(value[0]);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getDataCommission = (value) => {
    setIsLoading(true);
    getSystemConfiguration(value)
      .then((res) => {
        let value = Object.values(res.data.data[0]);
        setCommissionVal(value[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getDataEscrow = (value) => {
    setIsLoading(true);
    getSystemConfiguration(value)
      .then((res) => {
        let value = Object.values(res.data.data[0]);
        setEscrowDaysVal(value[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getDataGst = (value) => {
    setIsLoading(true);
    getSystemConfiguration(value)
      .then((res) => {
        let value = Object.values(res.data.data[0]);
        setGstVal(value[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getDataServiceFee = (value) => {
    setIsLoading(true);
    getSystemConfiguration(value)
      .then((res) => {
        let value = Object.values(res.data.data[0]);
        setServiceFee(value[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDataTitle("title");
    getDataCommission("commission_percentage");
    getDataEscrow("escrow_days");
    getDataGst("gst");
    getDataServiceFee("serviceFee");
  }, []);

  const showToast = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Erorr",
      detail: message,
      life: 3000,
    });
  };

  const handleUpdate = (title, value, valToUpdate) => {
    if (value == "") {
      showToast(`${title} is required`);
    } else {
      let payload = {
        column: valToUpdate,
        value:
          title == "Title"
            ? value
            : title == "Commission Percentage"
            ? parseFloat(value)
            : parseInt(value),
      };

      setIsLoading(true);
      updateSystemConfiguration(payload).then((res) => {
        setIsLoading(false);
        if (res.data.statusCode == 400) {
          let tempArr = [];
          res?.data?.message.map((val, ind) => {
            tempArr.push({
              severity: "error",
              summary: "Error",
              detail: val,
              life: 3000,
            });
          });
          toast.current.show(tempArr);
        } else {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "System Configuration Updated Successfully",
            life: 3000,
          });
        }
      });
    }
  };

  const renderConfig = (title, value, setVal, valToUpdate) => {
    return (
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <div className="field">
          <InputText
            placeholder={title}
            className="block"
            value={title}
            disabled
          />
        </div>
        <div className="field">
          <InputText
            value={value}
            className="block"
            style={{ margin: "0 10px" }}
            type={title == "Title" ? "text" : "number"}
            name="value"
            onChange={(e) => setVal(e.target.value)}
          />
        </div>

        <Button
          label={`Update ${title}`}
          className="p-button-help"
          onClick={() => handleUpdate(title, value, valToUpdate)}
        />
      </div>
    );
  };

  return (
    <div className="app-main">
      <Toast ref={toast} position="bottom-right" />
      <Sidebar active="systemConfiguration" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="System Configuration - Staywo"
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />

          <div className="row">
            <div className="col-lg-12">
              <Card title="System Configuration">
                {renderConfig("Title", titleVal, setTitleVal, "title")}
                {renderConfig(
                  "Commission Percentage",
                  commissionVal,
                  setCommissionVal,
                  "commission_percentage"
                )}
                {renderConfig(
                  "Escrow Days",
                  escrowDaysVal,
                  setEscrowDaysVal,
                  "escrow_days"
                )}
                {renderConfig("GST", gstVal, setGstVal, "gst")}
                {renderConfig("serviceFee", serviceFee, setServiceFee, "serviceFee")}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemConfiguration;
