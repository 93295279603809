import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllAccessibilities = () => {
  return Api(
    `${endPoints.allAccessibility}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addAccessibility = (params) => {
  return Api(
    `${endPoints.addAccessibility}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editAccessibilityById = (id, params) => {
  return Api(
    `${endPoints.editAccessibility}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteAccessibilityById = (id) => {
  return Api(
    `${endPoints.deleteAccessibility}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};

// Group

export const getAllAccessibilityGroup = () => {
  return Api(
    `${endPoints.allAccessibilityGroup}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addAccessibilityGroup = (params) => {
  return Api(
    `${endPoints.addAccessibilityGroup}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editAccessibilityGroupById = (id, params) => {
  return Api(
    `${endPoints.editAccessibilityGroup}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteAccessibilityGroupById = (id) => {
  return Api(
    `${endPoints.deleteAccessibilityGroup}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
