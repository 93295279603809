import React, { useRef } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { editFacility } from "../../services/facility";
import { editNearbyActivity } from "../../services/nearbyActivity";
import { editSafetyFeatures } from "../../services/safetyFeatures";
import { editSecurityById } from "../../services/securityGroups";
import { editAccessibilityById } from "../../services/accessibilityGroup";
import { Toast } from "primereact/toast";

const Miscellaneous = ({
  propertyAccessibility,
  propertyFacility,
  propertySecurity,
  propertyNearby,
  propertySafetyFeatures,
  propertyDetails,
}) => {

  const toast = useRef(null)

  const handleUpdateAccessibility = (item, index, val, ind) => {
    editAccessibilityById(val.id, {
      isChecked: !val.isChecked
    }).then(() => {
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Accessibility Updated Successfully",
        life: 3000,
      })
    }).catch(() => {
      toast.current.show({
        severity: "error", 
        summary: "Error",
        detail: "Something Went Wrong",
        life: 3000,
      });
    })
  }

  const handleUpdateFacility = async(id, check) => {
    const response = await editFacility(id, {
      isChecked: !check
    })
    if(response?.status === 200){
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Facility Updated Successfully",
        life: 3000,
      });
    }else{
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something Went Wrong",
        life: 3000,
      });
    }
  }

  const handleUpdateSecurity = async(id, check) => {
    const response = await editSecurityById(id, {
      isChecked: !check
    })
    if(response?.status === 200){
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Security Feature Updated Successfully",
        life: 3000,
      });
    }else{
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something Went Wrong",
        life: 3000,
      });
    }
  }

  const handleUpdateNearby = async(id, check) => {
    const response = await editNearbyActivity(id, {
      isChecked: !check
    })
    if(response?.status === 200){
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Nearby Activity Updated Successfully",
        life: 3000,
      });
    }else{
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something Went Wrong",
        life: 3000,
      });
    }
  }

  const handleSafetyFeatures = async (id, check) => {
    const response = await editSafetyFeatures(id, {
      isChecked: !check
    })
    if(response?.status === 200){
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Safety Feature Updated Successfully",
        life: 3000,
      });
    }else{
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something Went Wrong",
        life: 3000,
      });
    }
  }

  return (
    <div className="col-lg-12">
    <Toast ref={toast} position="bottom-right" />
      <Accordion activeIndex={8}>
        <AccordionTab header="Miscellaneous">
          <h5 style={{ marginTop: "15px" }}>All Miscellaneous</h5>
          <div style={{ padding: "5px" }}>
            <Accordion activeIndex={0}>
              <AccordionTab header="Accessibility">
                <div className="row">
                  {propertyAccessibility.map((item, index) => {
                    return (
                      <div className="col-lg-12">
                        <h5 style={{ marginTop: "15px" }}>{item.title}</h5>

                        <div className="row">
                          {item.accessibility.map((val, ind) => {
                            return (
                              <div key={val.id} className="col-lg-4">
                                <div
                                  className="field-checkbox"
                                  onClick={() =>
                                    handleUpdateAccessibility(item, index, val, ind)
                                  }
                                >
                                  <Checkbox
                                    inputId={val.id}
                                    name={val.id}
                                    value={val.isChecked}
                                    checked={val.isChecked} 
                                  />
                                  <label
                                    htmlFor="city1"
                                    style={{ marginBottom: 0 }}
                                  >
                                    {val.title}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AccordionTab>
            </Accordion>
            <Accordion activeIndex={1}>
              <AccordionTab header="Facility">
                <div className="row">
                  {!propertyFacility ? [] : propertyFacility.map((item, index) => {
                    return (
                      <div className="col-lg-12">
                        <h5 style={{ marginTop: "15px" }}>{item.title}</h5>
                        <div className="row">
                          {item.facilities.map((val, ind) => {
                            return (
                              <div key={val.id} className="col-lg-4">
                                <div
                                  className="field-checkbox"
                                  onClick={() =>
                                    handleUpdateFacility(val.id, val.isChecked)
                                  }
                                >
                                  <Checkbox
                                    inputId={val.id}
                                    name={val.id}
                                    value={val.isChecked}
                                    checked={val.isChecked}
                                  />
                                  <label
                                    htmlFor="city1"
                                    style={{ marginBottom: 0 }}
                                  >
                                    {val.title}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AccordionTab>
            </Accordion>
            <Accordion activeIndex={2}>
              <AccordionTab header="Security Feature">
                <div className="row">
                  {propertySecurity.map((item, index) => {
                    return (
                      <div className="col-lg-12">
                        <h5 style={{ marginTop: "15px" }}>{item.title}</h5>
                        <div className="row">
                          {item.securityFeature.map((val, ind) => {
                            return (
                              <div key={val.id} className="col-lg-4">
                                <div
                                  className="field-checkbox"
                                  onClick={() =>
                                    handleUpdateSecurity(val.id, val.isChecked)
                                  }
                                >
                                  <Checkbox
                                    inputId={val.id}
                                    name={val.id}
                                    value={val.isChecked}
                                    checked={val.isChecked}
                                  />
                                  <label
                                    htmlFor="city1"
                                    style={{ marginBottom: 0 }}
                                  >
                                    {val.title}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AccordionTab>
            </Accordion>
            <Accordion activeIndex={3}>
              <AccordionTab header="Nearby Activity">
                <div className="row">
                  {propertyNearby.map((item, index) => {
                    return (
                      <div className="col-lg-12">
                        <h5 style={{ marginTop: "15px" }}>{item.title}</h5>
                        <div className="row">
                          {item.nearbyActivity.map((val, ind) => {
                            return (
                              <div key={val.id} className="col-lg-4">
                                <div
                                  className="field-checkbox"
                                  onClick={() =>
                                    handleUpdateNearby(val.id, val.isChecked)
                                  }
                                >
                                  <Checkbox
                                    inputId={val.id}
                                    name={val.id}
                                    value={val.isChecked}
                                    checked={val.isChecked}
                                  />
                                  <label
                                    htmlFor="city1"
                                    style={{ marginBottom: 0 }}
                                  >
                                    {val.title}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AccordionTab>
            </Accordion>
            <Accordion activeIndex={4}>
              <AccordionTab header="Safety Feature">
                <div className="row">
                  {propertySafetyFeatures.map((item, index) => {
                    return (
                      <div className="col-lg-12">
                        <h5 style={{ marginTop: "15px" }}>{item.title}</h5>
                        <div className="row">
                          {item.safetyFeature.map((val, ind) => {
                            return (
                              <div key={val.id} className="col-lg-4">
                                <div
                                  className="field-checkbox"
                                  onClick={() =>
                                    handleSafetyFeatures(val.id, val.isChecked)
                                  }
                                >
                                  <Checkbox
                                    inputId={val.id}
                                    name={val.id}
                                    value={val.isChecked}
                                    checked={val.isChecked}
                                  />
                                  <label
                                    htmlFor="city1"
                                    style={{ marginBottom: 0 }}
                                  >
                                    {val.title}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AccordionTab>
            </Accordion>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default Miscellaneous;