import { API_URL } from '../../config';
import axios from 'axios';

export const getPropertyApi = async (url) => {
  try {
    const response = await axios.get(
      API_URL + 'property/find-properties' + url
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of properties : ', response);
      return response;
    }
  }
};
export const getPropertyPolicyByPropertyIdApi = async (id) => {
  try {
    const response = await axios.get(
      API_URL +
        `property-policy/getPropertyPolicyByPropertyId/{propertyId}?propertyId=${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of properties : ', response);
      return response;
    }
  }
};
export const getUserProperties = async (id, page, limit) => {
  try {
    const response = await axios.post(
      API_URL +
        `property/get-user-properties?page=${page}&limit=${limit}&userId=${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of get-user-properties : ', response);
      return response;
    }
  }
};

// Get All property for users

export const getPropertyUsers = async (url) => {
  try {
    const response = await axios.get(API_URL + `property/all-user?` + url);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of properties : ', response);
      return response;
    }
  }
};

// Delete Property Gallery Image

export const deletePropertyImage = async (id) => {
  try {
    const response = await axios.delete(API_URL + `property/delete/image/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of properties : ', response);
      return response;
    }
  }
};

export const getPropertyByIdApi = async (id) => {
  try {
    const response = await axios.get(API_URL + `property/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property : ', response);
      return response;
    }
  }
};
export const getPropertyFaciltiesById = async (id) => {
  try {
    const response = await axios.get(API_URL + `property/facility/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property facility: ', response);
      return response;
    }
  }
};
export const getPropertyNearbyActivitiesById = async (id) => {
  try {
    const response = await axios.get(
      API_URL + `property/nearby-activity/${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property nearby-activity: ', response);
      return response;
    }
  }
};
export const getPropertyAccessibiltiesById = async (id) => {
  try {
    const response = await axios.get(API_URL + `property/accessibility/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property accessibility: ', response);
      return response;
    }
  }
};
export const getPropertySafetyConsiderationById = async (id) => {
  try {
    const response = await axios.get(
      API_URL + `property/safety-consideration/${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property safety-consideration: ', response);
      return response;
    }
  }
};
export const getPropertySafetyFeatureById = async (id) => {
  try {
    const response = await axios.get(API_URL + `property/safety-feature/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property safety-feature: ', response);
      return response;
    }
  }
};
export const getPropertySecurityFeatureById = async (id) => {
  try {
    const response = await axios.get(
      API_URL + `property/security-feature/${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property security-feature: ', response);
      return response;
    }
  }
};
export const getPropertyHouseRulesById = async (id) => {
  try {
    const response = await axios.get(API_URL + `property/houserule/${id}`);
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property houserule: ', response);
      return response;
    }
  }
};

export const getPoliciesApi = async () => {
  try {
    const response = await axios.get(API_URL + `cancellation-policy`);

    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property : ', response);
      return response;
    }
  }
};

export const getPropertyCountByStatus = async (filter) => {
  try {
    const response = await axios.get(
      API_URL + `property/count-by-status?${filter}`
    );

    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property : ', response);
      return response;
    }
  }
};
