import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import { Card } from "primereact/card";
import Sidebar from "../../components/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  getAllRoles,
  addRole,
  editRoleById,
  deleteRoleById,
} from "./../../services/roles/index";
import CrudDataTable from "../../components/crudDataTable";
import Pagination from "../../components/pagination";
import { Toast } from "primereact/toast";
import "primeflex/primeflex.css";
import "../properties/style.css";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";

const Roles = () => {
  const toast = useRef(null);
  const [allRoles, setAllRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  let emptyProduct = {
    name: "",
    description: "",
    permissions: "",
  };
  const user_token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getData(0, 10);
  }, []);

  const getData = (page, limit) => {
    setIsLoading(true);
    getAllRoles(page, limit, user_token)
      .then((res) => {
        setIsLoading(false);
        setTotalRecords(res.data.total);
        setAllRoles(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleAddEdit = (product, setProduct, setProductDialog, type) => {
    if (
      product.name == "" ||
      product.permissions == "" ||
      product.description == ""
    ) {
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "name, permission & description is required",
        life: 3000,
      });
    } else {
      let payload = {
        name: product.name,
        description: product.description,
        permissions: product.permissions,
      };

      if (type === "add") {
        setIsLoading(true);
        addRole(payload, user_token).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: "error",
                summary: "Error",
                detail: val,
                life: 3000,
              });
            });
            setProductDialog(false);
            toast.current.show(tempArr);
          } else {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Role Created Successfully",
              life: 3000,
            });
            setProduct({
              name: "",
              description: "",
              permissions: "",
            });
            getData(0, 10);
            setTimeout(() => {
              setProductDialog(false);
            }, 1500);
          }
        });
      } else {
        setIsLoading(true);
        editRoleById(product.id, payload, user_token).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: "error",
                summary: "Error",
                detail: val,
                life: 3000,
              });
            });
            setProductDialog(false);
            toast.current.show(tempArr);
          } else {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Roles Updated Successfully",
              life: 3000,
            });
            setProduct({
              name: "",
              description: "",
              permissions: "",
            });
            getData(0, 10);
            setTimeout(() => {
              setProductDialog(false);
            }, 1500);
          }
        });
      }
    }
  };

  const DATA = [
    { id: 1, field: "id", header: "Id" },
    { id: 2, field: "name", header: "Name" },
    { id: 3, field: "description", header: "Description" },
    { id: 4, field: "permissions", header: "Permissions" },
  ];

  const onCustomPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    getData(event.page + 1, event.rows);
  };

  const renderDialog = (product, submitted, onInputChange) => {
    return (
      <>
        <div style={{ marginRight: "10px" }}>
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            value={product.name}
            onChange={(e) => onInputChange(e, "name")}
            required
            autoFocus
            className={classNames({
              "p-invalid": submitted && !product.title,
            })}
          />
        </div>

        <div style={{ marginRight: "10px" }}>
          <label htmlFor="name">Permission</label>
          <InputText
            id="permisison"
            value={product.permissions}
            onChange={(e) => onInputChange(e, "permissions")}
            required
          />
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="price">Description</label>
            <InputTextarea
              value={product.description}
              onChange={(e) => onInputChange(e, "description")}
              rows={5}
              cols={30}
            />
          </div>
        </div>
      </>
    );
  };

  const deleteSelectedItem = (id, setOpenDialog) => {
    setIsLoading(true);
    deleteRoleById(id, user_token).then((res) => {
      setIsLoading(false);
      if (res.data.statusCode == 400) {
        let tempArr = [];
        res?.data?.message.map((val, ind) => {
          tempArr.push({
            severity: "error",
            summary: "Error",
            detail: val,
            life: 3000,
          });
        });
        setOpenDialog(false);
        toast.current.show(tempArr);
      } else {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Role Deleted Successfully",
          life: 3000,
        });
        getData(0, 10);
        setTimeout(() => {
          setOpenDialog(false);
        }, 1500);
      }
    });
  };

  return (
    <>
      <Toast ref={toast} position="bottom-right" />
      <div className="app-main">
        <Sidebar active="roles" />
        <div className="app-main__outer" style={{ overflowX: "auto" }}>
          <div className="app-main__inner">
            <Banner
              title="All Roles - Staywo"
              icon="pe-7s-network icon-gradient bg-mean-fruit"
            />

            <div className="row">
              <div className="col-lg-12">
                <Card style={{ marginBottom: "20px" }}>
                  {isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <ProgressSpinner />
                    </div>
                  ) : (
                    <CrudDataTable
                      emptyProduct={emptyProduct}
                      data={allRoles && allRoles.length > 0 ? allRoles : []}
                      totalRecords={totalRecords}
                      headerColumns={DATA}
                      dialogHeader="Role Details"
                      addEditFunction={handleAddEdit}
                      deleteSelectedItem={deleteSelectedItem}
                      renderDialog={renderDialog}
                      tableTitle="Users"
                      showToolbar={true}
                      showActions={true}
                      showEdit={true}
                      showView={false}
                      showDelete={true}
                      showExport={false}
                      showSearch={false}
                      sortable={false}
                    />
                  )}

                  <Pagination
                    totalRecords={totalRecords}
                    customFirst={first}
                    customRows={rows}
                    onPageChange={onCustomPageChange}
                  />
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roles;
