const NODE_ENV = process.env.REACT_APP_ENV;
let API_URL = "";
switch (NODE_ENV) {
  case "development":
    API_URL = process.env.REACT_APP_DEVELOPMENT_BASE_URL;
    break;
  case "staging":
    API_URL = process.env.REACT_APP_STAGING_BASE_URL;
    break;
  case "testing":
    API_URL = process.env.REACT_APP_TESTING_BASE_URL;
    break;
  case "local":
    API_URL = process.env.REACT_APP_LOCAL_BASE_URL;
    break;
  default:
    API_URL = process.env.REACT_APP_PRODUCTION_BASE_URL;
    break;
}
export { API_URL };
