import { createSlice } from "@reduxjs/toolkit";

const initialState={
   type:"table"
    
}

 const viewSlice = createSlice({
    name: "type",
    initialState,
    reducers: {
      changeType: (state,{payload}) => {
       state.type=payload
      },
      
    }
   });
   export const { changeType } = viewSlice.actions;
   export default viewSlice.reducer