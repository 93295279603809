import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllFacilities = () => {
  return Api(
    `${endPoints.getAllFacilities}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addFacility = (params) => {
  return Api(`${endPoints.addFacility}`, params, requestType.POST, null, null);
};

export const editFacility = (id, params) => {
  return Api(
    `${endPoints.editFacility}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};

export const deleteFacility = (id) => {
  return Api(
    `${endPoints.deleteFacility}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
