import React, { useEffect } from "react";
import Upload from "./upload";

const FileUploader = ({
  thumbnailURLs,
  setThumbnailURLs,
  setThumbnail,
  thumbnail,
  title,
}) => {
  useEffect(() => {
    if (thumbnail?.length > 0) {
      const newThumbnailUrls = [];
      thumbnail.forEach((image) =>
        newThumbnailUrls.push(URL.createObjectURL(image))
      );
      setThumbnailURLs(newThumbnailUrls);
    }
  }, [thumbnail]);

  const onThumbnailChange = (e) => {
    console.log(e.target.files);
    setThumbnail((data) => [...data, ...e.target.files]);
  };

  const clearThumbnailImage = (imageSrc) => {
    const filterImage = thumbnailURLs.filter((name) => name != imageSrc);
    setThumbnailURLs(filterImage);
    //re-again url object
    const newImageUrls = [];
    filterImage.forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );
    setThumbnail(newImageUrls);
  };

  return (
    <div>
      <div>
        <Upload
          label={title}
          handleChange={(e) => onThumbnailChange(e)}
          disabled={thumbnail?.length >= 1 ? true : false}
          multiple={false}
        />
        <div style={{ display: "inline" }}>
          {thumbnailURLs?.map((thumbnailSrc) => (
            <div className="image-div ">
              <img
                src={thumbnailSrc}
                style={{
                  maxWidth: "100%",
                  height: "100%",
                }}
              />
              <div
                className="clear-button "
                onClick={() => clearThumbnailImage(thumbnailSrc)}
              >
                <i
                  className="fa fa-times"
                  style={{
                    cursor: "pointer",
                    color: "#2e94f7",
                  }}
                ></i>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
