import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Sidebar from "../../components/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  getAllBookings,
  getBookingInvoice,
} from "../../services/bookings/index";

import Pagination from "../../components/pagination";
import CrudDataTable from "../../components/crudDataTable/index";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Toolbar } from "primereact/toolbar";
import { priceBodyTemplate } from "../../constants/utils";
import BookingFilterDialog from "./BookingFilterDialog";

const BookingsList = () => {
  let emptyProduct = {
    image: null,
    propertyName: "",
    propertyOwner: "",
    price: 0,
    rating: 0,
    status: "",
  };
  const navigate = useNavigate();
  const filterDialogRef = useRef();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [customFirst, setCustomFirst2] = useState(0);
  const [customRows, setCustomRows2] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const toast = useRef(null);
  const getData = (page, limit, status, fromDate, toDate) => {
    setIsLoading(true);
    getAllBookings(page, limit, status, fromDate, toDate)
      .then((res) => {
        setIsLoading(false);
        setTotalRecords(res.data.total);

        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            ...item,
            bookingDate:
              item.startDate && item.endDate !== null
                ? `${moment(item.startDate).format("DD MMM YYYY")} - ${moment(
                    item.endDate
                  ).format("DD MMM YYYY")}`
                : "-",
            guestName: `${item.guest.firstName} - ${item.guest.lastName}`,
            propertyName: item.property.title,
            bookingStatus: item.bookingStatus,
          });
        });
        setProducts(tempArr);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData(0, customRows, "", "", "");
  }, []);

  const handleInvoice = (rowData) => {
    setIsLoading(true);
    getBookingInvoice(rowData.id)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const viewTepmlate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-raised mr-2"
          onClick={() => {
            navigate(
              `/bookingDetails/booking/${rowData.id}/property/${rowData.propertyId}`
            );
          }}
        />
        <Button
          icon="pi pi-print"
          className="p-button-rounded p-button-raised mr-2"
          onClick={() => handleInvoice(rowData)}
        />
      </>
    );
  };

  const statusTepmlate = (rowData) => {
    return (
      <>
        <div style={{ marginBottom: "10px" }}>
          <span
            style={{
              fontWeight: 500,
              background: "#c8e6c9",
              color: "#256029",
              padding: "5px 10px",
            }}
          >
            {rowData.bookingStatus}
          </span>
        </div>
      </>
    );
  };

  const DATA = [
    { id: 1, field: "id", header: "Id" },
    { id: 2, field: "bookingDate", header: "Booking Date" },
    {
      id: 3,
      field: "totalAmount",
      header: "Total Amount",
      body: (e) => priceBodyTemplate(e.totalAmount),
    },
    { id: 4, field: "guestName", header: "Guest Name" },
    { id: 5, field: "propertyName", header: "Property Name" },
    {
      id: 6,
      field: "bookingStatus",
      header: "Booking Status",
      body: statusTepmlate,
    },
    { id: 7, header: "Actions", body: viewTepmlate },
  ];

  const onPageChange = (event) => {
    setCustomFirst2(event.first);
    setCustomRows2(event.rows);
    setActivePage(event.page);
    getData(event.page, event.rows, "", "", "");
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Add Booking"
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={() => navigate("/AddEditBooking")}
        />
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Advance Filter"
          icon="pi pi-filter"
          className="p-button-help"
          style={{ fontSize: "12px", padding: "8px" }}
          onClick={() => filterDialogRef.current.isVisible({})}
        />
      </React.Fragment>
    );
  };

  return (
    <div className="app-main">
      <Toast ref={toast} position="bottom-right" />
      <Sidebar active="bookingList" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="All Bookings - Staywo"
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />

          <div className="row">
            <div className="col-lg-12">
              <Card title="Bookings">
                <Toolbar
                  className="mb-4"
                  left={leftToolbarTemplate}
                  right={rightToolbarTemplate}
                ></Toolbar>

                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={DATA}
                    data={products}
                    totalRecords={totalRecords}
                    emptyProduct={emptyProduct}
                    dialogHeader="Property Details"
                    tableTitle="Properties"
                    showToolbar={false}
                    showActions={false}
                    sortable={false}
                  />
                )}
                <Pagination
                  totalRecords={totalRecords}
                  customFirst={customFirst}
                  customRows={customRows}
                  onPageChange={onPageChange}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
      <BookingFilterDialog
        getData={getData}
        customRows={customRows}
        activePage={activePage}
        ref={filterDialogRef}
      />
    </div>
  );
};

export default BookingsList;
