import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import "./style.css";
import { useAuth } from "../../components/hooks/useAuth";
import {
  Navigate,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import { getVerifyToken, adminResetPass } from "./../../services/auth/index";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { Password } from "primereact/password";

const ResetPassword = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { email, token } = useParams();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    password: "",
    confirmPassword: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  useEffect(() => {
    getVerifyToken(token, email).then((res) => {
      console.log(res.data, "aslkajda");
      if (res.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Verfified successfully, Please change your password",
          life: 3000,
        });
      } else if (res.data.statusCode == 400 || 403 || 404) {
        let tempArr = [];
        res?.data?.message.map((val, ind) => {
          tempArr.push({
            severity: "error",
            summary: "Error",
            detail: val,
            life: 3000,
          });
        });
        toast.current.show(tempArr[0]);
      }
    });
  }, []);

  const onSubmit = (data) => {
    if (data.password !== data.confirmPassword) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Passwords do not match",
        life: 3000,
      });
    } else {
      let payload = {
        email: email,
        token,
        newPassword: data.password,
      };
      setIsLoading(true);
      adminResetPass(payload).then((res) => {
        setIsLoading(false);

        if (res.data.statusCode == 400 || 403) {
          let tempArr = [];
          res?.data?.message.map((val, ind) => {
            tempArr.push({
              severity: "error",
              summary: "Error",
              detail: val,
              life: 3000,
            });
          });
          toast.current.show(tempArr[0]);
        } else {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Password changed successfully",
            life: 3000,
          });
          setTimeout(() => {
            navigate("/login");
          }, 1500);
        }
      });
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <div
      className="form-demo"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-center">
        <div className="card" style={{ padding: "20px" }}>
          <h5 className="text-center">Reset Password</h5>
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: "New Password is required." }}
                  render={({ field, fieldState }) => (
                    <Password
                      id={field.password}
                      {...field}
                      feedback={false}
                      toggleMask
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="password"
                  className={classNames({ "p-error": errors.password })}
                >
                  New Password*
                </label>
              </span>
              {getFormErrorMessage("password")}
            </div>
            <div className="field">
              <span className="p-float-label">
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{ required: "Confirm Password is required." }}
                  render={({ field, fieldState }) => (
                    <Password
                      id={field.confirmPassword}
                      {...field}
                      feedback={false}
                      toggleMask
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="confirmPassword"
                  className={classNames({ "p-error": errors.confirmPassword })}
                >
                  Confirm Password*
                </label>
              </span>
              {getFormErrorMessage("confirmPassword")}
            </div>

            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ProgressSpinner style={{ width: "80px", height: "80px" }} />
              </div>
            ) : (
              <Button type="submit" label="Submit" className="mt-2" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
