import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../../components/header'
import { getPropertyNoteById, postNoteByPropertyId } from '../../services/properties'

const PropertyComments = () => {
const [notesText, setPropertyNotesText]=useState('')
const userData = JSON.parse(localStorage.getItem('user')) || null;
const [ownerLoading, setLoadingOwner] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
const [notes,setNotes]=useState([])
const {id}=useParams()
const propertyId=id;
    const handleNoteValues=(e)=>{
       setPropertyNotesText(e.target.value);
       console.log(notesText, id,"==========>values");

    }
    const increment=1;
    useEffect(()=>{
     getPropertyNoteById(id)
         .then((res) => {
             console.log(res, "resnotes==>")
             setIsLoading(false);
             setNotes(res?.data?.data);
             

         })
         .catch((err) => {
             setIsLoading(false);
         });
    },[])
    console.log(notes, "notes==>")
    const postNotes=()=>{
        const payLoad={
            userId:userData.id,
            note:notesText,
            propertyId: +propertyId,

        }
        console.log(payLoad, "==========>values");
        postNoteByPropertyId(payLoad)
            .then((res) => {
                setLoadingOwner(false);

                setPropertyNotesText(null);
                window.location.reload(false);
                // window.location.reload(false);
            })
            .catch((err) => {
                setLoadingOwner(false);
            });

    }
  return (
      <>
          <div className="">
              <div style={{ background: "#fff", padding: "24px" }}>
                  <h5>Comments</h5>
                  <div className="row justify-content-center">
                      <div className="col-lg-10 mt-3">
                        
                              <table className="table table-hover">
                                  <thead>
                                      <tr>
                                          <th>#</th>
                                          <th>Name</th>

                                          <th>Note</th>
                                          <th>Date</th>
                                         
                                      </tr>
                                  </thead>
                                  <tbody>

                                      {
                                          notes &&
                                          notes.map((data, index) => (
                                              <>
                                                  <tr>

                                                      
                                                      
                                                      <td>{index+1}</td>
                                                      
                                                      <td>{userData.firstName}</td>
                                                    
                                                      <td>{data?.note}</td>

                                                        <td>{data.id}</td>
                                                        <td><img style={{width: "50px", height: "50px", marginRight: "10px", borderRadius: "50px"}} src={!data.admin.profilePicture ? '/static/media/userPlaceholder.ea81230ccd2649876905.jpg' : data.admin.profilePicture } alt=''/>{`${data.admin.firstName} ${data.admin.lastName}`}</td>
                                                        <td style={{maxWidth: "200px"}}>{data.note}</td>
                                                        <td>{data.createdOn}</td>

                                                  </tr>
                                                  

                                              </>
                                          ))
                                      }
                                  </tbody>
                              </table>
                              
                          <div className="form-group mt-2" >
                              <textarea className="form-control" rows="5" id="comment"
                                  placeholder="New note" onChange={handleNoteValues}
                              ></textarea>
                              <Button className="mt-2 h-2rem btn-lg"
                               label="Post" onClick={postNotes} raised severity="help">




                                  
                              </Button>
                          </div>
                      </div>
                  </div>
                 



              </div>
          </div>
      </>
  )
}

export default PropertyComments