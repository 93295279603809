import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import "./style.css";
import { useAuth } from "../../components/hooks/useAuth";
import { Navigate, useNavigate } from "react-router-dom";
import { getforgotPasswordEmail } from "./../../services/auth/index";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { BiArrowBack } from 'react-icons/bi'

const ForgotPassword = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    email: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    setIsLoading(true);
    getforgotPasswordEmail(data.email).then((res) => {
      setIsLoading(false);
      if (res.data.statusCode == 400) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Code has been sent to your email address.",
          life: 3000,
        });
        reset();
      }
    });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <div
      className="form-demo"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Toast ref={toast} position="bottom-right" />
      <div className="flex justify-content-center">

        <div className="card" style={{ padding: "20px" }}>
          <div style={{
            display: "flex",
            cursor:"pointer"
           

          }} onClick={()=>navigate("/login")} >
            <BiArrowBack style={{marginTop:"3px",marginRight:"3px"}}/>
            <h6>Back</h6>
          </div>


          <h5 className="text-center">Forgot Password</h5>

          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <div className="field">
              <span className="p-float-label p-input-icon-right">
                <i className="pi pi-envelope" />
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address. E.g. example@email.com",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="email"
                  className={classNames({ "p-error": !!errors.email })}
                >
                  Email*
                </label>
              </span>
              {getFormErrorMessage("email")}
            </div>

            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <ProgressSpinner style={{ width: "80px", height: "80px" }} />
              </div>
            ) : (
              <Button type="submit" label="Submit" className="mt-2" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
