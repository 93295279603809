import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Sidebar from "../../components/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import Pagination from "../../components/pagination";
import CrudDataTable from "../../components/crudDataTable/index";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import {
  getAllSafetyFeatureGroup,
  addSafetyFeatureGroup,
  editSafetyFeatureGroup,
  deleteSafetyFeatureGroup,
} from "./../../services/safetyFeatures/index";
import moment from "moment";
import { IconPicker } from "react-fa-icon-picker";

const SafetyFeatureGroup = () => {
  let emptyProduct = {
    title: "",
    description: "",
    code: "FaBuilding",
  };

  const [safetyFeatureGroup, setSafetyFeatureGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customFirst, setCustomFirst2] = useState(0);
  const [customRows, setCustomRows2] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const toast = useRef(null);

  const getData = () => {
    setIsLoading(true);
    getAllSafetyFeatureGroup()
      .then((res) => {
        setIsLoading(false);
        setSafetyFeatureGroup(res.data.data.reverse());
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData(0, customRows);
  }, []);

  const createdOnTemplate = (rowData) => {
    return (
      <span>{moment(rowData?.createdOn).format("DD MMM YYYY hh:mm:ss")}</span>
    );
  };
  const modifiedOnTemplate = (rowData) => {
    return (
      <span>{moment(rowData?.modifiedOn).format("DD MMM YYYY hh:mm:ss")}</span>
    );
  };

  const DATA = [
    { id: 1, field: "id", header: "Id" },
    { id: 2, field: "title", header: "Title" },
    { id: 3, field: "description", header: "Description" },
    {
      id: 4,
      field: "createdOn",
      header: "Created On",
      body: createdOnTemplate,
    },
    {
      id: 5,
      field: "modifiedOn",
      header: "Modified On",
      body: modifiedOnTemplate,
    },
  ];

  const onPageChange = (event) => {
    setCustomFirst2(event.first);
    setCustomRows2(event.rows);
    getData(event.page, event.rows);
  };

  const handleAddEdit = (product, setProduct, setProductDialog, type) => {
    if (product.title == "" || product.description == "") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "title & description is required",
        life: 3000,
      });
    } else {
      let payload = {
        title: product.title,
        description: product.description,
        code: product.code,
        isActive: true,
      };
      if (type === "add") {
        setIsLoading(true);
        addSafetyFeatureGroup(payload).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: "error",
                summary: "Error",
                detail: val,
                life: 3000,
              });
            });
            setProductDialog(false);
            toast.current.show(tempArr);
          } else {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Safetey Feature Group Created Successfully",
              life: 3000,
            });
            setProduct({
              title: "",
              description: "",
              code: "FaBuilding",
            });
            getData();
            setTimeout(() => {
              setProductDialog(false);
            }, 1500);
          }
        });
      } else {
        setIsLoading(true);
        editSafetyFeatureGroup(product.id, payload).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: "error",
                summary: "Error",
                detail: val,
                life: 3000,
              });
            });
            setProductDialog(false);
            toast.current.show(tempArr);
          } else {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Safety Feature Group Updated Successfully",
              life: 3000,
            });
            setProduct({
              title: "",
              description: "",
              code: "FaBuilding",
            });
            getData();
            setTimeout(() => {
              setProductDialog(false);
            }, 1500);
          }
        });
      }
    }
  };

  const deleteSelectedItem = (id, setOpenDialog) => {
    setIsLoading(true);
    deleteSafetyFeatureGroup(id).then((res) => {
      setIsLoading(false);
      if (res.data.statusCode == 400) {
        let tempArr = [];
        res?.data?.message.map((val, ind) => {
          tempArr.push({
            severity: "error",
            summary: "Error",
            detail: val,
            life: 3000,
          });
        });
        setOpenDialog(false);
        toast.current.show(tempArr);
      } else {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Safety Feature Group Deleted Successfully",
          life: 3000,
        });
        getData();
        setTimeout(() => {
          setOpenDialog(false);
        }, 1500);
      }
    });
  };

  const renderDialog = (product, submitted, onInputChange) => {
    return (
      <>
        <div
          className="field"
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <label htmlFor="name">Title</label>
            <InputText
              id="name"
              value={product.title}
              onChange={(e) => onInputChange(e, "title")}
              required
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.title,
              })}
            />
          </div>
          <IconPicker
            value={product.code}
            onChange={(e) => onInputChange(e, "code")}
          />
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="price">Description</label>
            <InputTextarea
              value={product.description}
              onChange={(e) => onInputChange(e, "description")}
              rows={5}
              cols={30}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="app-main">
      <Toast ref={toast} position="bottom-right" />
      <Sidebar active="safetyFeatureGroup" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="Safety Feature Group - Staywo"
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />

          <div className="row">
            <div className="col-lg-12">
              <Card title="Safety Feature Group">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={DATA}
                    data={safetyFeatureGroup}
                    totalRecords={totalRecords}
                    renderDialog={renderDialog}
                    addEditFunction={handleAddEdit}
                    deleteSelectedItem={deleteSelectedItem}
                    emptyProduct={emptyProduct}
                    dialogHeader="Safety Feature Group Details"
                    tableTitle="Safety Feature Group"
                  />
                )}
                <Pagination
                  totalRecords={totalRecords}
                  customFirst={customFirst}
                  customRows={customRows}
                  onPageChange={onPageChange}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafetyFeatureGroup;
