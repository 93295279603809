import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Sidebar from "../../components/sidebar";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import "./style.css";
import {
  getAllSpaces,
  getAllSpaceGroup,
} from "./../../services/spaceGroupType/index";
import { getAllAreas } from "./../../services/area/index";
import {
  getPropertyById,
  createPropertyAmenities,
  updatePropertyById,
} from "./../../services/properties/index";
import { deletePropertyImage } from '../../services/properties/getProperty'
import { getAllAmenities } from "./../../services/amenities/index";
import { getAllUsersWithoutFilter } from "../../services/users/index";
import { Button } from "primereact/button";
import Location from "./Location";
import {
  updatePhotoAndVideoUrl,
  updatePropertyListingBasicById,
  updatePropertyCapacityBasicById,
  getPropertySafetyConsiderationByid,
  getPropertyAccessibility,
  getPropertyFacility,
  getPropertySecurity,
  getPropertyNearby,
  getPropertySafetyFeature
} from "./../../services/properties/index";
import { ProgressSpinner } from "primereact/progressspinner";
import { Accordion, AccordionTab } from "primereact/accordion";
import PropertyPolicies from "./PropertyPolicies";
import PricingSection from "./PricingSection";
import GuestInfoSection from "./GuestnfoSection";
import GuestSafety from "./GuestSafety";
import Miscellaneous from "./Miscellaneous";
import { MdGeneratingTokens } from "react-icons/md";

const propertypes = [
  {
    label: "Private",
    value: "Private",
  },
  {
    label: "Commercial",
    value: "Commercial",
  },
];

const bathData = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const floodData = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

const EditProperty = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useRef(null);
  const [inputVal, setInputVal] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    space: "",
    spaceGroup: "",
    areaVal: "",
    propTypeVal: "",
    listingTitle: "",
    propDescription: "",
    bedrooms: "",
    bathrooms: "",
    beds: "",
    customUrl: "",
    privateBathRoom: "",
    adults: "",
    childrens: "",
    infants: "",
    address: "",
    latitude: "",
    longitude: "",
    zipCode: "",
    pets: "",
    price: "",
    country: "",
    city: "",
    state: "",
    nightPrice: "",
    youtubeLink: "",
    floodRelief: "",
    weeklyNightPrice: "",
    monthlyNightPrice: "",
    cleaningFee: "",
    petFee: "",
    stayDiscount: "",
    extraGuestFee: "",
    weekendNightPrice: "",
    minStay: "",
    maxStay: "",

    interactionWithGuest: "",
    checkInInstruction: "",
    guestManual: "",
    direction: "",
    wifiName: "",
    wifiPass: "",

    instantBooking: true,
    governmentId: false,
    guestPhoto: false,
    checkIn: "",
    cancellationPolicy: "",
    checkOut: "",
    bookingMsg: false,
  });

  const [propertyDetails, setPropertyDetails] = useState({});
  const [allSpaces, setAllSpaces] = useState([]);
  const [allSpacesGroup, setAllSpacesGroup] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [allAmenities, setAllAmenities] = useState([]);
  const [thumnails, setThumbnails] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allUsersList, setAllUsersList] = useState([]);
  const [galleryImgUrls, setGalleryImgUrls] = useState([]);
  const [propertyPricePolicy, setPropertyPricePolicy] = useState([]);
  const [thumbnailUrls, setThumbnailUrls] = useState(null);
  const [isLoadingPhotoSection, setIsLoadingPhotoSection] = useState(false);
  const [isLoadingPropListing, setIsLoadingPropListing] = useState(false);
  const [propertyConsiderations, setPropertyConsiderations] = useState([]);
  const [propertyAccessibility, setPropertyAccessibility] = useState([]);
  const [propertyFacility, setPropertyFacility] = useState([]);
  const [propertySecurity, setPropertySecurity] = useState([]);
  const [propertyNearby, setPropertyNearby] = useState([]);
  const [propertySafetyFeatures, setPropertySafetyFeatures] = useState([]);

  const [mapCenter, setMapCenter] = useState({
    lat: 24.8607343,
    lng: 67.0011364,
    address: "DHA PHASE 2",
  });

  let token = JSON.parse(localStorage.getItem("token"));

  const getAllUsersData = () => {
    let token = JSON.parse(localStorage.getItem("token"));
    getAllUsersWithoutFilter(token)
      .then((res) => {
        console.log(res,"ddd")
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            label: `${item.firstName} ${item.lastName}`,
            value: item.id,
          });
        });
        setAllUsersList(tempArr);
      })
      .catch((err) => {});
  };
  const getSpacesData = () => {
    getAllSpaces()
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            label: item.title,
            value: item.id,
          });
        });
        setAllSpaces(tempArr);
      })
      .catch((err) => {});
  };

  const getSpacesGroup = () => {
    getAllSpaceGroup()
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            label: item.title,
            value: item.id,
          });
        });
        setAllSpacesGroup(tempArr);
      })
      .catch((err) => {});
  };

  const getAreasData = () => {
    getAllAreas()
      .then((res) => {
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            label: item.title,
            value: item.id,
          });
        });
        setAllAreas(tempArr);
      })
      .catch((err) => {});
  };

  const getAmenitiesData = (response) => {
    getAllAmenities()
      .then((res) => {
        let tempArr = [];
        if (response.propertyAmenities.length > 0) {
          res.data.data.map((val) => {
            let result = response.propertyAmenities.findIndex(
              (item) => item.amenityId == val.id
            );
            if (result !== -1) {
              tempArr.push({
                label: val.title,
                value: val.id,
                isChecked: true,
              });
            } else {
              tempArr.push({
                label: val.title,
                value: val.id,
                isChecked: false,
              });
            }
            setAllAmenities(tempArr);
          });
        }

        setAllAmenities(tempArr);
      })
      .catch((err) => {});
  };

  const getConsiderations = () => {
    getPropertySafetyConsiderationByid(id, token)
      .then((res) => {
        setPropertyConsiderations(res.data.data);
      })
      .catch((err) => {});
  };

  const getAccessibilities = () => {
    getPropertyAccessibility(id, token)
      .then((res) => {
        setPropertyAccessibility(res.data.data);
      })
      .catch((err) => {});
  };

  const getFacilities = () => {
    getPropertyFacility(id, token)
      .then((res) => {
        setPropertyFacility(res.data.data);
      })
      .catch((err) => {});
  };

  const getSecuritiesFeatures = () => {
    getPropertySecurity(id, token)
      .then((res) => {
        setPropertySecurity(res.data.data);
      })
      .catch((err) => {});
  };
  const getPropertyNearbyActivities = () => {
    getPropertyNearby(id, token)
      .then((res) => {
        setPropertyNearby(res.data.data);
      })
      .catch((err) => {});
  };
  const getPropertySafeties = () => {
    getPropertySafetyFeature(id, token)
      .then((res) => {
        setPropertySafetyFeatures(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setIsLoading(true);
    getPropertyById(id)
      .then((res) => {
        setIsLoading(false);
        let response = res.data.data;
        setPropertyDetails(response);

        setInputVal({
          ...inputVal,
          space: response.space.id,
          userId: response.user.id,
          firstName: response.user.firstName,
          lastName: response.user.lastName,
          spaceGroup: response.spaceType.id,
          areaVal: response.area.id,
          propTypeVal: response.propertyType,
          listingTitle: response.title,
          floodRelief: response.floodRelief,
          propDescription: response.description,
          bedrooms: response.propertyCapacity.noOfBedrooms,
          bathrooms: response.propertyCapacity.noOfBathrooms,
          beds: response.propertyCapacity.noOfBeds,
          privateBathRoom: response.propertyCapacity.isPrivateBathroom,
          adults: response.propertyCapacity.noOfAdults,
          childrens: response.propertyCapacity.noOfChildren,
          infants: response.propertyCapacity.noOfInfants,
          pets: response.propertyCapacity.noOfPets,
          latitude: response.lat,
          longitude: response.lng,
          address: response.address,
          country: response.country,
          state: response.state,
          city: response.city,
          customUrl: response.customUrl,
          thumnails: response.thumbnailUrl,
          youtubeLink:
            response.youtubeVideoLink != null ? response.youtubeVideoLink : "",
        });

        setPropertyPricePolicy(response.propertyPricePolicy);

        setMapCenter({
          lat: response.lat,
          lng: response.lng,
          address: response.address,
        });

        setThumbnailUrls(response.thumbnailUrl);
        setGalleryImgUrls(response.propertyImages);

        getAllUsersData(response);
        getSpacesData(response);
        getSpacesGroup(response);
        getAreasData(response);
        getAmenitiesData(response);
        getConsiderations(response);
        getAccessibilities();
        getFacilities();
        getSecuritiesFeatures();
        getPropertyNearbyActivities();
        getPropertySafeties();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const value = parseFloat(e.target.value) < 0
    if(!value){
      setInputVal({ ...inputVal, [e.target.name]: e.target.value });
    }
  };
  const handleChangeAmenities = (item, ind) => {
    let tempArr = [...allAmenities];

    let payload = {
      amenityId: item.value,
      propertyId: parseInt(id),
      status: !tempArr[ind].isChecked,
    };
    createPropertyAmenities(payload, token)
      .then((res) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Property Amenity Updated Successfully",
          life: 3000,
        });
        tempArr[ind].isChecked = !tempArr[ind].isChecked;
        setAllAmenities(tempArr);
      })
      .catch((err) => {});
  };

  const handleChangeThumbnail = (e) => {
    if (e.target.files) {
      setThumbnails([e.target.files]);
      setThumbnailUrls("");
    }
  };
  const handleChangeGallery = (e) => {
    let tempArr = [...galleryImages];

    if (e.target.files) {
      tempArr.push(e.target.files);
      setGalleryImages(tempArr);
    }
  };

  const handleRemoveImg = (item, ind) => {
    let tempArr = [...galleryImages];
    tempArr.splice(ind, 1);
    setGalleryImages(tempArr);
  };

  const showToast = (msg) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const handleUpdatePhotoSection = () => {
    let formData = new FormData();
    formData.append("youtubeVideoLink", inputVal.youtubeLink);

    if (galleryImages.length > 0 || galleryImgUrls.length > 0) {
      for (let i = 0; i < galleryImages.length; i++) {
        formData.append("galleryFiles", galleryImages[i][0]);
      }

      for (let i = 0; i < galleryImgUrls.length; i++) {
        formData.append("galleryFiles", galleryImgUrls[i]);
      }
      let thumbPic = thumbnailUrls == "" ? thumnails[0][0] : thumbnailUrls;
      formData.append("thumbnailFile", thumbPic);

      setIsLoadingPhotoSection(true);
      updatePhotoAndVideoUrl(id, formData, token)
        .then((res) => {
          setIsLoadingPhotoSection(false);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Photo And Video Saved Successfully",
            life: 3000,
          });
          // setTimeout(() => {
          //   navigate("/propertyList");
          // }, 1500);
        })
        .catch((err) => {
          setIsLoadingPhotoSection(false);
          console.log(err, "asdsahdksahj");
        });
    }
  };

  const handleUpdateListingBasic = () => {
    if (inputVal.listingTitle == "") {
      showToast("Title is required");
    } else if (inputVal.space == "") {
      showToast("Space is required");
    } else if (inputVal.spaceGroup == "") {
      showToast("Space Type is required");
    } else if (inputVal.areaVal == "") {
      showToast("Area is required");
    }else if (inputVal.userId == "") {
      showToast("User id is required");
    } else if (typeof(inputVal.floodRelief) !== "boolean") {
      showToast("Flood Relief field is required");
    } else if (inputVal.adults == "") {
      showToast("Number of adults is required");
    } else if (inputVal.childrens == "" && inputVal.childrens != 0) {
      showToast("Number of children is required");
    } else if (inputVal.bedrooms == "") {
      showToast("Number of Bedrooms is required");
    } else if (inputVal.bathrooms == "") {
      showToast("Number of Bathrooms is required");
    } else if (inputVal.beds == "") {
      showToast("Number of Beds is required");
    } else if (typeof(inputVal.privateBathRoom) !== "boolean") {
      showToast("Private bathroom field is required");
    } else if (inputVal.address == "") {
      showToast("Address is required");
    } else if (inputVal.country == "") {
      showToast("Country is required");
    } else if (inputVal.city == "") {
      showToast("City is required");
    } else if (inputVal.state == "") {
      showToast("State is required");
    } else {
      let payload = {
        userId: inputVal.userId,
        title: inputVal.listingTitle,
        description: inputVal.propDescription,
        customUrl: inputVal.customUrl,
        status: "LISTED",
        spaceId: inputVal.space,
        spaceTypeId: inputVal.spaceGroup,
        areaId: inputVal.areaVal,
        zipCode: inputVal.zipCode,
        address: inputVal.address,
        country: inputVal.country,
        state: inputVal.state,
        city: inputVal.city,
        lat: inputVal.latitude,
        lng: inputVal.longitude,
      };

      let params = {
        noOfAdults: parseInt(inputVal.adults),
        noOfChildren: parseInt(inputVal.childrens),
        noOfInfants: inputVal.infants ? parseInt(inputVal.infants) : 0,
        noOfPets: inputVal.pets ? parseInt(inputVal.pets) : 0,
        noOfBeds: parseInt(inputVal.beds),
        noOfBedrooms: parseInt(inputVal.bedrooms),
        noOfBathrooms: parseInt(inputVal.bathrooms),
        isPrivateBathroom: inputVal.privateBathRoom,
      };

      setIsLoadingPropListing(true);
      updatePropertyListingBasicById(id, payload, token)
        .then((res) => {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Listing Basic Updated Successfully",
            life: 3000,
          });
          setIsLoadingPropListing(false);
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Listing Basic Not Updated",
            life: 3000,
          });
          setIsLoadingPropListing(false);
        });
      updatePropertyCapacityBasicById(id, params, MdGeneratingTokens)
        .then((res) => {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Property Capacity Basic Updated Successfully",
              life: 3000,
            });
          setIsLoadingPropListing(false);
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Listing Basic Not Updated",
            life: 3000,
          });
          setIsLoadingPropListing(false);
        });
    }
  };

  const handleRemoveGalleryImages = (value) => {
    getPropertyById(id).then((res) => {

      deletePropertyImage(value.id).then((r) => {
        console.log(r)
      }).catch((e) => {
        console.log(e)
      })
      const response = res.data.data
      const update = response.propertyImages.filter((item) => {
        return item.id !== value.id
      })
      setGalleryImgUrls(update)
    })
  }

  return (
    <div className="app-main">
      <Toast ref={toast} position="bottom-right" />
      <Sidebar active="properties" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title={`Edit Property - Staywo`}
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />

          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-lg-12">
              <Accordion activeIndex={0}>
                <AccordionTab header="Photos">
                  <div className="row">
                    <div className="col-lg-12">
                      <h5 style={{ marginTop: "15px" }}>Thumbnail Photo</h5>
                      <p>
                        Your cover photo is a guest’s first impression of your
                        listing.
                      </p>
                      <InputText
                        placeholder="Upload Thumbnail"
                        onChange={handleChangeThumbnail}
                        type="file"
                        style={{ width: "100%", marginTop: "10px" }}
                      />
                    </div>

                    <div className="col-lg-12">
                      <div
                          className="col-lg-3"
                          style={{ marginBottom: 15 }}
                        >
                        <div
                          style={{
                            background: `url(${thumbnailUrls})`,
                            width: "100%",
                            height: "200px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            position: "relative",
                          }}
                        ></div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <h5 style={{ marginTop: "15px" }}>Gallery Images</h5>
                      <InputText
                        placeholder="Upload Gallery"
                        onChange={handleChangeGallery}
                        type="file"
                        style={{ width: "100%", marginTop: "10px" }}
                      />

                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-lg-12">
                          {galleryImages.length > 0 &&
                            galleryImages.map((item, ind) => {
                              return (
                                <p>
                                  {item[0].name}{" "}
                                  <span
                                    style={{
                                      color: "red",
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleRemoveImg(item, ind)}
                                  >
                                    X
                                  </span> 
                                </p>
                              );
                            })}
                        </div>

                        {galleryImgUrls.length > 0 &&
                          galleryImgUrls.map((item, ind) => {
                            return (
                              <div
                                className="col-lg-3"
                                style={{ marginBottom: 15 }}
                              >
                                <div
                                  style={{
                                    background: `url(${item.locationUrl})`,
                                    width: "100%",
                                    height: "200px",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    position: "relative",
                                  }}
                                ></div>
                                <button onClick={() => handleRemoveGalleryImages(item)} style={{top: "0px", right: "15px", position: "absolute", background: "red", border: "none", color: "white", padding: "5px", fontSize: "14px", cursor: "pointer"}}>Delete</button>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <h5 style={{ marginTop: "15px" }}>Media</h5>
                      {inputVal.youtubeLink !== "" && (
                        <iframe
                          width="100%"
                          height="350"
                          src={inputVal.youtubeLink}
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      )}

                      <InputText
                        value={inputVal.youtubeLink}
                        placeholder="Youtube Video Link"
                        onChange={handleChange}
                        style={{ width: "100%", marginTop: "10px" }}
                        name="youtubeLink"
                      />
                    </div>

                    <div
                      className="col-lg-12"
                      style={{ margin: "10px 0", textAlign: "right" }}
                    >
                      {isLoadingPhotoSection ? (
                        <ProgressSpinner />
                      ) : (
                        <Button
                          label={"Save"}
                          className="p-button-lg"
                          style={{ fontSize: "12px", padding: "10px 12px" }}
                          onClick={handleUpdatePhotoSection}
                        />
                      )}
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>

            <div className="col-lg-12">
              <Accordion activeIndex={1}>
                <AccordionTab header="Amenities">
                  <h5 style={{ marginTop: "15px" }}>All Amenities</h5>
                  <div className="row">
                    {allAmenities.map((item, ind) => {
                      return (
                        <div key={item.id} className="col-lg-2">
                          <div
                            className="field-checkbox"
                            onClick={() => handleChangeAmenities(item, ind)}
                          >
                            <Checkbox
                              inputId={item.id}
                              name={item.id}
                              value={item.isChecked}
                              checked={item.isChecked}
                            />
                            <label htmlFor="city1" style={{ marginBottom: 0 }}>
                              {item.label}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </AccordionTab>
              </Accordion>
            </div>

            <div className="col-lg-12">
              <Accordion activeIndex={2}>
                <AccordionTab header="House Rule">
                  <h5 style={{ marginTop: "15px" }}>House Rule</h5>
                  <h6 style={{ marginTop: "10px" }}>Additional Rules</h6>
                  <p>
                    You can include any requirements around safety concerns,
                    shared space rules and your community regulations.
                  </p>
                  <InputTextarea
                    value={inputVal.houseRule}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    name="houseRule"
                    rows={5}
                    cols={30}
                  />
                </AccordionTab>
              </Accordion>
            </div>

            <div className="col-lg-12">
              <Accordion activeIndex={3}>
                <AccordionTab header="Listing Basic">
                  <h5>Property Details</h5>
                  <div className="row">
                    <div className="col-lg-6">
                      <h6 style={{ marginTop: "10px" }}>Title</h6>
                      <InputText
                        value={inputVal.listingTitle}
                        placeholder="Title"
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        name="listingTitle"
                      />
                    </div>
                    <div className="col-lg-6">
                      <h6 style={{ marginTop: "10px" }}>
                        Property Description
                      </h6>
                      <InputTextarea
                        value={inputVal.propDescription}
                        placeholder="Property Description"
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        name="propDescription"
                        rows={5}
                        cols={30}
                      />
                    </div>

                    <div className="col-lg-6">
                      <h6>Space</h6>
                      <Dropdown
                        value={inputVal.space}
                        options={allSpaces}
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        optionLabel="label"
                        name="space"
                        placeholder="Space"
                      />
                    </div>

                    <div className="col-lg-6">
                      <h6>Select User</h6>
                      <Dropdown
                        value={inputVal?.userId}
                        options={allUsersList}
                        onChange={handleChange}
                        disabled
                        style={{ width: "100%" }}
                        optionLabel="label"
                        name="userId"
                        placeholder={`${inputVal?.firstName} ${inputVal?.lastName}`}
                      />
                    </div>

                    <div className="col-lg-6">
                      <h6 style={{ marginTop: "10px" }}>Space Type</h6>
                      <Dropdown
                        value={inputVal.spaceGroup}
                        options={allSpacesGroup}
                        style={{ width: "100%" }}
                        onChange={handleChange}
                        optionLabel="label"
                        name="spaceGroup"
                        placeholder="Space Type"
                      />
                    </div>

                    <div className="col-lg-6">
                      <h6 style={{ marginTop: "10px" }}>Area</h6>
                      <Dropdown
                        value={inputVal.areaVal}
                        options={allAreas}
                        style={{ width: "100%" }}
                        onChange={handleChange}
                        optionLabel="label"
                        name="areaVal"
                        placeholder="Area"
                      />
                    </div>
                    <div className="col-lg-6">
                      <h6 style={{ marginTop: "10px" }}>Property Type</h6>
                      <Dropdown
                        value={inputVal.propTypeVal}
                        options={propertypes}
                        style={{
                          width: "100%",
                        }}
                        onChange={handleChange}
                        optionLabel="label"
                        name="propTypeVal"
                        placeholder="Property Type"
                      />
                    </div>

                    <div className="col-lg-6">
                      <h6 style={{ marginTop: "10px" }}>Flood Relief</h6>
                      <Dropdown
                        value={inputVal.floodRelief}
                        options={floodData}
                        style={{ width: "100%" }}
                        onChange={handleChange}
                        optionLabel="label"
                        name="floodRelief"
                        placeholder="Flood Relief"
                      />
                    </div>

                    <div className="col-lg-6">
                      <h6 style={{ marginTop: "10px" }}>Custom URL</h6>
                      <InputText
                        value={inputVal.customUrl}
                        placeholder="Custom URL"
                        onChange={handleChange}
                        style={{ width: "100%" }}
                        name="customUrl"
                      />
                    </div>

                    <div className="col-lg-12">
                      <h5 style={{ marginTop: "15px" }}>Guest Detail</h5>
                      <div className="row">
                        <div className="col-lg-6">
                          <h6 style={{ marginTop: "10px" }}>No Of Adults</h6>
                          <InputText
                            value={inputVal.adults}
                            placeholder="Adults"
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            name="adults"
                            type="number"
                          />
                        </div>
                        <div className="col-lg-6">
                          <h6 style={{ marginTop: "10px" }}>No Of Childrens</h6>
                          <InputText
                            value={inputVal.childrens}
                            placeholder="Childrens"
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            name="childrens"
                            type="number"
                          />
                        </div>
                        <div className="col-lg-6">
                          <h6 style={{ marginTop: "10px" }}>No Of Infants</h6>
                          <InputText
                            value={inputVal.infants}
                            placeholder={inputVal.infants}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            name="infants"
                            type="number"
                          />
                        </div>
                        <div className="col-lg-6">
                          <h6 style={{ marginTop: "10px" }}>No Of Pets</h6>
                          <InputText
                            value={inputVal.pets}
                            placeholder="Pets"
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            name="pets"
                            type="number"
                          />
                        </div>

                        <div className="col-lg-6">
                          <h6 style={{ marginTop: "10px" }}>No Of Bedrooms</h6>
                          <InputText
                            value={inputVal.bedrooms}
                            placeholder="Bedrooms"
                            type="number"
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            name="bedrooms"
                          />
                        </div>
                        <div className="col-lg-6">
                          <h6 style={{ marginTop: "10px" }}>No Of Bathrooms</h6>
                          <InputText
                            value={inputVal.bathrooms}
                            placeholder="Bathrooms"
                            onChange={handleChange}
                            type="number"
                            style={{ width: "100%" }}
                            step={0.5}
                            name="bathrooms"
                          />
                        </div>

                        <div className="col-lg-6">
                          <h6 style={{ marginTop: "10px" }}>No Of Beds</h6>
                          <InputText
                            value={inputVal.beds}
                            placeholder="Beds"
                            type="number"
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            name="beds"
                          />
                        </div>
                        <div className="col-lg-6">
                          <h6 style={{ marginTop: "10px" }}>
                            Private Bathroom
                          </h6>
                          <Dropdown
                            value={inputVal.privateBathRoom}
                            options={bathData}
                            style={{ width: "100%" }}
                            onChange={handleChange}
                            optionLabel="label"
                            name="privateBathRoom"
                            placeholder="Private Bathroom"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <h5 style={{ marginTop: "15px" }}>Location</h5>
                      <Location
                        handleChange={handleChange}
                        inputVal={inputVal}
                        setInputVal={setInputVal}
                        setMapCenter={setMapCenter}
                        mapCenter={mapCenter}
                      />
                    </div>
                    <div
                      className="col-lg-12"
                      style={{ margin: "10px 0", textAlign: "right" }}
                    >
                      {isLoadingPropListing ? (
                        <ProgressSpinner />
                      ) : (
                        <Button
                          label={"Save"}
                          className="p-button-lg"
                          style={{ fontSize: "12px", padding: "10px 8px" }}
                          onClick={handleUpdateListingBasic}
                        />
                      )}
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>

            <GuestSafety
              propertyDetails={propertyDetails}
              propertyConsiderations={propertyConsiderations}
              getConsiderations={getConsiderations}
            />

            <PricingSection
              propertyPricePolicy={propertyPricePolicy}
              propertyDetails={propertyDetails}
            />

            <PropertyPolicies
              inputVal={inputVal}
              setInputVal={setInputVal}
              handleChange={handleChange}
              propertyDetails={propertyDetails}
            />

            <GuestInfoSection propertyDetails={propertyDetails} />

            <Miscellaneous
              propertyAccessibility={propertyAccessibility}
              propertyFacility={propertyFacility}
              propertySecurity={propertySecurity}
              propertyNearby={propertyNearby}
              propertySafetyFeatures={propertySafetyFeatures}
              propertyDetails={propertyDetails}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProperty;
