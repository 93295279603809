import React from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ active }) => {
  const navigate = useNavigate();
  return (
    <div className="app-sidebar sidebar-shadow">
      <div className="app-header__logo">
        <div className="logo-src"></div>
        <div className="header__pane ml-auto">
          <div>
            <button
              type="button"
              className="hamburger close-sidebar-btn hamburger--elastic"
              data-classname="closed-sidebar"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="app-header__mobile-menu">
        <div>
          <button
            type="button"
            className="hamburger hamburger--elastic mobile-toggle-nav"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <div className="app-header__menu">
        <span>
          <button
            type="button"
            className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
          >
            <span className="btn-icon-wrapper">
              <i className="fa fa-ellipsis-v fa-w-6"></i>
            </span>
          </button>
        </span>
      </div>
      <div className="scrollbar-sidebar" style={{ overflow: "scroll" }}>
        <div className="app-sidebar__inner">
          <ul className="vertical-nav-menu">
            <li className="app-sidebar__heading">Staywo Analytics</li>
            <li>
              <a
                style={{fontSize:"16px",fontFamily:""}}
                onClick={() => navigate("/")}
                className={active === "dashboard" ? "mm-active" : null}
              >
                <i className="metismenu-icon pe-7s-monitor"></i>
                Dashboard
              </a>
            </li>
            <li className="app-sidebar__heading">Users</li>
            <li>
              <a
              style={{fontSize:"16px",fontFamily:""}}
                onClick={() => navigate("/users")}
                className={active === "users" ? "mm-active" : null}
              >
                <i className="metismenu-icon pe-7s-users"></i>
                Users
              </a>
              <a
              style={{fontSize:"16px",fontFamily:""}}
                onClick={() => navigate("/verifyUsers")}
                className={active === "verifyUsers" ? "mm-active" : null}
              >
                <i className="metismenu-icon pe-7s-user"></i>
                Verify Users
              </a>
            </li>
            <li className="app-sidebar__heading">Properties</li>
            <li>
              <a
              style={{fontSize:"16px",fontFamily:""}}
                onClick={() => navigate("/propertyList")}
                className={active === "properties" ? "mm-active" : null}
              >
                <i className="metismenu-icon pe-7s-plugin"></i>
                Properties
              </a>
            </li>
            <li>
              <a
              style={{fontSize:"16px",fontFamily:""}}
                href="new-properties"
                className={active === "newProperties" ? "mm-active" : null}
              >
                <i className="metismenu-icon pe-7s-vector"></i>
                Newly Added Properties
              </a>
            </li>
            <li>
              <a
              style={{fontSize:"16px",fontFamily:""}}
                href="under-claim-properties"
                className={
                  active === "underClaimProperties" ? "mm-active" : null
                }
              >
                <i className="metismenu-icon pe-7s-voicemail"></i>
                Under Claim Properties
              </a>
            </li>
            <li>
              <a
              style={{fontSize:"16px",fontFamily:""}}
                href="property-reports"
                className={
                  active === "reports" ? "mm-active" : null
                }
              >
                <i className="metismenu-icon pe-7s-ticket"></i>
                Property Reports
              </a>
            </li>
            <li className="app-sidebar__heading">Bookings</li>

            <li>
              <a
              style={{fontSize:"16px",fontFamily:""}}
                onClick={() => navigate("/bookingList")}
                className={active === "bookingList" ? "mm-active" : null}
              >
                <i className="metismenu-icon pe-7s-ribbon"></i>
                Bookings
              </a>
            </li>
            <li>
              <a
              style={{fontSize:"16px",fontFamily:""}}
                onClick={() => navigate("/bookingTransactionHistory")}
                className={
                  active === "bookingTransactionHistory" ? "mm-active" : null
                }
              >
                <i className="metismenu-icon pe-7s-repeat"></i>
                Transaction History
              </a>
            </li>

            <li className="app-sidebar__heading">Roles And Permissions</li>

            <li>
              <a
              style={{fontSize:"16px",fontFamily:""}}
                onClick={() => navigate("/roles")}
                className={active === "roles" ? "mm-active" : null}
              >
                <i className="metismenu-icon pe-7s-network"></i>
                Roles
              </a>
            </li>
            <li>
              <a
              style={{fontSize:"16px",fontFamily:""}}
                onClick={() => navigate("/permissions")}
                className={active === "permissions" ? "mm-active" : null}
              >
                <i className="metismenu-icon pe-7s-portfolio"></i>
                Permissions
              </a>
            </li>
            <li className="app-sidebar__heading">Wallet</li>
            <li>
              <a
              style={{fontSize:"16px",fontFamily:""}}
                onClick={() => navigate("/wallet")}
                className={active === "wallet" ? "mm-active" : null}
              >
                <i className="metismenu-icon pe-7s-wallet"></i>
                Wallet
              </a>
            </li>
            <li className="app-sidebar__heading">Configrations</li>

            <li className="mm-active">
              <a style={{fontSize:"16px",fontFamily:""}}>
                <i className="metismenu-icon pe-7s-config"></i>
                Configrations
                <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
              </a>
              <ul className="mm-show">
                <li>
                  <a
                    onClick={() => navigate("/amenitiesGroup")}
                    className={active === "amenitiesGroup" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Amenities Group
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/amenities")}
                    className={active === "amenities" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Amenities
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/nearbyActivityGroup")}
                    className={
                      active === "nearbyActivityGroup" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Nearby Activity Group
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/area")}
                    className={active === "area" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Area
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/space")}
                    className={active === "space" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Spaces
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/spaceGroupType")}
                    className={active === "spaceGroupType" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Spaces Type
                  </a>
                </li>

                <li>
                  <a
                    onClick={() => navigate("/safetyFeatureGroup")}
                    className={
                      active === "safetyFeatureGroup" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Safety Feature Group
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/safetyFeatures")}
                    className={active === "safetyFeatures" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Safety Features
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/accessibility")}
                    className={active === "accessibility" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Accessibility
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/accessibilityGroup")}
                    className={
                      active === "accessibilityGroup" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Accessibility Group
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/houseRuleGroup")}
                    className={active === "houseRuleGroup" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    House Rule Group
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/houseRule")}
                    className={active === "houseRule" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    House Rule
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/facilities-group")}
                    className={
                      active === "facilitiesGroup" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Facilities Group
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/facilities")}
                    className={active === "facilities" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Facilities
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/security")}
                    className={active === "security" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Security
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/securityGroupType")}
                    className={
                      active === "SecurityGroupType" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Security Type
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/safetyConsiderationGroup")}
                    className={active === "safetyGroup" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Safety Group
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/safetyConsideration")}
                    className={
                      active === "safetyConsideration" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Safety Consideration
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/systemConfiguration")}
                    className={
                      active === "systemConfiguration" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    System Configuration
                  </a>
                </li>
                <li>
                  <a
                    href="property-info-configration"
                    className={active === "propertyInfo" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Property Info
                  </a>
                </li>
                <li>
                  <a
                    href="bed-type-configration"
                    className={active === "bedTypes" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Bed Types
                  </a>
                </li>
                <li>
                  <a
                    href="active-countries-configration"
                    className={
                      active === "activeCountries" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Active Countries
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/nearby-activities-configration")}
                    className={
                      active === "nearbyActivities" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Nearby Activities
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/coupons")}
                    className={active === "coupons" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Coupons
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => navigate("/page")}
                    className={active === "page" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Pages
                  </a>
                </li>
                <li>
                  <a
                    href="claim-questions-configration"
                    className={active === "claimQuestions" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Claim Questions
                  </a>
                </li>
                <li>
                  <a
                    href="claim-sub-questions-configration"
                    className={
                      active === "claimSubQuestions" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Claim Sub-Questions
                  </a>
                </li>
                <li>
                  <a
                    href="property-report-configration"
                    className={
                      active === "reportPropertyOptions" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Report Property Options
                  </a>
                </li>
                <li>
                  <a
                    href="faq-categories-configration"
                    className={active === "faqCategories" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    FAQ’s categories
                  </a>
                </li>
                <li>
                  <a
                    href="faq-configration"
                    className={active === "faq" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    FAQ’s
                  </a>
                </li>
                <li>
                  <a
                    href="diamond-host-review-configration"
                    className={
                      active === "diamondHostReviews" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Diamond Host Review
                  </a>
                </li>
                <li>
                  <a
                    href="diamond-host-requirement-configration"
                    className={
                      active === "diamondHostRequirements" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Diamond Host Req
                  </a>
                </li>
                <li>
                  <a
                    href="property-commision-criteria-configration"
                    className={
                      active === "commisionCriteria" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Commission Criteria
                  </a>
                </li>
                <li>
                  <a
                    href="/notification-criteria-configration"
                    className={
                      active === "notificationConfigration" ? "mm-active" : null
                    }
                  >
                    <i className="metismenu-icon"></i>
                    Notification Criteria
                  </a>
                </li>
              </ul>
            </li>
            <li className="app-sidebar__heading">CMS</li>

            <li className="mm-active">
              <a style={{fontSize:"16px",fontFamily:""}}>
                <i className="metismenu-icon pe-7s-config"></i>
                CMS
                <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
              </a>
              <ul className="mm-show">
                <li>
                  <a
                    onClick={() => navigate("/cms")}
                    className={active === "amenitiesGroup" ? "mm-active" : null}
                  >
                    <i className="metismenu-icon"></i>
                    Slider
                  </a>
                </li>
              </ul>
            </li>







          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
