import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

// Space Group Type

export const getAllSpaceGroup = () => {
  return Api(`${endPoints.allSpaceGroups}`, null, requestType.GET, null, null);
};

export const getSpaceTypeById = (id) => {
  return Api(
    `${endPoints.spaceTypeById}/${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addSpaceGroup = (params) => {
  return Api(
    `${endPoints.addSpaceGroup}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editSpaceGroupById = (id, params) => {
  return Api(
    `${endPoints.editSpaceGroup}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteSpaceGroupById = (id) => {
  return Api(
    `${endPoints.deleteSpaceGroup}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};

// // Space

export const getAllSpaces = () => {
  return Api(`${endPoints.allSpaces}`, null, requestType.GET, null, null);
};

export const addSpace = (params) => {
  return Api(`${endPoints.addSpace}`, params, requestType.POST, null, null);
};

export const editSpaceById = (id, params) => {
  return Api(
    `${endPoints.editSpace}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};

export const deleteSpaceById = (id) => {
  return Api(
    `${endPoints.deleteSpace}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
