import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { verifyUsersstatusArr } from "../../constants/staticData";
import { updateUserVerifyStatus } from "../../services/verifyUsers/index";

const VerifyUserStatusDialog = React.forwardRef(
  ({ getData, customRows }, ref) => {
    const user_token = JSON.parse(localStorage.getItem("token"));
    const toast = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const [dialogData, setDialogData] = useState(null);
    const [inputVal, setInputVal] = useState({
      status: "",
      description: "",
    });

    useImperativeHandle(ref, () => ({
      isVisible(params) {
        setDialogData(params);
        setOpenStatusDialog(true);
      },
    }));

    useEffect(() => {
      setInputVal({
        status: dialogData?.status,
        description: dialogData?.description,
      });
    }, [dialogData]);

    const handleChange = (e) => {
      setInputVal({ ...inputVal, [e.target.name]: e.value });
    };
    const handleChangeText = (e) => {
      setInputVal({ ...inputVal, description: e.target.value });
    };

    const hideDialog = () => {
      setOpenStatusDialog(false);
    };

    const handleChangeStatus = () => {
      let payload = {
        status: inputVal.status,
        description: inputVal.description,
        userId: dialogData.user.id,
      };

      if (inputVal.description == "") {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Reason is required",
          life: 3000,
        });
      } else {
        setIsLoading(true);

        updateUserVerifyStatus(dialogData.id, payload, user_token).then(
          (res) => {
            setIsLoading(false);
            if (res.data.statusCode == 400) {
              let tempArr = [];
              res?.data?.message.map((val, ind) => {
                tempArr.push({
                  severity: "error",
                  summary: "Error",
                  detail: val,
                  life: 3000,
                });
              });
              toast.current.show(tempArr);
            } else {
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "Status Updated Successfully",
                life: 3000,
              });
              setInputVal({
                status: "",
                description: "",
              });
              getData("ASC", 1, 10);
              setTimeout(() => {
                hideDialog();
              }, 100);
            }
          }
        );
      }
    };

    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
        />
        {!isLoading ? (
          <Button
            label="Save"
            icon="pi pi-check"
            className="p-button-text"
            onClick={handleChangeStatus}
          />
        ) : null}
      </React.Fragment>
    );

    return (
      <Dialog
        visible={openStatusDialog}
        style={{ width: "450px" }}
        header="Change User Status"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <Toast ref={toast} position="bottom-right" />
        <div>
          <Dropdown
            value={inputVal.status}
            options={verifyUsersstatusArr}
            onChange={handleChange}
            name="status"
            optionLabel="value"
            placeholder="Select Status"
          />

          <InputTextarea
            style={{ marginTop: "20px" }}
            value={inputVal.description}
            onChange={handleChangeText}
            name="description"
            placeholder="Reason"
            rows={5}
            cols={30}
          />
        </div>
      </Dialog>
    );
  }
);

export default VerifyUserStatusDialog;
