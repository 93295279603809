import React, { useState, useEffect, useRef } from 'react';
import Banner from '../../components/banner';
import Card from '../../components/card';
import Sidebar from '../../components/sidebar';
import { ProgressSpinner } from 'primereact/progressspinner';
import Pagination from '../../components/pagination';
import CrudDataTable from '../../components/crudDataTable/index';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  getAllPages,
  addPage,
  editPage,
  deletePage,
} from './../../services/page';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

const Page = () => {
  const [pages, setPages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customFirst, setCustomFirst2] = useState(0);
  const [customRows, setCustomRows2] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [type, setType] = useState('add');
  const [product, setProduct] = useState({
    name: '',
    slug: '',
    content: '',
    isActive: false,
  });
  const [productDialog, setProductDialog] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const toast = useRef(null);

  // const onInputNumberChange = (e, name) => {
  //   const val = e.value || 0;
  //   let _product = { ...product };
  //   _product[`${name}`] = val;

  //   setProduct(_product);
  // };

  const onInputChange = (e, name) => {
    if (name == 'icon' || name == 'code') {
      let _product = { ...product };
      _product[`${name}`] = e;

      setProduct(_product);
    } else if (name == 'isActive') {
      const val = e.value;
      let _product = { ...product };
      _product[`${name}`] = val;

      setProduct(_product);
    } else {
      const val = (e.target && e.target.value) || '';
      let _product = { ...product };
      _product[`${name}`] = val;

      setProduct(_product);
    }
  };

  const getData = (order, page, limit) => {
    setIsLoading(true);

    getAllPages(order, page, limit)
      .then((res) => {
        setIsLoading(false);
        setPages(res.data.data.reverse());
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData('ASC', 1, 10);
  }, []);

  const onPageChange = (event) => {
    setCustomFirst2(event.first);
    setCustomRows2(event.rows);
    getData(event.page, event.rows);
  };

  const openEditDialog = (rowData) => {
    setType('edit');
    const contentBlock = htmlToDraft(rowData.content);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const convertedVal = EditorState.createWithContent(contentState);
    setEditorState(convertedVal);

    setProduct({
      name: rowData.name,
      slug: rowData.slug,
      content: rowData.content,
      isActive: rowData.isActive,
      id: rowData.id,
    });
    setProductDialog(true);
  };

  const handleAddEdit = () => {
    const { name, slug, content, isActive } = product;
    if (name == '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Name is required',
        life: 3000,
      });
    } else if (slug == '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Content is required',
        life: 3000,
      });
    } else if (content == '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Content is required',
        life: 3000,
      });
    } else {
      let payload = {
        name,
        slug,
        content,
      };
      if (type === 'add') {
        setIsLoading(true);
        addPage(payload).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: 'error',
                summary: 'Error',
                detail: val,
                life: 3000,
              });
            });
            setProductDialog(false);
            toast.current.show(tempArr);
          } else {
            handleCloseDialog();
            toast.current.show({
              severity: 'success',
              summary: 'Successful',
              detail: 'Page Created Successfully',
              life: 3000,
            });
            getData('ASC', 1, 10);
          }
        });
      } else {
        let payload = {
          name,
          slug,
          content,
          isActive,
        };
        setIsLoading(true);
        editPage(product.id, payload).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: 'error',
                summary: 'Error',
                detail: val,
                life: 3000,
              });
            });
            setProductDialog(false);
            toast.current.show(tempArr);
          } else {
            handleCloseDialog();
            toast.current.show({
              severity: 'success',
              summary: 'Successful',
              detail: 'Page Updated Successfully',
              life: 3000,
            });
            getData('ASC', 1, 10);
          }
        });
      }
    }
  };

  const deleteSelectedItem = () => {
    setIsLoading(true);
    deletePage(selectedItem.id).then((res) => {
      setIsLoading(false);
      if (res.data.statusCode == 400) {
        let tempArr = [];
        res?.data?.message.map((val, ind) => {
          tempArr.push({
            severity: 'error',
            summary: 'Error',
            detail: val,
            life: 3000,
          });
        });
        setConfirmDialog(false);
        toast.current.show(tempArr);
      } else {
        toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: 'Page Deleted Successfully',
          life: 3000,
        });
        getData('ASC', 1, 10);
        setTimeout(() => {
          setConfirmDialog(false);
        }, 1500);
      }
    });
  };

  const handleCloseDialog = () => {
    setProductDialog(false);
    setProduct({
      name: '',
      slug: '',
      content: '',
      isActive: false,
    });
    setEditorState(EditorState.createEmpty());
  };

  const statusTepmlate = (rowData) => {
    return (
      <>
        <div style={{ marginBottom: '10px' }}>
          <span
            style={{
              fontWeight: 500,
              background: '#c8e6c9',
              color: '#256029',
              padding: '5px 10px',
            }}
          >
            {rowData.isActive ? 'Active' : 'Not Active'}
          </span>
        </div>
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div style={{ display: 'flex' }}>
        <Button
          icon='pi pi-pencil'
          className='p-button-rounded p-button-success mr-2'
          onClick={() => openEditDialog(rowData)}
        />
        <Button
          icon='pi pi-trash'
          className='p-button-rounded p-button-danger'
          onClick={() => {
            setConfirmDialog(true);
            setSelectedItem(rowData);
          }}
        />
      </div>
    );
  };

  const DATA = [
    { id: 1, field: 'id', header: 'Id' },
    { id: 2, field: 'name', header: 'Name' },
    // { id: 3, field: "date", header: "Date" },
    { id: 4, field: 'slug', header: 'Slug' },
    { id: 5, field: 'isActive', header: 'Status', body: statusTepmlate },
    { id: 6, header: 'Actions', body: actionBodyTemplate },
  ];

  const renderDialog = () => {
    return (
      <>
        <div className='grid'>
          <div className='col-6'>
            <label>Name</label>
            <InputText
              value={product.name}
              onChange={(e) => onInputChange(e, 'name')}
              required
              autoFocus
              className={classNames({
                'p-invalid': productDialog && !product.name,
              })}
            />
          </div>

          <div className='col-6'>
            <label>Slug</label>
            <InputText
              value={product.slug}
              onChange={(e) => onInputChange(e, 'slug')}
              required
              className={classNames({
                'p-invalid': productDialog && !product.slug,
              })}
            />
          </div>
          {
            // <div className="col-12">
            //   <label>Content</label>
            //   <InputTextarea
            //     value={product.content}
            //     onChange={(e) => onInputChange(e, "content")}
            //     required
            //     className={classNames({
            //       "p-invalid": submitted && !product.content,
            //     })}
            //   />
            // </div>
          }
          <div className='col-12 mt-3'>
            <label>Content</label>
            <div style={{ border: '1px solid #ddd' }}>
              <Editor
                style={{ marginBottom: '10px' }}
                editorState={editorState}
                onEditorStateChange={
                  (e) => {
                    setEditorState(e);
                    setProduct({
                      ...product,
                      content: draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                      ),
                    });
                  }
                  // onEditorStateChange={(e) => onInputChange(e, "content")
                }
              />
            </div>
          </div>

          {type === 'edit' && (
            <div className='field col-8'>
              <label>Status</label>
              <div className='flex'>
                <span className='mr-1'>In Active</span>
                <InputSwitch
                  checked={product.isActive}
                  onChange={(e) => onInputChange(e, 'isActive')}
                />
                <span className='ml-1'>Active</span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className='app-main'>
      <Toast ref={toast} position='bottom-right' />
      <Sidebar active='page' />
      <div className='app-main__outer'>
        <div className='app-main__inner'>
          <Banner
            title='All Pages - Staywo'
            icon='pe-7s-note2 icon-gradient bg-mean-fruit'
          />

          <div className='row'>
            <div className='col-lg-12'>
              <Card title='Pages'>
                {isLoading ? (
                  <div style={{ textAlign: 'center' }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <>
                    <Toolbar
                      className='mb-4'
                      left={
                        <Button
                          label='Add'
                          icon='pi pi-plus'
                          className='p-button-success mr-2'
                          onClick={() => {
                            setType('add');
                            setProductDialog(true);
                            setProduct({
                              name: '',
                              slug: '',
                              content: '',
                              isActive: false,
                            });
                            setEditorState(EditorState.createEmpty());
                          }}
                        />
                      }
                    ></Toolbar>
                    <CrudDataTable
                      headerColumns={DATA}
                      data={pages}
                      totalRecords={totalRecords}
                      renderDialog={renderDialog}
                      addEditFunction={handleAddEdit}
                      deleteSelectedItem={deleteSelectedItem}
                      emptyProduct={product}
                      dialogHeader='Page Details'
                      tableTitle='pages'
                      showToolbar={false}
                      showActions={false}
                    />
                  </>
                )}
                <Pagination
                  totalRecords={totalRecords}
                  customFirst={customFirst}
                  customRows={customRows}
                  onPageChange={onPageChange}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialog
        visible={confirmDialog}
        onHide={() => setConfirmDialog(false)}
        message='Are you sure you want to delete?'
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        accept={deleteSelectedItem}
        reject={() => setConfirmDialog(false)}
      />

      <Dialog
        visible={productDialog}
        style={{ width: '450px' }}
        header='Page Details'
        modal
        className='p-fluid'
        footer={
          <React.Fragment>
            <Button
              label='Cancel'
              icon='pi pi-times'
              className='p-button-text'
              onClick={() => handleCloseDialog()}
            />
            <Button
              label='Save'
              icon='pi pi-check'
              className='p-button-text'
              onClick={handleAddEdit}
            />
          </React.Fragment>
        }
        onHide={() => handleCloseDialog()}
      >
        {renderDialog()}
      </Dialog>
    </div>
  );
};

export default Page;
