import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

const Table = ({ data, columns, totalRecords }) => {
  return (
    <div className="datatable-templating-demo">
      <DataTable rows={totalRecords} value={data} responsiveLayout="scroll">
        {columns?.map((column) => {
          return (
            <Column
              key={column.id}
              field={column.field}
              header={column.header}
              body={column.body}
            ></Column>
          );
        })}
      </DataTable>
    </div>
  );
};

export default Table;
