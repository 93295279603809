import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import Pagination from "./../../components/pagination";
import { priceBodyTemplate } from "../../constants/utils";

const PropertySection = ({
  getUserProperties,
  totalRecordsProperty,
  userProperties,
  userDetail,
  propertiesLoading,
}) => {
  const [rowsProperties, setRowsProperties] = useState(10);
  const [firstProperty, setFirstProperty] = useState(0);

  const onChangeProperties = (event) => {
    setFirstProperty(event.first);
    setRowsProperties(event.rows);
    getUserProperties(userDetail.id, event.page, event.rows);
  };

  return (
    <>
      {propertiesLoading ? (
        <div style={{ textAlign: "center" }}>
          <ProgressSpinner />
        </div>
      ) : (
        <>
          {userProperties && userProperties.length > 0 && (
            <h4 style={{ marginTop: "15px" }}>Properties</h4>
          )}
          <div className="row">
            {userProperties && userProperties.length > 0 ? (
              userProperties.map((item) => {
                return (
                  <div className="col-sm-4">
                    <div
                      style={{
                        background: "#fff",
                        padding: "24px",
                        marginBottom: "10px",
                      }}
                    >
                      {item?.thumbnailUrl != null && (
                        <img
                          src={item?.thumbnailUrl}
                          width="100%"
                          height="265px"
                        />
                      )}
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Title: </h6>
                        <span className="address-style">{item.title}</span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Address: </h6>
                        <span className="address-style">{item.address}</span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Status: </h6>
                        <span className="address-style">{item.status}</span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Per Night Price: </h6>
                        <span className="address-style">
                          {priceBodyTemplate(item.perNightPrice)}
                        </span>
                      </div>
                      <div style={{ marginTop: "10px", display: "flex" }}>
                        <h6 style={{ fontWeight: "600" }}>Total Price: </h6>
                        <span className="address-style">
                          {priceBodyTemplate(item.price)}
                        </span>
                      </div>

                      <div style={{ textAlign: "right" }}>
                        <Link
                          to={`/propertyDetails/${item.id}`}
                          target="_blank"
                        >
                          <Button
                            label="View Detail"
                            icon="pi pi-eye"
                            className="p-button-sm"
                            style={{ fontSize: "12px", padding: "6px 8px" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-sm-4">
                <h3>No Record Found</h3>
              </div>
            )}
          </div>
        </>
      )}

      <Pagination
        totalRecords={totalRecordsProperty}
        customFirst={firstProperty}
        customRows={rowsProperties}
        onPageChange={(e) => onChangeProperties(e)}
      />
    </>
  );
};

export default PropertySection;
