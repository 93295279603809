import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Sidebar from "../../components/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import Pagination from "../../components/pagination";
import CrudDataTable from "../../components/crudDataTable/index";
import { Toast } from "primereact/toast";
import { getBookingTransactionHistory } from "./../../services/bookingTransactionHistory";
import moment from "moment";
import { priceBodyTemplate } from "../../constants/utils";

const BookingTransactionHistory = () => {
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customFirst, setCustomFirst2] = useState(0);
  const [customRows, setCustomRows2] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const toast = useRef(null);

  const getData = (order, page, limit) => {
    setIsLoading(true);

    getBookingTransactionHistory(
      order ? `?order=${order}` : "",
      page ? `&page=${page}` : "",
      limit ? `&limit=${limit}` : ""
    )
      .then((res) => {
        setIsLoading(false);
        setTotalRecords(res.data.total);
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            transactionId: item.id,
            propertyTitle: item.booking.property.title,
            bookingDate:
              item.booking.startDate && item.booking.endDate !== null
                ? `${moment(item.booking.startDate).format(
                    "DD MMM YYYY"
                  )} - ${moment(item.booking.endDate).format("DD MMM YYYY")}`
                : "-",
            amount: item.amount,
            paymentStatus: item.booking.paymentStatus,
            paymentMethod: item.paymentMethod,
          });
        });
        setTransactionHistory(tempArr);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData("DESC", 1, 10);
  }, []);

  const DATA = [
    { id: 1, field: "transactionId", header: "Transaction Id" },
    { id: 2, field: "propertyTitle", header: "Property Title" },
    {
      id: 3,
      field: "bookingDate",
      header: "Booking Date",
    },
    {
      id: 4,
      field: "amount",
      header: "Amount",
      body: (e) => priceBodyTemplate(e.amount),
    },
    { id: 5, field: "paymentStatus", header: "Payment Status" },
    {
      id: 6,
      field: "paymentMethod",
      header: "Payment Method",
    },
  ];

  const onPageChange = (event) => {
    setCustomFirst2(event.first);
    setCustomRows2(event.rows);
    getData("DESC", event.page, event.rows);
  };

  return (
    <div className="app-main">
      <Toast ref={toast} position="bottom-right" />
      <Sidebar active="bookingTransactionHistory" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="Booking Transaction History - Staywo"
            icon="pe-7s-repeat icon-gradient bg-mean-fruit"
          />

          <div className="row">
            <div className="col-lg-12">
              <Card title="Booking transaction history">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={DATA}
                    data={transactionHistory}
                    totalRecords={totalRecords}
                    tableTitle="Booking transaction history"
                    showView={false}
                    showToolbar={false}
                    showActions={false}
                  />
                )}
                <Pagination
                  totalRecords={totalRecords}
                  customFirst={customFirst}
                  customRows={customRows}
                  onPageChange={onPageChange}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingTransactionHistory;
