import React, { useState, useEffect } from 'react'
import Sidebar from '../../components/sidebar'
import { Dialog } from 'primereact/dialog';
import { deletePostSliders, getPostSidlers, postSliders, updatePostSliders } from '../../services/Cms/Cms';

function CMS() {
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [sliders, setSliders] = useState([])
  const [update, setUpdate] = useState(false)
  const [id, setId] = useState("")

  const handleSubmit = () => {
    let payload = { title, description }
    postSliders(payload)

      .then(res => {
        console.log("res", res)
        setVisible(false)
        getAllParentSliders();
      }
      )
      .catch((e) => {
        console.log("e", e)
      })
  }

  const getAllParentSliders = () => {
    getPostSidlers().then((res) => {
      console.log("res:", res)
      setSliders(res.data.data)

    }).catch((e) => {
      console.log("e:", e)
    })

  }
  useEffect(() => {
    getPostSidlers().then((res) => {
      console.log("res:", res)
      setSliders(res.data.data)

    }).catch((e) => {
      console.log("e:", e)
    })
  }, [
  ])

  const handleDelete = (id) => {
    deletePostSliders(id).then((res) => {
      console.log("res:", res)
      getAllParentSliders()
    }).catch((e) => {
      console.log("e:", e)
    })
  }

  const handleUpdate = (id) => {
    let payload = { title, description }
    updatePostSliders(id, payload).then((res) => {
      console.log("res:", res)
      getAllParentSliders()
    }).catch((e) => {
      console.log("e:", e)
    })
  }





  return (
    <React.Fragment>
      <div className="app-main" style={{ flex: "0", flexDirection: "column" }}>
        <Sidebar active="cms" />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <div className="page-title-icon">
                    <i className="pe-7s-display2 icon-gradient bg-mean-fruit"></i>
                  </div>
                  <div>Content Management System
                    <div className="page-title-subheading">This is an example dashboard created using build-in elements and components.</div>
                  </div>
                </div>
                <div className="page-title-actions">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "88%", marginLeft: "295px" }}>
          <div className="d-flex justify-content-between px-4 bg-light py-4">
            <div className="sliders">
              <h5 className="font-weight-bold">Sliders</h5>
            </div>
            <div className="addButton">
              <button className="btn btn-primary" onClick={() => setVisible(true)} >
                <i className="fa fa-plus">
                </i> &nbsp; New Slider</button>
            </div>
          </div>
          <div className="main-card mb-3 card" >
            <div className="card-body">
              <div className="row">
                <div className="col-md-1 ">
                  <div className="input-checkbox my-2" style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "5px",
                    padding: "0 5px",
                    width: "17%",
                    height: "30px"

                  }} >
                    <div className="checkbox">
                      <label className="checkbox-bootstrap checkbox-lg">
                        <input type="checkbox" />
                        <span className="checkbox-placeholder" />
                      </label>
                    </div>

                  </div>
                </div>

                <div className="col-md-2">
                  <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback" />
                    <input type="text" className="form-control" placeholder="Search" />
                  </div>
                </div>
                <div className="col-md-7"></div>
                <div className="col-md-1">
                  {/* date created button with dropdown */}
                  <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Date Created
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="mb-0 table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Slider Name</th>
                      <th>
                        Description
                      </th>
                      <th>Number of Slides</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sliders.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              id="checkbox1"
                              name="checkbox1"
                              value="option1"

                              //if checkbox is unchecked then false the isActive
                              onChange={() => {
                                if (item.isActive == true) {
                                  item.isActive = false
                                }
                                else {
                                  item.isActive = true
                                }
                                console.log("item", item)
                              }} checked={
                                item.isActive
                              }

                            />
                          </td>
                          <td>
                            <a
                              href={`
                              sliders/${item.id}

                              `}
                              className="text-primary"
                            >
                              {item.title}
                            </a>

                          </td>
                          <td>{item.description}</td>
                          <td>3</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => {
                                setVisible(true)
                                setUpdate(true)
                                setTitle(item.title)
                                setDescription(item.description)
                                setId(item.id)
                              }}

                            >Edit</button>&nbsp;
                            <button
                              onClick={() => handleDelete(item.id)}
                              className="btn btn-danger"
                            >Delete</button>

                          </td>

                        </tr>
                      )
                    })}
                  </tbody>


                  {/* <tbody>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          id="checkbox1"
                          name="checkbox1"
                          value="option1"
                        />

                      </td>

                      <td>
                        <a
                          href="sliders"
                          className="text-primary"
                        >
                          Home Slider
                        </a>

                      </td>
                      <td>home</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          id="checkbox1"
                          name="checkbox1"
                          value="option1"
                        />
                      </td>
                      <td>
                        <a
                          href="sliders"
                          className="text-primary"
                        >
                          Services Slider
                        </a>
                      </td>
                      <td>services</td>
                      <td>3</td>

                    </tr>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          id="checkbox1"
                          name="checkbox1"
                          value="option1"
                        />

                      </td>
                      <td>
                        <a
                          href="sliders"
                          className="text-primary"
                        >
                          About Slider
                        </a>
                      </td>
                      <td>about</td>
                      <td>3</td>
                    </tr>
                  </tbody> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog header="" visible={visible} style={{ width: '20vw' }} onHide={() => setVisible(false)}>
        <div className="form-group">
          <label htmlFor="sliderName">Title</label>
          <input type="text" className="form-control" id="sliderName" placeholder="Enter Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="sliderHandle">Description</label>
          <input type="text" className="form-control" id="sliderHandle" placeholder="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        {
          update ?
            <button className="btn btn-primary"
              onClick={
                () => {
                  handleUpdate(id)
                  setVisible(false)
                }
              }
            > Update</button>
            :
            <button className="btn btn-primary"
              onClick={handleSubmit}
            > Save</button>
        }
      </Dialog>
    </React.Fragment>
  )
}

export default CMS