import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/sidebar';
import moment from 'moment';
import { useFormik } from 'formik';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../components/formComponents/input';
import Card from '../../components/card';
import Buttons from "../../components/formComponents/button";
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { getBookingInvitationByIdApi } from '../../services/InviteGuest/getInvitationById';
import { getPropertyPolicyByPropertyIdApi } from '../../services/properties/getProperty';
import { getPropertyPricePolicyApi } from '../../services/properties/getPropertyPricePolicy';
import { getBookingByIdApi } from '../../services/bookings/getBooking';
import InfoBar from '../../components/infobar';
import { Box } from '@mui/system';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Button } from "primereact/button";
import { Rating, Typography } from '@mui/material';
import Header from '../../components/header';
import Swal from "sweetalert2";

import {
  editPropertyCalenderDateByRange,
  getPropertyCalenderPriceApi,
} from '../../services/properties/propertyCalenderPrice';
import { Toast } from 'primereact/toast';
const BookingCalendar = () => {
  // const { enqueueSnackbar } = useSnackbar();
  const localizer = momentLocalizer(moment);
  const { id } = useParams();
  const [calendarData, setCalendarData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [currentEvent, setCurrentEvent] = useState();
  const [guestInvitation, setGuestInvitation] = useState({});
  const [propertyPolicy, setPropertyPolicy] = useState({});
  const [propertyPricePolicy, setPropertyPricePolicy] = useState({});
  const [disabledDates, setDisabledDates] = useState([]);

  const params = window.location.search;
  const title = new URLSearchParams(params).get('title');
  const code = new URLSearchParams(params).get('code');

  const formik = useFormik({
    
    initialValues: {
      price: String(currentEvent?.price),
      startDate: moment(currentEvent?.start).format('YYYY-MM-DD'),
      endDate: moment(currentEvent?.start).format('YYYY-MM-DD'),
      privateNote: currentEvent?.privateNote,
      propertyId: id,
      bookingId: currentEvent?.bookingId,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log("=============>formik")
      try {
        const data = {
          price: values.price,
          isAvailable: isAvailable,
          startDate: moment(values.startDate).format('YYYY-MM-DD'),
          endDate: moment(values.endDate).format('YYYY-MM-DD'),
          privateNote: values.privateNote,
          propertyId: values.propertyId,
          bookingId: values.bookingId,
        };

        setIsButtonLoading(true);
        const response = await editPropertyCalenderDateByRange(data);
        if (response.status === 200) {
          getCalendarData();
          setIsButtonLoading(false);
          setShowModal(false);
          setCurrentEvent({
            ...currentEvent,
            privateNote: '',
          });
          Swal.fire({
            position: "center",
            icon: "success",
            text: "Start date and End date of property updated.",
            color: "black",
            showConfirmButton: false,
            timer: 2000,
          });
          // enqueueSnackbar('Start date and End date of property updated.', {
          //     variant: 'success',
          //     anchorOrigin: {
          //         vertical: 'bottom',
          //         horizontal: 'right',
          //     },
          // });
        }
      } catch (err) {
        setIsButtonLoading(false);
        setShowModal(false);
        Swal.fire({
          position: "center",
          icon: "error",
          text: "Failed to edit property",
          color: "black",
          showConfirmButton: false,
          timer: 2000,
        });
        
        // enqueueSnackbar('Failed to edit property', {
        //     variant: 'error',
        //     anchorOrigin: {
        //         vertical: 'bottom',
        //         horizontal: 'right',
        //     },
        // });
      }
    },
  });

  const getPropertyPolicy = async () => {
    let res = await getPropertyPolicyByPropertyIdApi(id);
    setPropertyPolicy(res.data.data);
  };
  const getPropertyPricePolicy = async () => {
    let res = await getPropertyPricePolicyApi(id);
    setPropertyPricePolicy(res);
  };
  const getCalendarData = async () => {
    const response = await getPropertyCalenderPriceApi(id);
    if (response.status === 200) {
      filterCalendarData(response?.data?.data);
    }
  };
  const today = moment().format('YYYY-MM-DD');

  //filtering object that would be passed to calendar obj
  const filterCalendarData = (data) => {
    let values = data;
    let array = [];
    values?.forEach((value) => {
      let data = {
        isBooked: value?.isBooked,
        title: 'Rs ' + value.price,
        start: new Date(value?.createdDate),
        end: new Date(value?.createdDate),
        isAvailable: value?.isAvailable,
        isTentative: value?.isTentative,
        bookingId: value?.bookingId,
        privateNote:
          value?.privateNote === undefined ? null : value?.privateNote,
        price: value.price,
        guestInvitationId: value?.guestInvitationId,
        ...(value?.isBooked || value?.isTentative
          ? { booking: value?.booking }
          : ''),
      };
      if (data?.isBooked && !data?.isTentative) {
        data.title = '';
      }
      if (
        moment(data?.start).format('ddd') === 'Sat' ||
        moment(data?.start).format('ddd') === 'Sun'
      ) {
        if (!(propertyPricePolicy?.weekendNightPrice === 0)) {
          data.title = `Rs.${propertyPricePolicy?.weekendNightPrice}`;
        }
      }
      array.push(data);
    });
    setCalendarData(array);
  };

  useEffect(() => {
    getPropertyPolicy();
    getPropertyPricePolicy();
  }, []);


  useEffect(() => {
    getCalendarData();
  }, [propertyPricePolicy]);

  const checkIsAvailableStatus = (e) => {
    if (disabledDates.includes(moment(e.start).format('YYYY-MM-DD'))) {
      var newstyle = {
        color: 'gray',
        display: 'block',
        cursor: 'not-allowed',
        opacity: '0.5',
      };
      return {
        style: newstyle,
      };
    }
    if (moment(e.start, 'YYYY-MM-DD').isBefore(today)) {
      var estyle = {
        color: 'gray',
        display: 'block',
        cursor: 'not-allowed',
        opacity: '0.5',
      };
      return {
        style: estyle,
      };
    } else if (e.isBooked === true && e.isTentative === false) {
      var bstyle = {
        color: 'green',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background:
          'linear-gradient(180deg, rgba(0,0,0,0) calc(25% - 1px), green calc(25%), rgba(0,0,0,0) calc(25% + 1px))',
      };
      return {
        style: bstyle,
      };
    } else if (e.isTentative === true && e.isBooked === false) {
      var tstyle = {
        color: 'yellow',
        display: 'block',
      };
      return {
        style: tstyle,
      };
    } else if (e.isAvailable === false) {
      var style = {
        color: 'red',
        display: 'block',
        textDecoration: 'line-through',
      };
      return {
        style: style,
      };
    } else {
      var dstyle = {
        color: 'white',
        display: 'block',
      };
      return {
        style: dstyle,
      };
    }
  };
  const onEventClick = (event) => {
    if (moment(event.start, 'YYYY-MM-DD').isBefore(today)) {
      return false;
    }

    if (disabledDates.includes(moment(event.start).format('YYYY-MM-DD'))) {
      return false;
    }
    let price = event?.title;
    if (event.privateNote == null) {
      setCurrentEvent({
        ...event,
        privateNote: '',
      });
    } else {
      setCurrentEvent(event);
    }
    price?.slice(0, -3);
    formik.initialValues.forDate = event?.start;
    formik.initialValues.privateNote = event?.privateNote;
    formik.initialValues.price = parseInt(price);
    setIsAvailable(event?.isAvailable);
    setShowModal(true);
  };
  const navigate = useNavigate();
  const [booking, setBooking] = useState({});
  const getBooking = async () => {
    const response = await getBookingByIdApi(formik.values.bookingId);
    setBooking(response);
  };

  useEffect(() => {
    if (formik.values.bookingId) {
      getBooking();
    }
  }, [formik.values.bookingId]);
  React.useEffect(() => {
    if (currentEvent?.guestInvitationId) {
      const getBookingInvitation = async () => {
        await getBookingInvitationByIdApi(currentEvent?.guestInvitationId).then(
          (res) => setGuestInvitation(res.data)
        );
      };
      getBookingInvitation();
    }
  }, [currentEvent]);

  React.useEffect(() => {
    let dds = [...disabledDates];
    let pushToDisabledDatesArray = (date) => {
      dds.push(moment(date).format('YYYY-MM-DD'));
    };
    setDisabledDates(dds);
    calendarData?.forEach((data) => {
      if (
        (data.isBooked === true || data.isTentative === true) &&
        moment(data.start).format('YYYY-MM-DD') ===
        moment(data?.booking?.startDate).format('YYYY-MM-DD')
      ) {
        //Advance Notice
        if (propertyPolicy?.advanceNotice === "At least two days' notice") {
          let endDate = moment(data?.booking?.endDate);
          let newDate = endDate.clone().add(1, 'day');
          pushToDisabledDatesArray(new Date(newDate.format('M/D/YYYY')));
        } else if (
          propertyPolicy?.advanceNotice === "At least three days' notice"
        ) {
          let endDate = moment(data?.booking?.endDate);
          let oneDayAfterEndDate = endDate.clone().add(1, 'day');
          let twoDayAfterEndDate = endDate.clone().add(2, 'day');
          pushToDisabledDatesArray(
            new Date(oneDayAfterEndDate.format('M/D/YYYY'))
          );
          pushToDisabledDatesArray(
            new Date(twoDayAfterEndDate.format('M/D/YYYY'))
          );
        } else if (
          propertyPolicy?.advanceNotice === "At least seven days' notice"
        ) {
          let endDate = moment(data?.booking?.endDate);
          let oneDayAfterEndDate = endDate.clone().add(1, 'day');
          let twoDayAfterEndDate = endDate.clone().add(2, 'day');
          let threeDayAfterEndDate = endDate.clone().add(3, 'day');
          let fourDayAfterEndDate = endDate.clone().add(4, 'day');
          let fiveDayAfterEndDate = endDate.clone().add(5, 'day');
          let sixDayAfterEndDate = endDate.clone().add(6, 'day');

          pushToDisabledDatesArray(
            new Date(oneDayAfterEndDate.format('M/D/YYYY'))
          );
          pushToDisabledDatesArray(
            new Date(twoDayAfterEndDate.format('M/D/YYYY'))
          );
          pushToDisabledDatesArray(
            new Date(threeDayAfterEndDate.format('M/D/YYYY'))
          );
          pushToDisabledDatesArray(
            new Date(fourDayAfterEndDate.format('M/D/YYYY'))
          );
          pushToDisabledDatesArray(
            new Date(fiveDayAfterEndDate.format('M/D/YYYY'))
          );
          pushToDisabledDatesArray(
            new Date(sixDayAfterEndDate.format('M/D/YYYY'))
          );
        }

        // PreparationTime
        if (
          propertyPolicy?.preparationTime ===
          'Book one night before and after each reservation.'
        ) {
          let startDate = moment(data?.booking?.startDate);
          let endDate = moment(data?.booking?.endDate);
          let oneDayBeforeStartDate = startDate.clone().subtract(1, 'day');
          let oneDayAfterEndDate = endDate.clone().add(1, 'day');
          console.log(oneDayBeforeStartDate);
          pushToDisabledDatesArray(
            new Date(oneDayBeforeStartDate.format('M/D/YYYY'))
          );
          pushToDisabledDatesArray(
            new Date(oneDayAfterEndDate.format('M/D/YYYY'))
          );
        } else if (
          propertyPolicy?.preparationTime ===
          'Book two nights before and after each reservation.'
        ) {
          let startDate = moment(data?.booking?.startDate);
          let endDate = moment(data?.booking?.endDate);
          let oneDayBeforeStartDate = startDate.clone().subtract(1, 'day');
          let oneDayAfterEndDate = endDate.clone().add(1, 'day');
          let twoDayBeforeStartDate = startDate.clone().subtract(2, 'days');
          let twoDayAfterEndDate = endDate.clone().add(2, 'days');
          pushToDisabledDatesArray(
            new Date(oneDayAfterEndDate.format('M/D/YYYY'))
          );
          pushToDisabledDatesArray(
            new Date(oneDayBeforeStartDate.format('M/D/YYYY'))
          );
          pushToDisabledDatesArray(
            new Date(twoDayBeforeStartDate.format('M/D/YYYY'))
          );
          pushToDisabledDatesArray(
            new Date(twoDayAfterEndDate.format('M/D/YYYY'))
          );
        }
      }
    }, []);
  }, [calendarData, propertyPolicy]);

  return (
    <SnackbarProvider max={4}>
    <>
      <Toast  position="bottom-right" />
      <Header />

      <div className="dashboard-content">
        {currentEvent &&
          !currentEvent?.isBooked &&
          !currentEvent?.isTentative && (
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: '5rem',
                padding: '16px',
                width: '24%',
              }}
            >
              <Box>
                <Typography sx={{ color: 'black', fontSize: '26px' }}>
                  Modify Dates
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ color: 'black', fontSize: '22px' }}>
                  {moment(currentEvent?.start).format('DD MMM YYYY')}
                </Typography>
              </Box>
              <Box sx={{ margin: '15px 0px' }}>
                <p
                  style={{ textAlign: 'left', color: 'black', fontSize: 14 }}
                  htmlFor='minmax'
                >
                  Start Date
                </p>
                <Calendar
                  variant='outlined'
                  style={{
                    width: '100%',
                    height: 50,
                    marginBottom: 10,
                  }}
                  id={'startDate'}
                  name={'startDate'}
                  placeholder='Enter Date'
                  value={new Date(formik.values.startDate)}
                  defaultValue={new Date(formik.values.startDate)}
                  onChange={formik.handleChange}
                />
                <p
                  style={{ textAlign: 'left', color: 'black', fontSize: 14 }}
                  htmlFor='minmax'
                >
                  End Date
                </p>
                <Calendar
                  variant='outlined'
                  style={{
                    width: '100%',
                    height: 50,
                    marginBottom: 10,
                  }}
                  id={'endDate'}
                  name={'endDate'}
                  placeholder='Enter Date'
                  value={new Date(formik.values.endDate)}
                  defaultValue={new Date(formik.values.endDate)}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box sx={{ margin: '18px 0px' }}>
                <p
                  style={{
                    textAlign: 'left',
                    color: 'black',
                    fontSize: 14,
                    marginTop: 10,
                  }}
                  htmlFor='minmax'
                >
                  Availability Status
                </p>
                <div className='col-lg-12'>
                  <RadioButton
                    name='isAvailable'
                    checked={isAvailable === true ? true : false}
                    value={isAvailable}
                    onChange={() => setIsAvailable(true)}
                  />
                  <label
                    style={{ marginLeft: 10, marginRight: 20, color: 'black' }}
                  >
                    {'Available'}
                  </label>
                  <RadioButton
                    name='isAvailable'
                    checked={isAvailable === false ? true : false}
                    value={isAvailable}
                    onChange={() => setIsAvailable(false)}
                  />
                  <label style={{ marginLeft: 10, color: 'black' }}>
                    {'Not Available'}
                  </label>
                </div>
              </Box>
              <Box sx={{ margin: '15px 0px' }}>
                <p
                  style={{ textAlign: 'left', color: 'black', fontSize: 14 }}
                  htmlFor='minmax'
                >
                  Price
                </p>
                <Input
                  id={'price'}
                  name={'price'}
                  label={'Enter Price'}
                  onChange={formik.handleChange}
                  value={formik.values.price ? formik.values.price : ''}
                  defaultValue={
                    currentEvent?.price ? currentEvent?.price : ''
                  }
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                  disabled={currentEvent?.isTentative ? true : false}
                  showIcon
                />
              </Box>
              <Box sx={{ margin: '15px 0px' }}>
                <p
                  style={{
                    textAlign: 'left',
                    color: 'black',
                    fontSize: 14,
                    marginTop: 10,
                  }}
                  htmlFor='minmax'
                >
                  Private Note
                </p>
                <Input
                  id={'privateNote'}
                  name={'privateNote'}
                  label={'Enter Private Note'}
                  style={{ height: '200px' }}
                  onChange={formik.handleChange}
                  value={formik.values.privateNote}
                  defaultValue={formik.values.privateNote}
                />
              </Box>

              <Box>
                
              <Button
                onClick={formik.handleSubmit}
                
                    label={'Save'}
                    isLoading={isButtonLoading}
                    loadingTitle={'Saving'}
                    style={{width:"100%"}}

                className="p-outline-sm"
                
                
              />


              </Box>

              <Box>
                <Typography
                  sx={{
                    color: 'black',
                    fontSize: '18px',
                    margin: '25px 0px',
                    padding: '18px 0px',
                    cursor: 'pointer',
                    borderTop: '1px solid gray',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => navigate(`/admin/invite-guest/${id}`)}
                >
                  <GroupAddIcon sx={{ marginRight: '8px' }} /> Invite Guest
                </Typography>
              </Box>
            </Box>
          )}
        {(currentEvent && currentEvent?.isBooked) ||
          (currentEvent && currentEvent?.isTentative) ? (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: '5rem',
              padding: '16px',
              width: '24%',
            }}
          >
            <Card data={booking} ></Card>
          </Box>
        ) : null}

        

        <Card>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                height: '12px',
                width: '12px',
                background: 'yellow',
              }}
            ></div>
            <span
              style={{
                marginLeft: '4px',
                color: '#fff',
                fontSize: '16px',
                color: "black"
              }}
            >
              {' '}
              Is Tentative
            </span>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                height: '12px',
                width: '12px',
                background: 'red',

              }}
            ></div>
            <span
              style={{
                marginLeft: '4px',
                color: '#fff',
                fontSize: '16px',
                color: "black"
              }}
            >
              {' '}
              Not Available
            </span>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                height: '12px',
                width: '12px',
                background: 'green',
              }}
            ></div>
            <span
              style={{
                marginLeft: '4px',
                color: '#fff',
                fontSize: '16px',
                color: "black"
              }}
            >
              {' '}
              Booked
            </span>
          </Box>

        </Card>


        <BigCalendar
          views={['month']}
          selectable={true}
          defaultView='month'
          localizer={localizer}
          events={calendarData}
          startAccessor='start'
          defaultDate={new Date()}
          style={{ height: '90vh' }}
          onSelectEvent={(event) => onEventClick(event)}
          eventPropGetter={(event) => checkIsAvailableStatus(event)
          }
        />

      </div>
    </>
      </SnackbarProvider>
  );
};

export default BookingCalendar;
