import React, { useState, useRef, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import {
  updatePropertyPricePolicyById,
  createPropertyPricePolicy,
} from "./../../services/properties/index";

const PricingSection = ({ propertyDetails, propertyPricePolicy }) => {

  const toast = useRef(null);
  const [isLoadingPricePolicy, setIsLoadingPricePolicy] = useState(false);
  let token = JSON.parse(localStorage.getItem("token"));

  const [inputVal, setInputVal] = useState({
    price: "",
    nightPrice: "",
    weeklyNightPrice: "",
    monthlyNightPrice: "",
    cleaningFee: "",
    petFee: "",
    stayDiscount: "",
    extraGuestFee: "",
    weekendNightPrice: "",
    minStay: "",
    maxStay: "",
  });

  // propertyPricePolicy & propertyDetails api giving response data as object.

  useEffect(() => {
    setInputVal({
      price: propertyDetails.price,
      nightPrice: propertyDetails?.perNightPrice,
      weeklyNightPrice: propertyPricePolicy?.weeklyNightPrice,
      monthlyNightPrice: propertyPricePolicy?.monthlyNightPrice,
      cleaningFee: propertyPricePolicy?.cleaningFee,
      petFee: propertyPricePolicy?.petFee,
      stayDiscount: propertyPricePolicy?.customLengthOfStayDiscount,
      extraGuestFee: propertyPricePolicy?.extraGuestFee,
      weekendNightPrice: propertyPricePolicy?.weekendNightPrice,
      minStay: propertyPricePolicy?.minimumStay,
      maxStay: propertyPricePolicy?.maximumStay
    });
  }, [propertyPricePolicy, propertyDetails]);

  const handleChange = (e) => {
    setInputVal({ ...inputVal, [e.target.name]: e.target.value });
  };

  const handleUpdatePricePolicy = () => {

    let payload = {
      propertyId: propertyDetails.id,
      weeklyNightPrice: inputVal.weeklyNightPrice,
      monthlyNightPrice: inputVal.monthlyNightPrice,
      customLengthOfStayDiscount: inputVal.stayDiscount,
      cleaningFee: inputVal.cleaningFee,
      petFee: inputVal.petFee,
      extraGuestFee: inputVal.extraGuestFee,
      weekendNightPrice: inputVal.weekendNightPrice,
      minimumStay: inputVal.minStay,
      maximumStay: inputVal.maxStay,
    };

    setIsLoadingPricePolicy(true);

    createPropertyPricePolicy(payload, token).then((res) => {

      setIsLoadingPricePolicy(false);

      if (res.status !== 200) {
        toast.current.show({
          severity: "error",
            summary: "Error",
            detail: res.data.message,
            life: 3000,
        });
      } else {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Price Policy Updated Successfully",
          life: 3000,
        });
      }
    });

  };

  return (
    <div className="col-lg-12">
      <Toast ref={toast} position="bottom-right" />
      <Accordion activeIndex={5}>
        <AccordionTab header="Pricing">
          <h5 style={{ marginTop: "15px" }}>Pricing Configuration</h5>
          <div className="row">
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Price</h6>
              <InputText
                value={inputVal.price}
                placeholder="Price"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="price"
                type="number"
              />
            </div>
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Per Night Price</h6>
              <InputText
                value={inputVal.nightPrice}
                placeholder="Per Night Price"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="nightPrice"
                type="number"
              />
            </div>
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Weekly Night Price</h6>
              <InputText
                value={inputVal.weeklyNightPrice}
                placeholder="Weekly Night Price"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="weeklyNightPrice"
                type="number"
              />
            </div>
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Monthly Night Price</h6>
              <InputText
                value={inputVal.monthlyNightPrice}
                placeholder="Monthly Night Price"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="monthlyNightPrice"
                type="number"
              />
            </div>
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Stay Discount</h6>
              <InputText
                value={inputVal.stayDiscount}
                placeholder="Stay Discount"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="stayDiscount"
                type="number"
              />
            </div>
            <div className="col-lg-12">
              <h5 style={{ marginTop: "15px" }}>Additional Charges</h5>
            </div>
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Cleaning Fee</h6>
              <InputText
                value={inputVal.cleaningFee}
                placeholder="Cleaning Fee"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="cleaningFee"
                type="number"
              />
            </div>
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Pet Fee</h6>
              <InputText
                value={inputVal.petFee}
                placeholder="Pet Fee"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="petFee"
                type="number"
              />
            </div>
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Extra Guest Fee</h6>
              <InputText
                value={inputVal.extraGuestFee}
                placeholder="Extra Guest Fee"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="extraGuestFee"
                type="number"
              />
            </div>
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Weekend Night Price</h6>
              <InputText
                value={inputVal.weekendNightPrice}
                placeholder="Weekend Night Price"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="weekendNightPrice"
                type="number"
              />
            </div>

            <div className="col-lg-12">
              <h5 style={{ marginTop: "15px" }}>Trip Length</h5>
            </div>

            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Minumum Stay</h6>
              <InputText
                value={inputVal.minStay}
                placeholder="Minimum Stay"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="minStay"
                type="number"
              />
            </div>
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Maximum Stay</h6>
              <InputText
                value={inputVal.maxStay}
                placeholder="Maximum Stay"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="maxStay"
                type="number"
              />
            </div>
          </div>
          <div style={{ margin: "10px 0", textAlign: "right" }}>
            {isLoadingPricePolicy ? (
              <ProgressSpinner />
            ) : (
              <Button
                label={"Save Price Policy"}
                className="p-button-lg"
                style={{ fontSize: "12px", padding: "10px 8px" }}
                onClick={handleUpdatePricePolicy}
              />
            )}
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default PricingSection;
