import { createSlice } from "@reduxjs/toolkit";

const initialState={
   userList:{},
   userId:"",
   userEditData:{}
    
}

 const userListSlice = createSlice({
    name: "type",
    initialState,
    reducers: {
      fetchUser: (state,{payload}) => {
       state.userList=payload
      },
      storeId: (state,{payload}) => {
         state.userId=payload
        },
        storeEditData:(state,{payload})=>{
         state.userEditData=payload
        }
        
        
      
    }
   });
   export const { fetchUser,storeId,storeEditData } = userListSlice.actions;
   export default userListSlice.reducer