import Api from '../index';
import { endPoints, requestType } from '../../constants/variables';
import { API_URL } from '../../config';
import axios from 'axios';

export const getAllProperties = (page, limit) => {
  return Api(
    `${endPoints.allProperties}?page=${page}&limit=${limit}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getPropertyPricePolicyApi = async (id) => {
  try {
    const response = await axios.get(
      API_URL + `property_price_policy/all?propertyId=${id}`
    );
    return response.data.data;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of property price policy: ', response);
      return response;
    }
  }
};

export const getAllFilterproperties = (status) => {
  return Api(
    `${endPoints.propertyInfos}?status=${status}`,
    null,
    requestType.GET,
    null,
    null
  );
};
export const getPropertyPolicyByPropertyIdApi = async (id) => {
  try {
    const response = await axios.get(
      API_URL +
      `property-policy/getPropertyPolicyByPropertyId/{propertyId}?propertyId=${id}`
    );
    return response;
  } catch (error) {
    if (error) {
      const { response } = error;
      console.log('Bad Response of properties : ', response);
      return response;
    }
  }
};

export const getPropertyInfos = (
  page,
  limit,
  sortBy,
  code,
  country,
  state,
  city,
  propertyType,
  recommended,
  inspiredDestination,
  minPrice,
  maxPrice,
  areaId,
  adults,
  beds,
  bedrooms,
  pets,
  infants,
  spaceId,
  spaceType,
  userId,
  status,
  booking,
  floodRelief,
  propAmenityId,
  childrenVal
) => {
  return Api(
    `${endPoints.propertyInfos}?page=${page}&limit=${limit}${sortBy}${code}${country}${state}${city}${propertyType}${recommended}${inspiredDestination}${minPrice}${maxPrice}${areaId}${adults}${beds}${bedrooms}${pets}${infants}${spaceId}${spaceId}${spaceType}${userId}${status}${booking}${floodRelief}${propAmenityId}${childrenVal}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getAllPropertiesById = (id, page, limit) => {
  return Api(
    `${endPoints.allProperties}?userId=${id}&page=${page}&limit=${limit}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getPropertyById = (id) => {
  return Api(
    `${endPoints.propertyByid}/${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getPropertyRatingByGuest = (page, id) => {
  return Api(
    `${endPoints.ratingByGuest}?page=${page}&property_id=${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const updatePropStatus = (params) => {
  return Api(
    `${endPoints.updatePropertyStatus}/${params?.property_id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};

export const addProperty = (params, token) => {
  return Api(
    `${endPoints.propertyByid}`,
    params,
    requestType.POST,
    true,
    token
  );
};

export const updatePropertyById = (id, params, token) => {
  return Api(
    `${endPoints.updateProperty}/${id}`,
    params,
    requestType.POST,
    true,
    token
  );
};

export const updatePhotoAndVideoUrl = (id, params, token) => {
  return Api(
    `${endPoints.updatePropertyPhotoAndVideo}/${id}`,
    params,
    requestType.PUT,
    true,
    token
  );
};

export const getAllAmenitiesByPropertyId = (id) => {
  return Api(
    `${endPoints.getAllAmenitiesOfProperty}/${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const createPropertyAmenities = (params, token) => {
  return Api(
    `${endPoints.createPropertyAmenity}`,
    params,
    requestType.POST,
    false,
    token
  );
  
};

export const updatePropertyListingBasicById = (id, params, token) => {
  return Api(
    `${endPoints.updatePropertyListingBasic}/${id}`,
    params,
    requestType.PUT,
    false,
    token
  );
};
export const updatePropertyCapacityBasicById = (id, params) => {
  return Api(
    `${endPoints.updatePropertyCapacityBasic}/${id}`,
    params,
    requestType.PUT,
    false,
    null
  );
};

export const updatePropertyPricePolicyById = (id, params) => {
  return Api(
    `${endPoints.updatePropertyPricePolicy}/${id}`,
    params,
    requestType.PUT,
    false,
    null
  );
};

export const createPropertyPricePolicy = (params, token) => {
  return Api(
    `${endPoints.createPropertyPricePolicy}`,
    params,
    requestType.POST,
    false,
    token
  );
};

export const getPropertySafetyConsiderationByid = (id, token) => {
  return Api(
    `${endPoints.propertySafetyConsideration}/${id}`,
    null,
    requestType.GET,
    false,
    token
  );
};

export const updateGuestInfoById = (id, params, token) => {
  return Api(
    `${endPoints.updateGuestInfo}/${id}`,
    params,
    requestType.PUT,
    false,
    token
  );
};

export const getAllPolicies = () => {
  return Api(
    `${endPoints.cancellationPolicy}?order=ASC`,
    null,
    requestType.GET,
    false,
    null
  );
};

export const updatePropertyPolicyById = (id, params) => {
  return Api(
    `${endPoints.updatePolicyProperty}/${id}`,
    params,
    requestType.PUT,
    false,
    null
  );
};

export const createPropertySafetyConsideration = (params) => {
  return Api(
    `${endPoints.createPropertySafetyConsideration}`,
    params,
    requestType.POST,
    false,
    null
  );
};

export const getPropertyAccessibility = (id, token) => {
  return Api(
    `${endPoints.propertyAccessibilty}/${id}`,
    null,
    requestType.GET,
    false,
    token
  );
};

export const getPropertyFacility = (id, token) => {
  return Api(
    `${endPoints.propertyFacility}/${id}`,
    null,
    requestType.GET,
    false,
    token
  );
};

export const getPropertySecurity = (id, token) => {
  return Api(
    `${endPoints.propertySecurity}/${id}`,
    null,
    requestType.GET,
    false,
    token
  );
};

export const getPropertyNearby = (id, token) => {
  return Api(
    `${endPoints.propertyNearbyActivity}/${id}`,
    null,
    requestType.GET,
    false,
    token
  );
};

export const getPropertySafetyFeature = (id, token) => {
  return Api(
    `${endPoints.propertySafetyFeature}/${id}`,
    null,
    requestType.GET,
    false,
    token
  );
};

export const getPropertyCalenderPriceApi = (id) => {
  return Api(
    `${endPoints.propertyPriceAll}?propertyId=${id}&startDate=01-01-2022&endDate=12-31-2022`,
    null,
    requestType.GET,
    false,
    null
  );
};
export const getPropertyCalenderPriceById = (id) => {
  return Api(
    `${endPoints.propertyPriceById}?propertyId=${id}`,
    null,
    requestType.GET,
    false,
    null
  );
};
export const editPropertyCalenderDateByRange = (params) => {
  return Api(
    `${endPoints.propertyPriceUpdateByRange}`,
    params,
    requestType.POST,
    false,
    null
  );
};
export const postNoteByPropertyId= (payLoad) => {
  return Api(
    `${endPoints.postNoteByPropertyId}`,
    payLoad,
    requestType.POST,
    
   
  );

};
export const getPropertyNoteById = (id) => {
  return Api(
    `${endPoints.getNoteByPropertyId}/${id}`,
    null,
    requestType.GET,
    false,
    null
  );
};
