import React, { useState, useEffect } from "react";
import Banner from "../../components/banner";
import Sidebar from "../../components/sidebar";
import { getTransactionHistoryById } from "./../../services/wallet";
import { useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import moment from "moment";
import CrudDataTable from "../../components/crudDataTable";
import Card from "../../components/card";
import { priceBodyTemplate } from "../../constants/utils";

const WalletDetails = () => {
  let { id } = useParams();
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);
    getTransactionHistoryById(id)
      .then((res) => {
        setIsLoading(false);
        setTransactionHistory(res.data.data[0]);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const createdOnTemplate = (rowData) => {
    return (
      <span>{moment(rowData?.createdOn).format("DD MMM YYYY hh:mm:ss")}</span>
    );
  };

  const DATA = [
    { id: 1, field: "id", header: "Id" },
    { id: 2, field: "description", header: "Description" },
    {
      id: 3,
      field: "previousAmount",
      header: "Previous Amount",
      body: (e) => priceBodyTemplate(e.previousAmount),
    },
    {
      id: 4,
      field: "newAmount",
      header: "New Amount",
      body: (e) => priceBodyTemplate(e.newAmount),
    },
    {
      id: 5,
      field: "difference",
      header: "Difference",
      body: (e) => priceBodyTemplate(e.difference),
    },
    { id: 6, field: "transactionType", header: "Transaction Type" },
    {
      id: 7,
      field: "createdOn",
      header: "Created On",
      body: createdOnTemplate,
    },
  ];

  return (
    <div className="app-main">
      <Sidebar active="wallet" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="Transaction History - Staywo"
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />
          <div className="row">
            <div className="col-lg-12">
              <Card title="Wallet Details">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={DATA}
                    data={transactionHistory}
                    showActions={false}
                    showView={false}
                    showToolbar={false}
                    showDelete={true}
                    showEdit={false}
                    sortable={false}
                  />
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletDetails;
