import React from "react";

const Upload = ({ handleChange, label, disabled, multiple }) => {
  return (
    <>
      <div className="image-uploder-container">
        <label className="image-uploader-label">
          <label className="image-uploader-sub-label">
            <i className="fa fa-camera" style={{ marginRight: "10px" }}></i>
            {label}
            <input
              type="file"
              accept="image/*"
              name="fileUpload"
              placeholder="Upload"
              multiple={multiple ? "multiple" : false}
              onChange={handleChange}
              value=""
              style={{ display: "none" }}
              disabled={disabled}
            />
          </label>
        </label>
      </div>
    </>
  );
};

export default Upload;
