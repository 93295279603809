import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllBookings = (page, limit, status, fromDate, toDate) => {
  return Api(
    `${endPoints.bookingAll}?page=${page}&limit=${limit}${status}${fromDate}${toDate}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getBookingByOwnerId = (id, page, limit, status) => {
  return Api(
    `${endPoints.bookingAll}?${id}&page=${page}&limit=${limit}&bookingStatus=${status}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getBookingByGuestId = (id, page, limit, status) => {
  return Api(
    `${endPoints.bookingAll}?${id}&page=${page}&limit=${limit}&bookingStatus=${status}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getBookingByBookingId = (id) => {
  return Api(
    `${endPoints.bookingByBookingId}/${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getBookingCancellation = (id) => {
  return Api(
    `${endPoints.getBookingCancellation}/${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getBookingUpdateRequest = (id, token) => {
  return Api(
    `${endPoints.getBookingUpdateRequest}/${id}`,
    null,
    requestType.GET,
    null,
    token
  );
};

export const getBookingRating = (id) => {
  return Api(
    `${endPoints.getBookingRating}/${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const getBookingInvoice = (id) => {
  return Api(
    `${endPoints.getBookingInvoice}/${id}`,
    null,
    requestType.POST,
    null,
    null
  );
};

export const addUserBooking = (params) => {
  return Api(
    `${endPoints.createBooking}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const calculateBookingPrice = (params) => {
  return Api(
    `${endPoints.bookingPriceCalc}`,
    params,
    requestType.POST,
    null,
    null
  );
};
