import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// prime react
import { Calendar } from 'primereact/calendar';
import { getPropertyCalenderPriceApi } from '../../services/properties/propertyCalenderPrice';
import moment from 'moment';
import { getPropertyRestrictedDaysApi } from '../../services/RestrictedDays/getPropertyRestrictedDays';
import { Button } from '@mui/material';

//Simple date picker component
const DatePickerInviteGuest = ({
  id,
  availableToday,
  name,
  // value,
  label,
  handleChange,
  helperText,
  placeholder,
  handleDateStart,
  handleDateEnd,
  handlers,
  basePrice,
  propertyPolicy,
  toast,
  propertyPricePolicy,
}) => {
  const [value, setValue] = useState([]);
  const date = new Date();
  const weekendPrice = propertyPricePolicy?.weekendNightPrice;
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);
  const [calendarData, setCalendarData] = useState([]);
  let [disabledDates, setDisabledDates] = useState([]);
  let [disabledDatesFormatted, setDisabledDatesFormatted] = useState([]);
  const [maxDate, setMaxDate] = useState('');
  const [minDate, setMinDate] = useState(new Date());
  const [minStayBlockedDates, setMinStayBlockedDates] = useState([]);

  const [restrictedDays, setRestrictedDayes] = React.useState({});
  const [restrictedDaysArray, setRestrictedDaysArray] = React.useState([]);
  React.useEffect(() => {
    const getPropertyRestrictedDays = async () => {
      const restrictedDayz = await getPropertyRestrictedDaysApi(id);
      setRestrictedDayes(restrictedDayz?.data?.data);
    };
    getPropertyRestrictedDays();
  }, [id]);

  useEffect(() => {
    const getCalendarData = async () => {
      const response = await getPropertyCalenderPriceApi(id);
      if (response.status === 200) {
        setCalendarData(response.data.data);
      }
    };

    getCalendarData();
  }, [id]);

  const anotherDateTemplate = (date) => {
    let pushToDisabledDatesArray = (date) => {
      if (!disabledDates.includes(date)) {
        disabledDates.push(date);
        disabledDatesFormatted.push(moment(date).format('YYYY-MM-DD'));
      }
    };
    for (let i = 0; i < calendarData.length; i++) {
      if (
        date.day === parseInt(moment(calendarData[i]['forDate']).format('D')) &&
        date.month ===
          parseInt(moment(calendarData[i]['forDate']).format('M')) - 1
      ) {
        if (
          calendarData[i]['isBooked'] === true ||
          calendarData[i]['isAvailable'] === false
        ) {
          let now = moment(calendarData[i]['forDate']);
          disabledDates.push(new Date(now.format('M/D/YYYY')));
          disabledDatesFormatted.push(now.format('YYYY-MM-DD'));

          let booking = calendarData[i]['booking'];

          //Advance Notice
          if (propertyPolicy?.advanceNotice === "At least two days' notice") {
            let endDate = moment(booking?.endDate);
            let newDate = endDate.clone().add(1, 'day');
            pushToDisabledDatesArray(new Date(newDate.format('M/D/YYYY')));
          } else if (
            propertyPolicy?.advanceNotice === "At least three days' notice"
          ) {
            let endDate = moment(booking?.endDate);
            let oneDayAfterEndDate = endDate.clone().add(1, 'day');
            let twoDayAfterEndDate = endDate.clone().add(2, 'day');
            pushToDisabledDatesArray(
              new Date(oneDayAfterEndDate.format('M/D/YYYY'))
            );
            pushToDisabledDatesArray(
              new Date(twoDayAfterEndDate.format('M/D/YYYY'))
            );
          } else if (
            propertyPolicy?.advanceNotice === "At least seven days' notice"
          ) {
            let endDate = moment(booking?.endDate);
            let oneDayAfterEndDate = endDate.clone().add(1, 'day');
            let twoDayAfterEndDate = endDate.clone().add(2, 'day');
            let threeDayAfterEndDate = endDate.clone().add(3, 'day');
            let fourDayAfterEndDate = endDate.clone().add(4, 'day');
            let fiveDayAfterEndDate = endDate.clone().add(5, 'day');
            let sixDayAfterEndDate = endDate.clone().add(6, 'day');

            pushToDisabledDatesArray(
              new Date(oneDayAfterEndDate.format('M/D/YYYY'))
            );
            pushToDisabledDatesArray(
              new Date(twoDayAfterEndDate.format('M/D/YYYY'))
            );
            pushToDisabledDatesArray(
              new Date(threeDayAfterEndDate.format('M/D/YYYY'))
            );
            pushToDisabledDatesArray(
              new Date(fourDayAfterEndDate.format('M/D/YYYY'))
            );
            pushToDisabledDatesArray(
              new Date(fiveDayAfterEndDate.format('M/D/YYYY'))
            );
            pushToDisabledDatesArray(
              new Date(sixDayAfterEndDate.format('M/D/YYYY'))
            );
          }
          // PreparationTime
          if (
            propertyPolicy?.preparationTime ===
            'Book one night before and after each reservation.'
          ) {
            let startDate = moment(booking?.startDate);
            let endDate = moment(booking?.endDate);
            let oneDayBeforeStartDate = startDate.clone().subtract(1, 'day');
            let oneDayAfterEndDate = endDate.clone().add(1, 'day');
            pushToDisabledDatesArray(
              new Date(oneDayBeforeStartDate.format('M/D/YYYY'))
            );
            pushToDisabledDatesArray(
              new Date(oneDayAfterEndDate.format('M/D/YYYY'))
            );
          } else if (
            propertyPolicy?.preparationTime ===
            'Book two nights before and after each reservation.'
          ) {
            let startDate = moment(booking?.startDate);
            let endDate = moment(booking?.endDate);
            let oneDayBeforeStartDate = startDate.clone().subtract(1, 'day');
            let oneDayAfterEndDate = endDate.clone().add(1, 'day');
            let twoDayBeforeStartDate = startDate.clone().subtract(2, 'days');
            let twoDayAfterEndDate = endDate.clone().add(2, 'days');
            pushToDisabledDatesArray(
              new Date(oneDayAfterEndDate.format('M/D/YYYY'))
            );
            pushToDisabledDatesArray(
              new Date(oneDayBeforeStartDate.format('M/D/YYYY'))
            );
            pushToDisabledDatesArray(
              new Date(twoDayBeforeStartDate.format('M/D/YYYY'))
            );
            pushToDisabledDatesArray(
              new Date(twoDayAfterEndDate.format('M/D/YYYY'))
            );
          }

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '11rem',
                textDecoration: 'line-through',
                fontSize: '14px',
              }}
            >
              {date.day}
              {calendarData[i]['price'] && (
                <>
                  <br />
                  <span
                    style={{
                      fontSize: '17px',
                      display: 'flex',
                    }}
                  >
                    {(moment(calendarData[i]['forDate']).format('ddd') ===
                      'Sat' ||
                      moment(calendarData[i]['forDate']).format('ddd') ===
                        'Sun') &&
                    weekendPrice !== 0 ? (
                      <>
                        {process.env.REACT_APP_CURRENCY}
                        {weekendPrice}
                      </>
                    ) : (
                      <>
                        {' '}
                        {process.env.REACT_APP_CURRENCY}
                        {calendarData[i]['price']}
                      </>
                    )}
                  </span>
                </>
              )}
            </div>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '11rem',
                fontSize: '14px',
              }}
            >
              {date.day}
              {calendarData[i]['price'] && (
                <>
                  <br />
                  <span
                    style={{
                      fontSize: '17px',
                      display: 'flex',
                    }}
                  >
                    {(moment(calendarData[i]['forDate']).format('ddd') ===
                      'Sat' ||
                      moment(calendarData[i]['forDate']).format('ddd') ===
                        'Sun') &&
                    weekendPrice !== 0 ? (
                      <>
                        {process.env.REACT_APP_CURRENCY}
                        {weekendPrice}
                      </>
                    ) : (
                      <>
                        {' '}
                        {process.env.REACT_APP_CURRENCY}
                        {calendarData[i]['price']}
                      </>
                    )}
                  </span>
                </>
              )}
            </div>
          );
        }
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '11rem',
          fontSize: '14px',
        }}
      >
        {date.day}
        {basePrice && (
          <>
            <br />
            <span
              style={{
                fontSize: '17px',
                display: 'flex',
              }}
            >
              {process.env.REACT_APP_CURRENCY}{basePrice}
            </span>
          </>
        )}
      </div>
    );
  };

  React.useEffect(() => {
    if (propertyPolicy?.availabilityWindow === '12 months in advance') {
      setMaxDate(moment().add(1, 'year').toDate());
    } else if (propertyPolicy?.availabilityWindow === '9 months in advance') {
      setMaxDate(moment().add(9, 'months').toDate());
    } else if (propertyPolicy?.availabilityWindow === '6 months in advance') {
      setMaxDate(moment().add(6, 'months').toDate());
    } else if (propertyPolicy?.availabilityWindow === '3 months in advance') {
      setMaxDate(moment().add(3, 'months').toDate());
    } else if (propertyPolicy?.availabilityWindow === 'Dates Unavailable') {
      setMaxDate(new Date());
      disabledDates.push(new Date());
    } else if (propertyPolicy?.availabilityWindow === 'All Future Dates') {
      setMaxDate(moment().add(3, 'years').toDate());
    }
  }, [propertyPolicy, disabledDates]);

  React.useEffect(() => {
    const arr = [];
    if (restrictedDays?.sunday === true) {
      arr.push(0);
    }
    if (restrictedDays?.monday === true) {
      arr.push(1);
    }
    if (restrictedDays?.tuesday === true) {
      arr.push(2);
    }
    if (restrictedDays?.wednesday === true) {
      arr.push(3);
    }
    if (restrictedDays?.thursday === true) {
      arr.push(4);
    }
    if (restrictedDays?.friday === true) {
      arr.push(5);
    }
    if (restrictedDays?.saturday === true) {
      arr.push(6);
    }
    setRestrictedDaysArray(arr);
  }, [restrictedDays]);
  const closeCalendar = () => {};
  const maximumStay = propertyPricePolicy?.maximumStay;
  const minimumStay = propertyPricePolicy?.minimumStay;

  React.useEffect(() => {
    if (maximumStay > 0 && value.length > 0) {
      let now = moment(value[0]);
      let maxDateForBooking = now.add(maximumStay, 'days');
      setMaxDate(new Date(maxDateForBooking));
    }
  }, [maximumStay, value]);
  return (
    <DatePickerContainer>
      <Calendar
        id='range'
        minDate={minDate}
        value={value}
        style={{ fontSize: '25px', width: '100%' }}
        disabledDates={[...disabledDates, ...minStayBlockedDates]}
        disabledDays={restrictedDaysArray}
        maxDate={maxDate}
        baseZIndex={9000}
        showButtonBar
        onClearButtonClick={() => {
          setMinStayBlockedDates([]);
          setValue([]);
          setMaxDate('');
        }}
        onChange={(e) => {
          if (e.value) {
            var now = moment(e.value[0])?.clone(),
              dates = [];
            while (now?.isSameOrBefore(moment(e.value[1]))) {
              dates.push(now.format('YYYY-MM-DD'));
              now.add(1, 'days');
            }
            function intersect(a, b) {
              var setA = new Set(a);
              var setB = new Set(b);
              var intersection = new Set([...setA].filter((x) => setB.has(x)));
              return Array.from(intersection);
            }

            let result = intersect(dates, disabledDatesFormatted);
            if (result.length > 0) {
              toast.current.show({
                severity: 'info',
                summary: 'Invalid Date Range',
                detail:
                  'You cannot select your stay between unavailable dates, please select a different date range!',
                life: 3000,
              });
              setValue([]);
              return false;
            } else {
              if (minimumStay > 1) {
                let minStayCheckoutDate = moment(e.value[0]).add(
                  minimumStay - 1,
                  'days'
                );
                var sd = moment(e.value[0]).add(1, 'days')?.clone(),
                  alldates = [];
                while (
                  sd?.isSameOrBefore(moment(new Date(minStayCheckoutDate)))
                ) {
                  alldates.push(new Date(sd));
                  sd.add(1, 'days');
                }
                console.log(minStayCheckoutDate.format('DD MMM, YYYY'));
                setMinStayBlockedDates(alldates);
              }
              handlers && handleDateStart(e.value[0]);
              handlers && handleDateEnd(e.value[1]);
              setValue(e.value);
            }
          }
        }}
        selectionMode='range'
        readOnlyInput
        placeholder={placeholder || 'Select a Date Range'}
        dateTemplate={anotherDateTemplate}
        numberOfMonths={2}
        dateFormat='dd M yy'
        footerTemplate={() => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '12px',
              width: '100%',
            }}
          >
            <Button variant='contained' onClick={closeCalendar}>
              Apply
            </Button>
          </div>
        )}
      />
    </DatePickerContainer>
  );
};

const DatePickerContainer = styled.div`
  height: 100%;

  .p-calendar input {
    text-align: center;
  }

  .p-calendar .p-inputtext {
    background: none;
    color: #fff;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    text-align: 'center';
    font-size: 14px;
    line-height: 1.4375em;
    min-height: 55px;

    &:hover {
      border-color: #fff;
    }
    &:focus {
      box-shadow: none;
      /* border-color: #326fd1; */
      border: 2px solid;
    }
  }

  .p-calendar {
    width: 100%;
    height: 100%;
    font-size: 25px;
  }
  .p-highlight {
    color: #0095f8 !important;
  }
  #datepicker {
    font-size: 25px;
  }
`;

export default DatePickerInviteGuest;

//DatePickerInviteGuest
