import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { getAllAreas } from "../../services/area/index";
import { getAllUsers } from "../../services/users/index";
import {
  getAllSpaces,
  getSpaceTypeById,
} from "../../services/spaceGroupType/index";
import { statusArr } from "../../constants/staticData";
import { getAllAmenities } from "../../services/amenities/index";
import { InputText } from "primereact/inputtext";

const sortArr = [
  { label: "Ascending", value: "ASC" },
  { label: "Descending", value: "DESC" },
];
const propArr = [
  { label: "Private", value: "Private" },
  { label: "Commercial", value: "Commercial" },
];

const recommendedArrr = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const PropertyFilterDialog = React.forwardRef(
  ({ getData, customRows, activePage }, ref) => {
    let token = JSON.parse(localStorage.getItem("token"));
    const toast = useRef();
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const [inputVal, setInputVal] = useState({
      sortBy: "",
      code: "",
      country: "",
      state: "",
      city: "",
      propertyType: "",
      recommended: "",
      inspiredDestination: "",
      minPrice: "",
      maxPrice: "",
      areaId: "",
      noOfAdults: "",
      noOfBeds: "",
      noOfBedRooms: "",
      noOfBathrooms: "",
      noOfPets: "",
      noOfInfants: "",
      noOfChildren: "",
      spaceId: "",
      spaceType: "",
      userId: "",
      status: "",
      instantBooking: "",
      floodRelief: "",
      propertyAmenityId: "",
    });
    const [allAreas, setAllAreas] = useState([]);
    const [allSpaces, setAllSpaces] = useState([]);
    const [allSpaceType, setAllSpaceType] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [allAmenities, setAllAmenities] = useState([]);

    useImperativeHandle(ref, () => ({
      isVisible(params) {
        setOpenFilterDialog(true);
      },
    }));

    useEffect(() => {
      getAllAreas()
        .then((res) => {
          setAllAreas(res.data.data);
        })
        .catch((err) => {});
      getAllSpaces()
        .then((res) => {
          setAllSpaces(res.data.data);
        })
        .catch((err) => {});

      getAllUsers(token)
        .then((res) => {
          let tempArr = [];
          res.data.data.data.map((item) => {
            tempArr.push({
              id: item.id,
              title: `${item.firstName} ${item.lastName}`,
            });
          });
          setAllUsers(tempArr);
        })
        .catch((err) => {});
      getAllAmenities()
        .then((res) => {
          setAllAmenities(res.data.data);
        })
        .catch((err) => {});
    }, []);

    const handleChange = (e) => {
      let eventName = e.target.name;
      let eventValue = e.target.value;
      setInputVal({ ...inputVal, [eventName]: eventValue });
    };

    const handleChangeSpace = (e) => {
      let eventName = e.target.name;
      let eventValue = e.target.value;
      setInputVal({ ...inputVal, [eventName]: eventValue });
      getSpaceTypeById(e.target.value)
        .then((res) => {
          setAllSpaceType(res.data.data);
        })
        .catch((err) => {});
    };

    const hideDialog = () => {
      setOpenFilterDialog(false);
    };

    const handleChangeFilter = () => {
      setOpenFilterDialog(false);

      let sortValue = inputVal.sortBy == "" ? "" : `&sortBy=${inputVal.sortBy}`;
      let codeValue = inputVal.code == "" ? "" : `&code=${inputVal.code}`;
      let countryValue =
        inputVal.country == "" ? "" : `&country=${inputVal.country}`;
      let stateValue = inputVal.state == "" ? "" : `&state=${inputVal.state}`;
      let cityValue = inputVal.city == "" ? "" : `&city=${inputVal.city}`;
      let propType =
        inputVal.propertyType == ""
          ? ""
          : `&propertyType=${inputVal.propertyType}`;
      let recomm =
        inputVal.recommended == ""
          ? ""
          : `&recommended=${inputVal.recommended}`;
      let destination =
        inputVal.inspiredDestination == ""
          ? ""
          : `&inspiredDestination=${inputVal.inspiredDestination}`;
      let minimumPrice =
        inputVal.minPrice == "" ? "" : `&minPrice=${inputVal.minPrice}`;
      let maximumPrice =
        inputVal.maxPrice == "" ? "" : `&maxPrice=${inputVal.maxPrice}`;
      let areaValue = inputVal.areaId == "" ? "" : `&areaId=${inputVal.areaId}`;
      let adultsVal =
        inputVal.noOfAdults == "" ? "" : `&noOfAdults=${inputVal.noOfAdults}`;
      let bedVal =
        inputVal.noOfBeds == "" ? "" : `&noOfBeds=${inputVal.noOfBeds}`;
      let bedroomsVal =
        inputVal.noOfBedRooms == ""
          ? ""
          : `&noOfBedRooms=${inputVal.noOfBedRooms}`;
      let bathValue =
        inputVal.noOfBathrooms == ""
          ? ""
          : `&noOfBathrooms=${inputVal.noOfBathrooms}`;
      let petValue =
        inputVal.noOfPets == "" ? "" : `&noOfPets=${inputVal.noOfPets}`;
      let infantsValue =
        inputVal.noOfInfants == ""
          ? ""
          : `&noOfInfants=${inputVal.noOfInfants}`;
      let childValue =
        inputVal.noOfChildren == ""
          ? ""
          : `&noOfChildren=${inputVal.noOfChildren}`;
      let spaceVal =
        inputVal.spaceId == "" ? "" : `&spaceId=${inputVal.spaceId}`;
      let spaceTypeVal =
        inputVal.spaceType == "" ? "" : `&spaceType=${inputVal.spaceType}`;
      let userIdVal = inputVal.userId == "" ? "" : `&userId=${inputVal.userId}`;
      let instantBook =
        inputVal.instantBooking == ""
          ? ""
          : `&instantBooking=${inputVal.instantBooking}`;
      let relief =
        inputVal.floodRelief == ""
          ? ""
          : `&floodRelief=${inputVal.floodRelief}`;
      let amenityId =
        inputVal.propertyAmenityId == ""
          ? ""
          : `&propertyAmenityId=${inputVal.propertyAmenityId}`;

      getData(
        activePage,
        customRows,
        sortValue,
        codeValue,
        countryValue,
        stateValue,
        cityValue,
        propType,
        recomm,
        destination,
        minimumPrice,
        maximumPrice,
        areaValue,
        adultsVal,
        bedVal,
        bedroomsVal,
        bathValue,
        petValue,
        infantsValue,
        spaceVal,
        spaceTypeVal,
        userIdVal,
        instantBook,
        relief,
        amenityId,
        childValue
      );
    };

    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
        />

        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={handleChangeFilter}
        />
      </React.Fragment>
    );

    return (
      <Dialog
        visible={openFilterDialog}
        style={{ width: "500px" }}
        header="Property Filters"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <Toast ref={toast} position="bottom-right" />
        <div className="row">
          <div className="col-lg-6">
            <label>Sort By</label>
            <Dropdown
              value={inputVal.sortBy}
              options={sortArr}
              onChange={handleChange}
              name="sortBy"
              optionLabel="value"
              placeholder="Select Sort By"
            />
          </div>
          <div className="col-lg-6">
            <label>Code</label>
            <InputText
              value={inputVal.code}
              onChange={handleChange}
              placeholder="Code"
              name="code"
            />
          </div>
          <div className="col-lg-6">
            <label>Country</label>
            <InputText
              value={inputVal.country}
              onChange={handleChange}
              placeholder="Country"
              name="country"
            />
          </div>
          <div className="col-lg-6">
            <label>State</label>
            <InputText
              value={inputVal.state}
              onChange={handleChange}
              placeholder="State"
              name="state"
            />
          </div>
          <div className="col-lg-6">
            <label>City</label>
            <InputText
              value={inputVal.city}
              onChange={handleChange}
              placeholder="City"
              name="city"
            />
          </div>
          <div className="col-lg-6">
            <label>Property Type</label>
            <Dropdown
              value={inputVal.propertyType}
              options={propArr}
              onChange={handleChange}
              name="propertyType"
              optionLabel="value"
              placeholder="Property Type"
            />
          </div>
          <div className="col-lg-6">
            <label>Recommended</label>
            <Dropdown
              value={inputVal.recommended}
              options={recommendedArrr}
              onChange={handleChange}
              name="recommended"
              optionLabel="label"
              placeholder="Recommended"
            />
          </div>
          <div className="col-lg-6">
            <label>Inspired Destination</label>
            <Dropdown
              value={inputVal.inspiredDestination}
              options={recommendedArrr}
              onChange={handleChange}
              name="inspiredDestination"
              optionLabel="label"
              placeholder="Inspired Destination"
            />
          </div>
          <div className="col-lg-6">
            <label>Minimum Price</label>
            <InputText
              value={inputVal.minPrice}
              onChange={handleChange}
              placeholder="Minimum Price"
              name="minPrice"
              type="number"
            />
          </div>
          <div className="col-lg-6">
            <label>Maximum Price</label>
            <InputText
              value={inputVal.maxPrice}
              onChange={handleChange}
              placeholder="Maximum Price"
              name="maxPrice"
              type="number"
            />
          </div>
          <div className="col-lg-6">
            <label>Select Area</label>
            <Dropdown
              value={inputVal.areaId}
              options={allAreas}
              onChange={handleChange}
              name="areaId"
              optionLabel="title"
              optionValue="id"
              placeholder="Select Area"
            />
          </div>
          <div className="col-lg-6">
            <label>Number Of Adults</label>
            <InputText
              value={inputVal.noOfAdults}
              onChange={handleChange}
              placeholder="Number Of Adults"
              name="noOfAdults"
              type="number"
            />
          </div>
          <div className="col-lg-6">
            <label>Number Of Beds</label>
            <InputText
              value={inputVal.noOfBeds}
              onChange={handleChange}
              placeholder="Number Of Beds"
              name="noOfBeds"
              type="number"
            />
          </div>
          <div className="col-lg-6">
            <label>Number Of Bedrooms</label>
            <InputText
              value={inputVal.noOfBedRooms}
              onChange={handleChange}
              placeholder="Number Of Bedrooms"
              name="noOfBedRooms"
              type="number"
            />
          </div>
          <div className="col-lg-6">
            <label>Number Of Pets</label>
            <InputText
              value={inputVal.noOfPets}
              onChange={handleChange}
              placeholder="Number Of Pets"
              name="noOfPets"
              type="number"
            />
          </div>
          <div className="col-lg-6">
            <label>Number Of Infants</label>
            <InputText
              value={inputVal.noOfInfants}
              onChange={handleChange}
              placeholder="Number Of Infants"
              name="noOfInfants"
              type="number"
            />
          </div>
          <div className="col-lg-6">
            <label>Number Of Childrens</label>
            <InputText
              value={inputVal.noOfChildren}
              onChange={handleChange}
              placeholder="Number Of Childrens"
              name="noOfChildren"
              type="number"
            />
          </div>

          <div className="col-lg-6">
            <label>Select Space</label>
            <Dropdown
              value={inputVal.spaceId}
              options={allSpaces}
              onChange={handleChangeSpace}
              name="spaceId"
              optionLabel="title"
              optionValue="id"
              placeholder="Select Space"
            />
          </div>
          <div className="col-lg-6">
            <label>Select Space Type</label>
            <Dropdown
              value={inputVal.spaceType}
              options={allSpaceType}
              onChange={handleChange}
              name="spaceType"
              optionLabel="title"
              optionValue="id"
              placeholder="Select Space Type"
            />
          </div>
          <div className="col-lg-6">
            <label>Select User</label>
            <Dropdown
              value={inputVal.userId}
              options={allUsers}
              onChange={handleChange}
              name="userId"
              optionLabel="title"
              optionValue="id"
              placeholder="Select User"
            />
          </div>
          <div className="col-lg-6">
            <label>Select Status</label>
            <Dropdown
              value={inputVal.status}
              options={statusArr}
              onChange={handleChange}
              name="status"
              optionLabel="label"
              optionValue="value"
              placeholder="Select Status"
            />
          </div>
          <div className="col-lg-6">
            <label>Select Instant Booking</label>
            <Dropdown
              value={inputVal.instantBooking}
              options={recommendedArrr}
              onChange={handleChange}
              name="instantBooking"
              optionLabel="label"
              optionValue="value"
              placeholder="Select Instant Booking"
            />
          </div>
          <div className="col-lg-6">
            <label>Select Flood Relief</label>
            <Dropdown
              value={inputVal.floodRelief}
              options={recommendedArrr}
              onChange={handleChange}
              name="floodRelief"
              optionLabel="label"
              optionValue="value"
              placeholder="Select Flood Relief"
            />
          </div>
          <div className="col-lg-6">
            <label>Select Property Amenity</label>
            <Dropdown
              value={inputVal.propertyAmenityId}
              options={allAmenities}
              onChange={handleChange}
              name="propertyAmenityId"
              optionLabel="title"
              optionValue="id"
              placeholder="Select Property Amenity"
            />
          </div>
        </div>
      </Dialog>
    );
  }
);

export default PropertyFilterDialog;
