import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllFacilityGroup = () => {
  return Api(`${endPoints.facilityGroup}`, null, requestType.GET, null, null);
};

export const addFacilityGroup = (params) => {
  return Api(
    `${endPoints.addfacilityGroup}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editFacilityGroup = (id, params) => {
  return Api(
    `${endPoints.editfacilityGroup}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteFacilityGroup = (id) => {
  return Api(
    `${endPoints.deletefacilityGroup}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
