import React, { useState, useImperativeHandle, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import ViewImagesDialog from "./ViewImagesDialog";

const ViewDetailsDialog = React.forwardRef(({ customRows }, ref) => {
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const imagesRef = useRef();

  useImperativeHandle(ref, () => ({
    isVisible(params) {
      setDialogData(params);
      setOpenStatusDialog(true);
    },
  }));

  const hideDialog = () => {
    setOpenStatusDialog(false);
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Close"
        // icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
    </React.Fragment>
  );

  return (
    <>
      <Dialog
        visible={openStatusDialog}
        style={{ width: "450px" }}
        header="User Details"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <h6 style={{ fontWeight: "600", marginTop: "20px" }}>
          Card Type: {dialogData?.idCardType}
        </h6>
        <h6 style={{ fontWeight: "600", marginTop: "20px" }}>
          Name: {dialogData?.user?.firstName} {dialogData?.user?.lastName}
        </h6>
        <h6 style={{ fontWeight: "600", marginTop: "20px" }}>
          Phone Number: {dialogData?.user?.phoneNumber}
        </h6>
        <h6 style={{ fontWeight: "600", marginTop: "20px" }}>
          Current Status: {dialogData?.status}
        </h6>

        <div>
          <h6 style={{ fontWeight: "600", marginTop: "20px" }}>Live Picture</h6>
          <Image
            height="200px"
            width="100%"
            style={{ cursor: "pointer" }}
            className="image-type"
            src={dialogData?.livePicture}
            onClick={() =>
              imagesRef.current.isVisible({
                image: dialogData?.livePicture,
                title: "Live Picture",
              })
            }
          />
        </div>
        <div>
          <h6 style={{ fontWeight: "600", marginTop: "20px" }}>
            Front Picture
          </h6>
          <Image
            height="200px"
            width="100%"
            style={{ cursor: "pointer" }}
            className="image-type"
            onClick={() =>
              imagesRef.current.isVisible({
                image: dialogData?.frontPicture,
                title: "Front Picture",
              })
            }
            src={dialogData?.frontPicture}
          />
        </div>
        <div>
          <h6 style={{ fontWeight: "600", marginTop: "20px" }}>Back Picture</h6>
          <Image
            height="200px"
            width="100%"
            className="image-type"
            style={{ cursor: "pointer" }}
            onClick={() =>
              imagesRef.current.isVisible({
                image: dialogData?.backPicture,
                title: "Back Picture",
              })
            }
            src={dialogData?.backPicture}
          />
        </div>
      </Dialog>
      <ViewImagesDialog ref={imagesRef} />
    </>
  );
});

export default ViewDetailsDialog;
