import React, { useState, useEffect } from "react";
import Banner from "../../components/banner";
import Sidebar from "../../components/sidebar";
import {
  calculateBookingPrice,
  getBookingByBookingId,
  getBookingCancellation,
  getBookingRating,
  getBookingUpdateRequest,
} from "./../../services/bookings/index";
import { useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import moment from "moment";
import Card from "../../components/card";
import CrudDataTable from "../../components/crudDataTable";
import { Rating } from "primereact/rating";
import { getBookingTransactionHistory } from "../../services/bookingTransactionHistory";
import Pagination from "../../components/pagination";
import { priceBodyTemplate } from "../../constants/utils";
import BookingDetailCard from "./BookingDetailCard";

const BookingDetails = () => {
  let { bookingId, propertyId } = useParams();
  const user_token = JSON.parse(localStorage.getItem("token"));
  const [bookingCancellation, setBookingCancellation] = useState([]);
  const [bookingUpdateRequest, setBookingUpdateRequest] = useState([]);
  const [bookingDetails, setBookingDetails] = useState({});
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [bookingRating, setBookingRating] = useState([]);
  const [customFirst, setCustomFirst2] = useState(0);
  const [customRows, setCustomRows2] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getData = (order, page, limit) => {
    setIsLoading(true);
    getBookingCancellation(bookingId)
      .then((res) => {
        setIsLoading(false);
        let tempArr = [];
        tempArr.push({
          ...res.data,
          reason: res.data.reason ? res.data.reason : "--",
          comment: res.data.comment ? res.data.comment : "--",
        });
        setBookingCancellation(tempArr);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getBookingUpdateRequest(bookingId, user_token)
      .then((res) => {
        setIsLoading(false);
        let tempArr = [];
        tempArr.push(res.data);
        setBookingUpdateRequest(tempArr);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getBookingRating(bookingId)
      .then((res) => {
        setIsLoading(false);
        let tempArr = [];
        tempArr.push(res.data.data);
        setBookingRating(tempArr);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getBookingTransactionHistory(
      `?order=${order}`,
      `&page=${page}`,
      `&limit=${limit}`,
      `&propertyId=${propertyId}`,
      `&bookingId=${bookingId}`
    )
      .then((res) => {
        setTotalRecords(res.data.total);
        let tempArr = [];
        res.data.data.map((item) => {
          tempArr.push({
            transactionId: item.id,
            propertyTitle: item.booking.property.title,
            bookingDate:
              item.booking.startDate && item.booking.endDate !== null
                ? `${moment(item.booking.startDate).format(
                    "DD MMM YYYY"
                  )} - ${moment(item.booking.endDate).format("DD MMM YYYY")}`
                : "-",
            amount: item.amount,
            paymentStatus: item.booking.paymentStatus,
            paymentMethod: item.paymentMethod,
          });
        });
        setTransactionHistory(tempArr);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    getBookingByBookingId(bookingId)
      .then((res) => {
        let data = res.data.data;
        let payload = {
          propertyId: data?.propertyId,
          startDate: data?.startDate,
          endDate: data?.endDate,
          noOfAdults: data.totalAdults,
          noOfChildren: data.totalChildren,
          noOfInfants: data.totalInfants,
          noOfPets: data.totalPets,
          validate: true,
          isModify: false,
        };
        calculateBookingPrice(payload)
          .then((res) => {
            setBookingDetails(res.data.data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData("DESC", 1, 10);
  }, []);

  const onPageChange = (event) => {
    setCustomFirst2(event.first);
    setCustomRows2(event.rows);
    getData("DESC", event.page, event.rows);
  };

  const createdOnTemplate = (date) => {
    return <span>{moment(date).format("DD MMM YYYY hh:mm:ss")}</span>;
  };

  const statusTepmlate = (rowData, type) => {
    return (
      <>
        <div style={{ marginBottom: "10px" }}>
          <span
            style={{
              fontWeight: 500,
              background: "#c8e6c9",
              color: "#256029",
              padding: "5px 10px",
            }}
          >
            {type === "reimbursementStatus"
              ? rowData.reimbursementStatus
              : rowData.status}
          </span>
        </div>
      </>
    );
  };

  const bookingCancellationData = [
    { id: 1, field: "id", header: "Id" },
    { id: 2, field: "reason", header: "Reason" },
    { id: 3, field: "comment", header: "Comment" },
    {
      id: 4,
      field: "reimbursementAmount",
      header: "Reimbursement Amount",
      body: (e) => priceBodyTemplate(e.reimbursementAmount),
    },
    {
      id: 5,
      field: "reimbursementStatus",
      header: "Reimbursement Status",
      body: (rowData) => statusTepmlate(rowData, "reimbursementStatus"),
    },
    {
      id: 6,
      field: "status",
      header: "Status",
      body: (rowData) => statusTepmlate(rowData, "status"),
    },
    {
      id: 7,
      field: "createdOn",
      header: "Created On",
      body: (e) => createdOnTemplate(e.createdOn),
    },
  ];

  const transactionData = [
    { id: 1, field: "transactionId", header: "Transaction Id" },
    { id: 2, field: "propertyTitle", header: "Property Title" },
    {
      id: 3,
      field: "bookingDate",
      header: "Booking Date",
    },
    {
      id: 4,
      field: "amount",
      header: "Amount",
      body: (e) => priceBodyTemplate(e.amount),
    },
    { id: 5, field: "paymentStatus", header: "Payment Status" },
    {
      id: 6,
      field: "paymentMethod",
      header: "Payment Method",
    },
  ];

  return (
    <div className="app-main">
      <Sidebar active="bookingList" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="Booking Details - Staywo"
            icon="pe-7s-note2 icon-gradient bg-mean-fruit"
          />

          <div className="row">
            <div className="col-lg-12">
              <Card title="Booking Cancellations">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={bookingCancellationData}
                    data={bookingCancellation}
                    showActions={false}
                    showView={false}
                    showToolbar={false}
                    showDelete={true}
                    showEdit={false}
                    sortable={false}
                  />
                )}
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Card title="Booking Update Requests">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={bookingCancellationData}
                    data={bookingUpdateRequest}
                    showActions={false}
                    showView={false}
                    showToolbar={false}
                    showDelete={true}
                    showEdit={false}
                    sortable={false}
                  />
                )}
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Card title="Booking Transaction History">
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable
                    headerColumns={transactionData}
                    data={transactionHistory}
                    totalRecords={totalRecords}
                    showActions={false}
                    showView={false}
                    showToolbar={false}
                    showDelete={true}
                    showEdit={false}
                    sortable={false}
                  />
                )}
                <Pagination
                  totalRecords={totalRecords}
                  customFirst={customFirst}
                  customRows={customRows}
                  onPageChange={onPageChange}
                />
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              {isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <ProgressSpinner />
                </div>
              ) : (
                <div style={{ marginBottom: "40px" }}>
                  <h4 style={{ marginTop: "15px" }}>Rating Details</h4>
                  {bookingRating?.map((item) => {
                    return (
                      <div style={{ background: "#fff", padding: "24px" }}>
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6 style={{ fontWeight: "600" }}>Guest:</h6>
                          <span className="address-style">
                            <Rating
                              value={item.guest_rating}
                              readOnly
                              stars={5}
                              cancel={false}
                            />
                          </span>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h6 style={{ fontWeight: "600" }}>Host:</h6>
                          <span className="address-style">
                            <Rating
                              value={item.host_rating}
                              readOnly
                              stars={5}
                              cancel={false}
                            />
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="col-lg-4">
              {isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <ProgressSpinner />
                </div>
              ) : (
                <div style={{ marginBottom: "40px" }}>
                  <h4 style={{ marginTop: "15px" }}>Booking Details</h4>
                  <div style={{ background: "#fff", padding: "24px" }}>
                    <BookingDetailCard calculatedData={bookingDetails} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
