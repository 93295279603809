import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getRatingByPropId = (id, page) => {
  return Api(
    `${endPoints.getRatingbyPropId}?property_id=${id}&page=${page}`,
    null,
    requestType.GET,
    null,
    null
  );
};
