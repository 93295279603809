import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Sidebar from "../../components/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  addUser,
  deleteUserById,
  getUsersByLimit,
  updateHostStatus,
  updateProfilePicture,
  updateStatusbyAdmin,
  updateUser,
  updateuserData,
} from "./../../services/users";
import { Image } from "primereact/image";
import CrudDataTable from "../../components/crudDataTable";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import Pagination from "../../components/pagination";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import "primeflex/primeflex.css";
import UserStatusDialog from "./UserStatusDialog";
import DropdownField from "../../components/formComponents/dropdown";
import { Password } from "primereact/password";
import { useNavigate } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox } from "primereact/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { changeType } from "../../redux/slices/viewSlice";
import { fetchUser } from "../../redux/slices/userList";
import { useForm } from "react-hook-form";
import { userschema } from "./schema";
import { yupResolver } from '@hookform/resolvers/yup';
import { upload } from "@testing-library/user-event/dist/upload";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { width } from "@mui/system";
import { InputTextarea } from "primereact/inputtextarea";
import { API_URL } from "../../config";
import axios from "axios";

const UsersList = () => {
  const user_token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();
  const toast = useRef(null);
  const imageRef = useRef();
  const dialogRef = useRef();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selected, setSelected] = useState(null);
  const [adduser, setAdduser] = useState({
    firstName: "zafar",
    middleName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    phoneNumber: ""


  })

  const [addRole, setRole] = useState(null)
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(userschema),
  });


  const [totalRecords, setTotalRecords] = useState(0);
  const [checked, setChecked] = useState(false);
  const [activeIndexTab, setActiveIndexTab] = useState(0);
  const [uploadImage, setUploadImage] = useState(null)
  const [gender, setGender] = useState(null);
  const [isGovtVerify, setIsGovtVerify] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isTipAccepted, setIsTipAccepted] = useState(false);
  const [isDeActivited, setIsDeActivited] = useState(false);
  const [isDaimondHost, setIsDaimondHost] = useState(false);
  const [isUserPhoto, setIsUserPhoto] = useState(null)
  const [isGoogleVerified, setIsGoogleVerified] = useState(false);
  const [isFaceBookVerified, setIsFacebookVerified] = useState(false);
  const [isAppleVerified, setIsAppleVerified] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [text, setText] = useState('');
  const [editLoader, setEditLoader] = useState(false)
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    password: "",
    isAppleToken: "",
    isGoogleToken: "",
    isFaceToken: ""

  })
  

  const { userList, userEditData } = useSelector(state => state.userList)

  const handleTextChange = (e) => {
    setText(e.target.value);
  };
  const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'French', value: 'fr' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Turkish', value: 'tr' },
    // Add more language options as needed
  ];

  const currencyOptions = [
    { label: 'USD', value: 'usd' },
    { label: 'EUR', value: 'eur' },
    { label: 'Lira', value: 'lira' },
    { label: 'Riyal', value: 'ry' },
    { label: 'Rupees', value: 'Rs.' },
    // Add more currency options as needed
  ];
  const handleGenderChange = (e) => {
    setGender(e.value);
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.value);
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.value);
  };
  const dispatch = useDispatch()
  const type = useSelector(state => state.ui.type)
  useEffect(() => {
    getData("ASC", 1, 10);
  }, []);
  useEffect(()=>{
    if(type==="add" || type==="table"){
      setUploadImage(null)
    }
  },[type])


  useEffect(() => {
    if (userList && userEditData) {
      const alluserData = userList && userList.length > 0 && userList.filter(x => x.id === userEditData.id)
      if (alluserData && alluserData.length > 0) {
        console.log(alluserData, "allll")
        setUserData({
          ...userData,
          username: alluserData[0].username ? alluserData[0].username : "",
          firstName: alluserData[0].firstName ? alluserData[0].firstName : "",
          middleName: alluserData[0].middleName ? alluserData[0].middleName : "",
          email: alluserData[0].email ? alluserData[0].email : "",

          lastName: alluserData[0].lastName ? alluserData[0].lastName : "",
          phoneNumber: alluserData[0].phoneNumber ? alluserData[0].phoneNumber : "",
          password: "",
          isGoogleToken: alluserData[0].googleToken ? alluserData[0].googleToken : "",
          isAppleToken: alluserData[0].appleToken ? alluserData[0].appleToken : "",
          isFaceToken: alluserData[0].facebookToken ? alluserData[0].facebookToken : "",



        });
        setIsTipAccepted(alluserData[0].acceptTip)
        setIsDeActivited(alluserData[0].deactivated)
        setIsDaimondHost(alluserData[0].isDiamondHost)
        setIsPhoneVerified(alluserData[0].isPhoneNumberConfirmed)
        setIsEmailVerified(alluserData[0].isActive)
        setIsGoogleVerified(alluserData[0].isGoogleVerified)
        setIsFacebookVerified(alluserData[0].isFacebookVerified)
        setIsAppleVerified(alluserData[0].isAppleVerified)
        const curr = currencyOptions.filter(x => x.value === alluserData[0].PreferredCurrency)
        const lang = languageOptions.filter(x => x.value === alluserData[0].preferredLanguage)
        setText(alluserData[0].description)
        setSelectedCurrency(curr[0].value)
        setSelectedLanguage(lang[0].value)
        setIsUserPhoto(alluserData[0].profilePicture)
        setIsGovtVerify(alluserData[0].verified)
        setGender(alluserData[0].gender)

      }
    }

  }, [userList, userEditData])

  const onUserDataChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  const getData = (order, page, limit) => {
    setIsLoading(true);
    getUsersByLimit(order, page, limit, user_token)
      .then((res) => {
        setTotalRecords(res.data.data.meta.itemCount);
        setIsLoading(false);
        let tempArr = [];
        dispatch(fetchUser(res.data.data.data))
        res.data.data.data.map((item) => {
          tempArr.push({
            id: item.id,
            email: item.email,
            isActive: item.isActive,
            name: `${item.firstName} ${item.middleName} ${item.lastName}`,
            phoneNumber: item.phoneNumber,
            profilePicture: item.profilePicture,
            status: item.status,
            username: item.username,
            description: item.description,
            isHost: item.isHost,
          });
        });

        setUsers(tempArr);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleEdit = () => {
    setEditLoader(true)
    const data = userList && userList.length > 0 && userList.filter(x => x.id === userEditData.id)
    const id = data && data.length > 0 && data[0].id
    if (id) {
      const payload = {
        firstName: userData.firstName ? userData.firstName : "",
        lastName: userData.lastName ? userData.lastName : "",
        middleName: userData.middleName ? userData.middleName : "",
        email: userData.email ? userData.email : "",
        password: userData.password ? userData.password : "",
        isActive: isEmailVerified,
        phoneNumber: userData.phoneNumber ? userData.phoneNumber : "",
        isPhoneNumberConfirmed: isPhoneVerified,
        description: text ? text : "",
        verified: isGovtVerify,
        acceptTip: isTipAccepted,
        deactivated: isDeActivited,
        isGoogleVerified: isGoogleVerified,
        googleToken: userData.isGoogleToken ? userData.isGoogleToken : "",
        isAppleVerified: isAppleVerified,
        appleToken: userData.isAppleToken ? userData.isAppleToken : "",
        isFacebookVerified: isFaceBookVerified,
        facebookToken: userData.isFaceToken ? userData.isFaceToken : "",
        gender: gender ? gender : "",
        preferredLanguage: selectedLanguage ? selectedLanguage : "",
        PreferredCurrency: selectedCurrency ? selectedCurrency : "",
        isDiamondHost: isDaimondHost
      }
      
      updateuserData(payload, id, user_token).then(res => {

        setEditLoader(false)
        // getData("ASC", 1, 10);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "User Updated Successfully",
          life: 3000,
        });

      }).catch(err => {
        setEditLoader(false)
        toast.current.show({
          severity: "error",
          summary: "Successful",
          detail: "Something went wrong",
          life: 3000,
        });
      })

    }

  }

  const handleAddEdit = (product, setProduct, setProductDialog, type) => {
    const {
      username,
      email,
      firstName,
      lastName,
      phoneNumber,
      password,
      role,
      id,
      middleName,
      address,
      description,
    } = product;
    if (product.firstName == "") {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "First Name is required",
        life: 3000,
      });
    } else if (product.lastName == "") {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "Last Name is required",
        life: 3000,
      });
    } else if (product.username == "") {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "User Name is required",
        life: 3000,
      });
    } else if (product.email == "") {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "Email is required",
        life: 3000,
      });
    } else if (product.phoneNumber == "") {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "Phone Number is required",
        life: 3000,
      });
    } else if (product.password == "") {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "Password is required",
        life: 3000,
      });
    } else if (product.role == "") {
      toast.current.show({
        severity: "error",
        summary: "error",
        detail: "Role is required",
        life: 3000,
      });
    } else {
      let payload = {
        username,
        email,
        firstName,
        lastName,
        phone: phoneNumber,
        password,
        roleId: parseInt(role),
        isActive: true,
      };
      if (type === "add") {
        setIsLoading(true);

        addUser(payload, user_token).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: "error",
                summary: "Error",
                detail: val,
                life: 3000,
              });
            });
            setProductDialog(false);
            toast.current.show(tempArr);
          } else {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "User Created Successfully",
              life: 3000,
            });
            setProduct({
              username: "",
              email: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              password: "",
              role: "",
            });
            getData("ASC", 1, 10);
            setTimeout(() => {
              setProductDialog(false);
            }, 1500);
          }
        });
      } else {
        let payload = {
          username,
          firstName,
          middleName,
          lastName,
          address,
          description,
          phoneNumber,
        };
        updateUser(payload, id, user_token)
          .then((res) => {
            if (res.data.statusCode == 400) {
              let tempArr = [];
              res?.data?.message.map((val, ind) => {
                tempArr.push({
                  severity: "error",
                  summary: "Error",
                  detail: val,
                  life: 3000,
                });
              });
              setProductDialog(false);
              toast.current.show(tempArr);
            } else {
              toast.current.show({
                severity: "success",
                summary: "Successful",
                detail: "User Updated Successfully",
                life: 3000,
              });
              getData("ASC", 1, 10);
              setTimeout(() => {
                setProductDialog(false);
              }, 1500);
            }
          })
          .catch((err) => {
            setProductDialog(false);
          });
      }
    }
  };

  const handleChangeHostStatus = (e, rowData) => {
    console.log(rowData, "rowdara")
    setChecked(e.value)
    const payload = {
      isHost: checked,
      userId: rowData.id
    }

    console.log(payload, "dddddpay")
    setIsLoading(true);
    updateStatusbyAdmin(rowData.id, payload, user_token)
      .then((res => {

        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Host Status Updated Successfully",
          life: 3000,
        });


        setIsLoading(false);
        getData("ASC", 1, 10);

      }))
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <div className="w-10rem">
        <Image height="100px" width="100px" src={rowData?.profilePicture ? rowData?.profilePicture : "https://sales.ufaber.com/public/static/website/updated_teacherpanel/asset/img/images/dummy-avatar.jpg"} />
        {/* <input
          style={{ visibility: "hidden", height: "0" }}
          ref={imageRef}
          onChange={(e) => handleChangePicture(e, rowData)}
          type={"file"}
        />
        <Button
          onClick={(e) => {
            e.stopPropagation();
            imageRef.current.click();
            setSelected(rowData.id);
          }}
          className="mt-2 h-2rem"
        >
          Update
        </Button> */}
      </div>
    );
  };

  const statusTepmlate = (rowData) => {
    return (
      <>
        <p style={{ fontWeight: 500, color: rowData.isActive ? "green" : "red" }}>
          {rowData.isActive ? "Active" : "In Active"}
        </p>
        <Button
          label="Change Status"
          className="p-button-sm"
          onClick={() => {
            dialogRef.current.isVisible(rowData);
          }}
        />
      </>
    );
  };

  const hostStatusTepmlate = (rowData) => {
    return (
      <>
        <InputSwitch
          checked={rowData.isHost}
          onChange={(e) => handleChangeHostStatus(e, rowData)}
        />
      </>
    );
  };

  const handleView = (rowData) => {
    navigate(`/userDetails/${rowData.id}`);
  };

  const DATA = [
    { id: 1, field: "id", header: "Id" },
    {
      id: 2,
      field: "profilePicture",
      header: "Profile Picture",
      body: imageBodyTemplate,
    },
    { id: 3, field: "name", header: "Name" },
    { id: 4, field: "email", header: "Email" },
    { id: 5, field: "phoneNumber", header: "Phone#" },
    {
      id: 6,
      field: "isHost",
      header: "Host Status",
      body: hostStatusTepmlate,
    },
    { id: 7, field: "isActive", header: "Status", body: statusTepmlate },
  ];

  const onCustomPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    getData("ASC", event.page + 1, event.rows);
  };

  const handleChangePicture = (e, rowData) => {
    var data = new FormData();
    data.append("file", e.target.files[0]);
    setIsLoading(true);
    updateProfilePicture(rowData.id, data, user_token)
      .then((res) => {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Profile Image Updated Successfully",
          life: 3000,
        });
        getData("ASC", 1, rows);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const deleteSelectedItem = (id, setOpenDialog) => {
    setIsLoading(true);
    deleteUserById(id, user_token).then((res) => {
      setIsLoading(false);
      if (res.data.statusCode == 400) {
        let tempArr = [];
        res?.data?.message.map((val, ind) => {
          tempArr.push({
            severity: "error",
            summary: "Error",
            detail: val,
            life: 3000,
          });
        });
        setOpenDialog(false);
        toast.current.show(tempArr);
      } else {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "User Deleted Successfully",
          life: 3000,
        });
        getData("ASC", 1, rows);
        setTimeout(() => {
          setOpenDialog(false);
        }, 1500);
      }
    });
  };

  const onAdduserChange = (e) => {
    console.log("run")
    setAdduser({ ...adduser, [e.target.name]: e.target.value })
  }

  const onDropDownChange = e => {
    setRole(e.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)

    const payload = {
      firstName: adduser.firstName,
      lastName: adduser.lastName,
      email: adduser.email,
      phone: adduser.phone,
      password: adduser.password,
      roleId: Number(addRole),
      isActive: true
    }



    addUser(payload, user_token).then((res) => {
      console.log(res)
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "User Added Successfully",
        life: 3000,
      });

      setIsLoading(false);
    }).catch(err => {
      console.log(err)
      setIsLoading(false)
    })


  };

  const handleChangeUserImage = (e) => {

    setUploadImage(e.target.files[0])
    setIsUserPhoto(e.target.files[0])
  }


  useEffect(() => {
    if (!uploadImage) return
    if (userList && userEditData) {
      const alluserData = userList && userList.length > 0 && userList.filter(x => x.id === userEditData.id)
      if (alluserData[0].id) {
        const formData = new FormData();
        formData.append('file', uploadImage);

        const config = {
          headers: {
            "Content-Type": "multipart/*",
            Authorization: `Bearer ${user_token}`,
          },

        }

        axios.put(`${API_URL}users/updateprofilepicture/${alluserData[0].id}`, formData, config).then(res => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Image Updated Successfully",
            life: 3000,
          });

        }).catch(err => {
          toast.current.show({
            severity: "error",
            summary: "Successful",
            detail: "Some thing went wrong",
            life: 3000,
          });
          setUploadImage(null)
          console.log(err)
        })

      }
    }


  }, [uploadImage])



  const renderDialog = (
    product,
    submitted,
    onInputChange,
    onInputNumberChange,
    setProduct,
    editImage,
    setEditImage,
    type
  ) => {

    return (
      <>
        <h3 style={{ color: "#3f6ad8", textAlign: "center" }}>{type === "edit" ? "Edit User" : "Add User"}</h3>
        <hr />

        <form onSubmit={onSubmit}>
          <div className="formgrid grid">
            {type === "add" && <div className="field col-4 flex flex-column">
              <label htmlFor="firstName">First Name</label>
              <InputText
                id="firstName"

                value={addUser.firstName}
                onChange={onAdduserChange}
                required
                autoFocus
                name="firstName"
                className={classNames({
                  "p-invalid": submitted && !product.firstName,
                })}
              />
              {errors.firstName && <span>{errors.firstName.message}</span>}
            </div>


            }

            {type === "add" && (
              <div className="field col-4 flex flex-column">
                <label htmlFor="middleName">Middle Name</label>
                <InputText
                  id="middleName"
                  value={addUser.middleName}

                  onChange={onAdduserChange}
                  required
                  name="middleName"
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.firstName,
                  })}
                />
              </div>
            )}

            {type === "add" && <div className="field col-4 flex flex-column">
              <label htmlFor="lastName">Last Name</label>
              <InputText
                id="lastName"
                value={addUser.lastName}
                onChange={onAdduserChange}
                required
                name="lastName"

                className={classNames({
                  "p-invalid": submitted && !product.lastName,
                })}
              />
            </div>}

            {type === "add" && <div className="field col-4 flex flex-column">
              <label htmlFor="username">User Name</label>
              <InputText
                id="username"
                value={addUser.username}
                onChange={onAdduserChange}
                required

                name="username"
                className={classNames({
                  "p-invalid": submitted && !product.username,
                })}
              />
            </div>}

            {type === "add" && <div className="field col-4 flex flex-column">
              <label htmlFor="email">Email</label>
              <InputText
                id="email"
                value={addUser.email}
                onChange={onAdduserChange}
                required

                name="email"
                className={classNames({
                  "p-invalid": submitted && !product.username,
                })}
              />
            </div>}

            {type === "add" && <div className="field col-4 flex flex-column">
              <label htmlFor="password">Password</label>
              <InputText
                id="password"
                value={addUser.password}
                onChange={onAdduserChange}
                required

                name="password"
                className={classNames({
                  "p-invalid": submitted && !product.username,
                })}
              />
            </div>}

            {type === "add" && <div className="field col-4 flex flex-column">
              <label htmlFor="phoneNumber">Phone Number</label>
              <InputText
                id="phoneNumber"
                value={addUser.phoneNumber}
                onChange={onAdduserChange}
                name="phoneNumber"


                required
                useGrouping={false}
              />
            </div>}

            {type === "add" && (
              <>

                <div className="field col-4 flex flex-column">
                  <DropdownField
                    value={addRole}
                    onChange={(e) => onDropDownChange(e)}
                    title={"Role"}
                    options={[
                      { label: "Admin", value: "0" },
                      { label: "Customer", value: "1" },
                    ]}
                    optionLabel={"label"}
                    placeholder={"Select Role"}
                  />
                </div>
              </>
            )}

          </div>

          {type === "add" &&
            <div className="flex align-item-center gap-3">
              <Button
                label="Cancel"
                icon="pi pi-times"
                className=""
                style={{ background: "#c70000" }}
                onClick={() => dispatch(changeType("table"))}
              />
              <Button
                label="Add"
                icon=""
                style={{ background: "#3f6ad8" }}
                type="submit"
                loading={isLoading}

              />

            </div>
          }
        </form>






        <div className="formgrid grid">

          <div className="formgrid grid col-8">


            {type === "edit" && <div className="field col-6 flex flex-column">
              <label htmlFor="firstName">First Name</label>
              <InputText
                id="firstName"
                value={userData.firstName}
                onChange={(e) => onUserDataChange(e)}
                required
                autoFocus
                name="firstName"
                className={classNames({
                  "p-invalid": submitted && !userData.firstName,
                })}
              />
            </div>}

            {type === "edit" && (
              <div className="field col-6 flex flex-column">
                <label htmlFor="middleName">Middle Name</label>
                <InputText
                  id="middleName"
                  value={userData.middleName}
                  onChange={(e) => onUserDataChange(e)}
                  required
                  name="middleName"
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !userData.firstName,
                  })}
                />
              </div>
            )}




            {type === "edit" && <div className="field col-6 flex flex-column">
              <label htmlFor="lastName">Last Name</label>
              <InputText
                id="lastName"
                value={userData.lastName}
                onChange={(e) => onUserDataChange(e)}
                required
                name="lastName"
                className={classNames({
                  "p-invalid": submitted && !userData.lastName,
                })}
              />
            </div>}
            {type === "edit" && <div className="field col-6 flex flex-column">
              <label htmlFor="username">User Name</label>
              <InputText
                id="username"
                value={userData.username}
                onChange={(e) => onUserDataChange(e)}
                required
                name="username"
                className={classNames({
                  "p-invalid": submitted && !userData.username,
                })}
              />
            </div>}



            {type === "edit" && <div className="field col-6 flex flex-column">
              <label htmlFor="email">Email</label>
              <InputText
                id="email"
                value={userData.email}
                onChange={(e) => onUserDataChange(e)}
                required
                name="email"
                className={classNames({
                  "p-invalid": submitted && !userData.username,
                })}
              />
            </div>}


            {type === "edit" && <div className="field col-6 flex flex-column">
              <label htmlFor="password">Password</label>
              <InputText
                id="password"
                value={userData.password}
                onChange={(e) => onUserDataChange(e)}
                required
                name="password"
                className={classNames({
                  "p-invalid": submitted && !userData.username,
                })}
              />
            </div>}




            {type === "edit" && <div className="field col-6 flex flex-column">
              <label htmlFor="phoneNumber">Phone Number</label>
              <InputText
                id="phoneNumber"
                value={userData.phoneNumber}
                onChange={(e) => onUserDataChange(e)}
                name="phoneNumber"
                required
                useGrouping={false}
              />
            </div>}
            {type === "edit" && (
              <>
                <div className="field col-6 flex flex-column">
                  <label htmlFor="address">Language</label>
                  <Dropdown
                    options={languageOptions}
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    placeholder="Select a language"
                  />
                </div>

                <div className="field col-6 flex flex-column">

                  <label htmlFor="address">Currency</label>
                  <Dropdown
                    options={currencyOptions}
                    value={selectedCurrency}
                    onChange={handleCurrencyChange}
                    placeholder="Select a currency"
                  />
                </div>
                <div className="flex align-items-center  col-6">
                  <h5>Gender</h5>
                  <div className="flex align-items-center ml-4 ">
                    <RadioButton
                      inputId="MALE"
                      name="gender"
                      value="MALE"
                      onChange={handleGenderChange}
                      checked={gender === 'MALE'}
                    />
                    <label htmlFor="MALE" className="ml-2">Male</label>
                  </div>

                  <div className="flex align-items-center ml-3 ">
                    <RadioButton
                      inputId="FEMALE"
                      name="gender"
                      value="FEMALE"
                      onChange={handleGenderChange}
                      checked={gender === 'FEMALE'}
                    />
                    <label htmlFor="FEMALE" className="ml-2">Female</label>
                  </div>
                </div>
                <div className="field col-12 flex flex-column">
                  <label htmlFor="description">Description</label>
                  <InputTextarea
                    value={text}
                    onChange={handleTextChange}
                    placeholder="Enter Description"
                    rows={5}
                    cols={30}
                  />
                </div>
              </>
            )}

            {type === "edit" && (
              <>
                <div className="" style={{ background: "lightgray", width: "100%", height: "2px", margin: "10px 0" }} />
                <div className="grid px-2 gap-4 col-12 mt-3">
                  <div className="  flex align-items-center col-4">
                    <Checkbox onChange={(e) => setIsGovtVerify(e.checked)} checked={isGovtVerify}></Checkbox>
                    <label htmlFor="" className="ml-2">Government Id Verified</label>
                  </div>
                  <div className=" col flex align-items-center col-4">
                    <Checkbox onChange={(e) => setIsPhoneVerified(e.checked)} checked={isPhoneVerified}></Checkbox>
                    <label htmlFor="" className="ml-2">Phone Confirmed</label>
                  </div>

                  <div className="flex align-items-center col-4">
                    <Checkbox onChange={(e) => setIsEmailVerified(e.checked)} checked={isEmailVerified}></Checkbox>
                    <label htmlFor="" className="ml-2">Email Verified</label>
                  </div>

                  <div className="flex align-items-center col-4">
                    <Checkbox onChange={(e) => setIsDeleted(e.checked)} checked={isDeleted}></Checkbox>
                    <label htmlFor="" className="ml-2">Deleted</label>
                  </div>
                  <div className="flex align-items-center col-4">
                    <Checkbox onChange={(e) => setIsTipAccepted(e.checked)} checked={isTipAccepted}></Checkbox>
                    <label htmlFor="" className="ml-2">Accept Tip</label>
                  </div>

                  <div className="flex align-items-center col-4">
                    <Checkbox onChange={(e) => setIsDeActivited(e.checked)} checked={isDeActivited}></Checkbox>
                    <label htmlFor="" className="ml-2">DeActivated</label>
                  </div>

                  <div className="flex align-items-center col-4">
                    <Checkbox onChange={(e) => setIsDaimondHost(e.checked)} checked={isDaimondHost}></Checkbox>
                    <label htmlFor="" className="ml-2">Daimond Host</label>
                  </div>







                </div>
              </>
            )}

            {type === "edit" && (
              <>
                <div className="" style={{ background: "lightgray", width: "100%", height: "2px", marginTop: "5px" }} />
                <div className="grid px-2 gap-y-2 col-12 mt-4">
                  <div className="flex  flex-column  col-4 ">


                    <div className="flex align-items-center  ">
                      <Checkbox onChange={(e) => setIsGoogleVerified(e.checked)} checked={isGoogleVerified}></Checkbox>
                      <label htmlFor="" className="ml-2">Google Verified</label>
                    </div>
                    <div className="field   ">

                      <InputText
                        id="isGoogle"
                        value={userData.isGoogleToken}
                        onChange={(e) => onUserDataChange(e)}
                        required
                        placeholder="google token"
                        name="isGoogleToken"
                        useGrouping={false}
                      />
                    </div>


                  </div>
                  <div className="flex  flex-column  col-4 ">


                    <div className="flex align-items-center ">
                      <Checkbox onChange={(e) => setIsAppleVerified(e.checked)} checked={isAppleVerified}></Checkbox>
                      <label htmlFor="" className="ml-2">Apple Verified</label>
                    </div>

                    <div className="field   ">

                      <InputText
                        id="isApple"
                        value={userData.isAppleToken}
                        onChange={(e) => onUserDataChange(e)}
                        required
                        placeholder="apple token"
                        name="isAppleToken"
                        useGrouping={false}
                      />
                    </div>


                  </div>

                  <div className="flex  flex-column  col-4 ">


                    <div className="flex align-items-center ">
                      <Checkbox onChange={(e) => setIsAppleVerified(e.checked)} checked={isFaceBookVerified}></Checkbox>
                      <label htmlFor="" className="ml-2">Facebook Verified</label>
                    </div>

                    <div className="field   ">

                      <InputText
                        id="isFacebook"
                        value={userData.isFaceToken}
                        onChange={(e) => onUserDataChange(e)}
                        placeholder="facebook token"
                        required
                        name="isFaceToken"
                        useGrouping={false}
                      />
                    </div>


                  </div>
                </div>
              </>
            )}
            {type === "edit" &&
              <div className="flex align-item-center gap-3 ">
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  className=""
                  style={{ background: "#c70000" }}
                  onClick={() => dispatch(changeType("table"))}
                />
                <Button
                  label="Update"
                  icon=""
                  style={{ background: "#3f6ad8" }}
                  disabled={editLoader}
                  loading={editLoader}
                  onClick={handleEdit}
                />

              </div>
            }



          </div>

          <div className="col-4 flex justify-content-center  mt-4">
            {type === "edit" &&
              <div className="field col-4 flex flex-column align-items-center">
                <label className="text-sm">Add  your Photo</label>
                <img src={uploadImage ? URL.createObjectURL(uploadImage) : isUserPhoto ? isUserPhoto : "https://sales.ufaber.com/public/static/website/updated_teacherpanel/asset/img/images/dummy-avatar.jpg"}
                  style={{ width: 200, height: 200 }}
                />
                <input type="file" id="fileUpload" accept="image/*" style={{ display: "none" }}
                  onChange={(event) => handleChangeUserImage(event)} />
                <label htmlFor="fileUpload" className="mt-3 bg-primary py-2 border-round-sm cursor-pointer px-3 ">Upload</label>
              </div>}
          </div>










        </div>

      </>
    );
  };

  const handleChangeTab = (e) => {
    setActiveIndexTab(e.index);
    // getData(userDetail.id, 0, 10, tabVal[e.index]);
  };

  const renderTab = (heading) => {

    return (
      <TabPanel header={heading}>
        {
          <div className="row">

            <div className="col-lg-12">
              <Card style={{ marginBottom: "20px" }}>
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <ProgressSpinner />
                  </div>
                ) : (
                  <CrudDataTable

                    data={users && users.length > 0 ? users : []}
                    totalRecords={totalRecords}
                    headerColumns={DATA}
                    dialogHeader="User Details"
                    addEditFunction={handleAddEdit}
                    tableTitle="Users"
                    showToolbar={true}
                    showActions={true}
                    showEdit={true}
                    showView={true}
                    handleView={handleView}
                    showDelete={heading == "Admin Users" ? true : false}
                    showExport={false}
                    showSearch={false}
                    sortable={false}
                    renderDialog={renderDialog}
                    deleteSelectedItem={deleteSelectedItem}
                  />
                )}
                {type === "table" && <Pagination
                  totalRecords={totalRecords}
                  customFirst={first}
                  customRows={rows}
                  onPageChange={onCustomPageChange}
                />}
              </Card>
            </div>
          </div>
        }
      </TabPanel>
    );
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="app-main">
        <Sidebar active="users" />
        <div className="app-main__outer" style={{ overflowX: "auto" }}>
          <div className="app-main__inner">
            <Banner
              title="All Users - Staywo"
              icon="pe-7s-users icon-gradient bg-mean-fruit"
            />

            <TabView
              activeIndex={activeIndexTab}
              onTabChange={(e) => handleChangeTab(e)}
              className="users-tab-style"
            >
              {renderTab("Customers", "customers")}
              {renderTab("Admin Users", "adminUsers")}
            </TabView>
          </div>
        </div>
        <UserStatusDialog getData={getData} customRows={rows} ref={dialogRef} />
      </div>
    </>
  );
};

export default UsersList;
