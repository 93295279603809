import React, { useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import Pagination from "./../../components/pagination";
import moment from "moment";
import { priceBodyTemplate } from "../../constants/utils";

const OwnerSection = ({
  getBookingByOwner,
  totalRecordsOwner,
  ownerData,
  ownerLoading,
  userDetail,
  bookingStatus,
}) => {
  const [firstOwner, setFirstOwner] = useState(0);
  const [rowsOwner, setRowsOwner] = useState(10);
  const onChangeByOwner = (event) => {
    setFirstOwner(event.first);
    setRowsOwner(event.rows);
    getBookingByOwner(userDetail.id, event.page, event.rows, bookingStatus);
  };

  return (
    <>
      {ownerLoading ? (
        <div style={{ textAlign: "center" }}>
          <ProgressSpinner />
        </div>
      ) : (
        <div className="row">
          {ownerData.length > 0 ? (
            ownerData.map((item) => {
              return (
                <div className="col-sm-4">
                  <div
                    style={{
                      background: "#fff",
                      padding: "24px",
                      marginBottom: "10px",
                      border: "1px solid #00000030",
                    }}
                  >
                    {item?.property?.thumbnailUrl != null && (
                      <img
                        src={item?.property?.thumbnailUrl}
                        width="100%"
                        height="265px"
                      />
                    )}
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Property Title: </h6>
                      <span className="address-style">
                        {item.property.title}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Guest Name: </h6>
                      <span className="address-style">
                        {item.guest.firstName} {item.guest.lastName}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Total Price: </h6>
                      <span className="address-style">
                        {priceBodyTemplate(item.totalAmount)}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Start Date: </h6>
                      <span className="address-style">
                        {moment(item?.startDate).format("DD MMM YYYY")}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>End Date: </h6>
                      <span className="address-style">
                        {moment(item?.endDate).format("DD MMM YYYY")}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Cleaning Charges: </h6>
                      <span className="address-style">
                        {priceBodyTemplate(
                          item?.booking_detail[0]?.cleaningCharges
                        )}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Per Night Price: </h6>
                      <span className="address-style">
                        {priceBodyTemplate(item.perNightPrice)}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Total Guests: </h6>
                      <span className="address-style">{item.totalguests}</span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Total Adults: </h6>
                      <span className="address-style">{item.totalAdults}</span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Total Children: </h6>
                      <span className="address-style">
                        {item.totalChildren}
                      </span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Total Infants: </h6>
                      <span className="address-style">{item.totalInfants}</span>
                    </div>
                    <div style={{ marginTop: "10px", display: "flex" }}>
                      <h6 style={{ fontWeight: "600" }}>Total Pets: </h6>
                      <span className="address-style">{item.totalPets}</span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-sm-4">
              <h3>No Record Found</h3>
            </div>
          )}
        </div>
      )}
      <Pagination
        totalRecords={totalRecordsOwner}
        customFirst={firstOwner}
        customRows={rowsOwner}
        onPageChange={(e) => onChangeByOwner(e)}
      />
    </>
  );
};

export default OwnerSection;
