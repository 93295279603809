import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getUsedCouponById = (id) => {
  return Api(
    `${endPoints.getUsedCouponById}/${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const deleteUsedCoupon = (id) => {
  return Api(
    `${endPoints.deleteCoupon}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
