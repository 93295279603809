import React, { useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import './style.css';
import { useAuth } from '../../components/hooks/useAuth';
import { Navigate, useNavigate } from 'react-router-dom';
import { adminLogin } from './../../services/auth/index';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

const LoginForm = () => {
  const navigate = useNavigate();
  const { login, user } = useAuth();
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    email: '',
    password: '',
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    let payload = {
      email: data.email,
      password: data.password,
      device_token:
        'd5KrF0JwAjOPt10_j0OrZc:APA91bGCV3oRQn2WyguWNBBLVPFWuEl9WLxF-4zsiGKrqzJ_VT7x8zWv7UiBRR_dp5Z7T7vx8mTOnieFYqmo7Yi8SuHe2Z-xLWNlQ0_1ee5Iu3yFVYQllWY1Eg5NZJKRxjo7nnbnU5Kd',
      deviceType: 'WEB',
    };

    setIsLoading(true);
    adminLogin(payload).then((res) => {
      console.log(res, 'ajshdkjsadkjsakdhkja');
      setIsLoading(false);

      if (res.data.statusCode == 400 || res.data.statusCode == 401) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: res.data.message,
          life: 3000,
        });
      } else {
        let userData = res.data.data.user;
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('token', JSON.stringify(res.data.data.token));
        login(userData);
        reset();
      }
    });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className='p-error'>{errors[name].message}</small>
    );
  };

  if (user) {
    return <Navigate to='/' replace />;
  }

  return (
    <div
      className='form-demo'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Toast ref={toast} position='bottom-right' />
      <div className='flex justify-content-center'>
        <div className='card' style={{ padding: '20px' }}>
          <h5 className='text-center'>Admin Login</h5>
          <form onSubmit={handleSubmit(onSubmit)} className='p-fluid'>
            <div className='field'>
              <span className='p-float-label p-input-icon-right'>
                <i className='pi pi-envelope' />
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: 'Email is required.',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Invalid email address. E.g. example@email.com',
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        'p-invalid': fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor='email'
                  className={classNames({ 'p-error': !!errors.email })}
                >
                  Email*
                </label>
              </span>
              {getFormErrorMessage('email')}
            </div>
            <div className='field'>
              <span className='p-float-label'>
                <Controller
                  name='password'
                  control={control}
                  rules={{ required: 'Password is required.' }}
                  render={({ field, fieldState }) => (
                    <Password
                      id={field.password}
                      {...field}
                      feedback={false}
                      toggleMask
                      className={classNames({
                        'p-invalid': fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor='password'
                  className={classNames({ 'p-error': errors.password })}
                >
                  Password*
                </label>
              </span>
              {getFormErrorMessage('password')}
            </div>
            <div style={{ textAlign: 'right', marginBottom: '5px' }}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/forgotPassword')}
              >
                Forgot Password?
              </span>
            </div>

            {isLoading ? (
              <div style={{ textAlign: 'center' }}>
                <ProgressSpinner style={{ width: '80px', height: '80px' }} />
              </div>
            ) : (
              <Button type='submit' label='Submit' className='mt-2' />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
