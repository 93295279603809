import React, { useState, useRef, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { updateGuestInfoById } from "./../../services/properties/index";
import { InputTextarea } from "primereact/inputtextarea";

const GuestInfoSectin = ({ propertyDetails }) => {
  const toast = useRef(null);
  const [isLoadingGuestInfo, setIsLoadngGuestInfo] = useState(false);
  let token = JSON.parse(localStorage.getItem("token"));
  const [inputVal, setInputVal] = useState({
    interactionWithGuest: "",
    checkInInstruction: "",
    guestManual: "",
    direction: "",
    wifiName: "",
    wifiPass: "",
  });

  useEffect(() => {
    setInputVal({
      interactionWithGuest: propertyDetails.interactionWithGuest,
      checkInInstruction: propertyDetails.checkInInstruction,
      guestManual: propertyDetails.guestManual,
      direction: propertyDetails.direction,
      wifiName: propertyDetails.wifiUsername,
      wifiPass: propertyDetails.wifiPassword,
    });
  }, [propertyDetails]);

  const handleChange = (e) => {
    setInputVal({ ...inputVal, [e.target.name]: e.target.value });
  };

  const handleUpateGuestInfo = () => {
    let payload = {
      interactionWithGuest: inputVal.interactionWithGuest,
      address: inputVal.address,
      direction: inputVal.direction,
      guestManual: inputVal.guestManual,
      checkInInstruction: inputVal.checkInInstruction,
      wifiUsername: inputVal.wifiName,
      wifiPassword: inputVal.wifiPass,
    };

    setIsLoadngGuestInfo(true);
    updateGuestInfoById(propertyDetails.id, payload, token).then((res) => {
      setIsLoadngGuestInfo(false);

      if (res.data.statusCode == 400) {
        let tempArr = [];
        res?.data?.message.map((val, ind) => {
          tempArr.push({
            severity: "error",
            summary: "Error",
            detail: val,
            life: 3000,
          });
        });
        toast.current.show(tempArr);
      } else {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Guest Info Updated Successfully",
          life: 3000,
        });
      }
    });
  };

  return (
    <div className="col-lg-12">
      <Toast ref={toast} position="bottom-right" />
      <Accordion activeIndex={7}>
        <AccordionTab header="Guest Info">
          <h5 style={{ marginTop: "15px" }}></h5>
          <div className="row">
            {/* <div className="col-lg-6">
              <h5 style={{ marginTop: "10px" }}>Pre-Booking Configration</h5>
              <InputTextarea
                value={inputVal.interactionWithGuest}
                onChange={handleChange}
                style={{ width: "100%" }}
                name="interactionWithGuest"
                rows={5}
                cols={30}
              />
            </div> */}

            {/* <div className="col-lg-6">
              <h5 style={{ marginTop: "10px" }}>Post-Booking Configuration</h5>
              <InputTextarea
                value={inputVal.guestManual}
                onChange={handleChange}
                style={{ width: "100%" }}
                name="guestManual"
                rows={5}
                cols={30}
              />
            </div> */}
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Check In Instruction</h6>
              <InputTextarea
                value={inputVal.checkInInstruction}
                onChange={handleChange}
                style={{ width: "100%" }}
                name="checkInInstruction"
                rows={5}
                cols={30}
              />
            </div>
            {/* <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Direction</h6>
              <InputTextarea
                value={inputVal.direction}
                onChange={handleChange}
                style={{ width: "100%" }}
                name="direction"
                rows={5}
                cols={30}
              />
            </div> */}

            {/* <div className="col-lg-12">
              <h5 style={{ marginTop: "10px" }}>
                Arrival Details - Wifi Details
              </h5>
            </div>
            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Wifi Name</h6>
              <InputText
                value={inputVal.wifiName}
                placeholder="Wifi Name"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="wifiName"
              />
            </div>

            <div className="col-lg-6">
              <h6 style={{ marginTop: "10px" }}>Wifi Password</h6>
              <InputText
                value={inputVal.wifiPass}
                placeholder="Wifi Password"
                onChange={handleChange}
                style={{ width: "100%" }}
                name="wifiPass"
              />
            </div> */}
          </div>
          <div style={{ margin: "10px 0", textAlign: "right" }}>
            {isLoadingGuestInfo ? (
              <ProgressSpinner />
            ) : (
              <Button
                label={"Save Guest Info"}
                className="p-button-lg"
                style={{ fontSize: "12px", padding: "10px 8px" }}
                onClick={handleUpateGuestInfo}
              />
            )}
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default GuestInfoSectin;
