import axios from "axios";
import { apiHeaders } from "../constants/variables";
// let baseUrl = "http://api.staywo.com:3001/";
import { API_URL } from "../config";

const api = async (path, params, method, imageType, token) => {
  let options;
  options = {
    headers: {
      "Content-Type": imageType
        ? apiHeaders.multipart_data
        : apiHeaders.application_json,
      ...(token !== null && { Authorization: `Bearer ${token}` }),
    },
    method: method,
    ...(params && { data: imageType ? params : JSON.stringify(params) }),
  };
  console.log("options", params);
  return axios(API_URL + path, options)
    .then((response) => {
      return response;
    })
    .catch(async (error) => {
      return error.response;
    });
};

export default api;
