import React, { useState, useEffect, useRef } from "react";
import Banner from "../../components/banner";
import { Card } from "primereact/card";
import Sidebar from "../../components/sidebar";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  getAllPermissions,
  addRole,
  editRoleById,
  deleteRoleById,
} from "./../../services/permissions/index";
import CrudDataTable from "../../components/crudDataTable";
import Pagination from "../../components/pagination";
import { Toast } from "primereact/toast";
import "primeflex/primeflex.css";
import "../properties/style.css";
import PermissionsDialog from "./PermissionsDialog";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

const Permissions = () => {
  const toast = useRef(null);
  const dialogRef = useRef();
  const [allPermissions, setAllPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  let emptyProduct = {
    name: "",
    description: "",
    permissions: "",
  };
  const user_token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    getAllPermissions(user_token)
      .then((res) => {
        setIsLoading(false);
        setTotalRecords(res.data.total);
        setAllPermissions(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleAddEdit = (product, setProduct, setProductDialog, type) => {
    if (
      product.name == "" ||
      product.permissions == "" ||
      product.description == ""
    ) {
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "name, permission & description is required",
        life: 3000,
      });
    } else {
      let payload = {
        name: product.name,
        description: product.description,
        permissions: product.permissions,
      };

      if (type === "add") {
        setIsLoading(true);
        addRole(payload, user_token).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: "error",
                summary: "Error",
                detail: val,
                life: 3000,
              });
            });
            setProductDialog(false);
            toast.current.show(tempArr);
          } else {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Role Created Successfully",
              life: 3000,
            });
            setProduct({
              name: "",
              description: "",
              permissions: "",
            });
            getData(0, 10);
            setTimeout(() => {
              setProductDialog(false);
            }, 1500);
          }
        });
      } else {
        setIsLoading(true);
        editRoleById(product.id, payload, user_token).then((res) => {
          setIsLoading(false);
          if (res.data.statusCode == 400) {
            let tempArr = [];
            res?.data?.message.map((val, ind) => {
              tempArr.push({
                severity: "error",
                summary: "Error",
                detail: val,
                life: 3000,
              });
            });
            setProductDialog(false);
            toast.current.show(tempArr);
          } else {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Roles Updated Successfully",
              life: 3000,
            });
            setProduct({
              name: "",
              description: "",
              permissions: "",
            });
            getData(0, 10);
            setTimeout(() => {
              setProductDialog(false);
            }, 1500);
          }
        });
      }
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div style={{ display: "flex" }}>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => dialogRef.current.isVisible(rowData)}
        />
      </div>
    );
  };

  const DATA = [
    { id: 1, field: "module", header: "Module" },
    { id: 2, header: "Actions", body: actionBodyTemplate },
  ];

  const onCustomPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    getData(event.page + 1, event.rows);
  };

  const deleteSelectedItem = (id, setOpenDialog) => {
    setIsLoading(true);
    deleteRoleById(id, user_token).then((res) => {
      setIsLoading(false);
      if (res.data.statusCode == 400) {
        let tempArr = [];
        res?.data?.message.map((val, ind) => {
          tempArr.push({
            severity: "error",
            summary: "Error",
            detail: val,
            life: 3000,
          });
        });
        setOpenDialog(false);
        toast.current.show(tempArr);
      } else {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Role Deleted Successfully",
          life: 3000,
        });
        getData(0, 10);
        setTimeout(() => {
          setOpenDialog(false);
        }, 1500);
      }
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Add"
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={() => dialogRef.current.isVisible({})}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      <Toast ref={toast} position="bottom-right" />
      <div className="app-main">
        <Sidebar active="permissions" />
        <div className="app-main__outer" style={{ overflowX: "auto" }}>
          <div className="app-main__inner">
            <Banner
              title="Permissions - Staywo"
              icon="pe-7s-portfolio icon-gradient bg-mean-fruit"
            />

            <div className="row">
              <div className="col-lg-12">
                <Card style={{ marginBottom: "20px" }}>
                  {/* <Toolbar
                    className="mb-4"
                    left={leftToolbarTemplate}
                  ></Toolbar> */}

                  {isLoading ? (
                    <div style={{ textAlign: "center" }}>
                      <ProgressSpinner />
                    </div>
                  ) : (
                    <CrudDataTable
                      emptyProduct={emptyProduct}
                      data={
                        allPermissions && allPermissions.length > 0
                          ? allPermissions
                          : []
                      }
                      totalRecords={totalRecords}
                      headerColumns={DATA}
                      dialogHeader="Permission Details"
                      addEditFunction={handleAddEdit}
                      deleteSelectedItem={deleteSelectedItem}
                      tableTitle="Permissions"
                      showToolbar={false}
                      showActions={false}
                      showEdit={true}
                      showDelete={false}
                      showView={false}
                      showExport={false}
                      showSearch={false}
                      sortable={false}
                    />
                  )}

                  {/* <Pagination
                    totalRecords={totalRecords}
                    customFirst={first}
                    customRows={rows}
                    onPageChange={onCustomPageChange}
                  /> */}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PermissionsDialog ref={dialogRef} />
    </>
  );
};

export default Permissions;
