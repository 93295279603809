import React, { useState } from "react";
import Banner from "../../components/banner";
import Card from "../../components/card";
import Table from "../../components/dataTable";
import InfoBar from "../../components/infobar";
import Sidebar from "../../components/sidebar";
import { Rating } from "primereact/rating";
import { data } from "../../fakeService/productSmall";

const UnderClaimProperties = () => {
  const [products, setProducts] = useState(data);

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={require(`../../images/${rowData.image}`)}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={rowData.image}
        className="product-image"
      />
    );
  };

  const priceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.price);
  };

  const ratingBodyTemplate = (rowData) => {
    return <Rating style={{color: "#ffcc01"}} value={rowData.rating} readOnly cancel={false} />;
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span
        className={`product-badge status-instock`}
      >
        {rowData.inventoryStatus}
      </span>
    );
  };

  const DATA = [
    { id: 1, field: "propertyId", header: "Id" },
    { id: 2, field: "image", header: "Image", body: imageBodyTemplate },
    { id: 3, field: "propertyName", header: "Name" },
    { id: 4, field: "propertyOwner", header: "Owner" },
    { id: 5, field: "price", header: "Price", body: priceBodyTemplate },
    { id: 6, field: "rating", header: "Rating", body: ratingBodyTemplate },
    { id: 7, field: "status", header: "Status", body: statusBodyTemplate },
  ];


  return (
    <div className="app-main">
      <Sidebar active="underClaimProperties" />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Banner
            title="All Properties - Staywo"
            icon="pe-7s-plugin icon-gradient bg-mean-fruit"
          />
          <div className="row">
            <div className="col-lg-4">
              <InfoBar
                title={"Claimed Properties"}
                subTitle={"Total Properties"}
                variant={"widget-numbers text-danger"}
                status={0}
              />
            </div>
            <div className="col-lg-4">
              <InfoBar
                title={"Claimed Properties"}
                subTitle={"Diamond Host Properties"}
                variant={"widget-numbers text-warning"}
                status={0}
              />
            </div>
            </div>
            
          <div className="row">
            <div className="col-lg-12">
              <Card title="Under Claim Properties">
                <Table columns={DATA} data={products} />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderClaimProperties;
