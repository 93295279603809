export const statusArr = [
  { label: "Listed", value: "LISTED" },
  { label: "Unlisted", value: "UNLISTED" },
  { label: "Suspended", value: "SUSPENDED" },
  { label: "Inprogress", value: "INPROGRESS" },
  { label: "Pending", value: "PENDING" },
];
export const verifyUsersstatusArr = [
  { label: "PENDING", value: "PENDING" },
  { label: "APPROVED", value: "APPROVED" },
  { label: "REJECTED", value: "REJECTED" },
];

export const TIMINGS = [
  { label: "1 A.M", value: "1 A.M" },
  { label: "2 A.M", value: "2 A.M" },
  { label: "3 A.M", value: "3 A.M" },
  { label: "4 A.M", value: "4 A.M" },
  { label: "5 A.M", value: "5 A.M" },
  { label: "6 A.M", value: "6 A.M" },
  { label: "7 A.M", value: "7 A.M" },
  { label: "8 A.M", value: "8 A.M" },
  { label: "9 A.M", value: "9 A.M" },
  { label: "10 A.M", value: "10 A.M" },
  { label: "11 A.M", value: "11 A.M" },
  { label: "12 A.M", value: "12 A.M" },
  { label: "1 P.M", value: "1 P.M" },
  { label: "2 P.M", value: "2 P.M" },
  { label: "3 P.M", value: "3 P.M" },
  { label: "4 P.M", value: "4 P.M" },
  { label: "5 P.M", value: "5 P.M" },
  { label: "6 P.M", value: "6 P.M" },
  { label: "7 P.M", value: "7 P.M" },
  { label: "8 P.M", value: "8 P.M" },
  { label: "9 P.M", value: "9 P.M" },
  { label: "10 P.M", value: "10 P.M" },
  { label: "11 P.M", value: "11 P.M" },
  { label: "12 P.M", value: "12 P.M" },
  { label: "Flexible", value: "Flexible" },
];
