import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllSecurities = () => {
  return Api(`${endPoints.allSecurities}`, null, requestType.GET, null, null);
};

export const addSecurity = (params) => {
  return Api(`${endPoints.addSecurity}`, params, requestType.POST, null, null);
};

export const editSecurityById = (id, params) => {
  return Api(
    `${endPoints.editSecurity}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};

export const deleteSecurityById = (id) => {
  return Api(
    `${endPoints.deleteSecurity}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};

// Group Type

export const getAllSecuritiesGroup = () => {
  return Api(
    `${endPoints.allSecuritiesGroup}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addSecurityGroup = (params) => {
  return Api(
    `${endPoints.addSecurityGroup}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editSecurityGroupById = (id, params) => {
  return Api(
    `${endPoints.editSecurityGroup}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteSecurityGroupById = (id) => {
  return Api(
    `${endPoints.deleteSecurityGroup}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
