import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

//Safety Feature Group

export const getAllSafetyFeatureGroup = () => {
  return Api(
    `${endPoints.safetyFeaturegroup}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addSafetyFeatureGroup = (params) => {
  return Api(
    `${endPoints.addSafetyFeaturegroup}`,
    params,
    requestType.POST,
    null,
    null
  );
};

export const editSafetyFeatureGroup = (id, params) => {
  return Api(
    `${endPoints.editSafetyFeaturegroup}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteSafetyFeatureGroup = (id) => {
  return Api(
    `${endPoints.deleteSafetyFeaturegroup}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};

//Safety Feature

export const getAllFeatures = () => {
  return Api(
    `${endPoints.getAllSafetyFeatures}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addSafetyFeatures = (params) => {
  return Api(
    `${endPoints.addSafetyFeatures}`,
    params,
    requestType.POST,
    null,
    null
  ); 
};

export const editSafetyFeatures = (id, params) => {
  return Api(
    `${endPoints.editSafetyFeatures}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteSafetyFeatures = (id) => {
  return Api(
    `${endPoints.deleteSafetyFeatures}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
