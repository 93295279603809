import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllCoupons = () => {
  return Api(`${endPoints.getAllCoupons}`, null, requestType.GET, null, null);
};

export const getCouponById = (id) => {
  return Api(
    `${endPoints.getCouponById}/${id}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addCoupon = (params) => {
  return Api(`${endPoints.addCoupon}`, params, requestType.POST, null, null);
};

export const editCoupon = (id, params) => {
  return Api(
    `${endPoints.editCoupon}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deleteCoupon = (id) => {
  return Api(
    `${endPoints.deleteCoupon}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
