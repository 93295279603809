import React, { useMemo } from "react";
import GoogleMapReact from "google-map-react";
import  {FaMapMarkerAlt}  from 'react-icons/fa';
const AnyReactComponent = ({ text }) => <div><FaMapMarkerAlt size={40} color={'red'} /><p>{text}</p></div>;

export default function SimpleMap({ mapValues }) {
  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
  console.log(mapValues,"ddddddddd")
  const renderMarkers = (map, maps) => {
    
    let marker = new maps.Marker({
      position: {
        lat: mapValues[0].lat ? mapValues[0].lat : 24.8607343,
        lng: mapValues[0].lng ? mapValues[0].lng : 67.0011364,
      },
      map,
      title: "Hello World!",
    });
    return marker;
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "300px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsKey }}
        center={{
          lat: mapValues[0].lat ? mapValues[0].lat : 0,
          lng: mapValues[0].lng ? mapValues[0].lng : 0,
        }}
        defaultZoom={15}
        gestureHandling="none"
        // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      >
        <AnyReactComponent
        key={mapValues[mapValues.length]}
          lat={mapValues[0].lat ? mapValues[0].lat : 0}
          lng={mapValues[0].lng ? mapValues[0].lng : 0}
          
        />
      </GoogleMapReact>
    </div>
  );
}
