import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllRoles = (page, limit, token) => {
  return Api(
    `${endPoints.allRoles}?page=${page}&limit=${limit}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addRole = (params, token) => {
  return Api(`${endPoints.addRole}`, params, requestType.POST, null, token);
};
export const editRoleById = (id, params, token) => {
  return Api(
    `${endPoints.editRole}/${id}`,
    params,
    requestType.PATCH,
    null,
    token
  );
};

export const deleteRoleById = (id, token) => {
  return Api(
    `${endPoints.deleteRole}/${id}`,
    null,
    requestType.DELETE,
    null,
    token
  );
};
