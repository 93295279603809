import Api from "../index";
import { endPoints, requestType } from "../../constants/variables";

export const getAllPages = (order, page, take) => {
  return Api(
    `${endPoints.getAllPages}?order=${order}&page=${page}&take=${take}`,
    null,
    requestType.GET,
    null,
    null
  );
};

export const addPage = (params) => {
  return Api(`${endPoints.addPage}`, params, requestType.POST, null, null);
};

export const editPage = (id, params) => {
  return Api(
    `${endPoints.editPage}/${id}`,
    params,
    requestType.PUT,
    null,
    null
  );
};
export const deletePage = (id) => {
  return Api(
    `${endPoints.deletePage}/${id}`,
    null,
    requestType.DELETE,
    null,
    null
  );
};
